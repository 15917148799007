import React, { useState } from 'react';
import CheckboxInput from '../../../components/Input/CheckboxInput';
import CounterInput from '../../../components/Input/CounterInput';
import ErrorIconRed from '../../../assets/icons/ErrorIconRed.svg';

/**
 * CheckBoxCounter component description.
 *
 * @CheckBoxCounter
 *
 */

interface CheckBoxCounterProps {
  className?: string;
  label: string;
  ischecked?: boolean;
  name: string;
  id?: string;
  isPrincipal?: boolean;
  initialCounter: number;
  setCounter?: React.Dispatch<React.SetStateAction<number>>;
  prefix: string;
  onChangeCounter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editItem?: boolean;
  deleteItem?: boolean;
  haserror?: boolean;
  errorMsg?: string;
}
const CheckBoxCounter: React.FC<CheckBoxCounterProps> = ({
  label,
  name,
  id,
  className,
  isPrincipal,
  initialCounter,
  prefix,
  onChangeCounter,
  editItem,
  deleteItem,
  haserror,
  errorMsg,
}) => {
  const [counter, setCounter] = useState<number>(initialCounter);
  const [title, setTitle] = useState<string>('');
  return (
    <div
      className={`CheckBoxCounter fl_col gp8 w-full ${haserror ? 'haserror' : ''}`}
    >
      <div className="fl_col gp8 w-full">
        <CheckboxInput
          label={label}
          name={name}
          id={id}
          className={className}
          //ischecked={ischecked}
          isPrincipal={isPrincipal}
          onChangeCounter={onChangeCounter}
          editItem={editItem}
          deleteItem={deleteItem}
          title={title}
          setTitle={setTitle}
          haserror={haserror}
          errorMsg={errorMsg}
        />
        <div className="fl_row ai_c gp6 CounterInputField">
          <CounterInput
            id={id}
            prefix={prefix}
            counter={counter}
            setCounter={setCounter}
            onChangeCounter={onChangeCounter}
          />
          {haserror && (
            <img className="fail-img" src={ErrorIconRed} alt="" width={24} />
          )}
        </div>
      </div>
      {haserror && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
    </div>
  );
};

export default CheckBoxCounter;
