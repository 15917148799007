import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CloseBtnPrimary from '../../assets/icons/CloseBtnPrimary.svg';
import EditPenPrimaryIcon from '../../assets/icons/EditPenPrimaryIcon.svg';
interface CheckboxInputProps {
  className?: string;
  label?: string;
  ischecked?: boolean;
  name?: string;
  id?: string;
  isPrincipal?: boolean;
  onChangeCounter?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editItem?: boolean;
  deleteItem?: boolean;
  title?: string;
  setTitle?: Dispatch<SetStateAction<string>>;
  haserror?: boolean;
  errorMsg?: string;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label,
  ischecked,
  name,
  id,
  className,
  isPrincipal,
  onChangeCounter,
  editItem,
  deleteItem,
  title,
  setTitle,
  haserror,
  errorMsg,
}) => {
  const [editableLabel, setEditableLabel] = useState(label || '');
  const [editItemButton, setEditItemButton] = useState(editItem || false);
  const [deleteItemButton, setDeleteItemButton] = useState(deleteItem || false);
  useEffect(() => {
    setEditItemButton(editItem ? editItem : false);
    setDeleteItemButton(deleteItem ? deleteItem : false);
  }, [editItem]);
  const onChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableLabel(e.target.value);
    setTitle && setTitle(e.target.value);
    onChangeCounter &&
      onChangeCounter({
        target: { id: id, name: title },
      } as React.ChangeEvent<HTMLInputElement>);
  };
  const handleEditClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setEditItemButton(false);
    setDeleteItemButton(true);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    const targetId = id;
    const targetName = name;
    if (onChangeCounter) {
      onChangeCounter({
        target: { id: targetId, name: targetName, value: 'delete' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div
      className={`CheckboxInput ai_c jc_s ${haserror ? 'haserror' : ''} ${className ? className : ''}`}
    >
      <div className="fl_row gp8 ai_c w-fit">
        <input
          autoComplete="off"
          className={isPrincipal ? 'principal' : ''}
          type="checkbox"
          id={id}
          name={name}
          checked={ischecked}
          onChange={onChangeCounter}
        />
        <div>
          {editItemButton === true ? (
            <input
              autoComplete="off"
              className="fielditem txt-nowrap p14 OxfordBlue02-txt w-500"
              type="text"
              value={editableLabel}
              onChange={onChangeLabel}
            />
          ) : (
            <label
              className="txt-nowrap p14 OxfordBlue02-txt w-500"
              htmlFor={id}
            >
              {label}
            </label>
          )}
        </div>
      </div>

      {editItemButton && (
        <img
          src={EditPenPrimaryIcon}
          alt=""
          onClick={(e: React.MouseEvent<HTMLImageElement>) =>
            handleEditClick(e)
          }
        />
      )}
      {deleteItemButton && (
        <img
          src={CloseBtnPrimary}
          alt=""
          id="delete"
          onClick={(e: React.MouseEvent<HTMLImageElement>) =>
            handleDeleteClick(e)
          }
        />
      )}
    </div>
  );
};

export default CheckboxInput;
