import React, { ReactNode } from 'react';

interface Tr {
  children: ReactNode;
}

const Tr: React.FC<Tr> = ({ children }) => {
  return <tr>{children}</tr>;
};

export default Tr;
