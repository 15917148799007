import React from 'react';

/**
 * FieldsAccessRightsItem component description.
 *
 * @FieldsAccessRightsItem
 *
 */

interface FieldsAccessRightsItemProps {
  name: string;
  status?: boolean;
  visibility?: boolean;
}

const FieldsAccessRightsItem: React.FC<FieldsAccessRightsItemProps> = ({
  name,
  status,
  visibility,
}) => {
  return (
    <div className="FieldsAccessRightsItem">
      <div className="field-wrapper fl_row jc_s pd10-t-b">
        <p className="p16 w-500">{name}</p>
        <div className="fl_row gp60">
          <div className="status-row">
            <input
              autoComplete="off"
              className="primaryCheckbox"
              type="checkbox"
              name=""
              id=""
            />
          </div>
          <div className="visibility-row">
            <input
              autoComplete="off"
              className="primaryCheckbox"
              type="checkbox"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldsAccessRightsItem;
