import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import Td from '../../../components/Table/Td-table';
import { languageData } from '../../../i18n';

export default function TableHeaderBilling({}) {
  return (
    <Thead>
      <Tr>
        <Td
          type="text"
          value={languageData.fr.reference}
          width={60}
          align="left"
        />
        <Td
          type="text"
          value={languageData.fr.Total_TTC}
          width={6}
          align="center"
        />
        <Td
          type="text"
          value={languageData.fr.Date_demission}
          width={6}
          align="center"
        />
        <Td
          type="text"
          value={languageData.fr.Date_de_paiement}
          width={6}
          align="center"
        />
        <Td type="text" value={languageData.fr.Etat} width={6} align="center" />
        <Td type="empty" width={2} />
      </Tr>
    </Thead>
  );
}
