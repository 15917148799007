import React from 'react';
import { languageData } from '../../i18n';
import MarketplaceIconGrey from '../../assets/icons/MarketplaceIconGrey.svg';
import EditIconTransparentBleu from '../../assets/icons/EditIconTransparentBleu.svg';
import PlusIconBlack from '../../assets/icons/PlusIconBlack.svg';
import TrashIconRed from '../../assets/icons/TrashIconRed.svg';
import PermissionValue from '../../components/Permissions/PermissionItem';
import { useDispatch, useSelector } from 'react-redux';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import { calculateTotalPermissionEdit } from '../../utils/roleUtils';
import { serviceRolesModels } from '../../models/services/module.role';
import Modal from '../../components/Modal/Modal';
import { setUserFormDataDetails } from '../../store/actions/actions';
import SkeletonLoader from '../../components/SkeletonLoader';
interface rowProps {
  rowId: string;
  showRoleModal: () => void;
  showmodalAccess: (rowId: string) => void;
  handleAddPermissionRow: () => void;
  handleDeletePermissionRow: () => void;
  checker?: boolean;
  roles?: serviceRolesModels.Role[];
  row: any;
}
export default function EditPermissionRoleRow(props: rowProps) {
  const [modalDelete, SetmodalDelete] = React.useState<boolean>(false);
  const [rowId, setRowId] = React.useState<string>('1');
  const [roleToDelete, setRoleToDelete] = React.useState<string>();
  const dispatch = useDispatch();
  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormData,
  );
  const userFormValidationRequested = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userDetailsFormValidationRequested,
  );
  const enableAccessField = () => {
    const enabled =
      userFormData?.permissions[0]?.rolesId.length !== 0 ? true : false;
    return enabled;
  };

  const hasError = (type: 'access' | 'role') => {
    if (userFormValidationRequested) {
      const currentPermissions = userFormData.permissions.find(
        (item) => item.rowId === props.rowId,
      );
  
      if (!currentPermissions) {
        return false;
      }

      switch (type) {
        case 'role':
          // Check if there are no roles or the first role is '0'
          return (
            currentPermissions.rolesId.length === 0 ||
            currentPermissions.rolesId[0] === '0'
          );

        case 'access':
          // Check if both chainesIds and restaurantsIds are empty
          return (
            currentPermissions.chainesIds.length === 0 &&
            currentPermissions.restaurantsIds.length === 0
          );

        default:
          return false;
      }
    } else {
      return false;
    }
  };

  const handleDeleteRole = () => {
    const updatedPermissions = userFormData?.permissions.map((row) => {
      if (row.rowId === rowId) {
        const updatedRoles = row.rolesId.filter(
          (role) => role !== roleToDelete,
        );
        return { ...row, rolesId: updatedRoles };
      }
      return row;
    });
    const updatedForm = {
      ...userFormData,
      permissions: updatedPermissions,
    };
    dispatch(setUserFormDataDetails(updatedForm));
    SetmodalDelete((prev) => !prev);
  };
  const showmodalDelete = (id: string) => {
    SetmodalDelete((prev) => !prev);
    setRowId(rowId);
    setRoleToDelete(id);
  };
  return (
    <div className="EditPermissionRoleRow pd5">
      <div className="permissions-listing fl_col gp20 pd8-r-l">
        <div className="permission-card fl_row gp20 ">
          <div className="fields-wrapper fl_row gp40">
            <div className="role-field role-row fl_col gp5 card-skeleton">
              <div
                className={`field-container fl_row gp4 fl_wrap fl-1 ${hasError('role') ? 'error-table' : ''}`}
              >
                {(() => {
                  const rolesToDisplay = props.row.rolesId
                    .slice(0, 2)
                    .map((roleId: string) =>
                      props.roles?.find((role) => role.id === roleId),
                    );

                  return rolesToDisplay.map((role: any) =>
                    role ? (
                      <PermissionValue
                        key={role.id}
                        type="role"
                        name={role.name}
                        onClickRemove={() => showmodalDelete(role.id)}
                      />
                    ) : null,
                  );
                })()}

                {props.row.rolesId.length > 2 ? (
                  <PermissionValue
                    type="slot"
                    totalSlot={props.row.rolesId.length - 2}
                  />
                ) : null}

                <div className="showmore" onClick={props.showRoleModal}>
                  <img src={EditIconTransparentBleu} alt="" width={16} />
                </div>
              </div>
              <SkeletonLoader type={'card'} show={false} />
            </div>
          </div>
          <div
            className={`access-field access-row fl_col gp5 fl-1 card-skeleton ${
              !enableAccessField() ? 'fullyDisabled' : ''
            }`}
          >
            <div className="field-wrapper fl_row gp40 h-full jc_s w-full gp5">
              <div
                className={`field-container fl_row gp4 fl_wrap fl-1 ${
                  hasError('access') ? 'error-table' : ''
                }`}
              >
                <PermissionValue
                  type="access"
                  slot={calculateTotalPermissionEdit(props.row, 'chainesIds')}
                  name={languageData.global.user.Entreprises}
                  icon={MarketplaceIconGrey}
                />
                <PermissionValue
                  type="access"
                  slot={calculateTotalPermissionEdit(
                    props.row,
                    'restaurantsIds',
                  )}
                  name={languageData.global.user.Etablissements}
                  icon={MarketplaceIconGrey}
                />
                <div
                  className="showmore"
                  onClick={() => props.showmodalAccess(props.rowId)}
                >
                  <img src={EditIconTransparentBleu} alt="" width={16} />
                </div>
              </div>
              {hasError('access') && 'aaaaaaaaaaaaaaaaaaaaaaaaa'}
              <div className="tools fl_row gp10">
                {props.rowId === '0' ? (
                  <img
                    className="add c-p"
                    src={PlusIconBlack}
                    alt=""
                    width={20}
                    onClick={props.handleAddPermissionRow}
                  />
                ) : (
                  <></>
                )}

                {props.rowId !== '0' ? (
                  <img
                    className="delete c-p"
                    src={TrashIconRed}
                    alt=""
                    width={20}
                    onClick={props.handleDeletePermissionRow}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <SkeletonLoader type={'card'} show={false}/>
          </div>
        </div>
      </div>
      <Modal
        description={[languageData.global.user.deleteDescription]}
        title={languageData.global.user.deleteConfirm}
        visibility={modalDelete}
        maxWidth={544}
        height={181}
        version={'Alert'}
        onClickConfirm={handleDeleteRole}
        confirm
        cancel
      />
    </div>
  );
}
