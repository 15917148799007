import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState = {
  searchValue: null,
  newModalShown: false,
  siderBarMode: 'basic',
  menuSideBar: 'menus',
  reduceMainSideBar: false,
  colorPicker:false,
  loading:false,
  cartAdded:false
};

function reducer(
  state = initialState,
  action: { type: string; value: string },
) {
  switch (action.type) {
    case actionTypes.CHANGE_SEARCH_FILED: {
      return {
        ...state,
        searchValue: action.value,
      };
    }
    case actionTypes.CLEAR_SEARCH_FILED: {
      return {
        ...state,
        searchValue: null,
      };
    }
    case actionTypes.NEW_MODAL_SHOWN: {
      return {
        ...state,
        newModalShown: action.value,
      };
    }
    case actionTypes.CHANGE_SIDE_BAR_MODE: {
      return {
        ...state,
        sideBarMode: action.value,
      };
    }
    case actionTypes.CHANGE_SIDE_BAR_MENU_MODE: {
      return {
        ...state,
        menuSideBar: action.value,
      };
    }
    case actionTypes.REDUCE_MAIN_SIDE_BAR: {
      return {
        ...state,
        reduceMainSideBar: action.value,
      };
    }
    case actionTypes.SET_COLOR_PICKER: {
      return {
        ...state,
        colorPicker: action.value,
      };
    }
    case actionTypes.UPDATE_LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }
    case actionTypes.CART_UPDATE: {
      return {
        ...state,
        cartAdded: action.value,
      };
    }

    default:
      return state;
  }
}
export default reducer;
