import * as React from 'react';
const GlossaireIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <rect
      width={8.333}
      height={8.333}
      x={6.332}
      y={6.334}
      stroke="#2A66C6"
      strokeWidth={1.5}
      rx={2}
    />
    <path
      stroke="#2A66C6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.332 8.834H9.665"
    />
    <path
      stroke="#2A66C6"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M18.835 10.5a8.333 8.333 0 0 1-15.001 5m-1.666-5a8.333 8.333 0 0 1 15-5M3.833 15.5 3 18M17.168 5.5l.833-2.5"
    />
  </svg>
);
export default GlossaireIcon;
