import * as actionTypes from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    success: false,
};

const activateDisactivateEtablissmentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.ACTIVATE_DEACTIVATE_ESTABLISSEMENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.ACTIVATE_DEACTIVATE_ESTABLISSEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            };
        case actionTypes.ACTIVATE_DEACTIVATE_ESTABLISSEMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
export default activateDisactivateEtablissmentReducer