import React from 'react';
import InputField from '../Input/Input';
import LabelInput from '../Input/LabelInput';
import ButtonElement from '../button/button';

/**
 * CreditCardForm component description.
 *
 * @CreditCardForm
 *
 */

interface CreditCardFormProps {
  // labelCardName: string;
  // labelCardNumber: string;
  // labelCardDate: string;
  // labelCardCvc: string;
  placeholderCardName?: string;
  placeholderCardNumber?: string;
  minLength?: number;
  maxLength?: number;
  errorMsg?: string;
  haserror?: boolean;
  month?: string;
  year?: string;
  isDisabled?: boolean;
  errorText?: string;
  onClickPay?: () => void;
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  placeholderCardName,
  placeholderCardNumber,
  errorText,
  onClickPay,
  minLength,
  isDisabled,
  year,
  month,
  haserror,
}) => {
  return (
    <div className="CreditCardForm w-full fl-1">
      <div className="form-wrapper fl_col gp20">
        <InputField
          placeholder={placeholderCardName}
          name="sireN_code"
          isRequired={true}
          // value={newFormData?.fiscalInformation?.sireN_code}
          type="number"
          label={'Nom sur la carte'}
          maxLength={9}
          // onChange={handleChange}
          style="form"
          // haserror={sirenError ?? false}
          // errorMsg={sirenErrorMsg}
          siren
        />
        {/* <LabelInput label={labelCardNumber} /> */}
        <div className="fl_col gp4">
          <InputField
            placeholder={placeholderCardNumber}
            isRequired={true}
            name="card_number"
            type="number"
            label={'Numéro CB'}
            style="card"
            isDisabled={false}
            maxLengthCardNumber={16}
            maxLength={16}
            className="haserror"
            haserror={haserror}
          />
          {haserror && <p className="p14 Carnelian-txt">{errorText}</p>}
        </div>
        <div className="date-cvc fl_col gp10">
          <div className="fl_row gp16">
            <div className="date-card fl_col gp8">
              <LabelInput label={'Expire le'} />
              <div className="inp fl_row ai_c rd8">
                <input
                  autoComplete="off"
                  name="date"
                  type="number"
                  className={`p14 pd5-r-l rd8 fl-1`}
                  disabled={isDisabled}
                  min="0"
                  minLength={minLength}
                  maxLength={12}
                  value={month}
                />
                <span>/</span>
                <input
                  autoComplete="off"
                  name="date"
                  type="number"
                  className={`p14 pd5-r-l rd8 fl-2`}
                  disabled={isDisabled}
                  value={month}
                />
              </div>
            </div>
            <div className="cvc-card fl_col gp8">
              <LabelInput label={'CVC'} />
              <div className="inp">
                <input
                  autoComplete="off"
                  name="cvc"
                  type="password"
                  className={`p14 pd14-r-l rd8`}
                  disabled={isDisabled}
                  min="0"
                  minLength={minLength}
                  maxLength={3}
                  value={year}
                />
              </div>
            </div>
          </div>
        </div>
        <ButtonElement
          className="w-full"
          type="TextButton"
          textSize="p18"
          text="Payer"
          textColor="white-txt"
          buttonSize="full"
          radius="rd12"
          onClick={onClickPay}
          buttonStyle="btn-primary"
        />
      </div>
    </div>
  );
};

export default CreditCardForm;
