import React, { ChangeEvent } from 'react';
import { languageData } from '../../../i18n';
import InputField from '../../Input/Input';
import ButtonElement from '../../button/button';
import RadioBoxInput from '../../Input/RadioBoxInput';
import RadioBoxInputOption from '../../Input/RadioBoxInputOption';

/**
 * AddMachineTypeModalContent component description.
 *
 * @AddMachineTypeModalContent
 *
 */

interface CompanyMachineTypeItem {
  id: number;
  designation: string;
}
interface AddMachineTypeModalContentProps {
  onClickSave?: () => void;
  CloseModal?: () => void;
  superTypes?: CompanyMachineTypeItem[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  machineId?: CompanyMachineTypeItem;
  machineName?: any;
}

const AddMachineTypeModalContent: React.FC<AddMachineTypeModalContentProps> = ({
  onClickSave,
  CloseModal,
  superTypes,
  onChange,
  machineId,
  machineName
}) => {
  return (
    <div className="AddMachineTypeModalContent">
      <div className="content-wrapper fl_col gp20">
        <InputField
          placeholder={'Saisissez nom de la machine'}
          isRequired={true}
          name="designation"
          type="text"
          value={machineName?.designation}
          label={'Désignation'}
          style="form"
          isDisabled={false}
          onChange={onChange}
        />
        {machineId?.id ? (
          <></>
        ) : (
          <RadioBoxInput
            className="isRequired"
            label={'Type de licence'}
            id={''}
          >
            {superTypes &&
              superTypes?.map((item) => (
                <RadioBoxInputOption
                  onChange={
                    onChange
                      ? onChange
                      : (event: ChangeEvent<HTMLInputElement>) => {
                          console.log('Change');
                        }
                  }
                  label={item.designation}
                  id={item.designation}
                  value={item.id}
                  name="typeOfMachineTypeId"
                />
              ))}
          </RadioBoxInput>
        )}
      </div>
      <div className="fl_row jc_fe gp20">
        <div className="">
          <ButtonElement
            className="w-full"
            type={'TextButton'}
            text={languageData.fr.Modal.cancel}
            buttonSize={'small'}
            onClick={CloseModal}
          />
        </div>

        <div className="">
          <ButtonElement
            className="w-full"
            type={'TextButton'}
            text="Sauvegarder"
            buttonSize={'small'}
            buttonStyle="btn-primary"
            onClick={onClickSave}
          />
        </div>
      </div>
    </div>
  );
};

export default AddMachineTypeModalContent;
