import React, { ReactNode } from 'react';

interface StepWizzardProps {
  children: ReactNode;
  className?: string;
  stepNumber: number;
  isActive: boolean;
}

const StepWizzard: React.FC<StepWizzardProps> = ({
  children,
  className,
  stepNumber,
  isActive,
}) => {
  if (!isActive) {
    return null;
  }

  return (
    <div className={`StepWizzard Step_0${stepNumber} ${className || ''}`}>
      {children}
    </div>
  );
};

export default StepWizzard;
