import * as actionTypes from '../actions/actionTypes';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';

const initialState: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState =
  {
    loading: false,
    syncEtablissemntType: null,
    error: null,
  };

const EstablishmentListMachinesReducer = (
  state = initialState,
  action: any,
): serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState => {
  switch (action.type) {
    case actionTypes.ESTABLISHMENT_List_MACHINES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ESTABLISHMENT_List_MACHINES_SUCCESS:
      return {
        ...state,
        loading: false,
        syncEtablissemntType: action.payload,
      };
    case actionTypes.SYNC_ESTABLISHMENT_MACHINES_SUCCESS:
      return {
        ...state,
        loading: false,
        syncEtablissemntType: action.payload,
      };
    case actionTypes.ESTABLISHMENT_List_MACHINES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default EstablishmentListMachinesReducer;
