import React from 'react';
import Toast, { ToastProps } from '../../../components/Toast/Toast';
const ToastHandler = React.forwardRef((_, ref) => {
  const [toastType, setToastType] = React.useState<ToastProps['type']>('info');
  const [isToastVisible, setIsToastVisible] = React.useState<boolean>(false);
  const [toastTitle, setToastTitle] = React.useState<string | undefined>('');

  React.useEffect(() => {
    setIsToastVisible(false);
  }, []);

  const openToast = React.useCallback(
    (type: ToastProps['type'], title: string) => {
      setToastType(type);
      setToastTitle(title);
      setIsToastVisible(true);
    },
    [],
  );

  const closeToast = React.useCallback(() => {
    setIsToastVisible(false);
  }, []);

  // Expose openToast and closeToast methods to parent components
  React.useImperativeHandle(ref, () => ({
    openToast,
    closeToast,
  }));

  return (
    <Toast
      type={toastType}
      description={toastTitle ? toastTitle : ''}
      isVisible={isToastVisible}
      timeout={4000}
      onClose={closeToast}
    />
  );
});
ToastHandler.displayName = 'ToastHandler';
export default ToastHandler;
