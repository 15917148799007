import React from 'react';

interface Props {
  strokeColor?: string;
}

const FamillesIcon: React.FC<Props> = ({ strokeColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2033_19116)">
        <path
          d="M1.33301 2.66536C1.33301 1.92898 1.92996 1.33203 2.66634 1.33203H5.33301C6.06939 1.33203 6.66634 1.92898 6.66634 2.66536V5.33203C6.66634 6.06841 6.06939 6.66536 5.33301 6.66536H2.66634C1.92996 6.66536 1.33301 6.06841 1.33301 5.33203V2.66536Z"
          stroke={strokeColor}
          strokeWidth="1.3"
        />
        <path
          d="M14.6663 3.9987C14.6663 5.47146 13.4724 6.66536 11.9997 6.66536C10.5269 6.66536 9.33301 5.47146 9.33301 3.9987C9.33301 2.52594 10.5269 1.33203 11.9997 1.33203C13.4724 1.33203 14.6663 2.52594 14.6663 3.9987Z"
          stroke={strokeColor}
          strokeWidth="1.3"
        />
        <path
          d="M6.66634 11.9987C6.66634 13.4715 5.47243 14.6654 3.99967 14.6654C2.52692 14.6654 1.33301 13.4715 1.33301 11.9987C1.33301 10.5259 2.52692 9.33203 3.99967 9.33203C5.47243 9.33203 6.66634 10.5259 6.66634 11.9987Z"
          stroke={strokeColor}
          strokeWidth="1.3"
        />
        <path
          d="M9.33301 10.6654C9.33301 9.92899 9.92996 9.33203 10.6663 9.33203H13.333C14.0694 9.33203 14.6663 9.92899 14.6663 10.6654V13.332C14.6663 14.0684 14.0694 14.6654 13.333 14.6654H10.6663C9.92996 14.6654 9.33301 14.0684 9.33301 13.332V10.6654Z"
          stroke={strokeColor}
          strokeWidth="1.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_2033_19116">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FamillesIcon;
