import React from 'react';
import logo from '../../assets/icons/LogoFrenchInnov.svg';
/**
 * Invoice component description.
 *
 * @Invoice
 *
 */

interface InvoiceProps {
  paymentBy: string;
  iban: string;
}

const InvoiceFooter: React.FC<InvoiceProps> = ({ paymentBy, iban }) => {
  return (
    <div className="InvoiceFooter">
      <div className="footer-wrapper fl_col">
        <div className="fl_col gp5 col_">
          <p className="p10 w-600">Règlement par {paymentBy}</p>
          <p className="p10">Code IBAN: {iban}</p>
        </div>
        <div className="fl_row ai_c">
          <div className="fl-2 fl_col gp4">
            <div className="fl_row gp4 p10 Independence-txt w-500">
              <p>FRENCHINNOV</p>
              <p>
                {' '}
                <span></span> Société par actions simppfiée (SAS)
              </p>
              <p>
                {' '}
                <span></span> Capital de 40 000 €
              </p>
            </div>
            <div className="fl_row gp4 p10 Independence-txt w-500">
              <p>SIRET: 81201712700018 NAF-APE: 6202A</p>
              <p>
                {' '}
                <span></span> Numéro TVA: FR86812017127
              </p>
            </div>
          </div>
          <div className="site Independence-txt w-500">
            <p className="p10">frenchinnov.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceFooter;
