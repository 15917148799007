import { v4 as uuidv4 } from 'uuid';
import { languageData } from '../i18n';
export const createNewRedevance = () => ({
  key: uuidv4(),
  name: `${languageData.global.Form.redevanceDefaultTitle}`,
  pct: 0,
  isPrinciple: false,
  isActive: false,
  editItem: true,
  deleteItem: false,
});
