import { languageData } from '../i18n';
import { serviceEntrepriseModels } from '../models/services/module.entreprise';
import validateEmail from './EmailValidation';

interface FieldValidationParams {
  category?: string;
  fieldName?: string;
  isRequired?: boolean;
  fieldValue?: string | number | null | undefined;
}
interface FieldValidationEditGeneralInfosCompanyParams {
  fieldName?: string;
  fieldValue?: string | number | null | undefined;
}
export function isFieldEmpty(
  value: string | number | null | undefined | boolean,
): boolean {
  return value === '' || value === null || value === undefined || value === 0;
}
export function getErrorMessage(params: FieldValidationParams): string {
  const { fieldName, fieldValue } = params;

  if (isFieldEmpty(fieldValue)) {
    return languageData.global.Form.errorMsg.requiredFieldMissing;
  }
  if (fieldName === 'sireN_code') {
    const siren_length = fieldValue?.toString();
    if (siren_length && siren_length?.length > 9) {
      return languageData.global.Form.errorMsg.sirenLength;
    }
  }
  if (fieldName === 'email') {
    return !validateEmail(fieldValue?.toString())
      ? languageData.global.Form.errorMsg.emailNotValid
      : '';
  }
  return '';
}
export function getErrorMessageEditCompany(
  params: FieldValidationEditGeneralInfosCompanyParams,
): string {
  const { fieldName, fieldValue } = params;

  if (isFieldEmpty(fieldValue)) {
    return languageData.global.Form.errorMsg.requiredFieldMissing;
  }

  if (fieldName === 'email') {
    const email = typeof fieldValue === 'string' ? fieldValue : '';
    const validatedEmail = validateEmail(email);

    return !validatedEmail
      ? languageData.global.Form.errorMsg.emailNotValid
      : '';
  }

  return '';
}
export function getErrorMessageRedevance(
  item: serviceEntrepriseModels.royaltiesInformation,
): string {
  // Check if the field is required and the value is empty or undefined
  if (item.pct === 0) {
    return languageData.global.Form.errorMsg.principalRedevanceZero;
  } else {
    type RoyaltiesInformationKey = keyof typeof item;
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const value = item[key as RoyaltiesInformationKey];
        if (isFieldEmpty(value)) {
          return languageData.global.Form.errorMsg.redevanceInvalid;
        }
      }
    }
  }

  return '';
}
