import React from 'react';
import CloseBtnPrimary from '../../assets/icons/CloseBtnPrimary.svg';
import { languageData } from '../../i18n';
import { ModalProps } from '../../models/services/module.modals';
import FieldName from '../Catalogue/FieldName';
import ButtonElement from '../button/button';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

const ModalAddCarte: React.FC<ModalProps> = (props) => {
  return (
    <div
      className={`Modal ModalAddCarte ${props.className ? props.className : ''} ${props.version + '_modal'} ${props.type} ${props.visibility ? '' : 'hidden'}`}
    >
      <div className="OverlayModal" />
      <div className="ModalContent rd12">
        <div className="modal-wrapper pd24 fl_col gp20 jc_s">
          <div className="ModalHeader fl_row jc_s">
            <p className="p18 w-700">{props.title}</p>
            {props.close && (
              <img
                className="closebtn"
                onClick={props.handelclose}
                src={CloseBtnPrimary}
                alt=""
              />
            )}
          </div>
          <div className="ModalBody fl_col gp20">
            <div className="fl_col gp40">
              <FieldName
                onChange={(e) =>
                  props.onCartConfigChange &&
                  props.onCartConfigChange('designation', e.target.value)
                }
                label={languageData.fr.card.intitule}
                placeholder={languageData.fr.card.intitule}
              />
              <p className="p14 Independence-txt">
                {languageData.fr.card.creerCarte}
              </p>
            </div>
            <div className="cart-select fl_col gp12">
              <div
                className={`option fl_row gp10 ai_s pd16 ${props.configCarte?.cradType == 0 ? 'checkedOption' : ''} `}
                onClick={() =>
                  props.onCartConfigChange &&
                  props.onCartConfigChange('cradType', 0)
                }
              >
                <input
                  autoComplete="off"
                  type="radio"
                  name="option-select"
                  id=""
                  checked={props.configCarte?.cradType == 0}
                />
                <div className="fl_col gp5">
                  <p className="p14 w-700 Charcoal-txt">
                    {languageData.fr.card.autre}
                  </p>
                  <p className="p14 Charcoal-txt">
                    {languageData.fr.card.optimiserAutre}
                  </p>
                </div>
              </div>
              <div
                className={`option fl_row gp10 ai_s pd16 ${props.configCarte?.cradType == 1 ? 'checkedOption' : ''} `}
                onClick={() =>
                  props.onCartConfigChange &&
                  props.onCartConfigChange('cradType', 1)
                }
              >
                <input
                  autoComplete="off"
                  type="radio"
                  name="option-select"
                  id=""
                  checked={props.configCarte?.cradType == 1}
                />
                <div className="fl_col gp5">
                  <p className="p14 w-700 Charcoal-txt">
                    {languageData.fr.card.webMobile}
                  </p>
                  <p className="p14 Charcoal-txt">
                    {languageData.fr.card.optimiserWebMobile}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ModalFooter">
            <div className="wrapperFooter">
              <div className="fl_row gp12">
                <div className="fl-1">
                  <ButtonElement
                    type={'TextButton'}
                    text={languageData.fr.Modal.cancel}
                    buttonSize={'small'}
                    onClick={props.handelclose}
                  />
                </div>
                <div className="fl-3">
                  <ButtonElement
                    isDisabled={
                      (props?.configCarte?.designation
                        ? props?.configCarte?.designation?.length < 3
                        : true) || props.configCarte?.cradType == undefined
                    }
                    type={'TextButton'}
                    text={languageData.fr.Modal.confirm}
                    buttonSize={'small'}
                    buttonStyle={
                      props?.configCarte?.designation &&
                      props?.configCarte?.designation?.length > 3 &&
                      props.configCarte?.cradType != undefined
                        ? 'btn-primary'
                        : ''
                    }
                    onClick={props.handleSave}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddCarte;
