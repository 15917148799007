import React from 'react';

interface OptionItemProps {
  target:
    | 'country'
    | 'dropdown'
    | 'search'
    | 'reference'
    | 'reflags'
    | 'tva'
    | 'color';
  value: string;
  icon?: string;
  onClick?: (value: string, icon?: string, reference?: string) => void;
  reference?: string;
  checked?: boolean;
  hideCheck?: boolean;
  Codecolor?: string;

  hideIcon?: boolean;
}
const OptionItem: React.FC<OptionItemProps> = ({
  target,
  value,
  icon,
  onClick,
  reference,
  checked,
  hideCheck,
  Codecolor,
  hideIcon,
}) => {

  const optionContent = () => {
    switch (target) {
      case 'country':
        return (
          <li
            className="fl_row p12 ai_c gp10 pd8-t-b pd10-r-l"
            onClick={() => onClick && onClick(value, icon)}
          >
            {icon && (
              <img
                src={icon}
                alt="icon"
                style={{ width: '20px', height: '20px' }}
              />
            )}
            {value}
          </li>
        );
      case 'search':
      case 'dropdown':
        return (
          <li
            className="fl_row ai_c gp10 pd8-t-b pd10-r-l item-list"
            onClick={() => onClick && onClick(value)}
          >
            {icon && !hideIcon && (
              <img
                src={icon}
                alt="icon"
                style={{ width: '20px', height: '20px' }}
              />
            )}
            <p>{value}</p>
          </li>
        );

      case 'color':
        return (
          <li
            className="fl_row ai_c gp10 pd8-t-b pd10-r-l item-list"
            onClick={() => onClick && onClick(value)}
          >
            <span
              className="option-color"
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: `${Codecolor ? Codecolor : 'black'}`,
              }}
            ></span>

            <p>{value}</p>
          </li>
        );

      case 'reference':
        return (
          <li
            className="fl_row ai_c gp10 pd8-t-b pd10-r-l item-list"
            onClick={() => onClick && onClick(value, reference)}
          >
            <div className="fl_row gp10">
              <span className="reference">{reference} </span>
              <span className="value">{value}</span>
            </div>
          </li>
        );

      case 'reflags':
        return (
          <li
            className="fl_row ai_c gp10 pd8-t-b pd10-r-l item-list"
            onClick={() => onClick && onClick(value, reference)}
          >
            {icon && (
              <img
                src={icon}
                alt="icon"
                style={{ width: '20px', height: '20px' }}
              />
            )}
            <div className="fl_row gp10">
              <span className="reference">{reference} </span>
              <span className="value">{value}</span>
            </div>
          </li>
        );
      case 'tva':
        return (
          <div
            className="fl_row ai_c gp10 pd4-t-b pd10-r-l p14"
            onClick={() => onClick && onClick(value, reference)}
          >
            {!hideCheck && (
              <input
                autoComplete="off"
                type="radio"
                value={value}
                name="tva"
                id="xxx"
                checked={checked}
              />
            )}
            <label htmlFor={value}>
              {reference} {value}
            </label>
          </div>
        );
    }
  };

  return <>{optionContent()}</>;
};

export default OptionItem;
