import React from 'react';
import { ModalProps } from '../../../models/services/module.modals';
import { languageData } from '../../../i18n';
import EnleverRedIcon from '../../../assets/icons/enleverRedIcon.svg';
import ModalEditArticle from '../ModalEditArticlee';
export default function EditArticleModalController(props: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper fl_col gp20 w-full jc_c ai_c">
      <ModalEditArticle
        articleChanges={props.articleChanges}
        purshaseMethods={props.purshaseMethods}
        onClickPurshaseMethod={props.onClickPurshaseMethod}
        onChangeFieldComposition={props.onChangeFieldComposition}
        composition={props.composition}
      />
    </div>
  );

  const modalContentFooter = (
    <div className="wrapperFooter w-full ai_c jc_s">
      <div className="elever fl_row gp4 ai_c c-p" onClick={props.onUndrag}>
        <img src={EnleverRedIcon} alt="" />
        <p className="p14 w-700 Carnelian-txt">
          {languageData.fr.modalsCatalogues.undrag}
        </p>
      </div>
      <div className="">
        <div className="fl_row gp12 w-full">
          {props.cancel && (
            <button
              className="btn_cat btn-lightGrey"
              onClick={props.closeModal}
            >
              {props.cancelTxt}
            </button>
          )}
          {props.save && (
            <button
              id="save"
              onClick={props.handleSave}
              className={
                props.hasChange
                  ? `${props.purshaseMethods ? (props.articleChanges?.purshaseMethod?.length == 0 ? 'btn medium btn-disabledGrey' : 'btn medium btn-primary') : 'btn medium btn-primary'}`
                  : 'btn medium btn-disabledGrey'
              }
              disabled={
                props.hasChange
                  ? props.purshaseMethods
                    ? props.articleChanges?.purshaseMethod?.length == 0
                    : false
                  : true
              }
            >
              {props.saveTxt}
            </button>
          )}
        </div>
      </div>
    </div>
  );
  return { modalContentBody, modalContentFooter };
}
