import { serviceTaxesModels } from '../../models/services/module.taxesInfos';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceTaxesModels.TaxesInfosState = {
  taxesInfos: null,
  taxesInfos_list: [],
  codesNaf_list: [],
  taxesInfosResponse: {},
  /////////////
  taxesInfosEtablissement: null,
  taxesInfosResponseEtablissement: {},
  /////////
};

function reducer(
  state = initialState,
  action: serviceTaxesModels.getTaxesInfosAction,
) {
  switch (action.type) {
    case actionTypes.VERIFY_TAXES_INFOS: {
      return { ...state, taxesInfos: action.taxesInfos };
    }
    case actionTypes.VERIFY_TAXES_INOFS_RESPONSE: {
      return { ...state, taxesInfosResponse: action.taxesInfos };
    }
    case actionTypes.RESET_VERIF_RESPONSE: {
      return {
        ...state,
        taxesInfosResponse: initialState.taxesInfosResponse,
      };
    }
    ///// etablissement
    case actionTypes.VERIFY_TAXES_INFOS_ETABLISSEMENT: {
      return { ...state, taxesInfosEtablissement: action.taxesInfos };
    }
    case actionTypes.VERIFY_TAXES_INOFS_RESPONSE_ETABLISSEMENT: {
      return { ...state, taxesInfosResponseEtablissement: action.taxesInfos };
    }
    case actionTypes.RESET_VERIF_RESPONSE_ETABLISSEMENT: {
      return {
        ...state,
        taxesInfosResponseEtablissement:
          initialState.taxesInfosResponseEtablissement,
      };
    }
    case actionTypes.GET_CODES_NAF: {
      return { ...state };
    }
    case actionTypes.GET_CODES_NAF_SUCCESS: {
      return {
        ...state,
        codesNaf_list: action.payload,
      };
    }
    default:
      return state;
  }
}

export default reducer;
