import React, { useEffect } from 'react';
import TableHeader from '../../components/Table/TableHeader';
import { languageData } from '../../i18n';
import CardContainer from '../CardContainer';
import UserPermissionsCard from './UserPermissionsCard';
import AddUserProfileCard from './AddUserProfileCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUser,
  resetAddUserSuccess,
  resetUserFormData,
  setUserFormData,
  setUserFormValidationRequested,
} from '../../store/actions/actions';
import useUserFormValidation from './useUserFormValidation';
import { CloseTab } from '../../services/tabs/CloseTab';
import { serviceTabsModels } from '../../models/services/module.tabs';
import tabs_list from '../../data/tabsList';
import { serviceAppUserModels } from '../../models/services/module.appUser';

export default function AddUser() {
  const dispatch = useDispatch();
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormData,
  );
  // Ref to hold initial state
  const initialUserFormData =
    React.useRef<serviceAppUserModels.AppUserPayload>();
  useEffect(() => {
    initialUserFormData.current = userFormData;
    dispatch(resetUserFormData());
    dispatch(setUserFormValidationRequested(false));
    dispatch(resetAddUserSuccess());
  }, []);

  const cancelChanges = () => {
    if (initialUserFormData.current) {
      dispatch(setUserFormData(initialUserFormData.current));
    }
  };

  const formHasErrors = useUserFormValidation(userFormData);
  const onClickSave = () => {
    dispatch(setUserFormValidationRequested(true));
    const filteredPermissions = userFormData.permissions.map((permission) => ({
      ...permission,
      rolesId: permission.rolesId.filter((role) => role !== ''),
    }));
    const data = {
      ...userFormData,
      permissions: filteredPermissions,
    };
    if (!formHasErrors) {
      dispatch(
        addUser(data, () => {
          cancelChanges();
        }),
      );
      closeTab();
    }
  };
  const handleChange = () => {
    const updatedData = {
      ...userFormData,
      user: {
        ...userFormData.user,
        isActive: !userFormData.user.isActive,
      },
    };
    dispatch(setUserFormData(updatedData));
  };
  const closeTab = () => {
    CloseTab({
      key: tabs_list[10].tabContent.key,
      open_tabs,
      name: tabs_list[10].name,
      dispatch,
    });
  };

  return (
    <div className="AddUser">
      <div className="user-wrapper fl_col gp10">
        <div className="header-Content">
          <TableHeader
            title={languageData.global.user.titleAddUser}
            type="adduser"
            textAdd={languageData.global.Modal.save}
            onChange={handleChange}
            labelSwitcher={
              userFormData.user?.isActive
                ? languageData.global.status.Active
                : languageData.global.status.deactivated
            }
            onClickSave={onClickSave}
            ischecked={userFormData.user?.isActive}
          />
        </div>

        <div className="body-Content">
          <CardContainer type={'form'}>
            <div className="card-content fl_col gp32">
              <AddUserProfileCard />
              <UserPermissionsCard cancelChanges={cancelChanges} />
            </div>
          </CardContainer>
        </div>
      </div>
    </div>
  );
}
