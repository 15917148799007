import { call, put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
  affectMachineToTypesFailure,
  affectMachineToTypesSuccess,
  EstablishmentListMachinesFailure,
  EstablishmentListMachinesSuccess,
  EstablishmentListMachinesSuperFailure,
  EstablishmentListMachinesSuperSuccess,
  syncEstablishmentMachinesFailure,
  syncEstablishmentMachinesSuccess,
} from '../actions/actions';
import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';
import etablissementApi from '../../services/apiHandlers/etablissementApi';
import { serviceEtablissementModels } from '../../models/services/module.etablissement';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
interface SyncEstablishmentMachinesRequestAction {
  type: string;
  payload: {
    establishementId: number;
    page?: number;
    pageSize?: number;
  };
  _?: (
    list: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState[],
  ) => void;
}
export function* syncEstablishmentMachinesSaga(
  action: SyncEstablishmentMachinesRequestAction,
) {
  try {
    const response: AxiosResponse = yield etablissementApi.getSyncEtablissement(
      action.payload.establishementId,
    );
    if (response.data) {
      yield put(syncEstablishmentMachinesSuccess(response.data));
    }
  } catch (error: any) {
    yield put(syncEstablishmentMachinesFailure(error.message));
  }
}
export function* EstablishmentListMachinesSaga(
  action: SyncEstablishmentMachinesRequestAction,
) {
  try {
    const response: AxiosResponse = yield etablissementApi.getListMachine(
      action.payload.establishementId,
      action.payload.page,
      action.payload.pageSize,
    );
    if (response.data) {
      yield put(EstablishmentListMachinesSuccess(response.data));
    }
  } catch (error: any) {
    yield put(EstablishmentListMachinesFailure(error.message));
  }
}

export function* EstablishmentListMachineSupersSaga(
  action: SyncEstablishmentMachinesRequestAction,
) {
  try {
    const response: AxiosResponse = yield etablissementApi.getListMachineSuper(
      action.payload.establishementId,
    );
    if (response.data) {
      yield put(EstablishmentListMachinesSuperSuccess(response.data));
      action._ && action._(response.data);
    }
  } catch (error: any) {
    yield put(EstablishmentListMachinesSuperFailure(error.message));
  }
}
export function* addEtablissmentTypeSuperSaga(action: any) {

  try {
    const response: AxiosResponse = yield etablissementApi.addMachineTypeSuper(
      action.payload,
    );
    if (response.data) {
      yield put(affectMachineToTypesSuccess(response.data));
      action?._ && action?._();
    }
  } catch (error: any) {
    yield put(affectMachineToTypesFailure(error.message));
  }
}
