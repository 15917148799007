import React from 'react';
import CorrectGreenIcon from '../../../assets/icons/CorrectGreenIcon.svg';
import IncorrectGreenIcon from '../../../assets/icons/IncorrectGreenIcon.svg';
import { languageData } from '../../../i18n';
import { usePasswordValidation } from '../../../utils/usePasswordValidation';

interface PasswordLevelProps {
  password: string;
}

const PasswordLevel: React.FC<PasswordLevelProps> = ({ password }) => {
  // const isLongEnough = password.length >= 12;
  // const hasDigit = /\d/.test(password);
  // const hasLowercase = /[a-z]/.test(password);
  // const hasUppercase = /[A-Z]/.test(password);
  // const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  // const level = () => {
  //   if (
  //     isLongEnough &&
  //     hasDigit &&
  //     hasLowercase &&
  //     hasUppercase &&
  //     hasSpecialCharacter
  //   ) {
  //     return 'strong';
  //   }
  //   return 'low';
  // };
  const {
    isLongEnough,
    hasDigit,
    hasLowercase,
    hasUppercase,
    hasSpecialCharacter,
    level,
  } = usePasswordValidation(password);
  const levelText = () => {
    switch (level) {
      case 'low':
        return (
          <p style={{ color: '#DC2828' }} className="p12 w-700">
            {languageData.global.account.lowLevelPassword}
          </p>
        );
      case 'strong':
        return (
          <p style={{ color: '#3B8521' }} className="p12 w-700">
            {languageData.global.account.strongLevelPassword}
          </p>
        );
      default:
        return <p className="p12 "></p>;
    }
  };

  return (
    <div className="PasswordLevel Independence-txt fl_col gp10">
      <p className="p12 fl_row ai_c gp5">
        {languageData.global.account.security} : <span>{levelText()}</span>{' '}
      </p>
      <div className={`level-progress fl_row gp4 ${level}-level`}>
        <span className="level1 fl-1"></span>
        <span className="level2 fl-1"></span>
      </div>
      <div className="info-level fl_col gp10">
        <div className="characters fl_row gp10 ai_c">
          <img
            src={isLongEnough ? CorrectGreenIcon : IncorrectGreenIcon}
            alt=""
          />
          <p className="p12">{languageData.global.account.lengthMsg}</p>
        </div>
        <div className="digit fl_row gp10 ai_c">
          <img src={hasDigit ? CorrectGreenIcon : IncorrectGreenIcon} alt="" />
          <p className="p12">{languageData.global.account.digitMsg}</p>
        </div>
        <div className="lowercase fl_row gp10 ai_c">
          <img
            src={hasLowercase ? CorrectGreenIcon : IncorrectGreenIcon}
            alt=""
          />
          <p className="p12">{languageData.global.account.lowerCase}</p>
        </div>
        <div className="capitalize fl_row gp10 ai_c">
          <img
            src={hasUppercase ? CorrectGreenIcon : IncorrectGreenIcon}
            alt=""
          />
          <p className="p12">{languageData.global.account.uppercase}</p>
        </div>
        <div className="specialCharacter fl_row gp10 ai_c">
          <img
            src={hasSpecialCharacter ? CorrectGreenIcon : IncorrectGreenIcon}
            alt=""
          />
          <p className="p12">{languageData.global.account.specialChar}</p>
        </div>
      </div>
    </div>
  );
};

export default PasswordLevel;
