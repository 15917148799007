export interface CountriesFlagsAndCodesItem {
  flag: string;
  country: string;
  code: string;
  key: number;
  phoneCode: string;
}

const CountriesFlagsAndCodes = [
  { flag: 'ad.svg', country: 'Andorra', code: 'AD', key: 1, phoneCode: '+376' },
  {
    flag: 'ae.svg',
    country: 'United Arab Emirates',
    code: 'AE',
    key: 2,
    phoneCode: '+971',
  },
  {
    flag: 'af.svg',
    country: 'Afghanistan',
    code: 'AF',
    key: 3,
    phoneCode: '+93',
  },
  {
    flag: 'ag.svg',
    country: 'Antigua and Barbuda',
    code: 'AG',
    key: 4,
    phoneCode: '+1-268',
  },
  {
    flag: 'ai.svg',
    country: 'Anguilla',
    code: 'AI',
    key: 5,
    phoneCode: '+1-264',
  },
  { flag: 'al.svg', country: 'Albania', code: 'AL', key: 6, phoneCode: '+355' },
  { flag: 'am.svg', country: 'Armenia', code: 'AM', key: 7, phoneCode: '+374' },
  { flag: 'ao.svg', country: 'Angola', code: 'AO', key: 8, phoneCode: '+244' },
  {
    flag: 'ar.svg',
    country: 'Argentina',
    code: 'AR',
    key: 9,
    phoneCode: '+54',
  },
  {
    flag: 'as.svg',
    country: 'American Samoa',
    code: 'AS',
    key: 10,
    phoneCode: '+1-684',
  },
  { flag: 'at.svg', country: 'Austria', code: 'AT', key: 11, phoneCode: '+43' },
  {
    flag: 'au.svg',
    country: 'Australia',
    code: 'AU',
    key: 12,
    phoneCode: '+61',
  },
  { flag: 'aw.svg', country: 'Aruba', code: 'AW', key: 13, phoneCode: '+297' },
  {
    flag: 'az.svg',
    country: 'Azerbaijan',
    code: 'AZ',
    key: 14,
    phoneCode: '+994',
  },
  {
    flag: 'ba.svg',
    country: 'Bosnia and Herzegovina',
    code: 'BA',
    key: 15,
    phoneCode: '+387',
  },
  {
    flag: 'bb.svg',
    country: 'Barbados',
    code: 'BB',
    key: 16,
    phoneCode: '+1-246',
  },
  {
    flag: 'bd.svg',
    country: 'Bangladesh',
    code: 'BD',
    key: 17,
    phoneCode: '+880',
  },
  { flag: 'be.svg', country: 'Belgium', code: 'BE', key: 18, phoneCode: '+32' },
  {
    flag: 'bf.svg',
    country: 'Burkina Faso',
    code: 'BF',
    key: 19,
    phoneCode: '+226',
  },
  {
    flag: 'bg.svg',
    country: 'Bulgaria',
    code: 'BG',
    key: 20,
    phoneCode: '+359',
  },
  {
    flag: 'bh.svg',
    country: 'Bahrain',
    code: 'BH',
    key: 21,
    phoneCode: '+973',
  },
  {
    flag: 'bi.svg',
    country: 'Burundi',
    code: 'BI',
    key: 22,
    phoneCode: '+257',
  },
  { flag: 'bj.svg', country: 'Benin', code: 'BJ', key: 23, phoneCode: '+229' },
  {
    flag: 'bl.svg',
    country: 'Saint Barthélemy',
    code: 'BL',
    key: 24,
    phoneCode: '+590',
  },
  {
    flag: 'bm.svg',
    country: 'Bermuda',
    code: 'BM',
    key: 25,
    phoneCode: '+1-441',
  },
  { flag: 'bn.svg', country: 'Brunei', code: 'BN', key: 26, phoneCode: '+673' },
  {
    flag: 'bo.svg',
    country: 'Bolivia',
    code: 'BO',
    key: 27,
    phoneCode: '+591',
  },
  { flag: 'br.svg', country: 'Brazil', code: 'BR', key: 28, phoneCode: '+55' },
  {
    flag: 'bs.svg',
    country: 'Bahamas',
    code: 'BS',
    key: 29,
    phoneCode: '+1-242',
  },
  { flag: 'bt.svg', country: 'Bhutan', code: 'BT', key: 30, phoneCode: '+975' },
  {
    flag: 'bw.svg',
    country: 'Botswana',
    code: 'BW',
    key: 31,
    phoneCode: '+267',
  },
  {
    flag: 'by.svg',
    country: 'Belarus',
    code: 'BY',
    key: 32,
    phoneCode: '+375',
  },
  { flag: 'bz.svg', country: 'Belize', code: 'BZ', key: 33, phoneCode: '+501' },
  { flag: 'ca.svg', country: 'Canada', code: 'CA', key: 34, phoneCode: '+1' },
  {
    flag: 'cc.svg',
    country: 'Cocos (Keeling) Islands',
    code: 'CC',
    key: 35,
    phoneCode: '+61',
  },
  {
    flag: 'cd.svg',
    country: 'Democratic Republic of the Congo',
    code: 'CD',
    key: 36,
    phoneCode: '+243',
  },
  {
    flag: 'cf.svg',
    country: 'Central African Republic',
    code: 'CF',
    key: 37,
    phoneCode: '+236',
  },
  {
    flag: 'cg.svg',
    country: 'Republic of the Congo',
    code: 'CG',
    key: 38,
    phoneCode: '+242',
  },
  {
    flag: 'ch.svg',
    country: 'Switzerland',
    code: 'CH',
    key: 39,
    phoneCode: '+41',
  },
  {
    flag: 'ci.svg',
    country: 'Ivory Coast',
    code: 'CI',
    key: 40,
    phoneCode: '+225',
  },
  {
    flag: 'ck.svg',
    country: 'Cook Islands',
    code: 'CK',
    key: 41,
    phoneCode: '+682',
  },
  { flag: 'cl.svg', country: 'Chile', code: 'CL', key: 42, phoneCode: '+56' },
  {
    flag: 'cm.svg',
    country: 'Cameroon',
    code: 'CM',
    key: 43,
    phoneCode: '+237',
  },
  { flag: 'cn.svg', country: 'China', code: 'CN', key: 44, phoneCode: '+86' },
  {
    flag: 'co.svg',
    country: 'Colombia',
    code: 'CO',
    key: 45,
    phoneCode: '+57',
  },
  {
    flag: 'cr.svg',
    country: 'Costa Rica',
    code: 'CR',
    key: 46,
    phoneCode: '+506',
  },
  { flag: 'cu.svg', country: 'Cuba', code: 'CU', key: 47, phoneCode: '+53' },
  {
    flag: 'cv.svg',
    country: 'Cape Verde',
    code: 'CV',
    key: 48,
    phoneCode: '+238',
  },
  {
    flag: 'cw.svg',
    country: 'Curaçao',
    code: 'CW',
    key: 49,
    phoneCode: '+599',
  },
  {
    flag: 'cx.svg',
    country: 'Christmas Island',
    code: 'CX',
    key: 50,
    phoneCode: '+61',
  },
  { flag: 'cy.svg', country: 'Cyprus', code: 'CY', key: 51, phoneCode: '+357' },
  {
    flag: 'cz.svg',
    country: 'Czech Republic',
    code: 'CZ',
    key: 52,
    phoneCode: '+420',
  },
  { flag: 'de.svg', country: 'Germany', code: 'DE', key: 53, phoneCode: '+49' },
  {
    flag: 'dj.svg',
    country: 'Djibouti',
    code: 'DJ',
    key: 54,
    phoneCode: '+253',
  },
  { flag: 'dk.svg', country: 'Denmark', code: 'DK', key: 55, phoneCode: '+45' },
  {
    flag: 'dm.svg',
    country: 'Dominica',
    code: 'DM',
    key: 56,
    phoneCode: '+1-767',
  },
  {
    flag: 'do.svg',
    country: 'Dominican Republic',
    code: 'DO',
    key: 57,
    phoneCode: '+1-809',
  },
  {
    flag: 'dz.svg',
    country: 'Algeria',
    code: 'DZ',
    key: 58,
    phoneCode: '+213',
  },
  {
    flag: 'ec.svg',
    country: 'Ecuador',
    code: 'EC',
    key: 59,
    phoneCode: '+593',
  },
  {
    flag: 'ee.svg',
    country: 'Estonia',
    code: 'EE',
    key: 60,
    phoneCode: '+372',
  },
  { flag: 'eg.svg', country: 'Egypt', code: 'EG', key: 61, phoneCode: '+20' },
  {
    flag: 'er.svg',
    country: 'Eritrea',
    code: 'ER',
    key: 62,
    phoneCode: '+291',
  },
  { flag: 'es.svg', country: 'Spain', code: 'ES', key: 63, phoneCode: '+34' },
  {
    flag: 'et.svg',
    country: 'Ethiopia',
    code: 'ET',
    key: 64,
    phoneCode: '+251',
  },
  {
    flag: 'fi.svg',
    country: 'Finland',
    code: 'FI',
    key: 65,
    phoneCode: '+358',
  },
  { flag: 'fj.svg', country: 'Fiji', code: 'FJ', key: 66, phoneCode: '+679' },
  {
    flag: 'fm.svg',
    country: 'Micronesia',
    code: 'FM',
    key: 67,
    phoneCode: '+691',
  },
  { flag: 'fr.svg', country: 'France', code: 'FR', key: 68, phoneCode: '+33' },
  { flag: 'ga.svg', country: 'Gabon', code: 'GA', key: 69, phoneCode: '+241' },
  {
    flag: 'gb.svg',
    country: 'United Kingdom',
    code: 'GB',
    key: 70,
    phoneCode: '+44',
  },
  {
    flag: 'gd.svg',
    country: 'Grenada',
    code: 'GD',
    key: 71,
    phoneCode: '+1-473',
  },
  {
    flag: 'ge.svg',
    country: 'Georgia',
    code: 'GE',
    key: 72,
    phoneCode: '+995',
  },
  { flag: 'gh.svg', country: 'Ghana', code: 'GH', key: 73, phoneCode: '+233' },
  { flag: 'gm.svg', country: 'Gambia', code: 'GM', key: 74, phoneCode: '+220' },
  { flag: 'gn.svg', country: 'Guinea', code: 'GN', key: 75, phoneCode: '+224' },
  {
    flag: 'gq.svg',
    country: 'Equatorial Guinea',
    code: 'GQ',
    key: 76,
    phoneCode: '+240',
  },
  { flag: 'gr.svg', country: 'Greece', code: 'GR', key: 77, phoneCode: '+30' },
  {
    flag: 'gt.svg',
    country: 'Guatemala',
    code: 'GT',
    key: 78,
    phoneCode: '+502',
  },
  {
    flag: 'gw.svg',
    country: 'Guinea-Bissau',
    code: 'GW',
    key: 79,
    phoneCode: '+245',
  },
  { flag: 'gy.svg', country: 'Guyana', code: 'GY', key: 80, phoneCode: '+592' },
  {
    flag: 'hk.svg',
    country: 'Hong Kong',
    code: 'HK',
    key: 81,
    phoneCode: '+852',
  },
  {
    flag: 'hn.svg',
    country: 'Honduras',
    code: 'HN',
    key: 82,
    phoneCode: '+504',
  },
  {
    flag: 'hr.svg',
    country: 'Croatia',
    code: 'HR',
    key: 83,
    phoneCode: '+385',
  },
  { flag: 'ht.svg', country: 'Haiti', code: 'HT', key: 84, phoneCode: '+509' },
  { flag: 'hu.svg', country: 'Hungary', code: 'HU', key: 85, phoneCode: '+36' },
  {
    flag: 'id.svg',
    country: 'Indonesia',
    code: 'ID',
    key: 86,
    phoneCode: '+62',
  },
  {
    flag: 'ie.svg',
    country: 'Ireland',
    code: 'IE',
    key: 87,
    phoneCode: '+353',
  },
  { flag: 'in.svg', country: 'India', code: 'IN', key: 89, phoneCode: '+91' },
  { flag: 'iq.svg', country: 'Iraq', code: 'IQ', key: 90, phoneCode: '+964' },
  { flag: 'ir.svg', country: 'Iran', code: 'IR', key: 91, phoneCode: '+98' },
  {
    flag: 'is.svg',
    country: 'Iceland',
    code: 'IS',
    key: 92,
    phoneCode: '+354',
  },
  { flag: 'it.svg', country: 'Italy', code: 'IT', key: 93, phoneCode: '+39' },
  {
    flag: 'jm.svg',
    country: 'Jamaica',
    code: 'JM',
    key: 94,
    phoneCode: '+1-876',
  },
  { flag: 'jo.svg', country: 'Jordan', code: 'JO', key: 95, phoneCode: '+962' },
  { flag: 'jp.svg', country: 'Japan', code: 'JP', key: 96, phoneCode: '+81' },
  { flag: 'ke.svg', country: 'Kenya', code: 'KE', key: 97, phoneCode: '+254' },
  {
    flag: 'kg.svg',
    country: 'Kyrgyzstan',
    code: 'KG',
    key: 98,
    phoneCode: '+996',
  },
  {
    flag: 'kh.svg',
    country: 'Cambodia',
    code: 'KH',
    key: 99,
    phoneCode: '+855',
  },
  {
    flag: 'ki.svg',
    country: 'Kiribati',
    code: 'KI',
    key: 100,
    phoneCode: '+686',
  },
  {
    flag: 'km.svg',
    country: 'Comoros',
    code: 'KM',
    key: 101,
    phoneCode: '+269',
  },
  {
    flag: 'kn.svg',
    country: 'Saint Kitts and Nevis',
    code: 'KN',
    key: 102,
    phoneCode: '+1-869',
  },
  {
    flag: 'kp.svg',
    country: 'North Korea',
    code: 'KP',
    key: 103,
    phoneCode: '+850',
  },
  {
    flag: 'tn.svg',
    country: 'Tunisia',
    code: 'TN',
    key: 118,
    phoneCode: '+216',
  },
  { flag: 'eg.svg', country: 'Egypt', code: 'EG', key: 119, phoneCode: '+20' },
  {
    flag: 'ma.svg',
    country: 'Morocco',
    code: 'MA',
    key: 120,
    phoneCode: '+212',
  },
  {
    flag: 'sa.svg',
    country: 'Saudi Arabia',
    code: 'SA',
    key: 121,
    phoneCode: '+966',
  },
  {
    flag: 'ps.svg',
    country: 'Palestine',
    code: 'PS',
    key: 13,
    phoneCode: '+970',
  },

  {
    flag: 'kr.svg',
    country: 'South Korea',
    code: 'KR',
    key: 104,
    phoneCode: '+82',
  },
  {
    flag: 'kw.svg',
    country: 'Kuwait',
    code: 'KW',
    key: 105,
    phoneCode: '+965',
  },
  {
    flag: 'kz.svg',
    country: 'Kazakhstan',
    code: 'KZ',
    key: 106,
    phoneCode: '+7',
  },
  { flag: 'la.svg', country: 'Laos', code: 'LA', key: 107, phoneCode: '+856' },
  {
    flag: 'lb.svg',
    country: 'Lebanon',
    code: 'LB',
    key: 108,
    phoneCode: '+961',
  },
  {
    flag: 'lc.svg',
    country: 'Saint Lucia',
    code: 'LC',
    key: 109,
    phoneCode: '+1-758',
  },
  {
    flag: 'li.svg',
    country: 'Liechtenstein',
    code: 'LI',
    key: 110,
    phoneCode: '+423',
  },
  {
    flag: 'lk.svg',
    country: 'Sri Lanka',
    code: 'LK',
    key: 111,
    phoneCode: '+94',
  },
  {
    flag: 'lr.svg',
    country: 'Liberia',
    code: 'LR',
    key: 112,
    phoneCode: '+231',
  },
  {
    flag: 'ls.svg',
    country: 'Lesotho',
    code: 'LS',
    key: 113,
    phoneCode: '+266',
  },
  {
    flag: 'lt.svg',
    country: 'Lithuania',
    code: 'LT',
    key: 114,
    phoneCode: '+370',
  },
  {
    flag: 'lu.svg',
    country: 'Luxembourg',
    code: 'LU',
    key: 115,
    phoneCode: '+352',
  },
  {
    flag: 'lv.svg',
    country: 'Latvia',
    code: 'LV',
    key: 116,
    phoneCode: '+371',
  },
  { flag: 'ly.svg', country: 'Libya', code: 'LY', key: 117, phoneCode: '+218' },
];
export default CountriesFlagsAndCodes;
