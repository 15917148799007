import React from 'react';

interface Props {
  strokeColor?: string;
}

const EtapesIcon: React.FC<Props> = ({ strokeColor }) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00033 12.0013C2.44804 12.0013 2.00033 12.449 2.00033 13.0013C2.00033 13.5536 2.44804 14.0013 3.00033 14.0013C3.55261 14.0013 4.00033 13.5536 4.00033 13.0013C4.00033 12.449 3.55261 12.0013 3.00033 12.0013ZM0.666992 13.0013C0.666992 11.7126 1.71166 10.668 3.00033 10.668C4.28899 10.668 5.33366 11.7126 5.33366 13.0013C5.33366 14.29 4.28899 15.3346 3.00033 15.3346C1.71166 15.3346 0.666992 14.29 0.666992 13.0013Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 4.33333H6.66699V3H12.667V4.33333Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 9.0013H6.66699V7.66797H12.667V9.0013Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 14.3333H6.66699V13H12.667V14.3333Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80511 1.80609L3.13845 4.47276C2.8781 4.73311 2.45599 4.73311 2.19564 4.47276L0.862305 3.13942L1.80511 2.19661L2.66704 3.05854L4.8623 0.863281L5.80511 1.80609Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80511 6.47406L3.13845 9.14073C2.8781 9.40107 2.45599 9.40107 2.19564 9.14073L0.862305 7.80739L1.80511 6.86458L2.66704 7.72651L4.8623 5.53125L5.80511 6.47406Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default EtapesIcon;
