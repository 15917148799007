import tabs from '../../data/InitialTabsList';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { getFirstPart } from '../../services/tabs/CloseTab';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceTabsModels.TabsState = {
  tabs: tabs,
  activeTab: '',
  activeTabContent: tabs[0]?.tabContent,
  unsavedChanges: [],
};

function reducer(state = initialState, action: serviceTabsModels.tabAction) {
  switch (action.type) {
    case actionTypes.ADD_TAB: {
      const newTab: serviceTabsModels.tabPayload = { ...action.payload };
      return {
        ...state,
        tabs: [...state.tabs, newTab],
      };
    }
    case actionTypes.REMOVE_TAB: {
      let filteredTabs;
      action.payload.id
        ? (filteredTabs = state.tabs.filter(
            (tab) => tab.id !== action.payload.id,
          ))
        : (filteredTabs = state.tabs.filter(
            (tab) => tab.name !== action.payload.name,
          ));

      return {
        ...state,
        tabs: filteredTabs,
        activeTab: filteredTabs.length > 0 ? state.activeTab : '',
      };
    }
    case actionTypes.OPEN_TAB: {
      return {
        ...state,
      };
    }
    case actionTypes.SELECT_TAB: {
      let selectedTab;
      action.payload.id
        ? (selectedTab = state.tabs.find((tab) => tab.id === action.payload.id))
        : (selectedTab = state.tabs.find(
            (tab) => tab.name === action.payload.name,
          ));

      const name = selectedTab?.id
        ? selectedTab?.tabContent?.key + selectedTab?.id
        : selectedTab?.tabContent?.key;

      return {
        ...state,
        activeTab: name || '',
      };
    }
    case actionTypes.SET_ACTIVE_TAB_CONTENT: {
      return {
        ...state,
        activeTabContent: action.payload,
      };
    }
    case actionTypes.SET_ACTIVE_TAB: {
      let selectedTab;
      action.payload.id
        ? (selectedTab = state.tabs.find((tab) => tab.id === action.payload.id))
        : (selectedTab = state.tabs.find(
            (tab) => tab.name === action.payload.name,
          ));

      return {
        ...state,
        activeTab: action.payload.tabContent?.key || '',
      };
    }
    case actionTypes.UPDATE_TABS:
      return {
        ...state,
        tabs: action.payload,
      };
    case actionTypes.RESET_TABS: {
      return {
        ...state,
        tabs: tabs,
        activeTab: '',
      };
    }
    case actionTypes.UNSAVED_CHANGES_DATA: {
      return {
        ...state,
        unsavedChanges: action.payload,
      };
    }

    default:
      return state;
  }
}

export default reducer;
