//! AUTHENTIFICATION MIDDELWARE
import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import {
  failure,
  setFormesJuridiques,
  resetLoggedIn,
  resetPasswordSuccesAction,
} from '../actions/actions';
import formesJuridiquesApi from '../../services/apiHandlers/formesJuridiquesApi';
import { unauthorizedError } from '../../utils/unauthorizedError';

export function* getFormesJuridiquesSaga() {
  try {
    const response: AxiosResponse = yield formesJuridiquesApi.get();
    if (response.data) {
      yield put(setFormesJuridiques(response.data.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
