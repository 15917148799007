import React, { useState } from 'react';
import ListingEtatDeStockContainer from './ListingEtatDeStock';
import ChooseEtablissement from './ChooseEtablissement';
import EtatDeStockPrimaryColor from '../../../../assets/icons/EtatDeStockPrimaryColor.svg';
import HandDrawnImg from '../../../../assets/icons/HandDrawnImg.svg';
import EtatDeStockContainer from './EtatDeStockContainer';
import EtatDesStockImgContainer from '../../../../assets/images/EtatDesStockImgContainer.png';

export default function WelcomeEtatStock({
  onConfirm,
}: {
  onConfirm?: () => void;
}) {
  return (
    <div className="WelcomeEtatStock">
      <div className="container-wrapper fl_row gp40 ai_c">
        <div className="left-side">
          <div className="side-wrapper fl_col gp10">
            <div className="fl_row jc_c ">
              <img src={EtatDeStockPrimaryColor} alt="" />
            </div>
            <p className="p48 w-700">Gérer Etat des stocks</p>
            <p className="p18 Independence-txt">
              Gérer la disponibilité des articles, menus et catégories en toute
              simplicité.
            </p>
          </div>
          <div className="img_container fl_row jc_c">
            <img className="pd20-t-b " src={EtatDesStockImgContainer} alt="" />
          </div>
        </div>
        <div className="hand-drow-img">
          <img src={HandDrawnImg} alt="" />
        </div>
        <div className="right-side fl-1 rd16">
          <div className="side-wrapper fl_col gp40">
            <div className="text-block fl_col gp10 txt_center">
              {' '}
              <p className="p36 w-700">Choix de l’établissement</p>
              <p className="p18">
                Sélectionnez un établissement pour modifier la disponibilité des
                articles, menus et catégories.
              </p>
            </div>
            <ChooseEtablissement onConfirm={onConfirm} />
          </div>
        </div>
      </div>
    </div>
  );
}
