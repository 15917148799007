import axios from 'axios';
import { config } from '../../config';

export const BaseUrl = config.api_baseUrl;
export const HubUrl = config.signalR_hub_url;
export const PictureBaseUrl = config.api_baseUrl_Picture;
export const axiosInstance = axios.create({});
export const unAuthAxios = axios.create({});
export const axiosUploadInstance = axios.create({});
export const authUploadAxios = axios.create({});
unAuthAxios.interceptors.request.use(
  async (config: any) => {
    delete config.headers['Authorization'];
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Platform: 'web',
      timezone: 'Etc/GMT-1',
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// Clear default headers set by Axios
//unAuthAxios.defaults.headers.common = {};
axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = await localStorage.getItem('accessToken');
    const token = accessToken ?? ""
    localStorage.setItem('accessToken', token);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      Platform: 'web',
      timezone: browserTimezone,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
axiosUploadInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = await localStorage.getItem('accessToken');
    const token = accessToken ?? ""
    localStorage.setItem('accessToken', token);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
      Platform: 'web',
      timezone: browserTimezone,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
authUploadAxios.interceptors.request.use(
  async (config: any) => {
    const accessToken = await localStorage.getItem('accessToken');
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
      Platform: 'web',
      timezone: browserTimezone,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// Clear default headers set by Axios
axiosInstance.defaults.headers.common = {};
axiosInstance.interceptors.response.use( (response) => {
  
  return response;
},
(error) => {
  return Promise.reject(error);
})
//axiosInstance.defaults.headers.common = {};
axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.failureResponse &&
      error.response.data.failureResponse.codeFI?.code === 808
    ) {
      localStorage.removeItem('accessToken');
      const accessToken = await localStorage.getItem('accessToken');
      const token = await accessToken ?? ""
      localStorage.setItem('accessToken', token);
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${token}`;
      return axiosInstance(config);
    }
    return Promise.reject(error);
  },
);

unAuthAxios.interceptors.response = axiosInstance.interceptors.response;
export default axiosInstance;
export const authAxios = axiosInstance;