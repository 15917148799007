import React, { ChangeEvent } from 'react';
import AddProductIconBlue from '../../../assets/icons/AddProductIconBlue.svg';
import smalImageAvatar from '../../../assets/icons/smalImageAvatar.svg';
/**
 * AddProduct component description.
 *
 * @AddProduct
 *
 */

interface AddProductProps {
  name: string;
  img?: string;
  onClick?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onDrag?: () => void;
  selected?: boolean;
  hideImg?: boolean;
}

const AddProduct: React.FC<AddProductProps> = ({
  name,
  img,
  onClick,
  onChange,
  onDrag,
  selected,
  hideImg,
}) => {
  return (
    <div className="AddProduct pd5-r-l" title={name}>
      <div className="Addproduct-wrapper fl_row jc_s ai_c">
        <div className="fl_row gp8 ai_c">
          <div className="fl_row gp4 ai_c pd10-t-b ">
            <input
              autoComplete="off"
              maxLength={25}
              type="checkbox"
              name=""
              id=""
              checked={selected}
              onChange={onChange}
            />
            {!hideImg && (
              <img
                draggable="false"
                className="c-p "
                style={{
                  width: 32,
                  height: 32,
                  objectFit: 'cover',
                  borderRadius: 32,
                }}
                src={img || smalImageAvatar}
                alt=""
              />
            )}
          </div>
          <p className="product-name p14 " title={name}>
            {name}
          </p>
        </div>
        <img
          className="select-product"
          src={AddProductIconBlue}
          alt=""
          onClick={onDrag}
        />
      </div>
    </div>
  );
};

export default AddProduct;
