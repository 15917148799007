import React from 'react';
import { ModalProps } from '../../../models/services/module.modals';

export function AlertModalController({ ...props }: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper fl_col gp5">
      {props.description &&
        props.description.map((desc, index) => <p key={index}>{desc}</p>)}
    </div>
  );

  const modalContentFooter = (
    <div className="wrapperFooter">
      <div className="fl_row gp12">
        {props.cancel && (
          <button className="btn btn-primary-light" onClick={props.closeModal}>
            {props.cancelTxt}
          </button>
        )}
        {props.reject && (
          <button className="btn btn-primary-light" onClick={props.closeModal}>
            {props.rejectTxt}
          </button>
        )}
        {props.save && (
          <button id="save" className="btn btn-primary">
            {props.saveTxt}
          </button>
        )}
        {props.confirm && <button className="btn btn-primary">{props.confirmTxt}</button>}
      </div>
    </div>
  );

  return {
    modalContentBody,
    modalContentFooter,
  };
}
