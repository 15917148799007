import React, { ChangeEvent, useMemo, useState } from 'react';
import StepWizzard from '../StepWizzard';
import FormCard from '../FormCard';
import flagsAndNamesSeparator from '../../../../../utils/FlagsAndNamesSeparator';
import CountriesFlagsAndCodes, {
  CountriesFlagsAndCodesItem,
} from '../../../../../data/CountriesFlagsAndCode';
import { languageData } from '../../../../../i18n';
import { getError } from '../../../../../utils/GetErrors';
import { getErrorMessage } from '../../../../../utils/GetErrorMessages';
import InputField from '../../../../../components/Input/Input';
import SelectInput2 from '../../../../../components/Input/CountrySelect/SelectInput2';
import {
  extractDigits,
  formatPhoneNumber,
} from '../../../../../utils/PhoneNumbersUtils';
import { serviceEtablissementModels } from '../../../../../models/services/module.etablissement';
import tabs_list from '../../../../../data/tabsList';
import { useDispatch, useSelector } from 'react-redux';
import { unsavedChangesRegister } from '../../../../../store/actions/actions';
import checkCartType from '../../../../../utils/checkCartType';
import { useUnsavedChanges } from '../../useUnsavedChanges';
export default function Step2() {
  const [cardType, setCardType] = useState<string>('unknown');
  const { flags, countriesCodes, countriePhoneCodes } = useMemo(
    () => flagsAndNamesSeparator(),
    [],
  );

  const [phoneCode, setPhoneCode] = useState<string>();
  const validationRequestedStep2 = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.validationRequestedStep2Etablissement,
  );
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  const dispatch = useDispatch();
  const { newFormData } = useUnsavedChanges();

  const onSelectMobilePhone = (selectedValue: string) => {
    const selectedItem = CountriesFlagsAndCodes?.find(
      (item: CountriesFlagsAndCodesItem) => item.code === selectedValue,
    );

    if (selectedItem) {
      setPhoneCode(selectedItem.phoneCode?.toString());
    }
    const array = [
      {
        key: tabs_list[1].name,
        value: {
          type: 'action',
          payload: {
            payload: {
              ...newFormData?.payload,
              financialInformation: {
                ...newFormData?.payload?.financialInformation,
                owner_mobile_number: selectedItem?.phoneCode
                  ? selectedItem.phoneCode?.toString()
                  : '',
              },
            },
          },
        },
      },
    ];
    dispatch(unsavedChangesRegister(array));
  };
  const handleChangeOwnerMobileNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    const extractedDigits = extractDigits(value);

    const parsedNumber = extractedDigits;
    const array = [
      {
        key: tabs_list[1].name,
        value: {
          type: 'action',
          payload: {
            payload: {
              ...newFormData?.payload,
              financialInformation: {
                ...newFormData?.payload?.financialInformation,
                owner_mobile_number: parsedNumber,
              },
            },
          },
        },
      },
    ];
    dispatch(unsavedChangesRegister(array));
  };
  function changeCartNumber(value: string) {
    setCardType(checkCartType(value?.charAt(0)));
    return value;
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const data =
      name === 'owner_mobile_number'
        ? formatPhoneNumber(
            value,
            
            newFormData?.payload?.financialInformation?.owner_mobile_number,
          )
        : name === 'card_number'
          ? changeCartNumber(value)
          : value;
    const array = [
      {
        key: tabs_list[1].name,
        value: {
          type: 'action',
          payload: {
            payload: {
              ...newFormData?.payload,
              financialInformation: {
                ...newFormData?.payload?.financialInformation,
                royaltiesInformation:
                  newFormData?.payload?.financialInformation?.royaltiesInformation?.map(
                    (
                      royalty: serviceEtablissementModels.royaltiesInformation,
                    ) =>
                      royalty.name === 'string'
                        ? { ...royalty, [name]: data }
                        : royalty,
                  ),
                [name]: data,
              },
            },
          },
        },
      },
    ];
    dispatch(unsavedChangesRegister(array));
  };
  return (
    <div className="fl_col gp10">
      <StepWizzard
        className="FormWizzardContentCard fl_col gp20 ai_c"
        stepNumber={2}
        isActive={addEtablissementStep === 2}
      >
        <FormCard
          labelCard={languageData.global.bank_account_info}
          labelDescription={languageData.global.payment_reception}
          fieldsetLabel={languageData.global.bank_account_iban}
          grid={3}
          gap="gp24"
          align="flex-end"
        >
          <InputField
            isRequired={true}
            name="owner"
            type="text"
            // placeholder="Nom du titulaire du compte"
            label={languageData.global.account_holder}
            onChange={handleChange}
            style="form"
            isDisabled={false}
            allowOnlyLetters
            haserror={
              validationRequestedStep2
                ? getError({
                    category: 'financialInformation',
                    fieldName: 'owner',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation?.owner,
                  })
                : false
            }
            errorMsg={
              validationRequestedStep2
                ? getErrorMessage({
                    category: 'financialInformation',
                    fieldName: 'owner',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation?.owner,
                  })
                : ''
            }
          />
          {/* <SelectInput2
            isRequired={true}
            label={languageData.global.mobile}
            name="owner_mobile_number"
            onSelect={onSelectMobilePhone}
            //value={props.formData.fiscalInformation.naF_code_id}
            target="reflags"
            icons={flags}
            reference={countriePhoneCodes}
            options={countriesCodes}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeOwnerMobileNumber(e)
            }
            isDisabled={false}
            haserror={
              validationRequestedStep2
                ? getError({
                    category: 'financialInformation',
                    fieldName: 'owner_mobile_number',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation
                        ?.owner_mobile_number,
                  })
                : false
            }
            errorMsg={
              validationRequestedStep2
                ? getErrorMessage({
                    category: 'financialInformation',
                    fieldName: 'owner_mobile_number',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation
                        ?.owner_mobile_number,
                  })
                : ''
            }
          /> */}
        </FormCard>
        <FormCard grid={2} gap="gp24" align="flex-end">
          <InputField
            isRequired={true}
            name="iban"
            type="text"
            label={languageData.global.iban}
            onChange={handleChange}
            style="form"
            isDisabled={false}
            haserror={
              validationRequestedStep2
                ? getError({
                    category: 'financialInformation',
                    fieldName: 'iban',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation?.iban,
                  })
                : false
            }
            errorMsg={
              validationRequestedStep2
                ? getErrorMessage({
                    category: 'financialInformation',
                    fieldName: 'iban',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation?.iban,
                  })
                : ''
            }
          />
          <InputField
            name="swifT_BIC"
            type="text"
            label={languageData.global.swift_bic}
            onChange={handleChange}
            style="form"
            isDisabled={false}
          />
        </FormCard>
        <FormCard
          fieldsetLabel={languageData.global.credit_card}
          grid={2}
          gap="gp24"
          align="flex-end"
        >
          <InputField
            isRequired={true}
            name="card_number"
            type="number"
            label={languageData.global.credit_card_number}
            onChange={handleChange}
            style="card"
            isDisabled={false}
            cardType={cardType}
            maxLengthCardNumber={16}
            maxLength={16}
            className="haserror"
            haserror={
              validationRequestedStep2
                ? getError({
                    category: 'financialInformation',
                    fieldName: 'card_number',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation?.card_number,
                  })
                : false
            }
            errorMsg={
              validationRequestedStep2
                ? getErrorMessage({
                    category: 'financialInformation',
                    fieldName: 'card_number',
                    isRequired: true,
                    fieldValue:
                      newFormData?.payload?.financialInformation?.card_number,
                  })
                : ''
            }
          />
        </FormCard>
      </StepWizzard>
    </div>
  );
}
