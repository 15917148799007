import React from 'react';

/**
 * EmptyTable component description.
 *
 * @EmptyTable
 *
 */

interface EmptyTableProps {
  title: string;
  description?: string;
}

const EmptyTable: React.FC<EmptyTableProps> = ({ title, description }) => {
  return (
    <div className="EmptyTable fl_col gp20 AuroMetalSaurus-txt ai_c mg40-t-b">
      <p className="p24 w-700">{title}</p>
      <p className="p16">{description}</p>
    </div>
  );
};

export default EmptyTable;
