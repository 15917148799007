import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
const App = lazy(() => import('./App'));
import configureStore from './store/store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// instanciate store
export const store = configureStore({});
//pass store to persistor
export const Persistor = persistStore(store);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={Persistor}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </PersistGate>
  </Provider>
);
