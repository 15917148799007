import React from 'react';
import StepWizzard from '../StepWizzard';
import { serviceTabsModels } from '../../../../../models/services/module.tabs';
import { useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../../../../../models/services/module.entreprise';
import FormSuccessMsg from '../FormSuccessMsg';
import { serviceEtablissementModels } from '../../../../../models/services/module.etablissement';
import { useUnsavedChanges } from '../../useUnsavedChanges';

interface Step3Props {
  open_tabs: serviceTabsModels.Tab[];
}
export default function Step3(props: Step3Props) {
  const addEtablissementStep = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.addEtablissementStep,
  );
  const etablissementId = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState}) =>
      state.etablissement.etablissementId
  );
  const { newFormData } = useUnsavedChanges();
  return (
    <div>
      <StepWizzard
        className="FormWizzardContentCard fl_col gp20 ai_c"
        stepNumber={3}
        isActive={addEtablissementStep === 3}
      >
        <FormSuccessMsg
          open_tabs={props.open_tabs}
          id={etablissementId}
          name={newFormData?.payload?.generalInformation?.commercial_Name ?? ''}
        />
      </StepWizzard>
    </div>
  );
}
