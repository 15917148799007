import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../components/Table/Table';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import Modal from '../../../components/Modal/Modal';
import TableHeader from '../../../components/Table/TableHeader';
import LicenceAffectionModalContent from '../../../components/Etablissement/Modal/LicenceAffectionModalContent';
import { useSelector } from 'react-redux';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import { useDispatch } from 'react-redux';
import {
  affectMachineToTypesRequest,
  EstablishmentListMachinesRequest,
  EstablishmentListMachinesSuperRequest,
  syncEstablishmentMachinesRequest,
} from '../../../store/actions/actions';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';
import EmptyTable from '../../../components/Table/EmptyTable';
import Preloader from '../../../components/Preloader';
import Pagination from '../../../components/Table/Pagination-table';
import { usePagination } from '../../../pages/etablissement/pages/usePagination';
import { languageData } from '../../../i18n';
import PageTable from '../../Table/PageTable';
import TableContent from '../../Table/TableContent';
import TableSection from '../../Table/TableSection';

export default function LicencesListContainer() {
  const [openModal, setOpenModal] = useState(false);
  const [resultData, setresultData] =
    useState<serviceEtablissmentMachineTypeModels.Iresult>();
  //const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedIds, setSelectedIds] = useState<number>();
  const dispatch = useDispatch();
  const etablissementSheet = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.etablissementSheet,
  );

  const { loading, error } = useSelector(
    (state: {
      syncEstablishmentMachines: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState;
    }) => state.syncEstablishmentMachines,
  );
  const EtablissemntListMachines = useSelector(
    (state: {
      EstablishmentListMachines: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState;
    }) => state.EstablishmentListMachines.syncEtablissemntType,
  );

  const EtablissemntListMachinesSuper = useSelector(
    (state: {
      EstablishmentListMachinesSuper: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState;
    }) => state.EstablishmentListMachinesSuper.syncEtablissemntType,
  );
  const { claimsEtablissement, isAdmin } = useSelector(
    (state: { auth: { claimsEtablissement: string[]; isAdmin: boolean } }) =>
      state.auth,
  );

  const paginationConfig = useMemo(
    () => ({
      currentPage: 1,
      itemsPerPage: 10,
    }),
    [],
  );
  const {
    currentPage,
    itemsPerPage,

    handleNextClick,
    handlePreviousClick,
    handlePageChange,
  } = usePagination(paginationConfig);

  const pagination = useMemo(
    () => ({
      paginationModel: {
        page: currentPage,
        pageSize: itemsPerPage,
      },
    }),
    [currentPage, itemsPerPage],
  );
  useEffect(() => {
    dispatch(
      EstablishmentListMachinesRequest(
        etablissementSheet?.id as number,
        pagination.paginationModel.page,
        pagination.paginationModel.pageSize,
      ),
    );
  }, [dispatch, pagination]);

  const handleSubmit = () => {
    dispatch(
      syncEstablishmentMachinesRequest(etablissementSheet?.id as number),
    );
  };

  useEffect(() => {
    dispatch(
      EstablishmentListMachinesSuperRequest(etablissementSheet?.id as number),
    );
  }, []);
  console.log('EtablissemntListMachines',EtablissemntListMachines)
  const handleRadioChange = (id: number) => {
    //   setSelectedIds((prevIds: number[]) => {
    //     if (prevIds.includes(id)) {
    //       return prevIds.filter((prevId: number) => prevId !== id);
    //     } else {
    //       return [...prevIds, id];
    //     }
    //   }
    setSelectedIds(id as number);
    // );
  };

  const handleSubmitEffectMachine = () => {
    const payload: serviceEtablissmentMachineTypeModels.AffectMachineToTypesPayload =
      {
        establishementId: etablissementSheet?.id as number,
        machineId: resultData?.id as number,
        machineTypeId: selectedIds && (selectedIds as number),
      };
    dispatch(
      affectMachineToTypesRequest(payload, () => {
        dispatch(
          EstablishmentListMachinesRequest(
            etablissementSheet?.id as number,
            pagination.paginationModel.page,
            pagination.paginationModel.pageSize,
          ),
        );
      }),
    );

    setOpenModal(false);
  };

  if (loading) return <Preloader />;
  if (error) return <p>Error: {error}</p>;

  return (
    <PageTable className="LicencesListContainer">
      <Modal
        className="AddSubscriptionsModal"
        version="custom"
        title={'Machine 123'}
        visibility={openModal}
        onClickClose={() => setOpenModal(false)}
        maxWidth={480}
        content={
          <LicenceAffectionModalContent
            EtablissemntListMachinesSuper={
              EtablissemntListMachinesSuper?.result
            }
            resultData={resultData}
            handleRadioChange={handleRadioChange}
            onClickSave={handleSubmitEffectMachine}
            CloseModal={() => setOpenModal(false)}
          />
        }
        save
        saveTxt="Sauvegarder"
      />
      <TableSection>
        <TableHeader
          type="synch"
          title={languageData.fr.TableHeaders.Machines}
          onClickAdd={handleSubmit}
          showSync={claimsEtablissement?.includes('Licences.Synchroniser')}
          isAdmin={isAdmin}
          textAdd={languageData.fr.add}
        />
        <TableContent>
          <Table>
            <Thead>
              <Tr>
                <Td
                  type="text"
                  value={languageData.fr.Modal.Modaldesignation}
                  width={70}
                  align="left"
                />
                <Td
                  type="text"
                  value={languageData.fr.TableHeaders.status}
                  width={5}
                  align="center"
                />
                <Td
                  type="text"
                  value={languageData.fr.TableHeaders.Affectation}
                  width={5}
                  align="center"
                />
                <Td type="empty" width={5} />
              </Tr>
            </Thead>
            <Tbody>
              {EtablissemntListMachines &&
              EtablissemntListMachines?.result?.length > 0 ? (
                EtablissemntListMachines?.result?.map(
                  (result: serviceEtablissmentMachineTypeModels.Iresult) => (
                    <Tr key={result.id}>
                      <Td
                        style="bold"
                        type="text"
                        value={result.designation}
                        width={70}
                        align="left"
                      />
                      <Td
                        type="status"
                        width={5}
                        value={!result.isDeactivated}
                        align="center"
                      />
                      <Td
                        type="affectation"
                        width={5}
                        affectationData={result.listTypes}
                      />
                      {(isAdmin ||
                        claimsEtablissement?.includes(
                          'Licences.Modification',
                        )) && (
                        <Td
                          type="linkMachine"
                          onClick={() => {
                            setOpenModal(true);
                            setresultData(result);
                          }}
                          width={5}
                        />
                      )}
                    </Tr>
                  ),
                )
              ) : (
                <>
                  <EmptyTable
                    title={languageData.fr.TableHeaders.combine}
                    description={languageData.fr.TableHeaders.description}
                  />
                
                </>
              )}
            </Tbody>
          </Table>
        </TableContent>
      </TableSection>
      <Pagination
        totalPagesNumbers={EtablissemntListMachines?.pagination?.nbPage}
        nextpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handleNextClick(currentPage)
        }
        prevpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handlePreviousClick(currentPage)
        }
        currentPage={currentPage}
        totalPages={EtablissemntListMachines?.pagination?.nbPage}
        handlePageChange={handlePageChange}
      />
    </PageTable>
  );
}
