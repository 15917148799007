import { AxiosError, AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';

import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  getSubFamilyFail,
  getSubFamilySuccess,
  postSubFamilySuccess,
} from '../../actions/catalogues/actions';
import { subFamilyAction } from '../../../models/services/module.catalogue';

export function* startGetSubFamilies(action: subFamilyAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getSubFamily(action.id);
    if (response.data) {
      yield put(getSubFamilySuccess(response.data?.data?.result[0]??[]));
      action._ ? action._(response.data?.data?.result[0]??[]) : null;
    }
  } catch (error: unknown) {
    yield put(getSubFamilyFail(error));
  }
}
export function* startPostSubFamily(action: subFamilyAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.saveSubFamily(
      action.subFamily,
    );
    if (response.data) {
      yield put(postSubFamilySuccess());
      action._();
    }
  } catch (error) {
    NotificationErrorProvider(error);
  }
}
