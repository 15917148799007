import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';

import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  getFamilyFail,
  getFamilySuccess,
  postFamilySuccess,
} from '../../actions/catalogues/actions';
import { familyAction } from '../../../models/services/module.catalogue';

export function* startGetFamilies(action: familyAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getFamily();
    if (response.data) {
      yield put(getFamilySuccess(response.data?.data?.result??[]));
      action._ ? action._(response.data?.data?.result??[]) : null;
    }
  } catch (error: any) {
    yield put(getFamilyFail(error));
  }
}

export function* startPostFamily(action: familyAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.saveFamily(
      action.family,
    );

    if (response.data) {
      yield put(postFamilySuccess());
      action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}

export function* startDeleteFamily(action: familyAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteFamily(
      action.id,
      action.typeOfDelete,
    );
    if (response.data) {
      yield put(postFamilySuccess());
      action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
