import React from 'react';
import EntrepriseIcon from '../assets/icons/EntrepriseIcon';
import { languageData } from '../i18n';

export interface StepItem {
  Icon: JSX.Element; // Use JSX.Element for representing a React element (including SVG)
  Name: string;
  Description?: string; // Optional description
  Tag?: string; // Optional tag
  isActive: boolean;
  isDone: boolean;
  activePartOne?: boolean;
  activePartTwo?: boolean;
  donePartOne?: boolean;
  donePartTwo?: boolean;
}

// Initialize the steps data array with the correct type
const stepsDataInitialState: StepItem[] = [
  {
    Icon: <EntrepriseIcon />,
    Name: 'Entreprise',
    Description: `${languageData.global.generalInformation}`,
    Tag: 'Compte Bancaire',
    isActive: true,
    isDone: false,
    activePartOne: true,
    activePartTwo: false,
    donePartOne: false,
    donePartTwo: false,
  },
  {
    Icon: <EntrepriseIcon />,
    Name: `${languageData.global.status.inApproval}`,
    isActive: false,
    isDone: false,
  },
];
export default stepsDataInitialState;
