import React, { ReactNode } from 'react';

/**
 * PageTable component description.
 *
 * @PageTable
 *
 */

interface PageTableProps {
  children: ReactNode;
  className?: string;
}

const PageTable: React.FC<PageTableProps> = ({ children, className }) => {
  return (
    <div className={`PageTable fl_col jc_s gp10 h-full ${className}`}>{children}</div>
  );
};

export default PageTable;
