import React, { ChangeEvent, useEffect, useState } from 'react';
import ItemStock from '../../../../container/Catalogue/EtatDeStock/ItemStock';
import Modal from '../../../../components/Modal/Modal';
import EditStockModalContent from '../../../../container/Catalogue/EtatDeStock/EditStockModalContent';
import EtatDeStockCard from '../../../../container/Catalogue/EtatDeStock/EtatDeStockCard';
import {
  Menu,
  getArticleParams,
} from '../../../../models/services/module.catalogue';
import { useDispatch } from 'react-redux';
import {
  NotificationErrorProvider,
  NotificationSuccessProvider,
} from '../../../../utils/Notificationprovider';
import { startGetMenuEtatDeStock } from '../../../../store/actions/catalogues/actions';
import { catalogueApi } from '../../../../services/apiHandlers/catalogueApi';
import StockFilterToggle from './StockFilterToggle';
export default function ListingEtatDeStockMenus({
  idEstablishment,
}: {
  idEstablishment: string;
}) {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [currentActiveToggle, setcurrentActiveToggle] = useState(-1);
  const [searchValue, setsearchValue] = useState<string>();
  const [menus, setmenus] = useState<Menu[]>([]);
  const [loading, setloading] = useState(false);
  const [ActiveStatus, setActiveStatus] = useState<
    'exhausted' | 'sold-out-today' | 'in-stock'
  >('in-stock');
  const [paramsArticle, setparamsArticle] = useState<getArticleParams>({});
  const [meta, setmeta] = useState<{
    all?: number;
    epuise: number;
    stock: number;
  }>({
    all: 0,
    epuise: 0,
    stock: 0,
  });
  const dispatch = useDispatch();

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setsearchValue(e.target.value);
  };
  const onSelectArticle = (id: number, checked: boolean) =>
    setmenus(
      menus?.map((article) => ({
        ...article,
        selected: article.id === id ? checked : article.selected,
      })),
    );
  useEffect(() => {
    if (currentActiveToggle != undefined) {
      setloading(true);
      dispatch(
        startGetMenuEtatDeStock(
          (_) => {
            setloading(false);
            setmenus((_ as any)?.menus as Menu[]);
            let a = _;
            if (currentActiveToggle == -1) {
              setmeta({
                all: (_ as any)?.menus?.length,
                epuise: (a as any)?.epuiseCount,
                stock: (a as any)?.enStockCount,
              });
            }
          },

          currentActiveToggle as any,
          Number(idEstablishment),
        ),
      );
    }
  }, [currentActiveToggle]);

  const selectedCount = (menus ?? [])?.filter((_) => _.selected)?.length ?? 0;
  const selectedArticles = (menus ?? [])?.filter((_) => _.selected);
  const updateEtatDeStock = async () => {
    try {
      const response = await catalogueApi?.updateEtatDeStockMenus({
        IdEstablishment: Number(idEstablishment),
        etat:
          ActiveStatus == 'exhausted' ? 1 : ActiveStatus == 'in-stock' ? 0 : 2,
        menuIds: selectedArticles?.map((_) => _.id),
      });
      NotificationSuccessProvider('edit', 'Menu');
      setloading(true);
      dispatch(
        startGetMenuEtatDeStock(
          (_) => {
            setloading(false);
            setmenus((_ as any)?.menus as Menu[]);
            let a = _;
            if (currentActiveToggle == -1) {
              setmeta({
                all: (_ as any)?.menus?.length,
                epuise: (a as any)?.epuiseCount,
                stock: (a as any)?.enStockCount,
              });
            }
          },

          currentActiveToggle as any,
          Number(idEstablishment),
        ),
      );
      setShowModalEdit(false);
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const onChangeToggle = (n: number) => {
    setcurrentActiveToggle(n);
  };
  return (
    <div className="ListingEtatDeStockContainer">
      <Modal
        version="custom"
        content={
          <EditStockModalContent
            isMenu
            activeStatus={ActiveStatus}
            setActiveStatus={(s) => setActiveStatus(s)}
            slot={selectedCount}
            onClickCancel={() => setShowModalEdit(false)}
            onClickEdit={updateEtatDeStock}
          >
            {selectedArticles?.map((_) => (
              <EtatDeStockCard
                name={_.designation}
                oldStatus={
                  _.etatStock == 0
                    ? 'in-stock'
                    : _.etatStock == 1
                      ? 'exhausted'
                      : 'sold-out-today'
                }
                currentStatus={ActiveStatus as any}
                onClickDelete={() => onSelectArticle(_.id, false)}
              />
            ))}
          </EditStockModalContent>
        }
        // title={""}
        visibility={showModalEdit}
        maxWidth={720}
        // height={480}
        close
      />

      <div className="content-container ">
        <div className="filter-section fl_row jc_s pd16-t-b pd24-r-l ai_e">
          <StockFilterToggle
            counts={meta}
            isForMenu
            active={currentActiveToggle}
            onchange={onChangeToggle}
          ></StockFilterToggle>
          <button
            className={`edit-stock-btn ${selectedCount > 0 ? 'has-items' : ''}`}
            onClick={
              selectedCount > 0 ? () => setShowModalEdit(true) : undefined
            }
          >
            Modifier état de stock •{' '}
            <span className="w-600 pd10-l">{selectedCount}</span>
          </button>
        </div>
        {!loading ? (
          <div className="listing-items pd12-t-b pd16-r-l ">
            {menus?.length == 0 && <span>Pas de menus pour le moment</span>}
            {menus?.map((_) => (
              <ItemStock
                onClickCheck={(e) => {
                  onSelectArticle(_.id, e.target.checked);
                }}
                selected={_.selected}
                name={_.designation}
                status={
                  _.etatStock == 0
                    ? 'in-stock'
                    : _.etatStock == 1
                      ? 'exhausted'
                      : 'sold-out-today'
                }
              />
            ))}
          </div>
        ) : (
          <div className="fl_row w-full h-full jc_c">
            <span>Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
}
