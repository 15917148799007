export default function validateEmail(
  email: string | number | null | undefined,
): boolean {
  const value = typeof email === 'string' ? email : '';
  const emailRegex =
    /^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;

  // Vérifier si l'adresse email correspond au regex
  if (!emailRegex.test(value)) {
    return false;
  }

  // Vérifier que l'adresse email commence par un nom d'utilisateur
  const atIndex = value.indexOf('@');
  const username = value.substring(0, atIndex);
  if (username.length === 0) {
    return false;
  }

  // Vérifier que le nom d'utilisateur ne contient pas de caractères spéciaux en première ou dernière position
  if (!/^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*$/.test(username)) {
    return false;
  }

  // Vérifier l'extension de domaine
  const domain = value.substring(atIndex + 1);
  const domainParts = domain.split('.');
  if (domainParts.length < 2) {
    return false;
  }

  // Vérifier que l'extension de domaine est valide
  const domainName = domainParts.slice(0, -1).join('.');
  const domainExtension = domainParts[domainParts.length - 1];
  if (
    !/^[a-zA-Z0-9-]+$/.test(domainName) ||
    !/^[a-zA-Z]+$/.test(domainExtension)
  ) {
    return false;
  }

  return true;
}
