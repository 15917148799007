import React from 'react';
import Xred from '../../../../assets/icons/X-red.svg';
import CorrectGreenIcon from '../../../../assets/icons/CorrectGreenIcon.svg';
import CheckedMarkGrey from '../../../../assets/icons/CheckedMarkGrey.svg';
import MoreIconGrey from '../../../../assets/icons/MoreIconGrey.svg';
import CloseIconWhite from '../../../../assets/icons/CloseIconWhite.svg';
import PlusIconWhite from '../../../../assets/icons/PlusIconWhite.svg';
/**
 * TableUserCard component description.
 *
 * @TableUserCard
 *
 */

interface TableUserCardProps {
  title: string;
  type: 'activated' | 'expired' | 'canceled';
  total?: string;
  dateStart?: string;
  dateEnd?: string;
  prefix?: string;
  onClickUnSubscribe?: () => void;
  onClickActivate?: () => void;
}

const TableUserCard: React.FC<TableUserCardProps> = ({
  title,
  type,
  total,
  dateStart,
  dateEnd,
  prefix,
  onClickUnSubscribe,
  onClickActivate,
}) => {
  const GetStatus = () => {
    switch (type) {
      case 'activated':
        return (
          <div className="card-status fl_row gp8 rd30">
            <img src={CorrectGreenIcon} alt="" />
            <p className="p12 w-500"> Activé</p>
          </div>
        );
      case 'canceled':
        return (
          <div className="card-status fl_row gp8 rd30">
            <img src={CheckedMarkGrey} alt="" />
            <p className="p12 w-500">Annulé</p>
          </div>
        );
      case 'expired':
        return (
          <div className="card-status fl_row gp8 rd30">
            <img src={Xred} alt="" />
            <p className="p12 w-500 Carnelian-txt">Expiré</p>
          </div>
        );
    }
  };

  const GetOptionList = () => {
    switch (type) {
      case 'expired':
        return (
          <div className="list-item-wrapper fl_col gp4 ">
            <div
              className="list-item fl_row ai_c gp8 pd8"
              onClick={onClickActivate}
            >
              <img src={CloseIconWhite} alt="" />
              <p className="p12">Activer</p>
            </div>
          </div>
        );
      case 'canceled':
        return (
          <div className="list-item-wrapper fl_col gp4 ">
            <div
              className="list-item fl_row ai_c gp8 pd8"
              onClick={onClickActivate}
            >
              <img src={CloseIconWhite} alt="" />
              <p className="p12">Activer</p>
            </div>
          </div>
        );
      case 'activated':
        return (
          <div className="list-item-wrapper fl_col gp4 ">
            <div
              className="list-item fl_row ai_c gp8 pd8"
              onClick={onClickUnSubscribe}
            >
              <img src={PlusIconWhite} alt="" />
              <p className="p12">Se désabonner</p>
            </div>
          </div>
        );
    }
  };
  return (
    <div className="table-body">
      <div className={`card-wrapper ${type}-card`}>
        <div className="table-content fl_row ai_c jc_s pd16-r-l">
          <div className="fl_row  w-full content-wrapper jc_s">
            <p className="p14 w-500 title-item">{title}</p>
            <div className="fl_row jc_s fl-1">
              <div className="item-tabel">{GetStatus()}</div>
              <div className="item-tabel">
                <p className="p14">
                  {total} {prefix}
                </p>
              </div>
              <div className="item-tabel">
                <p className="p14">{dateStart}</p>
              </div>
              <div className="item-tabel">
                <p className="p14">{dateEnd}</p>
              </div>
            </div>
          </div>
          <div className="options">
            <img className="more" src={MoreIconGrey} alt="" />
            <div className="select-list white-txt">{GetOptionList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableUserCard;
