import React, { useEffect, useState, ChangeEvent, LegacyRef } from 'react';

interface CurrencyInputProps {
  defaultValue?: number | string; // Allow both number and string for flexibility
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void; // Callback to handle changes
  placeholder?: string; // Optional placeholder
  reff?: LegacyRef<HTMLInputElement> | undefined;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  defaultValue = '', // Default value is an empty string
  onChange,
  placeholder = '0.00',
  reff,
}) => {
  const formatCurrency = (value: string): string => {
    const numericValue = value.replace(/[^\d.-]/g, ''); // Remove non-numeric characters
    const number = parseFloat(numericValue);

    if (isNaN(number)) return '';

    // Format number to 2 decimal places
    return new Intl.NumberFormat('fr-FR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const [amount, setAmount] = useState<string>(formatCurrency(String()));

  useEffect(() => {
    if (defaultValue) setAmount(String(defaultValue)); // Format when defaultValue prop changes
  }, [defaultValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Prevent "E" or "e" from being typed
    inputValue = inputValue.replace(/[eE]/g, '');

    // Restrict to valid numeric input and up to two decimal places
    const validValue = inputValue.match(/^\d*([.,]\d{0,2})?$/)
      ? inputValue
      : amount;

    // Update the displayed value
    setAmount(validValue);

    // Create a raw numeric value for the onChange prop
    const rawValue = parseFloat(
      validValue.replace(',', '.').replace(/[^\d.-]/g, ''),
    );

    // Call the onChange prop with the new event object
    onChange &&
      onChange({
        ...e,
        target: {
          ...e.target,
          value: isNaN(rawValue) ? '' : rawValue.toFixed(2),
        },
      });
  };

  return (
    <input
      ref={reff}
      type="number"
      value={amount}
      defaultValue={defaultValue}
      onChange={handleChange}
      placeholder={placeholder}
      onKeyDown={(e) => {
        if (e.key === 'e' || e.key === 'E') {
          e.preventDefault(); // Prevent typing "E" or "e"
        }
      }}
    />
  );
};

export default CurrencyInput;
