import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceCampanyMachineTypeModels.CompanyMachineTypesState = {
    campanyMachineType: [],
    loading: false,
    error: null,
  };
  
  export const CreatecompanyMachineTypesReducer = (state = initialState, action: any): serviceCampanyMachineTypeModels.CompanyMachineTypesState => {
    switch (action.type) {
      case actionTypes.ADD_COMPANY_MACHINE_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case actionTypes.ADD_COMPANY_MACHINE_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          campanyMachineType: [...state.campanyMachineType, action.payload],
        };
      case actionTypes.ADD_COMPANY_MACHINE_TYPE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };