import React from 'react';
import IconSuccessForm from '../../../../assets/icons/IconSuccessForm.svg';

import { useDispatch } from 'react-redux';
import { CloseTab } from '../../../../services/tabs/CloseTab';
import { serviceTabsModels } from '../../../../models/services/module.tabs';
import tabs_list from '../../../../data/tabsList';
import { OpenTab } from '../../../../services/tabs/OpenTab';
import { languageData } from '../../../../i18n';
import ButtonElement from '../../../../components/button/button';
import { addEtablissementCurrentStep } from '../../../../store/actions/actions';
import useHandleCancel from './FormSteps/ResetAddForm';
interface StartCompanyProps {
  open_tabs: serviceTabsModels.Tab[];
  id: number;
  name: string;
}
export default function FormSuccessMsg(props: StartCompanyProps) {
  const dispatch = useDispatch();
  const handleCancel = useHandleCancel();
  const handleStartCompany = () => {
    CloseTab({
      open_tabs: props.open_tabs,
      name: props.name,
      dispatch: dispatch,
    });

    OpenTab({
      key: tabs_list[5].tabContent.key,
      label: tabs_list[5].name,
      tabs: props.open_tabs.filter((tab) => tab.name !== tabs_list[1].name),
      dispatch,
      id: props.id,
      name: props.name,
    });
    dispatch(addEtablissementCurrentStep(1));
    handleCancel();
  };
  return (
    <div className="FormSuccessMsg">
      <div className="msg-wrapper fl_col ai_c gp55">
        <img loading="lazy" src={IconSuccessForm} alt="IconSuccessForm" />
        <div className="fl_col ai_c">
          <p className="p26 DarkPurple-txt">
            {languageData.global.request_submitted}
          </p>
          <p className="p18 Manatee-txt">
            {languageData.global.welcome_message}
          </p>
        </div>
        <ButtonElement
          type="TextButton"
          text={languageData.global.lets_start}
          buttonSize="xx-large"
          buttonStyle="btn-UltramarineBlue"
          onClick={handleStartCompany}
        />
      </div>
    </div>
  );
}
