import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceCampanyMachineTypeModels.MachineTypeResponse = {
  campanyMachineType: null,
  loading: false,
  error: null,
};

export const companyMachineTypesReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    // Handle fetching company machine types
    case actionTypes.FETCH_COMPANY_MACHINE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_COMPANY_MACHINE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        campanyMachineType: action.payload,
      };
    case actionTypes.FETCH_COMPANY_MACHINE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Handle updating company machine types
    case actionTypes.UPDATE_COMPANY_MACHINE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_COMPANY_MACHINE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        campanyMachineType: action.payload,
      };
    case actionTypes.UPDATE_COMPANY_MACHINE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
