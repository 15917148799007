import {
    Article,
    ArticleSaved,
  } from './../../../models/services/module.catalogue.d';
  import * as actionTypes from '../../actions/catalogues/actionTypes';
  const initialState: {
    articles: ArticleSaved[];
    articleSuccess: boolean;
  } = {
    articles: [],
    articleSuccess: false,
  };
  
  function reducer(
    state = initialState,
    action: { type: string; articles?: ArticleSaved[]; item?: string },
  ) {
    switch (action.type) {
      case actionTypes.SUCCESS_GET_ARTICLE: {
        return {
          ...state,
          articles: action.articles,
        };
      }
      case actionTypes.POST_ARTICLE_SUCCESS: {
        return {
          ...state,
          articleSuccess: true,
        };
      }
      case actionTypes.CLEAR_STORE_ITEM: {
        let key = action?.item;
        let obj: Record<string, null> = {};
        obj[key as string] = null;
        return {
          ...state,
          ...obj,
        };
      }
      default:
        return state;
    }
  }
  
  export default reducer;
  