import React from 'react';
import UploadFile from '../../assets/icons/UploadFile.svg';
import DownloadIcon from '../../assets/icons/DownloadIcon.svg';
import DeleteFileIcon from '../../assets/icons/DeleteFileIcon.svg';
import { languageData } from '../../i18n';
/**
 * HeaderUploadFile component description.
 *
 * @HeaderUploadFile
 *
 */
interface HeaderUploadFileProps {
  onClickAdd?: () => void;
  onClickDownload?: () => void;
  onClickEnleve?: () => void;
  onClickRemove?: () => void;
  disabled?: boolean;
  onClose?: () => void;
  selectedFolder?: boolean;
}
const HeaderUploadFile: React.FC<HeaderUploadFileProps> = ({
  onClickAdd,
  onClickDownload,
  onClickRemove,
  disabled,
  selectedFolder,
}) => {
  return (
    <div className="HeaderUploadFile w-full">
      <div className="header-wrapper w-full fl_row jc_s w-full">
        <div className="FolderName">
          <p className="p20 w-500 txt_center">
            {languageData.fr.fileManager.folder}
          </p>
        </div>

        <div className="actions fl_row gp20">
          {selectedFolder && (
            <div className="add fl_row ai_c gp8 " onClick={onClickAdd}>
              <img src={UploadFile} alt="UploadFile" />
              <p className="p12 w-300 Independence-txt ">
                {languageData.fr.fileManager.add}
              </p>
            </div>
          )}
          {!disabled && (
            <div
              className="download fl_row ai_c gp8"
              onClick={disabled ? undefined : onClickDownload}
            >
              <img src={DownloadIcon} alt="DownloadIcon" />
              <p className="p12 w-300 Independence-txt">
                {languageData.fr.fileManager.download}
              </p>
            </div>
          )}
          {!disabled && (
            <div
              className="delete fl_row ai_c gp8"
              onClick={disabled ? undefined : onClickRemove}
            >
              <img src={DeleteFileIcon} alt="DeleteFileIcon" />
              <p className="p12 w-300 Independence-txt">
                {languageData.fr.fileManager.delete}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderUploadFile;
