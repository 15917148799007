import React, { ChangeEvent } from 'react';

/**
 * RadioBoxInputOption component description.
 *
 * @RadioBoxInputOption
 *
 */

interface RadioBoxInputOptionProps {
  label: string;
  id?: string;
  value: string | number;
  name?: string;
  checkbox?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const RadioBoxInputOption: React.FC<RadioBoxInputOptionProps> = ({
  label,
  id,
  value,
  name,
  checkbox,
  onChange,
  checked,
}) => {
  return (
    <div className="RadioBoxInputOption">
      <div className="option-wrapper fl_row gp10">
        {checkbox ? (
          <input
            autoComplete="off"
            type="checkbox"
            id={id}
            name="name"
            value={value}
            onChange={onChange}
          ></input>
        ) : (
          <input
            autoComplete="off"
            type="radio"
            id={id}
            name="name"
            value={value}
            onChange={onChange}
            defaultChecked={checked}
          ></input>
        )}
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  );
};

export default RadioBoxInputOption;
