import { ChangeEvent } from 'react';
import AddProductIconBlue from '../../../assets/icons/AddProductIconBlue.svg';
import AddProductIcon from '../../../assets/icons/AddProductIconLightGrey.svg';
import SearchIconGreyInp from '../../../assets/icons/SearchIconGreyInp.svg';
import Breadcrumbs from '../../../container/Breadcrumbs';
import { languageData } from '../../../i18n';
import { ArticleSaved } from '../../../models/services/module.catalogue';
import { Option } from '../../../utils/catalogueHelper/FamilyHelper';
import InputField from '../../Input/InputCatalogue';
import SelectInput from '../../Input/SelectedInputCatalogue';
import AddProduct from '../Articles/AddProduct';
interface propstype {
  families: Option[];
  subFamilies: Option[];
  onChange: (type: 'family' | 'subfamily', value: number) => void;
  articles: ArticleSaved[];
  onDrag: (Article: ArticleSaved) => void;
  onSelect: (article: ArticleSaved, checked: boolean, all?: boolean) => void;
  topAddEnabled?: boolean;
  onDragSelected: () => void;
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  allChecked: boolean;
  hideTitle?: boolean;
  hidePadding?: boolean;
}
export default function IngredientsNav({
  families,
  subFamilies,
  onChange,
  articles,
  onDrag,
  onSelect,
  topAddEnabled,
  onDragSelected,
  onSearch,
  allChecked,
  hideTitle,
  hidePadding,
}: propstype) {
  return (
    <div className="IngredientsNav">
      {!hideTitle && <Breadcrumbs mainpage={'Article'} subpage="" />}
      <div className="nav-wrapper">
        <div className="fl_col gp10 pd10-r-l">
          <div className="search-inp">
            <img className="icon-search" src={SearchIconGreyInp} alt="" />
            <InputField
              onChange={onSearch}
              type="text"
              style="search"
              placeholder={languageData.fr.sidebarIngredient.articles}
            />
          </div>
          <SelectInput
            placeholder={languageData.fr.modalsFamille.selectFamily}
            hideOptionItem
            onChange={(id) => onChange('family', id)}
            style="dropdown"
            options={families}
            target="dropdown"
          />
          <SelectInput
            placeholder={languageData.fr.modalsFamille.selectSubFamily}
            hideOptionItem
            onChange={(id) => onChange('subfamily', id)}
            style="dropdown"
            options={subFamilies}
            target="dropdown"
          />
        </div>
        <div
          className={`products-list ${hidePadding ? '' : 'pd20-t-b pd8-r-l'} `}
        >
          <div className="products-wrapper">
            <div className={`header-products fl_row jc_s pd10-t-b`}>
              <div className="fl_row ai_c gp8 ">
                <input
                  autoComplete="off"
                  checked={topAddEnabled && allChecked}
                  type="checkbox"
                  name=""
                  id=""
                  onChange={(e) => {
                    onSelect({} as ArticleSaved, e.target.checked, true);
                  }}
                />
                <p className="p14 OxfordBlue02-txt">
                  {languageData.fr.navMenu.selectionnerTous}
                </p>
              </div>
              <div
                className="Select-all fl_row gp8 c-p"
                onClick={onDragSelected}
              >
                <p
                  className={`p12  ${!topAddEnabled ? 'LightSilver-txt' : ''}`}
                >
                  {languageData.fr.navMenu.ajouter}
                </p>
                <img
                  src={topAddEnabled ? AddProductIconBlue : AddProductIcon}
                  alt=""
                />
              </div>
            </div>
            <div
              className={`listing-products fl_col gp12 ${hidePadding ? '' : ''} `}
            >
              {articles &&
                articles?.map(
                  (article) =>
                    !article.disabled && (
                      <AddProduct
                        selected={article.selected}
                        onChange={(e) => onSelect(article, e.target.checked)}
                        onDrag={() => onDrag(article)}
                        name={article.name}
                        img={article.url}
                      />
                    ),
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
