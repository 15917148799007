export const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

const formatDateToISOString = (inputDate: string): string => {
  if (!isValidDate(inputDate)) {
    return '';
  }

  // Create a new Date object from the input date string
  const dateObject = new Date(inputDate);

  // Set the time to midnight (00:00:00) in the browser's timezone
  dateObject.setHours(0, 0, 0, 0);

  // Get the ISO 8601 string representation of the date in local timezone
  const isoString = dateObject.toISOString();

  // Return the formatted ISO string
  return isoString;
};

export const formatDateToDayMonthYear = (inputDate: string): string => {
  // Check if the input date string is valid
  if (!isValidDate(inputDate)) {
    return '';
  }

  const dateObject = new Date(inputDate);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  // Format the date as 'dd/MM/YYYY'
  const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year.toString()}`;

  // Return the formatted date
  return formattedDate;
};

export default formatDateToISOString;
