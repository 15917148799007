export const configProd = {
  api_timeout: 5000,
  app_version: '1.1.0',
  mode: 'dev',
  api_baseUrl: 'https://fi-ne-prod-bomobile-01.azurewebsites.net',
  //api_baseUrl: 'https://backendbackoffice.frenchinnov.fr',
  api_baseUrl_Picture: 'https://fioeatpublicfolder.frenchinnov.fr/uploads/',
  signalR_hub_url: '/chatHub',
};

