import { serviceEtablissementModels } from '../../models/services/module.etablissement';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceEtablissementModels.EtablissementState = {
  etablissement: null,
  etablissement_general_info: null,
  etablissements: {
    establishements: [],
    nbestablishements: 0,
  },
  addError: undefined,
  addSuccess: undefined,
  totalPagesNumbers: 0,
  etablissementSheet: undefined,
  etablissementInfos: undefined,
  financialData: undefined,
  editResponse: undefined,
  etablissementId: 0,
  selectedExistingCompanyCreateEtablissement: 0,
  addEtablissementFormData: null,
  addEtablissementStep: 1,
  validationRequestedEtablissement: false,
  validationRequestedFiscalEtablissement: false,
  validationRequestedStep2Etablissement: false,
  stepsDataEtablissement: [],
};
type Action =
  | serviceEtablissementModels.etablissementPayload
  | serviceEtablissementModels.etablissementId
  | servicePaginationModels.PaginationModelType;
function reducer(
  state = initialState,
  action: {
    type: string;
    payload: Action;
    establishements: serviceEtablissementModels.setAllEstablishementAction;
  },
) {
  switch (action.type) {
    case actionTypes.ADD_ETABLISSEMENT: {
      return { ...state, etablissement: action.payload };
    }
    case actionTypes.ADD_ETABLISSEMENT_SUCCESS: {
      return { ...state, addSuccess: true, etablissementId: action.payload };
    }
    case actionTypes.ADD_ETABLISSEMENT_FAILURE: {
      return { ...state, addSuccess: false };
    }
    case actionTypes.ADD_ETABLISSEMENT_GENERAL_INFO: {
      return { ...state, etablissement_general_info: action.payload };
    }
    case actionTypes.REMOVE_ETABLISSEMENT: {
      return {
        ...state,
        etablissementId: action.payload,
      };
    }
    case actionTypes.EDIT_ETABLISSEMENT_GENERAL_INFO:
      return {
        ...state,
        etablissement: action.payload,
      };
    case actionTypes.EDIT_ETABLISSEMENT_FINANCIAL_INFO:
      return {
        ...state,
        etablissement: action.payload,
      };
    case actionTypes.GET_ETABLISSEMENT: {
      return { ...state, etablissement: action.payload };
    }
    case actionTypes.GET_ALL_ETABLISSEMENT:
      return {
        ...state,
        etablissements: [],
      };
    case actionTypes.SET_ALL_ETABLISSEMENT:
      return {
        ...state,
        etablissements: {
          establishements: action.establishements.establishements,
          nbestablishements:
            action.establishements.establishements_total_number,
        },
      };
    case actionTypes.SET_ALL_ETABLISSEMENT_TOTAL_PAGE_NUMBER:
      return {
        ...state,
        totalPagesNumbers: action.payload,
      };
    case actionTypes.SET_ETABLISSEMENT_SHEET:
      return {
        ...state,
        etablissementSheet: action.payload,
      };
    case actionTypes.SET_ETABLISSEMENT_INFOS:
      return {
        ...state,
        etablissementInfos: action.payload,
      };
    case actionTypes.GET_ETABLISSEMENT_SHEET:
      return {
        ...state,
      };
    case actionTypes.GET_ETABLISSEMENT_INFOS:
      return {
        ...state,
        etablissementInfos: action.payload,
      };
    case actionTypes.GET_ETABLISSEMENT_FINANCIAL_INFOS:
      return {
        ...state,
      };
    case actionTypes.SET_ETABLISSEMENT_FINANCIAL_INFOS:
      return {
        ...state,
        financialData: action.payload,
      };
    case actionTypes.EDIT_ETABLISSEMENT_SUCCESS:
      return {
        ...state,
        editResponse: true,
      };
    case actionTypes.EDIT_ETABLISSEMENT_FAILURE:
      return {
        ...state,
        editResponse: false,
      };
    case actionTypes.RESET_EDIT_ETABLISSEMENT_RESPONSE:
      return {
        ...state,
        editResponse: undefined,
      };
    case actionTypes.RESET_ADD_ETABLISSEMENT_SUCCESS_STATE:
      return {
        ...state,
        addSuccess: undefined,
      };
    case actionTypes.SELECT_COMPANY_EXISTING_CREATE_ETABLISSEMENT:
      return {
        ...state,
        selectedExistingCompanyCreateEtablissement: action.payload,
      };
    case actionTypes.RESET_COMPANY_EXISTING_CREATE_ETABLISSEMENT:
      return {
        ...state,
        selectedExistingCompanyCreateEtablissement: 0,
      };
    /********************* */
    case actionTypes.ADD_ETABLISSEMENT_FORM_DATA:
      return {
        ...state,
        addEtablissementFormData: action.payload,
      };
    case actionTypes.RESET_ETABLISSEMENT_FORM_DATA:
      return {
        ...state,
        addEtablissementFormData: null,
      };
    case actionTypes.ADD_ETABLISSEMENT_STEP:
      return {
        ...state,
        addEtablissementStep: action.payload,
      };
    /************************ add etablissement form states */
    case actionTypes.ADD_ETABLISSEMENT_VALIDATION_REQUESTED:
      return {
        ...state,
        validationRequestedEtablissement: action.payload,
      };
    case actionTypes.ADD_ETABLISSEMENT_VALIDATION_REQUESTED_STEP2:
      return {
        ...state,
        validationRequestedStep2Etablissement: action.payload,
      };
    case actionTypes.ADD_ETABLISSEMENT_VALIDATION_REQUESTED_FISCAL:
      return {
        ...state,
        validationRequestedFiscalEtablissement: action.payload,
      };
    case actionTypes.ADD_ETABLISSEMENT_STEPS_DATA:
      return {
        ...state,
        stepsDataEtablissement: action.payload,
      };
    /********************* */
    default:
      return state;
  }
}

export default reducer;
