import React, { useState } from 'react';
import '../Pagination/Pagination.css';
import LeftPaginationArrow from '../../assets/icons/LeftPaginationArow.svg';
import RightPaginationArrow from '../../assets/icons/RightArrowGreyIcon.svg';
import Spinner from '../../components/skeleton/spinner';
import { languageData } from '../../i18n';
import SkeletonLoader from '../SkeletonLoader';

/**
 * Pagination component description.
 *
 * @Pagination
 *
 */

interface PaginationProps {
  totalPagesNumbers?: number;
  nextpageClick?: any;
  prevpageClick?: any;
  currentPage?: number;
  totalPages?: number;
  handlePageChange?: (page: number) => void;
  showSkeleton?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPagesNumbers,
  nextpageClick,
  prevpageClick,
  currentPage,
  totalPages,
  handlePageChange,
  showSkeleton,
}: PaginationProps) => {
  const [loading, setLoading] = useState(false);

  const getPageNumbers = () => {
    const pages: (string | number)[] = [];

    // Calculate start and end pages
    const startPage: number = Math.max((currentPage || 1) - 2, 1);
    const endPage: number = Math.min((currentPage || 1) + 2, totalPages || 1);

    // Add the first page and ellipsis if necessary
    if ((currentPage || 1) > 3) {
      pages.push(1);
      if ((currentPage || 1) > 4) {
        // pages.push('...');
      }
    }
    // Add the range of pages
    const pageRange = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
    pages.push(...pageRange);

    // Add the last page and ellipsis if necessary
    if ((currentPage || 1) < (totalPages || 1) - 2) {
      if ((currentPage || 1) < (totalPages || 1) - 3) {
        pages.push('...');
      }
      pages.push(totalPages || 1);
    }

    return pages;
  };

  const handlePageClick = (page: number) => {
    setLoading(true);
    if (handlePageChange) {
      handlePageChange(page);
    }
    setLoading(false);
  };

  return (
    <div className="pagination fl_row jc_s">
      <SkeletonLoader show={showSkeleton} type="card" />
      <span className="p14 w-500">
        {languageData.global.Pagination.page} {currentPage}{' '}
        {languageData.global.Pagination.of} {totalPages}
      </span>
      <div className="fl_row">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <button
              className="page-link p14 w-500"
              onClick={prevpageClick}
              disabled={currentPage === 1}
            >
              <img src={LeftPaginationArrow} alt="Previous" />
            </button>
            {getPageNumbers().map((page, index) =>
              typeof page === 'string' ? (
                <span key={index} className="page-ellipsis p14 w-500">
                  {page}
                </span>
              ) : (
                <button
                  key={index}
                  className={`page-link p14 w-500 ${
                    page === currentPage ? 'active' : ''
                  }`}
                  onClick={() => handlePageClick(page)}
                >
                  {page}
                </button>
              ),
            )}
            <button
              className="page-link p14 w-500"
              onClick={nextpageClick}
              disabled={currentPage === totalPages}
            >
              <img src={RightPaginationArrow} alt="Next" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Pagination;
