import FieldsAccessRightsItem from '../UserRoles/FieldsAccessRightsItem';

export default function FieldsAccessRightsModalContent() {
  return (
    <div className="FieldsAccessRightsModalContent">
      <div className="modal-wrapper fl_col gp20">
        <div className="fields-header fl_row jc_fe">
          <div className="fl_row gp60">
            <div className="fl_row gp10 visibility-row ">
              <label className="p12 w-900" htmlFor="status">
                Hide / Show
              </label>
              <input
                autoComplete="off"
                className="primaryCheckbox"
                type="checkbox"
                id="status"
                name="status"
              />
            </div>
            <div className="fl_row gp10 status-row">
              <label className="p12 w-900" htmlFor="visibility">
                Enable / Disable
              </label>
              <input
                autoComplete="off"
                className="primaryCheckbox"
                type="checkbox"
                id="visibility"
                name="visibility"
              />
            </div>
          </div>
        </div>
        <div className="fields-listing fl_col">
          <FieldsAccessRightsItem name="Informations générales" />
          <FieldsAccessRightsItem name="Update statut" />
          <FieldsAccessRightsItem name="Information fiscales" />
          <FieldsAccessRightsItem name="Adresse physique " />
          <FieldsAccessRightsItem name="Input 1" />
          <FieldsAccessRightsItem name="Informations générales" />
          <FieldsAccessRightsItem name="Update statut" />
          <FieldsAccessRightsItem name="Information fiscales" />
          <FieldsAccessRightsItem name="Adresse physique " />
          <FieldsAccessRightsItem name="Input 1" />
          <FieldsAccessRightsItem name="Informations générales" />
          <FieldsAccessRightsItem name="Update statut" />
          <FieldsAccessRightsItem name="Information fiscales" />
          <FieldsAccessRightsItem name="Adresse physique " />
          <FieldsAccessRightsItem name="Input 1" />
          <FieldsAccessRightsItem name="Informations générales" />
          <FieldsAccessRightsItem name="Update statut" />
          <FieldsAccessRightsItem name="Information fiscales" />
          <FieldsAccessRightsItem name="Adresse physique " />
          <FieldsAccessRightsItem name="Input 1" />
        </div>
      </div>
    </div>
  );
}
