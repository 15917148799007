import React from 'react';
import RolesListBox from '../../../components/UserRoles/RolesListBox';
import RoleItem from '../../../components/UserRoles/RoleItem';

export default function RolesListContainer(props: any) {
  return (
    <div id="RolesListContainer" className="RolesListContainer">
      <RolesListBox>
        {props.sectionList.map((item: any) => {
          return (
            <RoleItem
              id={item.id}
              name={item.name}
              isActive={item.isActive}
              onClick={props.onClick}
              key={''}
            />
          );
        })}
      </RolesListBox>
    </div>
  );
}
