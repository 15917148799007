import React from 'react';
import {
  ArticleChanges,
  screen as screenType,
} from '../../models/services/module.catalogue';
import CheckBox from '../Catalogue/Checkbox';

/**
 * ModalEditArticle component description.
 *
 * @ModalEditArticle
 *
 */

interface ModalEditArticleProps {
  screens?: screenType[];
  articleChanges?: ArticleChanges;
  handleSelectEcran?: (
    screen?: screenType,
    all?: boolean,
    checked?: boolean,
  ) => void;
}
const ModalEditScreenArticle: React.FC<ModalEditArticleProps> = ({
  screens,
  articleChanges,
  handleSelectEcran,
}) => {
  return (
    <div className="ModalEditArticle fl_col fl-1 w-full">
      <div className="fl_row gp20 jc_st fl_wrap screen_list">
        {screens &&
          screens?.map((_, __) => (
            <CheckBox
              isChecked={
                articleChanges?.screens?.find(
                  (s) => (s.screenId ? s.screenId : s.id) == _.id,
                ) != undefined
              }
              onChange={(e) =>
                handleSelectEcran &&
                handleSelectEcran(_, false, e.target.checked)
              }
              label={_.designation}
              boxStyle
            />
          ))}
      </div>
    </div>
  );
};

export default ModalEditScreenArticle;
