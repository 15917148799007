import { put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  getListIntervallesAbonnementSuccess,
  getListIntervallesAbonnementFailure,
  createAbonnementSuccess,
  createAbonnementFailure,
  fetchAbonnementDataSuccess,
  fetchAbonnementDataFailure,
  getAbonnementByIdSuccess,
  getAbonnementByIdFailure,
  getListAbonnementsClientSuccess,
  getListAbonnementsClientFailure,
} from '../actions/actions';
import etablissementApi from '../../services/apiHandlers/etablissementApi';

interface bodyRequest {
  designation: string;
  description: string;
  totalHT: number;
  totalTTC: number;
  vat: number;
  intervalleId: number;
}
export function* getListIntervallesAbonnementSaga() {
  try {
    const response: AxiosResponse =
      yield etablissementApi.getListIntervallesAbonnement();

    if (response.data) {
      yield put(getListIntervallesAbonnementSuccess(response.data.result));
    }
  } catch (error: any) {
    yield put(getListIntervallesAbonnementFailure(error.message));
  }
}

export function* getAbonnementByIdSaga(action: {
  type: string;
  payload: { abonnementId: number };
}) {
  const { abonnementId } = action.payload;

  try {
    const response: AxiosResponse =
      yield etablissementApi.getAbonnementById(abonnementId);

    if (response.data) {
      yield put(getAbonnementByIdSuccess(response.data.result));
    }
  } catch (error: any) {
    yield put(getAbonnementByIdFailure(error.message));
  }
}

export function* addAbonnementSaga(action: {
  type: string;
  payload: {
    establishementId: number;
    data: bodyRequest;
    _?: () => void;
  };
}) {
  const { establishementId, data } = action.payload;
  try {
    const response: AxiosResponse = yield etablissementApi.addAbonnement(
      establishementId,
      data,
    );
    if (response.data) {
      yield put(createAbonnementSuccess(response.data.result));
      if (action.payload._) {
        action.payload._();
      }
    }
  } catch (error: any) {
    yield put(createAbonnementFailure(error.message));
  }
}

export function* listAbonnementSaga(action: {
  type: string;
  payload: { establishementId: number; page?: number; pageSize?: number };
}) {
  const { establishementId, page, pageSize } = action.payload;

  try {
    const response: AxiosResponse = yield etablissementApi.ListAbonnement(
      establishementId,
      page,
      pageSize,
    );
    if (response.data) {
      yield put(fetchAbonnementDataSuccess(response.data));
    }
  } catch (error: any) {
    yield put(fetchAbonnementDataFailure(error.message));
  }
}

export function* updateAbonnementSaga(action: {
  type: string;
  payload: { abonnementId: number; data: bodyRequest; _?: () => void };
}) {
  const { abonnementId, data } = action.payload;
  try {
    const response: AxiosResponse = yield etablissementApi.updateAbonnement(
      abonnementId,
      data,
    );
    if (response.data) {
      yield put(createAbonnementSuccess(response.data.result));
      if (action.payload._) {
        action.payload._();
      }
    }
  } catch (error: any) {
    yield put(createAbonnementFailure(error.message));
  }
}

export function* getListAbonnementsClientSaga(action: {
  type: string;
  payload: { establishementId: number };
}) {
  const { establishementId } = action.payload;

  try {
    const response: AxiosResponse = yield etablissementApi.getListAbonnementsClient(
      establishementId,
    );
    if (response.data) {
      yield put(getListAbonnementsClientSuccess(response.data));
    }
  } catch (error: any) {
    yield put(getListAbonnementsClientFailure(error.message));
  }
}