import * as actionTypes from '../actions/actionTypes';
interface Action {
  type: string;
  payload?: boolean;
}

const initialState = {
  etablissementState: false,
};

function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.ETABLISSEMENT_STATE: {
      return {
        ...state,
        etablissementState: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
