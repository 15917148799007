import React, { useState } from 'react';
import ListingEtatDeStockContainer from './ListingEtatDeStock';
import ChooseEtablissement from './ChooseEtablissement';
import EtatDeStockPrimaryColor from '../../../../assets/icons/EtatDeStockPrimaryColor.svg';
import HandDrawnImg from '../../../../assets/icons/HandDrawnImg.svg';
import EtatDeStockContainer from './EtatDeStockContainer';
import EtatDesStockImgContainer from '../../../../assets/images/EtatDesStockImgContainer.png';
export default function EtatDeStock() {
  const [showListingEtatDeStock, setShowListingEtatDeStock] = useState(true);
  return (
    <div className="EtatDeStockContainer white-bg h-full">
     <EtatDeStockContainer />
    </div>
  );
}
