import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTaxesModels } from '../../../models/services/module.taxesInfos';
import { getCodesNAF } from '../../../store/actions/actions';
import { useUnsavedChanges } from './useUnsavedChanges';

export const useFetchCodesNAF = () => {
  const dispatch = useDispatch();
  const { newFormData } = useUnsavedChanges();
  const codesNAF = useSelector(
    (state: { taxesInfos: serviceTaxesModels.TaxesInfosState }) =>
      state.taxesInfos.codesNaf_list,
  );

  useEffect(() => {
    dispatch(getCodesNAF());
  }, []);
  const codesNafReferences = React.useMemo(() => {
    if (codesNAF) {
      return codesNAF.map((item) => item.reference ?? '');
    }
    return [];
  }, [codesNAF]);

  const codesNafOptions = React.useMemo(() => {
    if (codesNAF) {
      return codesNAF.map((item) => item.designation);
    }
    return [];
  }, [codesNAF]);
  const codeNafDefaultValue = (): string | number | null => {
    if (codesNAF && newFormData?.payload?.fiscalInformation?.naF_code_id) {
      const selectedItem = codesNAF.find(
        (item: serviceTaxesModels.CodesNAF) =>
          item.id === newFormData?.payload?.fiscalInformation.naF_code_id,
      );
      return selectedItem ? selectedItem.designation : '';
    }
    return '';
  };

  return { codesNAF, codesNafOptions, codesNafReferences, codeNafDefaultValue };
};
