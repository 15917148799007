import { useState } from 'react';
// this hook will be replaced by the new one
const usePagination = (
  initialPaginationModel: servicePaginationModels.PaginationModelType,
) => {
  const [currentPage, setCurrentPage] = useState(
    initialPaginationModel.paginationModel.page,
  );

  const [updatePaginationModel, setPaginationModel] = useState<
    servicePaginationModels.PaginationModelType | any
  >(initialPaginationModel.paginationModel);

  const handleNextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (initialPaginationModel.paginationModel.nbPage) {
      if (
        initialPaginationModel.paginationModel.page <
        initialPaginationModel.paginationModel.nbPage
      ) {
        // Check if not on the last page
        const updatedModel = {
          nbPage: initialPaginationModel.paginationModel.nbPage,
          pageSize: initialPaginationModel.paginationModel.pageSize,
          page: initialPaginationModel.paginationModel.page + 1,
        }; // Update page in model
        setPaginationModel(updatedModel);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handlePreviousClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (initialPaginationModel.paginationModel.page > 1) {
      const updatedModel = {
        nbPage: initialPaginationModel.paginationModel.nbPage,
        pageSize: initialPaginationModel.paginationModel.pageSize,
        page: initialPaginationModel.paginationModel.page - 1,
      };
      setPaginationModel(updatedModel);
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageChange = (page: number) => {
    const updatedModel = {
      nbPage: initialPaginationModel.paginationModel.nbPage,
      pageSize: initialPaginationModel.paginationModel.pageSize,
      page,
    };
    setPaginationModel(updatedModel);
    setCurrentPage(page);
  };
  return {
    handleNextClick,
    handlePreviousClick,
    currentPage,
    updatePaginationModel,
    handlePageChange,
  };
};
//not used
export default usePagination;
