import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  ListFacture: null,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    nbPage: 1,
  },
  error: null,
};

const factureReducer = (state = initialState, action: any) => {

  switch (action.type) {
    
    case actionTypes.GET_LIST_FACTURE_REQUEST:
      return { ...state, loading: true };
    case actionTypes.GET_LIST_FACTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        ListFacture: action.payload,
        pagination: action.payload.pagination,
      };
    case actionTypes.GET_LIST_FACTURE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default factureReducer;
