import React from 'react';
import RemoveItemGreyIcon from '../../assets/icons/RemoveItemGreyIcon.svg';
/**
 * PermissionValue component description.
 *
 * @PermissionValue
 *
 */

interface PermissionValueProps {
  rowId?: string;
  name?: string;
  icon?: string;
  type?: 'role' | 'access' | 'slot';
  slot?: number;
  totalSlot?: number;
  onClickRemove?: () => void;
}

const PermissionValue: React.FC<PermissionValueProps> = ({
  rowId,
  name,
  icon,
  type,
  slot,
  totalSlot,
  onClickRemove,
}) => {
  return (
    <div className={`PermissionValue`} id={rowId}>
      <div className="item-wrapper fl_row gp12 ai_c">
        {type === 'slot' ? (
          <></>
        ) : (
          <>
            {icon && <img className="icon-item" src={icon} alt="" width={16} />}
            <p className="p14 AuroMetalSaurus-txt w-600">{name}</p>
            {/* {type === 'role' && (
              <img
                className="icon-remove c-p"
                src={RemoveItemGreyIcon}
                alt=""
                onClick={onClickRemove}
              />
            )} */}
          </>
        )}

        {type === 'access' && <span className="slot p12">{slot}</span>}
        {type === 'slot' && <span className="slot p12">+{totalSlot}</span>}
      </div>
    </div>
  );
};

export default PermissionValue;
