import React from 'react';

interface RightArrowIconGreyProps {
  fillColor?: string;
}

const RightArrowIconGrey: React.FC<RightArrowIconGreyProps> = ({
  fillColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="8"
      fill="none"
      viewBox="0 0 4 6"
    >
      <path
        fill={fillColor || '#667085'}
        fillRule="evenodd"
        d="M2.223 3L.361 1.138l.943-.943L3.637 2.53c.26.26.26.682 0 .942L1.304 5.805l-.943-.943L2.223 3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default RightArrowIconGrey;
