import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CardContainer from '../../CardContainer';
import HeaderEtablissementProfileInfo from '../../../components/ProfileInfo/HeaderEtablissement';
import TabSwitcherEtablissements from './TabSwitcherEtablissements';
import { languageData } from '../../../i18n';
import TableHeader from '../../../components/Table/TableHeader';
import FicheTabEtablissements from './FicheTabEtablissements';
import InformationFinanciereEtablissement from './InformationFinanciereEtablissement';
import {
  editEtablissementGeneralInfo,
  resetEditResponse,
  editEtablissementFinancialInfo,
  activateDeactivateEstablishementRequest,
  getEtablissementByIdInfos,
} from '../../../store/actions/actions';
import Toast from '../../../components/Toast/Toast';
import CatalogueTabContent from '../TabsCompany/CatalogueTabContent';
import UsersContainer from '../../Users/UsersContainer';
import ButtonElement from '../../../components/button/button';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionsListAdminContainer from './SubscriptionsListContainer';
import BillsListContainer from './BillsListContainer';
import LicencesListContainer from './LicencesListContainer';
import InputField from '../../../components/Input/Input';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';

interface EtablissementprofileInfoProps {
  id: number;
  activeMiniTab?: string;
  setActiveMiniTab?: Dispatch<SetStateAction<string>>;
  etablissementInfos: any;
  etablissementSheet: any;
  etablissementFinancialData: any;
}

export default function EtablissementsProfileInfoContainer(
  props: EtablissementprofileInfoProps,
) {
  const [formDateState, setFormDateState] = useState<
    Partial<serviceEtablissmentUpdate.IRoot>
  >({
    payload: { id: props?.id },
  });

  const [formDataFinancialInfos, setFormDataFinancial] = useState<
    Partial<serviceEtablissmentUpdate.IPayload>
  >({ payload: { id: props?.id } });

  const editResponse = useSelector(
    (state: { etablissement: { editResponse: boolean } }) =>
      state.etablissement.editResponse,
  );

  const claimsEtablissement = useSelector(
    (state: { auth: { claimsEtablissement: string[] } }) =>
      state.auth.claimsEtablissement,
  );

  const dirty = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.dirtyEditForms,
  );
  const ActivateDisactivateEtablissement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedActiveStatus = event.target.checked;
    dispatch(
      activateDeactivateEstablishementRequest(
        props.etablissementSheet?.id,
        updatedActiveStatus,
        () => {
          dispatch(
            getEtablissementByIdInfos({ id: props.etablissementSheet?.id }),
          );
        },
      ),
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetEditResponse());
  }, []);

  const handleSubmit = async () => {
    if (props.activeMiniTab === '1') {
      if (dirty) {
        dispatch(
          editEtablissementGeneralInfo(
            (formDateState as serviceEtablissmentUpdate.IRoot)?.payload,
          ),
        );
        dispatch(resetEditResponse());
      }
    }

    if (props.activeMiniTab === '2') {
      dispatch(
        editEtablissementFinancialInfo(
          (formDataFinancialInfos as serviceEtablissmentUpdate.IPayload)
            ?.payload,
        ),
      );
      dispatch(resetEditResponse());
    }
  };

  const completeAddress =
    props.etablissementInfos?.address +
    ', ' +
    props.etablissementInfos?.city +
    ', ' +
    props.etablissementInfos?.postal_code;
  return (
    <div className="ProfileInfo EtablissementsProfileInfoContainer fl_col gp10 h-full">
      <CardContainer ClassName="HeaderProfileInfoContainer" type="standard">
        <div className="profile-wrapper fl_col gp32">
          <HeaderEtablissementProfileInfo
            name={props.etablissementInfos?.commercial_Name}
            siren={props.etablissementInfos?.sireT_code}
            fixe={props.etablissementInfos?.fixed_line_telephone_number}
            mobile={props.etablissementInfos?.mobile_telephone_number}
            adresse={completeAddress}
            avatar={props.etablissementInfos?.logo}
          />
        </div>
        <div className="toggle-action">
          <InputField
            style="switcher"
            label={
              props.etablissementInfos?.status &&
              props.etablissementInfos?.status.name
            }
            name="active"
            checked={
              props.etablissementInfos?.status &&
              props.etablissementInfos?.status.name == 'active'
                ? true
                : false
            }
            onChange={ActivateDisactivateEtablissement}
          />
        </div>
      </CardContainer>

      <CardContainer ClassName="HeaderTabSwitcherContainer" type="standard">
        <TabSwitcherEtablissements
          setActiveMiniTab={props.setActiveMiniTab}
          activeMiniTab={props.activeMiniTab}
        />
      </CardContainer>

      {/* <FicheTabEtablissements /> 
     <InformationFinanciereEtablissement />  */}
      {props.activeMiniTab === '1' && (
        <>
          {claimsEtablissement?.includes('Fiche.Affichage') && (
            <>
              <TableHeader
                type="save"
                onClickSave={handleSubmit}
                textAdd={languageData.global.add}
                claimEtablissementModification={claimsEtablissement?.includes(
                  'Géneral.Modification',
                )}
                claimEtablissementFicheModification={claimsEtablissement?.includes(
                  'Fiche.Affichage',
                )}
              />
              <FicheTabEtablissements
                etablissementSheet={props.etablissementSheet}
                etablissementInfos={props.etablissementInfos}
                setFormDateState={setFormDateState}
                formDateState={formDateState}
                claimEtablissementModification={claimsEtablissement?.includes(
                  'Géneral.Modification',
                )}
              />
            </>
          )}
        </>
      )}
      {props.activeMiniTab === '2' && (
        <>
          <CardContainer type="standard" ClassName="save-settings fl_row jc_fe">
            {claimsEtablissement?.includes(
              'Information financière.Affichage',
            ) &&
              claimsEtablissement?.includes(
                'Information financière.Modification',
              ) && (
                <>
                  <ButtonElement
                    buttonStyle="btn-primary"
                    type={'TextButton'}
                    text={languageData.global.Modal.save}
                    buttonSize={'large'}
                    onClick={handleSubmit}
                  />
                </>
              )}
          </CardContainer>
          {claimsEtablissement?.includes(
            'Information financière.Affichage',
          ) && (
            <>
              <InformationFinanciereEtablissement
                //setDirty={setDirty}
                //id={props.id}
                financialInfos={props.etablissementFinancialData}
                setFormDataFinancial={setFormDataFinancial}
                formDataFinancialInfos={formDataFinancialInfos}
                claimEtablissementInformationFinancièreModification={claimsEtablissement?.includes(
                  'Information financière.Modification',
                )}
                //validationRequestedFinancial={validationRequestedFinancial}
                //formData={formDataFinancialInfos}
                //setFormData={setFormDataFinancialInfos}
                //setValidationRequestedFinancial={setValidationRequestedFinancial}
              />
            </>
          )}
        </>
      )}
      {/* {props.activeMiniTab === '5' && (
        <UserRolesContainer establishmentId={props.id} />
      )} */}
      {props.activeMiniTab === '6' && <UsersContainer />}
      {props.activeMiniTab === '7' && (
        <div>
          {/* <CardContainer type="standard"> */}
          <CatalogueTabContent
            companyId={props.id}
            companyName={props.etablissementInfos?.commercial_Name}
          />
          {/* </CardContainer> */}
        </div>
      )}
      {props.activeMiniTab === '8' && (
        // <SubscriptionsListUserContainer />
        <SubscriptionsListAdminContainer />
      )}
      {props.activeMiniTab === '9' && <BillsListContainer />}

      {props.activeMiniTab === '10' && <LicencesListContainer />}

      <Toast
        type={editResponse === true ? 'success' : 'error'}
        description={editResponse ? 'success' : 'error'}
        isVisible={editResponse}
        timeout={4000}
      />
      {/* </div>  */}
    </div>
  );
}
