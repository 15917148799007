import { useDispatch } from 'react-redux';
import axiosInstance, { BaseUrl, unAuthAxios } from './apiGate';
const authApi = {
  login: async (body: serviceAuthModels.loginBody) => {
    try {
      const response = await unAuthAxios.post(BaseUrl + '/Account/login', body);
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout: async () => {
    try {
      const response = await axiosInstance.post(BaseUrl + '/Account/logout');
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  forgetPasswordLink: async (body: string) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + '/Account/forgot-password-link',
        body,
      );
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  
  resetPasswordLink: async (body: serviceAuthModels.IresetPasswordPaylod) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + '/Account/reset-password-link',
        body,
      );
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
export default authApi;
