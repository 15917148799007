import React from 'react';

/**
 * PermissionItem component description.
 *
 * @PermissionItem
 *
 */

interface PermissionItemProps {
  name: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const PermissionItem: React.FC<PermissionItemProps> = ({
  name,
  id,
  onChange,
  checked,
}) => {
  return (
    <div className="PermissionItem fl-1">
      <div className="item-wrapper fl_row gp10 ai_c">
        <input
          autoComplete="off"
          className="primaryCheckbox"
          type="checkbox"
          name={name}
          id={id}
          onChange={onChange}
          checked={checked}
        />
        <label htmlFor={id}>{name}</label>
      </div>
    </div>
  );
};

export default PermissionItem;
