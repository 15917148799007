import React, { useEffect } from 'react';
import CloseBtnModalGreyIcon from '../../../assets/icons/CloseBtnModalGreyIcon.svg';
import ModalController from './ModalController';
import { ModalProps } from '../../../models/services/module.modals';
/**
 * Modal component description.
 *
 * @Modal
 *
 */
const Modal = (props: ModalProps) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const saveButton = document.getElementById(
          'save',
        ) as HTMLButtonElement | null;
        if (
          saveButton &&
          !saveButton.disabled &&
          props.handleSave &&
          props.visibility
        ) {
          props.handleSave();
        }
      }
    };
    if (!props.disableEnter) window.addEventListener('keydown', handleKeyDown);
    else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.handleSave, props.disableEnter]);
  let modalContentBody = ModalController(props).modalContentBody;
  let modalContentFooter = ModalController(props).modalContentFooter;
  if (!props.visibility) return null;
  return (
    <div
      className={`Modal ${props.className ? props.className : ''} ${props.version + '_modal'} ${props.type ? props.type : ''}  ${props.visibility ? '' : 'hidden'}`}
    >
      <div
        className={`OverlayModal ${props.withoutOverlay ? 'overlayXModal' : ''}`}
      ></div>
      <div className="ModalContent rd12">
        <div className="modal-wrapper pd24-t-b fl_col gp20 jc_s">
          <div className="ModalHeader ">
            <div className="fl_row jc_s pd24-r-l">
              <div>
                <p className="p18 w-900">{props.title}</p>
                {props.subtitle && (
                  <p className="pd5-t-b p12 w-400 ">{props.subtitle}</p>
                )}
              </div>

              {props.close && (
                <img
                  className="closebtn"
                  onClick={props.closeModal}
                  src={CloseBtnModalGreyIcon}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="ModalBody pd24-r-l fl_row jc_s h-full">
            {modalContentBody}
          </div>
          <div
            style={
              props.withoutOverlay
                ? {
                    paddingBottom: 20,
                  }
                : {}
            }
            className={`ModalFooter pd24-r-l h-full ${props.type == 'upload' ? 'hide' : ''} `}
          >
            {modalContentFooter}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
