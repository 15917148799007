import { put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  getFactureByIdFailure,
  getFactureByIdSuccess,
  getListFacutreFailure,
  getListFacutreSuccess,
} from '../actions/actions';
import etablissementApi from '../../services/apiHandlers/etablissementApi';

export function* fetchListFactureSaga(action: {
  type: string;
  payload: { idEstablishement: number; page?: number; pageSize?: number };
}) {
  const { idEstablishement, page, pageSize } = action.payload;
  try {
    const response: AxiosResponse = yield etablissementApi.getListFacture(
      idEstablishement,
      page,
      pageSize,
    );
    if (response?.data) {
      yield put(
        getListFacutreSuccess(response.data.result, response.data.pagination),
      );
    }
  } catch (error: string | any) {
    yield put(getListFacutreFailure(error.message));
  }
}

export function* fetchListFactureDetailsSaga(action: {
  type: string;
  payload: { idFacture: number };
}) {
  const { idFacture } = action.payload;
  try {
    const response: AxiosResponse =
      yield etablissementApi.getFactureById(idFacture);
    if (response?.data) {
      yield put(getFactureByIdSuccess(response.data.result));
    }
  } catch (error: string | any) {
    yield put(getFactureByIdFailure(error.message));
  }
}
