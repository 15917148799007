import {
  Family,
  SubFamily,
} from './../../../models/services/module.catalogue.d';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  subFamilies: SubFamily | undefined;
  familySuccess: boolean;
} = {
  subFamilies: undefined,
  familySuccess: false,
};
function reducer(
  state = initialState,
  action: { type: string; subFamilies?: Family[]; item?: string },
) {
  switch (action.type) {
    case actionTypes.SUCCESS_GET_SUBFAMILY: {
      return {
        ...state,
        subFamilies: action.subFamilies,
      };
    }
    case actionTypes.POST_SUBFAMILY_SUCCESS: {
      return {
        ...state,
        familySuccess: true,
      };
    }
    case actionTypes.CLEAR_STORE_ITEM: {
      let key = action?.item;
      let obj: Record<string, null> = {};
      obj[key as string] = null;
      return {
        ...state,
        ...obj,
      };
    }
    default:
      return state;
  }
}
export default reducer;
