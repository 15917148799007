import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const convertToPDF = () => {
  const targetElement = document.getElementById('InvoiceData');
  if (targetElement) {
    html2canvas(targetElement, {
      scale: 3, // Increase the scale factor to improve the resolution
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('image/jpeg', 1.0); // Set quality to 1.0 for best quality
      const pdf = new jsPDF('p', 'mm', 'a4');

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('invoice.pdf');
    });
  }
};
