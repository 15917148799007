import React from 'react';

interface Props {
  strokeColor?: string;
}

const BadgeIcon: React.FC<Props> = ({ strokeColor }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33301 4.2C1.33301 3.0799 1.33301 2.51984 1.55099 2.09202C1.74274 1.71569 2.0487 1.40973 2.42503 1.21799C2.85285 1 3.4129 1 4.53301 1H7.46634C8.58645 1 9.1465 1 9.57432 1.21799C9.95065 1.40973 10.2566 1.71569 10.4484 2.09202C10.6663 2.51984 10.6663 3.0799 10.6663 4.2V13L5.99967 10.3333L1.33301 13V4.2Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BadgeIcon;
