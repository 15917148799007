import React, { ReactNode } from 'react';

interface FormCardProps {
  children: ReactNode;
  labelCard?: string;
  labelDescription?: string;
  className?: string;
  fieldsetLabel?: string;
  grid: number;
  gap?: string;
  align?: 'flex-end' | 'flex-start' | 'center';
}

const FormCard: React.FC<FormCardProps> = ({
  children,
  labelCard,
  labelDescription,
  fieldsetLabel,
  className,
  grid,
  gap,
  align,
}) => {
  return (
    <div className={`FormCard gp12 w-600 ${className ? className : ''}`}>
      <div className="fl_col">
        {labelCard && <p className="p14 pd15-t-b">{labelCard}</p>}
        <p className="p13 Independence-txt w-300">{labelDescription}</p>
      </div>
      {fieldsetLabel && (
        <div className="fieldsetLabel pd15-t-b gp8 fl_row ai_c">
          <p className="p16 w-500 Independence-txt">{fieldsetLabel}</p>
          <span className="line"></span>
        </div>
      )}
      <div
        className={`Grid ${gap ? gap : ''}`}
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${grid}, 1fr)`,
          alignItems: align,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default FormCard;
