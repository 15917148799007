import React, { ChangeEvent, useState } from 'react';
import CloseBtnPrimary from '../../assets/icons/CloseBtnPrimary.svg';
import { languageData } from '../../i18n';
import { Channel } from '../../models/services/module.cart';
import { ModalProps } from '../../models/services/module.modals';
import CartCheckboxParentElement from '../Carts/CartCheckboxParentElement';
import CheckBox from '../Catalogue/Checkbox';
import InputField from '../Input/InputCatalogue';
import ButtonElement from '../button/button';

/**
 * Modal component description.
 *
 * @Modal
 *
 */

const ModalAddCarteSettings: React.FC<ModalProps> = (props) => {
  const [filtredItem, setfiltredItem] = useState<Channel[]>();
  const [query, setquery] = useState<string>();
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    let query = event.target.value;
    setquery(query);
  };
  return (
    <div
      className={`Modal ModalAddCarteSettings ${props.className ? props.className : ''} ${props.version + '_modal'} ${props.type} ${props.visibility ? '' : 'hidden'}`}
    >
      <div className="OverlayModal" />
      <div className="ModalContent rd12">
        <div className="modal-wrapper pd24 fl_col gp20 jc_s">
          <div className="fl_col">
            <div className="ModalHeader fl_row jc_s ai_s">
              <div className="fl_col gp5">
                <p className="p18 w-700 DarkJungleGreen-txt">
                  {'Affecter carte'}
                </p>
              </div>
              {props.close && (
                <img
                  className="closebtn"
                  onClick={props.handelclose}
                  src={CloseBtnPrimary}
                  alt=""
                />
              )}
            </div>
            <div className="ModalBody fl_col gp20">
              <div className="cart-header">
                <p className="p16 pd5-t-b Independence-txt pd10-b">
                  {'La carte est affectée au(x) entreprise(s) suivante(s) :'}
                </p>
              </div>
              <div className="steps fl_col gp30">
                <div className="step-header">
                  <div className="fl_row ai_c jc_s header-container">
                    <p className="p16 w-700">Entreprise</p>
                    <div className="search-inp">
                      <InputField
                        onChange={onSearch}
                        type="text"
                        placeholder="Search"
                        style="search"
                      />
                    </div>
                    <div className="select-all fl_row gp8">
                      <CheckBox
                        circle
                        onChange={(e) =>
                          filtredItem
                            ? setfiltredItem(
                                filtredItem.map((_) => ({
                                  ..._,
                                  assigned: true,
                                })),
                              )
                            : props.handlePvSelection &&
                              props.handlePvSelection(
                                e.target.checked,
                                true,
                                undefined,
                              )
                        }
                        label={languageData.fr.navMenu.selectionnerTous}
                        isChecked={
                          props.channelList?.filter((_) => _.assigned).length ==
                          props.channelList?.length
                        }
                        name="select-all"
                        className="select-all"
                      />
                    </div>
                  </div>
                </div>

                <div className="step01">
                  <div className="listing-list fl_col gp16">
                    {props.channelList
                      ?.filter((p) =>
                        p.name
                          ?.toLowerCase()
                          .includes(query?.toLowerCase() ?? ''),
                      )
                      ?.map((_, __) => (
                        <CartCheckboxParentElement
                          selectAll={_.assigned}
                          isCheckedAll={
                            _.typeMachines?.filter((m) => m.assigned).length ==
                            _.typeMachines?.length
                          }
                          onChangeSelectAll={(e) => {
                            props.handleMachineSelection &&
                              props.handleMachineSelection(
                                e.target.checked,
                                _.id,
                                _.id,
                                true,
                              );
                          }}
                          // Important "Zero bug" => Add Condition to select the checkbox type
                          BoxTypeSelectAll={'fullySelected'}
                          // BoxTypeSelectAll={'notFullySelected'}
                          // BoxTypeSelectAll={'emptySelection'}
                          circle
                          isChecked={_.assigned}
                          onChange={(e) =>
                            props.handlePvSelection &&
                            props.handlePvSelection(
                              e.target.checked,
                              false,
                              _.id,
                            )
                          }
                          name={_.name}
                        >
                          {_.assigned &&
                            _.typeMachines?.map((m, __) => (
                              <CartCheckboxParentElement
                                boxType="squareBox"
                                isChecked={m.assigned}
                                onChange={(e) =>
                                  props.handleMachineSelection &&
                                  props.handleMachineSelection(
                                    e.target.checked,
                                    m.id,
                                    _.id,
                                  )
                                }
                                name={m.name}
                              >
                                {/*  {_.pointOfSales?.map((p, i) => (
                            <CartCheckboxChildElement
                              onChange={(e) =>
                                props.handlePvSelection &&
                                props.handlePvSelection(
                                  p,
                                  e.target.checked,
                                  false,
                                  _.id,
                                )
                              }
                              name={p.name}
                              isChecked={p.assigned}
                            />
                          ))} */}
                              </CartCheckboxParentElement>
                            ))}
                        </CartCheckboxParentElement>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ModalFooter">
            {/*  <div className="steps-progress">
              <span
                className={`${stepNumber == 1 ? 'active-step' : ''}`}
              ></span>
              <span
                className={`${stepNumber == 2 ? 'active-step' : ''}`}
              ></span>
            </div> */}
            <div className="wrapperFooter">
              <div className="fl_row gp12">
                <div className="fl-1">
                  <ButtonElement
                    type={'TextButton'}
                    text={languageData.fr.Modal.cancel}
                    buttonSize={'small'}
                    onClick={props.handelclose}
                  />
                </div>
                <div className="fl-3">
                  <ButtonElement
                    type={'TextButton'}
                    text="Valider"
                    buttonSize={'small'}
                    buttonStyle="btn-primary"
                    onClick={props.handleSave}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddCarteSettings;
