import React, { ReactNode } from 'react';
import DoubleUpArrow from '../../assets/icons/DoubleUpArrow.svg';
interface ItemsListProps {
  id?: any;
  label?: string;
  children: ReactNode;
  slot?: number;
  selectItemsSlot?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  isChecked?: boolean;
  extend?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ItemsList: React.FC<ItemsListProps> = ({
  id,
  label,
  children,
  slot,
  selectItemsSlot,
  isChecked,
  onClick,
  extend,
  onChange,
}) => {
  return (
    <div
      className={`ItemsList ${extend ? 'extend-list' : ''} ${isChecked ? 'isChecked' : ''}`}
    >
      <div className="list-wrapper">
        <div className="list-header fl_row pd10-r-l pd15-t-b jc_s w-full">
          <div className="fl_row ai_c gp10">
            <input
              autoComplete="off"
              className="primaryCheckbox"
              type="checkbox"
              name={label}
              id="checkbox-o"
              checked={isChecked}
              onChange={(event) => onChange(event)}
            />
            <label htmlFor="checkbox-o"></label>
            <span>{label}</span>
          </div>
          {(slot ?? 0) < 1 ? (
            ''
          ) : (
            <div className="fl_row ai_c gp20">
              <div className="fl_row p14 w-700">
                <p style={{ color: '#2D874E' }}>{selectItemsSlot ?? '0'}</p>
                <span>/</span>
                <p>{slot}</p>
              </div>
              <img
                className="c-p arrow-img"
                src={DoubleUpArrow}
                alt=""
                width={10}
                onClick={onClick}
              />
            </div>
          )}
        </div>
        <div className={`list-items ${extend ? '' : 'hide'}`}>{children}</div>
      </div>
    </div>
  );
};

export default ItemsList;
