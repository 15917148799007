import { useState } from 'react';
type T = {
  currentPage: number;
  itemsPerPage: number;
};

export function usePagination(initialData: T) {
  const [currentPage, setCurrentPage] = useState(initialData.currentPage);
  const [itemsPerPage] = useState(initialData.itemsPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleNextClick = (pageNumber: number) =>
    setCurrentPage(pageNumber + 1);

  const handlePreviousClick = (pageNumber: number) =>
    setCurrentPage(pageNumber - 1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  return {
    currentPage,
    itemsPerPage,
    paginate,
    handleNextClick,
    handlePreviousClick,
    handlePageChange,
  };
}
