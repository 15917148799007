import React, { ReactNode } from 'react';
import ButtonElement from '../../../../components/button/button';
import RefreshIconWhite from '../../../../assets/icons/RefreshIconWhite.svg';
interface TableCardTypeProps {
  children: ReactNode;
  type: 'activated' | 'expired' | 'canceled';
  onClickActivate?: () => void;
}

const TableCardType: React.FC<TableCardTypeProps> = ({
  children,
  type,
  onClickActivate,
}) => {
  const TableHeader = () => {
    switch (type) {
      case 'activated':
        return (
          <div className="header-content fl_row ai_c jc_s">
            <p className="p16 w-500 FunGreen-txt">Activé</p>
          </div>
        );
      case 'canceled':
        return (
          <div className="header-content fl_row ai_c jc_s">
            <p className="p16 w-500 Nevada-txt">Annulé</p>
          </div>
        );
      case 'expired':
        return (
          <div className="header-content fl_row ai_c jc_s">
            <p className="p16 w-500 ChiliPepper-txt">Expiré</p>
            <ButtonElement
              buttonStyle="btn-primary"
              type={'IconButton'}
              text={'Activer'}
              buttonSize={'large'}
              onClick={onClickActivate}
              icon={RefreshIconWhite}
              reverse
            />
          </div>
        );
    }
  };
  return (
    <div className="TableCardType fl_col gp16">
      {TableHeader()}
      <div className="tables-listing fl_col gp20">{children}</div>
    </div>
  );
};
export default TableCardType;
