import { useDispatch, useSelector } from 'react-redux';

import {
  editEntrepriseFinancialInfo,
  editEntrepriseGeneralInfo,
  setValidationRequestedEditFicheTab,
  setValidationRequestedEditFinancialTab,
} from '../../../store/actions/actions';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import useResetResponse from './useResetResponse';

const useHandleSubmit = (activeMiniTab: string) => {
  const dispatch = useDispatch();
  const resetResponse = useResetResponse();

  const dirty = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.dirtyEditForms,
  );

  const editCompanyFormDataFinancial = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.editCompanyFormDataFinancial,
  );
  const editCompanyFormData = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.editCompanyFormData,
  );

  



  const successActiveMiniTabOne = () => {
   
    if (dirty) {
      dispatch(setValidationRequestedEditFicheTab(true));
  
      dispatch(editEntrepriseGeneralInfo(editCompanyFormData));
      resetResponse();
    }
  };

  const successActiveMiniTabTwo = () => {
    if (dirty) {
      dispatch(setValidationRequestedEditFinancialTab(true));
      dispatch(editEntrepriseFinancialInfo(editCompanyFormDataFinancial));
      resetResponse();
    }
  };

  const handleSubmit = () => {

    switch (activeMiniTab) {
      case '1':
        successActiveMiniTabOne();
        break;
      case '2':
        successActiveMiniTabTwo();
        break;
      default:
        break;
    }
  };

  return handleSubmit;
};

export default useHandleSubmit;
