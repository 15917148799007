import React, { ReactNode } from 'react';

interface MobileTableCardProps {
  children: ReactNode;
  className?: string;
  expand?: boolean;
  paid?: boolean;
}

const MobileTableCard: React.FC<MobileTableCardProps> = ({
  children,
  className,
  expand,
  paid,
}) => {
  return (
    <div
      className={`col-table fl_row ai_c pd10-t-b ${className ? className : ''}  ${expand ? 'ExpandTable' : ''}`}
    >
      <div className="col-wrapper pd8-r-l fl_col gp10">{children}</div>
    </div>
  );
};

export default MobileTableCard;
