import { AxiosResponse } from 'axios';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  levelPayload,
  levelsAction,
} from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
export function* startGetAllLevels(action: levelsAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllLevels();
    if (response.data) {
      action._ && action._(response.data?.data?.result??[]);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* getLevelById(action: levelsAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getLevelById(
      '' + action.id,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startPostLevels(action: levelsAction) {
  try {
    console.log(' action.level', action.levelPayload);
    const response: AxiosResponse = yield catalogueApi.postLevels(
      action.levelPayload as levelPayload,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* deleteLevels(action: levelsAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteLevel(
      '' + action.id,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
