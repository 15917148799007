import React, { ChangeEvent } from 'react';

/**
 * CheckBox component description.
 *
 * @CheckBox
 *
 */

interface CheckBoxProps {
  label?: string;
  isChecked?: boolean;
  name?: string;
  className?: string;
  id?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  boxStyle?: boolean;
  tripleCheck?: boolean;
  square?: boolean;
  circle?: boolean;
  errorMsg?: string;
  defaultValue?: boolean;
  boxType?:
    | 'fullySelected'
    | 'notFullySelected'
    | 'emptySelection'
    | 'squareBox'
    | 'roundedBox';
}

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  isChecked,
  className,
  onChange,
  name,
  boxStyle,
  tripleCheck,
  circle,
  id,
  defaultValue,
  square,
  boxType,
}) => {
  const GetBoxType = () => {
    switch (boxType) {
      case 'fullySelected':
        return <span className="fullySelected-checkbox-shape"></span>;
      case 'notFullySelected':
        return <span className="notFullySelected-checkbox-shape"></span>;
      case 'emptySelection':
        return <span className="emptySelection-checkbox-shape"></span>;
      case 'squareBox':
        return <span className="squareBox-checkbox-shape"></span>;
      case 'roundedBox':
        return <span className="roundedBox-checkbox-shape"></span>;
      default:
        return '';
    }
  };

  return (
    <div
      className={`CheckBox relative fl_row 
        ${boxStyle}
        ${isChecked ? 'checked' : ''} 
        ${circle ? 'circle-inp' : ''} 
        ${boxStyle ? 'boxStyle' : ''} 
        ${boxType ? boxType + '-checkbox-shape' : ''}`}
    >
      <div className="checkbox-wrapper fl_row ai_c gp4">
        <input
          defaultChecked={defaultValue}
          autoComplete="off"
          type="checkbox"
          name={name}
          id={id}
          checked={isChecked}
          onChange={onChange}
        />
        {circle && <span className="circle-checkbox-shape"></span>}
        {boxStyle && <span className="boxStyle-checkbox-shape"></span>}
        {tripleCheck && <span className="tripleCheck-checkbox-shape"></span>}
        {square && <span className="square-checkbox-shape"></span>}
        {boxType && GetBoxType()}
        {label && (
          <label
            className={`txt-nowrap p14 OxfordBlue02-txt w-500 ${className}`}
            htmlFor={name}
            id={name}
          >
            {label}
          </label>
        )}
      </div>
      {/* {errorMsg && (
        <div className="fl_row gp5 ai_c errorMsgCheckbox">
          <img src={ErrorIconRed} alt="" />
          <p className=" p12 VenetianRed-text">
            {'Field error msg is wrong !'}
          </p>
        </div>
      )} */}
    </div>
  );
};

export default CheckBox;
