import React, { useEffect } from 'react';
const FormWizzard = React.lazy(() => import('../FormWizzard/FormWizzard'));
import { ToastProps } from '../../../components/Toast/Toast';
import ToastHandler from '../Toast/ToastHandler';
import useInitializeStepsData from './useInitializeStepsData';
import useFormValidation from './useFormValidation';
import useToast from './useToast';
import useHandleFormChanges from './useHandleFormChanges';
import tabs_list from '../../../data/tabsList';
import { initialState } from '../../../utils/addCompanyFormInitialState';
import { unsavedChangesRegister } from '../../../store/actions/actions';
import { useDispatch } from 'react-redux';

const AddCompany: React.FC = () => {
  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);
  const dispatch = useDispatch();
  useInitializeStepsData();
  useFormValidation();
  useHandleFormChanges();
  useToast(toastRef);
  useEffect(() => {
    const array = [
      {
        key: tabs_list[3].name,
        value: {
          type: 'action',
          payload: {
            payload: {
              ...initialState
            },
          },
        },
      },
    ];
    dispatch(unsavedChangesRegister(array));
  },[])
  return (
    <div className='h-full form-section'>
      <FormWizzard />
      <ToastHandler ref={toastRef} />
    </div>
  );
};
export default AddCompany;
