import { languageData } from '../../../../i18n';

export const renderButtonText = (type = '', selectedStepNumber: number) =>
  languageData.global.Buttons.footer[
    selectedStepNumber === 1
      ? type === 'cancel'
        ? 'cancel'
        : 'next'
      : type === 'cancel'
        ? 'previous'
        : 'finish'
  ] || '';
