import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddElementIcon from '../../../assets/icons/AddElementCircleWhiteIcon.svg';
import CatalogueIconPrimary from '../../../assets/icons/CatalogueIconPrimary.svg';
import GlossaireIconPrimary from '../../../assets/icons/GlossaireIconPrimary.svg';
import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import ButtonElement from '../../../components/button/button';
import tabs_list from '../../../data/tabsList';
import { languageData } from '../../../i18n';
import {
  Channel,
  cardSingle,
  filtredPvConfig,
} from '../../../models/services/module.cart';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { OpenTab } from '../../../services/tabs/OpenTab';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import PageTable from '../../Table/PageTable';
import TableContent from '../../Table/TableContent';
import TableSection from '../../Table/TableSection';
import {
  getFiltredPvList,
  postAssignPv,
  updateCart,
} from '../../../store/actions/catalogues/actions';
import ModalAddCarteSettings from '../../../components/Modal/ModalAddCarteSettings';
import { serviceCampanyMachineTypeModels } from '../../../models/services/module.CampanyMachineType';
import { NotificationSuccessProvider } from '../../../utils/Notificationprovider';
import { fetchCompanyMachineTypesRequest } from '../../../store/actions/actions';

interface CatalogueTabContentProps {
  companyId: number;
  companyName: string;
}
interface pointOfSaleMachines {
  id: number;
  pointOfSaleId?: number;
  typeMachineId?: number;
  cardId: string;
  posId?: number;
  typeMachinesId?: number;
}
interface modalDataLcl {
  visible: boolean;
  title?: string;
  actionTarget?: string;
  actionTargetId?: string;
}
interface pointOfSaleMachine_Cards {
  id: number;
  pointOfSaleId?: number;
  typeMachineId?: number;
  cardId?: string;
}
export default function CatalogueTabContent(props: CatalogueTabContentProps) {
  const [carts, setcarts] = useState<cardSingle[]>([]);
  const navigate = useNavigate();
  const [listPv, setlistPv] = useState<Channel[]>();
  const [Card, setCard] = useState<{
    id: number;
  }>();
  const [Machines, setMachines] = useState<
    serviceCampanyMachineTypeModels.MachineType[]
  >([]);
  const handleClsePvSetting = () => {
    setsettingPv({ visible: false, title: '' });
    setlistPv([]);
  };
  const dispatch = useDispatch();
  const [settingPv, setsettingPv] = useState<modalDataLcl>({
    visible: false,
    title: '',
  });
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const cartAdded = useSelector(
    (state: { uiCatalogue: { cartAdded: boolean } }) =>
      state.uiCatalogue?.cartAdded,
  );
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const onClickEtatDeStockCard = () => {
    OpenTab({
      key: props.companyName,
      label: tabs_list[16].name,
      tabs: open_tabs,
      dispatch,
      id: 16,
      companyName: props.companyName,
      companyId: '' + props.companyId,
      isEtablishement: activeTabContent.isEtablishement ?? false,
    });
  };
  const onClickGlossaire = () => {
    // HandelMenuToggleBtn();
    OpenTab({
      key: props.companyName,
      label: tabs_list[6].name,
      tabs: open_tabs,
      dispatch,
      id: 5,
      companyName: props.companyName,
      companyId: '' + props.companyId,
      isEtablishement: activeTabContent.isEtablishement ?? false,
    });
  };
  const openCarte = () => {
    OpenTab({
      key: props.companyName,
      label: tabs_list[12].name,
      tabs: open_tabs,
      dispatch,
      id: 11,
      companyName: props.companyName,
      companyId: '' + props.companyId,
      isEtablishement: activeTabContent.isEtablishement ?? false,
    });
  };
  const fetchCarts = async (companyId?: string) => {
    try {
      let cards = await catalogueApi.getAllCarts(companyId as string);
      setcarts((cards.data?.data?.result ?? []) as cardSingle[]);
    } catch (error) {
      setcarts([]);
    }
  };
  useEffect(() => {
    if (cartAdded) {
      fetchCarts(activeTabContent.companyId);
      dispatch(updateCart(false));
    }
  }, [cartAdded]);
  useEffect(() => {
    fetchCarts(activeTabContent.companyId);
  }, []);

  const onEdit = (id?: string, designation?: string) => {
    navigate(`/carte/add`, { state: { card: { id, designation } } });
    OpenTab({
      key: designation,
      label: tabs_list[13].name,
      tabs: open_tabs,
      dispatch,
      id: 12,
      companyName: designation,
      companyId: '' + props.companyId,
      isEtablishement: activeTabContent.isEtablishement ?? false,
    });
  };

  const handleMachineSelection = (
    isChecked?: boolean,

    chId?: number,
    pvId?: number,
    all?: boolean,
  ) => {
    setlistPv((prev) =>
      prev?.map((item) =>
        item.id == pvId
          ? {
              ...item,
              typeMachines: item.typeMachines?.map((m) =>
                all
                  ? { ...m, assigned: isChecked ?? false }
                  : m.id == chId
                    ? { ...m, assigned: isChecked ?? false }
                    : m,
              ),
            }
          : { ...item },
      ),
    );
  };
  const handlePvSelection = (
    isChecked?: boolean,
    all?: boolean,
    chId?: number,
  ) => {
    setlistPv((prev) =>
      prev?.map((item) =>
        all
          ? {
              ...item,
              assigned: isChecked,
            }
          : item.id == chId
            ? {
                ...item,
                assigned: isChecked,
              }
            : { ...item },
      ),
    );
  };
  const onSubmitAssingPV = () => {
    const pvs = listPv?.filter((_) => _.assigned) as Channel[];

    const machs: number[] = Machines.filter((_) => _.assigned).map((m) => m.id);
    dispatch(
      postAssignPv(
        {
          actionTarget: '' + settingPv.actionTarget,
          actionTargetId: '' + settingPv.actionTargetId,
          cardId: '' + Card?.id,
          pointOfSaleMachines: pvs?.flatMap((p) =>
            p.typeMachines
              ?.filter((pm) => pm.assigned)
              ?.map((m) => ({
                id: 0,
                posId: p.id as number,
                typeMachinesId: m.id as number,
                cardId: '' + Card?.id,
              })),
          ) as pointOfSaleMachines[],
          pointOfSaleMachine_Cards: pvs?.flatMap((p) =>
            p.typeMachines
              ?.filter((pm) => pm.assigned)
              ?.map((m) => ({
                id: 0,
                pointOfSaleId: p.id as number,
                typeMachineId: m.id as number,
                cardId: '' + Card?.id,
              })),
          ) as pointOfSaleMachine_Cards[],
        },
        () => {
          NotificationSuccessProvider('add', 'Points de ventes et machines');
          setsettingPv({ visible: false });
          setlistPv([]);
        },
      ),
    );
  };
  const handleOpenPvSetting = (title: string, actionData?: filtredPvConfig) => {
    setsettingPv({ visible: true, title: title, ...actionData });
  };
  const onGetListPv = (config: filtredPvConfig, title: string, id: number) => {
    handleOpenPvSetting(title, config);
    dispatch(
      getFiltredPvList({ ...config, cardId: '' + id }, (_) => {
        setlistPv(_.map((p) => ({ ...p })));
      }),
    );
  };
  const handleSelectCard = (card: cardSingle) => {
    setsettingPv({
      ...settingPv,
      visible: true,
    });
    setCard(card);
    fetchMachines();
    onGetListPv(
      {
        actionTarget: 'CARD',
        actionTargetId: '' + card.id,
      },
      card.designation ?? '',
      card.id,
    );
  };
  const fetchMachines = async () => {
    const id = await localStorage.getItem('idCompany');
    const params = {
      companyId: parseInt(id ?? ''),
    };
    dispatch(
      fetchCompanyMachineTypesRequest(params, (_) => {
        console.log('Machines2', _);
        setMachines(
          _.map((m) => ({
            ...m,
          })),
        );
      }),
    );
  };
  return (
    <>
      <PageTable className="CatalogueTabContent">
        <TableSection>
          <div className="tab-header fl_row w-full jc_s fl_wrap gp20 hide">
            <div className="table-info fl_col gp10">
              <div className="fl_row gp40">
                <p className="p18 w-900 DarkJungleGreen-txt">
                  {languageData.global.catalogue.cartes}
                </p>
              </div>
            </div>
            <div className="fl_row gp8 ai_c fl_wrap jc_fe">
              {!activeTabContent?.isEtablishement && (
                <ButtonElement
                  reverse
                  buttonStyle="btn-white-primary"
                  type="IconButton"
                  buttonSize="large"
                  text={languageData.global.catalogue.glossaire}
                  icon={GlossaireIconPrimary}
                  onClick={onClickGlossaire}
                />
              )}
              <ButtonElement
                reverse
                buttonStyle="btn-white-primary"
                type="IconButton"
                buttonSize="large"
                text={languageData.global.catalogue.stockStatus}
                icon={CatalogueIconPrimary}
              />

              {!activeTabContent?.isEtablishement && (
                <ButtonElement
                  onClick={openCarte}
                  reverse
                  buttonStyle="btn-primary"
                  type="IconButton"
                  buttonSize="large"
                  text={languageData.global.catalogue.CreateCart}
                  icon={AddElementIcon}
                />
              )}
            </div>
          </div>
          <TableHeader
            isEtablishement={activeTabContent?.isEtablishement}
            type="catalogue"
            title={languageData.global.catalogue.cartes}
            textAddMobile="Nouveau"
            onClickGlossaire={onClickGlossaire}
            onClickOpenCart={openCarte}
            onClickEtatDeStock={onClickEtatDeStockCard}
          />
          <TableContent>
            <DesktopTableContainer>
              <Table className="Table TableCatalogues rd12">
                <Thead>
                  <Tr>
                    <Td type="text" value={'Désignation'} width={97} />
                    <Td type="empty" width={3} />
                  </Tr>
                </Thead>
                <Tbody>
                  {carts &&
                    carts.map((_) => (
                      <Tr>
                        <Td type="text" value={_.designation} width={97} />
                        {/* <Td type="edit-catalogue" width={3} /> */}
                        {!activeTabContent?.isEtablishement && (
                          <Td
                            type="edit-catalogue"
                            onClick={() => handleSelectCard(_)}
                          />
                        )}
                        <Td
                          width={3}
                          type="nav"
                          onClick={() => onEdit(_.id + '', _.designation)}
                        />
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </DesktopTableContainer>
          </TableContent>
        </TableSection>
      </PageTable>
      {settingPv.visible && (
        <ModalAddCarteSettings
          handleMachineSelection={handleMachineSelection}
          machines={[...Machines]}
          handlePvSelection={handlePvSelection}
          channelList={listPv}
          handelclose={handleClsePvSetting}
          description={[
            'Sélectionner les points de vente ou vous souhaitez faire apparaître la carte.',
          ]}
          subtitle="Points de vente affectés"
          title={settingPv.title ?? ''}
          version={'Item'}
          type={'edit'}
          visibility={settingPv.visible}
          close
          handleSave={onSubmitAssingPV}
          maxWidth={680}
        />
      )}
    </>
  );
}
