export default function getCurrentDate(): string {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  // Formatting month and day with leading zeros if necessary
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  // Return the formatted date in 'yyyy-mm-dd' format
  return `${year}-${formattedMonth}-${formattedDay}`;
}
