import { languageData } from '../../i18n';

export const generateEmptyText = (page: string,type:string) => {
  return (type=="l"? languageData.fr.emptyText.noElement:type=="nl"?languageData.fr.emptyText.noElementte:languageData.fr.emptyText.noElementt) + ' ' + page;
};
export function isValidURL(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}