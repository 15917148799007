import React, { ReactNode, useState } from 'react';
import MenuCatalogueComponents from './MenuCatalogue.components';
import IngredientNavbarContainer from '../../../container/Articles/IngredientNavbar';
import AllergenesNavbarContainer from '../../../container/Articles/AllergenesNabar';
import NavMenu from '../../../container/Menus/NavMenu';
import NavCategory from '../../../container/Menus/NavCategory';
export default function LeftNavbarCatalogueComponent({
  type,
  changeCurrentPath,
  currentPath
}: {
  type: 'basic' | 'ing' | 'alg' | 'menu' | 'category';
  changeCurrentPath: (path: string) => void;
  currentPath:string
}) {
  const [showMenuReduce, setShowMenuReduce] = useState(false);
  const HandelMenuReduce = () => {
    document.querySelector('.version-app')?.classList?.toggle('hide');

    setShowMenuReduce((prevState) => !prevState);
  };

  return (
    <div
      className={`LeftnavbarCatalogue fl_col ${showMenuReduce ? 'ReduceMenu' : ''}`}
    >
      {type == 'alg' ? (
        <AllergenesNavbarContainer  />
      ) : type == 'ing' ? (
        <IngredientNavbarContainer  currentPath={currentPath}  />
      ) : type == 'menu' ? (
        <NavMenu  currentPath={currentPath}/>
      ) : type == 'category' ? (
        <NavCategory currentPath={currentPath} ></NavCategory>
      ) : (
        <MenuCatalogueComponents currentPath={currentPath} HandelMenuReduce={HandelMenuReduce} changeCurrentPath={changeCurrentPath} />
      )}
    </div>
  );
}
