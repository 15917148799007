import React, { useEffect } from 'react';

interface OptionItemProps {
  target: 'country' | 'dropdown' | 'search' | 'reference' | 'reflags';
  value: string;
  icon?: string;
  onClick?: (value: string, icon?: string, reference?: string) => void;
  reference?: string;
}

const OptionItem: React.FC<OptionItemProps> = ({
  target,
  value,
  icon,
  onClick,
  reference,
}) => {
  const optionContent = () => {
    switch (target) {
      case 'country':
        return (
          <li
            className="fl_row p12 ai_c gp10 pd8-t-b pd10-r-l"
            onClick={() => onClick && onClick(value, icon)}
          >
            {icon && (
              <img
                src={icon ? require(`../../data/flags/${icon}`) : ''}
                alt="icon"
                style={{ width: '20px', height: '20px' }}
              />
            )}
            {value}
          </li>
        );
      case 'search':
      case 'dropdown':
        return (
          <li
            className="fl_row ai_c gp10 pd8-t-b pd10-r-l item-list"
            onClick={() => onClick && onClick(value)}
          >
            {icon && (
              <img
                src={icon}
                alt="icon"
                style={{ width: '20px', height: '20px' }}
              />
            )}
            <p>{value}</p>
          </li>
        );

      case 'reference':
        return (
          <li
            className="fl_row ai_c gp10 pd8-t-b pd10-r-l item-list"
            onClick={() => onClick && onClick(value, reference)}
          >
            <div className="fl_row gp10">
              <span className="reference">{reference} </span>
              <span className="value">{value}</span>
            </div>
          </li>
        );

      case 'reflags':
        return (
          <li
            className="fl_row ai_c gp10 pd8-t-b pd10-r-l item-list"
            onClick={() => {
              {
                onClick && onClick(value, reference);
              }
            }}
          >
            {icon && (
              <img
                src={icon ? require(`../../data/flags/${icon}`) : ''}
                alt="icon"
                style={{ width: '20px', height: '20px' }}
              />
            )}
            <div className="fl_row gp10">
              <span className="reference">{reference} </span>
            </div>
          </li>
        );
        return null;
    }
  };

  return <>{optionContent()}</>;
};

export default OptionItem;
