import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  getBadgesFail,
  uploadImageSuccess,
} from '../../actions/catalogues/actions';
import {
  badgeAction,
  imageAction,
} from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
export function* startGetImages(action: imageAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getImagesOfFolder(
      action.path as string,
    );
    if (response.data) {
      yield put(uploadImageSuccess(response.data?.data?.result));
      action?._ ? action._(response.data?.data?.result) : null;
    }
  } catch (error: any) {
    yield put(getBadgesFail(error));
  }
}
export function* startPostImage(action: imageAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postImageInFolder(
      action.path as string,
      parseInt(action.id as string),
      action.img,
    );
    if (response.data) {
      yield put(uploadImageSuccess(response.data?.data?.result));
      action._ ? action._() : null;
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startDelteImage(action: imageAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteImage(
      parseInt(action.id as string),
    );
    if (response.data) {
      yield put(uploadImageSuccess(response.data?.data?.result));
      action._ ? action._() : null;
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
