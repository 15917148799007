import {
  Family,
} from './../../../models/services/module.catalogue.d';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  families: Family[];
  subfamilies: Family[];
  familySuccess: boolean;
} = {
  families: [],
  subfamilies: [],
  familySuccess: false,
};
function reducer(
  state = initialState,
  action: { type: string; families?: Family[]; item?: string },
) {
  switch (action.type) {
    case actionTypes.SUCCESS_GET_FAMILY: {
      return {
        ...state,
        families: action.families,
      };
    }
    case actionTypes.POST_FAMILY_SUCCESS: {
      return {
        ...state,
        familySuccess: true,
      };
    }
    case actionTypes.CLEAR_STORE_ITEM: {
      let key = action?.item;
      let obj: Record<string, null> = {};
      obj[key as string] = null;
      return {
        ...state,
        ...obj,
      };
    }
    default:
      return state;
  }
}
export default reducer;
