import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import * as actionTypes from '../actions/actionTypes';
const initialState = {
  abonnement: null,
  loading: false,
  error: null,
};

const abonnementByIdReducer = (
  state = initialState,
  action: {
    type: string;
    payload: serviceEtablissmentMachineTypeModels.DataAbonnementById;
  },
) => {
  switch (action.type) {
    case actionTypes.GET_ABONNEMENT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_ABONNEMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        abonnement: action.payload,
      };
    case actionTypes.GET_ABONNEMENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.RESET_ABONNEMENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default abonnementByIdReducer;
