import * as actionTypes from '../actions/actionTypes';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';

const initialState = {
  loading: false,
  getListStatutsEst: null,
  error: null,
};

const getListStatutsEstablishementsReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case actionTypes.GET_LIST_STATUTS_ESTABLISSEMENTS_REQUEST:
      return { ...state, loading: true, error: null };
    case actionTypes.GET_LIST_STATUTS_ESTABLISSEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getListStatutsEst: action.payload,
      };
    case actionTypes.GET_LIST_STATUTS_ESTABLISSEMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default getListStatutsEstablishementsReducer;
