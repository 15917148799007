import React from 'react';
import { ModalProps } from '../../../models/services/module.modals';
export default function UnsavedChangeModalController(props: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper fl_col gp5">
      {props.description &&
        props.description.map((desc, index) => <p key={index}>{desc}</p>)}
    </div>
  );
  const modalContentFooter = (
    <div className="wrapperFooter">
      <div className="fl_row gp12">
        {props.cancel && (
          <button
            style={{ border: '1px solid grey' }}
            className="btn medium btn-lightGrey"
            onClick={props.closeModal}
          >
            {props.cancelTxt}
          </button>
        )}
        {props.reject && (
          <button
            className="fl-2 btn medium btn-primary"
            onClick={props.handleSave}
          >
            {props.rejectTxt}
          </button>
        )}
      </div>
    </div>
  );

  return { modalContentBody, modalContentFooter };
}
