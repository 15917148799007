import React from 'react';
import ArrowRightPrimary from '../../assets/icons/ArrowRightPrimary.svg';
/**
 * RoleItem component description.
 *
 * @RoleItem
 *
 */

interface RoleItemProps {
  name: string;
  id: string;
  // key : number;
  isActive?: boolean;
  onClick: (id: string) => void;
}

const RoleItem: React.FC<RoleItemProps> = ({ name, id, onClick, isActive }) => {
  return (
    <div
      className={`RoleItem fl_row ai_c jc_s pd10-t-b pd20-r-l ${isActive ? 'active-item white-bg ' : ''}`}
      onClick={() => onClick(id)}
      id={id}
      // key={key}
    >
      <p className="p16 w-400">{name}</p>
      {isActive && <img src={ArrowRightPrimary} alt="" />}
    </div>
  );
};

export default RoleItem;
