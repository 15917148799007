import { serviceRolesModels } from '../../models/services/module.role';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceRolesModels.RolesState = {
  roles: [],
  roleById: {},
  roleByIdUpdated: {},
  loading: false,
  error: null,
  storeID: '',
  addRoleSuccess: undefined,
  addResponse: false,
  deleteResponse: null,
  newRole: false,
  nbRoles: 0,
  totalPagesNumbers: 0,
};

function reducer(state = initialState, action: serviceRolesModels.rolesAction) {
  switch (action.type) {
    case actionTypes.GET_ROLES_LIST: {
      return { ...state };
    }
    case actionTypes.GET_ETABLISSEMENT_ROLES_LIST: {
      return { ...state };
    }
    case actionTypes.RESET_ROLES_LIST: {
      return { ...state, roles: [] };
    }
    case actionTypes.RESET_ADD_RESPONSE: {
      return { ...state, addRoleSuccess: undefined };
    }
    case actionTypes.GET_ROLES_LIST_SUCCESS: {
      return { ...state, roles: action.payload };
    }
    case actionTypes.SET_ROLES_TOTAL_PAGE_NUMBER: {
      return { ...state, totalPagesNumbers: action.payload };
    }
    case actionTypes.SET_ROLES_NUMBER: {
      return {
        ...state,
        nbRoles: action.payload,
      };
    }
    case actionTypes.ADD_ROLE: {
      return {
        ...state,
      };
    }
    case actionTypes.ADD_ROLE_SUCCESS: {
      return {
        ...state,
        addRoleSuccess: true,
      };
    }
    case actionTypes.ADD_ROLE_FAILURE: {
      return {
        ...state,
        addRoleSuccess: false,
      };
    }
    case actionTypes.ADD_NEW_ROLE: {
      return {
        ...state,
        newRole: action.payload,
      };
    }
    case actionTypes.GET_ROLES_ID: {
      return {
        ...state,
        roleById: action.payload,
      };
    }
    case actionTypes.STORE_ID: {
      return {
        ...state,
        storeID: action.payload,
      };
    }
    case actionTypes.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roleByIdUpdated: action.payload,
      };
    case actionTypes.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Failed to update role',
      };

    case actionTypes.DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
      case actionTypes.DELETE_ROLE_SUCCESS:
        return {
          ...state,
          deleteResponse: action.payload,
        };
    default:
      return state;
  }
}

export default reducer;
