import React from 'react';

/**
 * ImageItemUploadFile component description.
 *
 * @ImageItemUploadFile
 *
 */

interface ImageItemUploadFileProps {
  src: string;
  onSelect?: () => void;
  selected?: boolean;
  // editItemImg?: (event: React.MouseEvent<HTMLDivElement>) => void;
  //onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const ImageItemUploadFile: React.FC<ImageItemUploadFileProps> = ({
  src,
  onSelect,
  selected,
}) => {
  return (
    <img
      src={src}
      alt=""
      onClick={onSelect}
      style={{objectFit:"cover"}}
      className={selected ? 'selectedImg' : ''}
    />
  );
};

export default ImageItemUploadFile;
