import React from 'react';
import InputField from '../../components/Input/Input';
import { languageData } from '../../i18n';
import ButtonElement from '../../components/button/button';
import PasswordLevel from '../../components/Etablissement/Auth/PasswordLevel';

/**
 * EditUserProfilePassword component description.
 *
 * @EditUserProfilePassword
 *
 */

interface EditUserProfilePasswordProps {
  oldPassword?: string;
  newPassword?: string;
  rewritePassword?: string;
  onClickCancel?: () => void;
  onClickSave?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: any;
  errorMsg?: string;
  errorMessage?: any;
  confirm?: boolean;
  passwords?: any;
}

const EditUserProfilePassword: React.FC<EditUserProfilePasswordProps> = ({
  oldPassword,
  newPassword,
  rewritePassword,
  onClickCancel,
  onClickSave,
  onChange,
  error,
  errorMsg,
  errorMessage,
  confirm,
  passwords,
}) => {
  return (
    <div className="EditUserProfilePassword fl_col gp40">
      <div className="fl_col gp10">
        <div className="fl_col gp16">
          <InputField
            type="password"
            //value={oldPassword || ''}
            name="oldPassword"
            isRequired={true}
            style="auth"
            label={'Mot de passe actuel'}
            errorMsg={errorMessage.oldPass.hasMessage}
            haserror={errorMessage.oldPass.hasError}
            placeholder="Saisissez votre mot de passe"
            onChange={onChange}
          />
          {/* <div className="fl_col gp5"> */}
          <InputField
            type="password"
            //value={newPassword || ''}
            name="newPassword"
            isRequired={true}
            style="auth"
            label={'Nouveau mot de passe'}
            placeholder="Nouveau mot de passe"
            onChange={onChange}
            haserror={
             !(passwords.newPassword === passwords.rewritePassword)
            }
            errorMsg={
               !(passwords.newPassword === passwords.rewritePassword)
                ? errorMessage.rewritePassword.hasMessage
                : ''
            }
          />
          <PasswordLevel password={passwords.newPassword} />
          <div className="rewrite-pw">
            <InputField
              type="password"
              //value={rewritePassword || ''}
              name="rewritePassword"
              isRequired={true}
              style="auth"
              label={'Confirmer le mot de passe'}
              placeholder="Confirmer le mot de passe "
              onChange={onChange}
              haserror={
                confirm &&
                !(passwords.newPassword === passwords.rewritePassword)
              }
              errorMsg={
                confirm &&
                !(passwords.newPassword === passwords.rewritePassword)
                  ? errorMessage.rewritePassword.hasMessage
                  : ''
              }
            />
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="modal-footer">
        <div className="footer-wrapper fl_row jc_fe gp20">
          <div className="fl-1">
            <ButtonElement
              type={'TextButton'}
              text="Annuler"
              buttonSize={'medium'}
              buttonStyle="btn-primary-light"
              onClick={onClickCancel}
            />
          </div>
          <div className="fl-2">
            <ButtonElement
              type={'TextButton'}
              text="Sauvegarder"
              buttonSize={'medium'}
              buttonStyle="btn-primary"
              onClick={onClickSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserProfilePassword;
