import axiosInstance, { BaseUrl } from './apiGate';

const formesJuridiquesApi = {
  get: async () => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + '/v1/get_list_legal_forms',
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
export default formesJuridiquesApi;
