import { serviceAppUserModels } from '../../models/services/module.appUser';
import axiosInstance, { BaseUrl, unAuthAxios } from './apiGate';

interface IupdateUser {
  lastName: string;
  email: string;
  firstName: string;
  phoneNumber: string;
}
const userApi = {
  addUser: async (body: serviceAppUserModels.AppUserPayload) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + `/v1/createUserWeb`,
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getUsers: async (body: servicePaginationModels.PaginationModelType) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListUsersWeb?page=${body.paginationModel.page}&pageSize=${body.paginationModel.pageSize}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getUserById: async (id: string) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getDetailsUserWeb?id=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUser: async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        BaseUrl + `/v1/deleteUserWeb?id=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  editUser: async (payload: serviceAppUserModels.AppUserPayload) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/editUserWeb`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkUserByEmail: async (email: string) => {
    try {
      const response = await unAuthAxios.get(
        BaseUrl + `/Account/CheckInactiveAccount?email=${email}`,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  resetPassword: async (payload: serviceAppUserModels.setPasswordBody) => {
    try {
      const response = await unAuthAxios.put(
        BaseUrl + `/Account/SetPassword`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  editProfileUser: async (payload: IupdateUser) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/editProfileUser`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePasswordUser: async (payload: any) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/changePasswordUser`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
export default userApi;
