import React, { MouseEvent } from 'react';
import BurgerMenu from '../../assets/icons/BurgerMenuBlackIcon.svg';
/**
 * TabItem component description.
 *
 * @TabItem
 *
 */

interface TabItemProps {
  id?: string;
  name: string;
  icon?: string;
  isActive?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const TabItem: React.FC<TabItemProps> = ({
  id,
  name,
  icon,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={`TabItem fl_row ai_c mg15-r-l ${isActive ? 'ActiveTab' : ''}`}
    >
      <div className="click-tab" onClick={(onClick)} id={id}></div>
      {icon && <img className="icon-tab" src={icon} alt="" />}
      <img className="BurgerMenu" src={BurgerMenu} alt="" />
      <p
        id={id}
        className={`p14 w-600 ${isActive ? 'primary-txt' : 'OxfordBlue02-txt'}`}
      >
        {name}
      </p>
    </div>
  );
};

export default TabItem;
