import React, { ReactNode } from 'react';

interface ContainerDashboardProps {
  children: ReactNode;
  ClassName?: string;
}
const ContainerDashboard: React.FC<ContainerDashboardProps> = ({
  children,
  ClassName,
}) => {
  return (
    <div className={`ContainerDashboard ${ClassName ? ClassName : ''}`}>
      {children}
    </div>
  );
};

export default ContainerDashboard;
