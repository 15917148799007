
import { serviceAppUserModels } from '../../models/services/module.appUser';
import * as actionTypes from '../actions/actionTypes';

// lastName: '',
//   email: '',
//   firstName: '',
//   phoneNumber: '',
const initialState = {
  loading: false,
  updatedUser: {},
  error: null,
};

const updateUserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_SECTION_REQUEST:
      return { ...state, loading: true, error: null };
    case actionTypes.UPDATE_USER_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedUser: action.payload,
        error: null,
      };
    case actionTypes.UPDATE_USER_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        updatedUser: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateUserReducer;
