import React, { useEffect } from 'react';

/**
 * ItemList component description.
 *
 * @ItemList
 *
 */

interface ItemListProps {
  value: string;
  name?: string;
  id?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  isChannelChecked?:any
}

const ItemList: React.FC<ItemListProps> = ({
  value,
  name,
  id,
  onChange,
  isChecked,

}) => {

  return (
    <div className="ItemList pd20-r-l pd10-t-b fl_row gp10">
      <input
        autoComplete="off"
        className="primaryCheckbox"
        type="checkbox"
        name={name}
        id={id}
        onChange={onChange}
        checked={isChecked}
      />
      <label htmlFor={id}>{ value}</label>
    </div>
  );
};

export default ItemList;
