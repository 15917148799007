import * as actionTypes from '../actions/actionTypes';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';

interface State {
  loading: boolean;
  Filteredetablissements: any;
  error: string | null;
}

const initialState: State = {
  loading: false,
  Filteredetablissements: null,
  error: null,
};

const FilteredetablissementsReducer = (state = initialState, action: any): State => {
  switch (action.type) {
    case actionTypes.GET_LIST_ESTABLISSEMENTS_REQUEST_ALL:
      return { ...state, loading: true, error: null };
    case actionTypes.GET_LIST_ESTABLISSEMENTS_SUCCESS_ALL:
      return { ...state, loading: false, Filteredetablissements: action.payload, error: null };
    case actionTypes.GET_LIST_ESTABLISSEMENTS_FAILURE_ALL:
      return { ...state, loading: false, Filteredetablissements: null, error: action.payload };
    default:
      return state;
  }
};

export default FilteredetablissementsReducer;
