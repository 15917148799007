import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import Modal from '../../../components/Modal/Modal';
import InvoiceContainer from '../Invoice/InvoiceContainer';
import AddMachineTypeModalContent from '../../../components/Etablissement/Modal/AddMachineTypeModalContent';
import TableHeader from '../../../components/Table/TableHeader';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  fetchCompanyMachineTypesRequest,
  fetchMachineSuperTypesRequest,
  addCompanyMachineTypeRequest,
  updateCompanyMachineTypeRequest,
} from '../../../store/actions/actions';
import { languageData } from '../../../i18n';

import { serviceTabsModels } from '../../../models/services/module.tabs';
import { serviceCampanyMachineTypeModels } from '../../../models/services/module.CampanyMachineType';
import Spinner from '../../../components/skeleton/spinner';
import EmptyTable from '../../../components/Table/EmptyTable';
import PageTable from '../../Table/PageTable';
import TableSection from '../../Table/TableSection';
import TableContent from '../../Table/TableContent';
import DesktopTableContainer from '../../Table/DesktopTableContainer';

export default function TypeMachine() {
  const [showInvoice, setShowInvoice] = useState(false);
  const [ModalVisivility, setModalVisivility] = useState(false);
  const [updateTab, setUpdateTab] = useState(false);
  const [machineId, setMachineId] = useState<any>();
  const [errors, setErrors] = useState({ designation: '' });
  const [machineName, setMachineName] = useState({
    designation: '',
    typeOfMachineTypeId: '',
  });

  const dispatch = useDispatch();

  const { campanyMachineType, loading, error } = useSelector(
    (state: {
      companyMachineTypes: serviceCampanyMachineTypeModels.MachineTypeResponse;
    }) => state.companyMachineTypes,
  );
  const { MachineSuperTypes } = useSelector(
    (state: {
      companyMachineSuperTypes: {
        MachineSuperTypes: serviceCampanyMachineTypeModels.IsuperMachineType;
      };
    }) => state.companyMachineSuperTypes,
  );


  const { id } = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const params = {
    companyId: id,
  };

  useEffect(() => {
    dispatch(fetchCompanyMachineTypesRequest(params));
  }, []);

  useEffect(() => {
    dispatch(fetchMachineSuperTypesRequest());
  }, []);

  const handleSubmit = () => {
    if (!machineName?.designation || machineName?.designation.trim() === '') {
      setErrors({ designation: languageData.fr.messages.Empty });
      return;
    }
    setErrors({ designation: '' });

    if (updateTab) {
      dispatch(
        updateCompanyMachineTypeRequest(
          machineId?.id ?? 0,
          machineName?.designation,
          () => {
            dispatch(fetchCompanyMachineTypesRequest(params));
          },
        ),
      );
      setUpdateTab(false);
    } else {
      setUpdateTab(false);
      dispatch(
        addCompanyMachineTypeRequest(
          {
            companyId: id,
            designation: machineName?.designation,
            typeOfMachineTypeId: machineName?.typeOfMachineTypeId,
          },
          () => {
            dispatch(fetchCompanyMachineTypesRequest(params));
          },
        ),
      );
    }

    setModalVisivility(false);
  };
  useEffect(() => {
    if (machineId?.id) {
      setMachineName((prev) => ({
        ...prev,
        designation: machineId?.designation || '',
        typeOfMachineTypeId: machineId?.typeOfMachineTypeId || '',
      }));
    }
  }, [machineId]);
  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMachineName({
      ...machineName,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (!ModalVisivility) {
      setErrors({ designation: '' });
    }
  }, [ModalVisivility]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;

  return (
    <PageTable className="TypeMachine">
      {showInvoice && (
        <InvoiceContainer onClick={() => setShowInvoice(false)} />
      )}
      <Modal
        // this modal is used for add & edit types
        className="AddSubscriptionsModal"
        version="custom"
        title={'Ajouter type'}
        customDescription="Créer un type machine."
        visibility={ModalVisivility}
        close
        onClickClose={() => setModalVisivility(false)}
        maxWidth={480}
        content={
          <AddMachineTypeModalContent
            machineId={machineId}
            machineName={machineName}
            onChange={handleChangeName}
            superTypes={MachineSuperTypes?.result}
            onClickSave={handleSubmit}
            CloseModal={() => setModalVisivility(false)}
          />
        }
        save
        saveTxt="Sauvegarder"
      />
      {errors.designation && (
        <span className="error">{errors.designation}</span>
      )}

      <TableSection>
        <TableHeader
          type="add"
          title={'Machines'}
          onClickAdd={() => {
            setModalVisivility(true);
            setUpdateTab(false);
          }}
          textAdd={'Ajouter'}
        />
      </TableSection>
      <TableContent>
        <DesktopTableContainer>
          <Table>
            <Thead>
              <Tr>
                <Td type="text" value="Désignation" width={80} align="left" />
                <Td type="filter" value="Créée le" width={5} align="center" />
                <Td type="text" value="Affectée" width={5} align="center" />
                <Td type="empty" width={5} />
              </Tr>
            </Thead>
            <Tbody>
              {campanyMachineType && campanyMachineType?.result?.length > 0 ? (
                campanyMachineType?.result?.map(
                  (machine: serviceCampanyMachineTypeModels.Machine) => (
                    <Tr key={machine.id}>
                      <Td
                        style="bold"
                        type="text"
                        value={machine.designation}
                        width={52}
                        align="left"
                      />
                      <Td
                        type="text"
                        value={
                          machine.iDate
                            ? new Date(machine.iDate).toLocaleDateString(
                                'fr-FR',
                              )
                            : 'N/A'
                        }
                        width={8}
                        align="center"
                      />
                      <Td
                        type="status"
                        width={8}
                        value={machine.isAffected ? 'oui' : 'non'}
                      />
                      {!machine.isAffected && (
                        <Td
                          type="editStatus"
                          width={8}
                          onClick={() => {
                            setModalVisivility(true);
                            setUpdateTab(true);
                            setMachineId(machine as any);
                          }}
                        />
                      )}
                    </Tr>
                  ),
                )
              ) : (
                <EmptyTable
                  title={'Entreprise(s) introuvable(s)'}
                  description={
                    'Merci de synchroniser ou vérifier la connexion de vos machines.'
                  }
                />
              )}
            </Tbody>
          </Table>
        </DesktopTableContainer>
      </TableContent>
    </PageTable>
  );
}
