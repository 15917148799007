import React from 'react';
import EntrepriseIcon from '../assets/icons/EntrepriseIcon';
import EtablissementIcon from '../assets/icons/EtablissementIcon';
import { languageData } from '../i18n';
import { StepItem } from './addCompanyStepsDataInitialState';

// Initialize the steps data array with the correct type
const stepsDataInitialState: StepItem[] = [
  {
    Icon: <EntrepriseIcon />,
    Name: 'Entreprise',
    Description: `${languageData.global.generalInformation}`,
    Tag: 'Compte Bancaire',
    isActive: true,
    isDone: false,
    activePartOne: true,
    activePartTwo: false,
    donePartOne: false,
    donePartTwo: false,
  },
  {
    Icon: <EtablissementIcon />,
    Name: 'Etablissement',
    Description: `${languageData.global.generalInformation}`,
    Tag: 'Compte Bancaire',
    isActive: false,
    isDone: false,
    activePartOne: false,
    activePartTwo: false,
    donePartOne: false,
    donePartTwo: false,
  },
  {
    Icon: <EntrepriseIcon />,
    Name: `${languageData.global.generalInformation}`,
    isActive: false,
    isDone: false,
  },
];
export default stepsDataInitialState;
