import React from 'react';
import ButtonElement from '../button/button';
import CheckedIconGreen from '../../assets/icons/CheckedIconGreen.svg';
import DownloadIconWhite from '../../assets/icons/DownloadIconWhite.svg';
import MastercardLogo from '../../assets/icons/MastercardLogo.svg';
import VisaLogo from '../../assets/icons/VisaLogo.svg';
import AmericanExpressLogo from '../../assets/icons/AmericanExpressLogo.svg';
import Globe from '../../assets/icons/globe.svg';
/**
 * PaymentInvoice component description.
 *
 * @PaymentInvoice
 *
 */

interface PaymentInvoiceProps {
  title?: string;
  transactionNumber?: string;
  paymentDate?: string;
  cardNumber?: string;
  cardType?: 'express' | 'master' | 'visa';
  onClickDownload?: () => void;
  onClickClose?: () => void;
}

const PaymentInvoice: React.FC<PaymentInvoiceProps> = ({
  title,
  transactionNumber,
  paymentDate,
  cardType,
  cardNumber,
  onClickDownload,
  onClickClose,
}) => {
  const cardImg = () => {
    switch (cardType) {
      case 'express':
        return AmericanExpressLogo;
      case 'master':
        return MastercardLogo;
      case 'visa':
        return VisaLogo;
      default:
        return Globe;
    }
  };
  const DigitNumbers = () => {
    return cardNumber?.substring(0, 4);
  };
  return (
    <div className="PaymentInvoice">
      <div className="invoice-wrapper fl_col gp20">
        <div className="invoice-header fl_col ai_c gp20">
          <p className="p18 w-700">{title}</p>
          <img src={CheckedIconGreen} alt="" />
          <p className="p18 w-700">Paiement effectué avec succès</p>
        </div>
        <div className="invoice-content pd30-r-l">
          <div className="content-wrapper fl_col gp10">
            <div className="fl_row jc_s">
              <p className="p16 AuroMetalSaurus-txt">Numéro transaction </p>
              <p className="p16 Independence-txt w-700">{transactionNumber}</p>
            </div>
            <div className="fl_row jc_s">
              <p className="p16 AuroMetalSaurus-txt">Date de paiement</p>
              <p className="p16 Independence-txt w-700">{paymentDate}</p>
            </div>
            <div className="fl_row jc_s">
              <p className="p16 AuroMetalSaurus-txt">Moyen de paiement</p>
              <div className="fl_row ai_e gp4">
                <img height={24} width={34} src={cardImg()} alt="" />
                <p className="p16 Independence-txt w-700">
                  {' '}
                  <span>****</span>
                  <span>{DigitNumbers()}</span>{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="invoice-footer pd20-t">
          <div className="footer-wrapper fl_row gp10">
            <ButtonElement
              className="w-full"
              type="TextButton"
              textSize="p18"
              text="Fermer"
              textColor="black"
              buttonSize="full"
              radius="rd12"
              onClick={onClickClose}
              buttonStyle="btn-black-light"
            />
            <ButtonElement
              className="w-full"
              type="IconButton"
              textSize="p18"
              text="Télécharger facture"
              textColor="white-txt"
              buttonSize="full"
              radius="rd12"
              onClick={onClickDownload}
              buttonStyle="btn-primary"
              icon={DownloadIconWhite}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInvoice;
