import React from 'react';
import { ModalProps } from '../../models/services/module.modals';
import ItemModalController from './ItemModalController';
import AlertModalController from './AlertModalController';
import CustomModalController from './CustomModalController';
export default function ModalController(props: ModalProps) {
  const { version } = props;
  let modalContentBody;
  let modalContentFooter;
  switch (version) {
    case 'Item':
      modalContentBody = ItemModalController(props).modalContentBody;
      modalContentFooter = ItemModalController(props).modalContentFooter;
      break;
    case 'Alert':
      modalContentBody = AlertModalController(props).modalContentBody;
      modalContentFooter = AlertModalController(props).modalContentFooter;
      break;
    case 'custom':
      modalContentBody = CustomModalController(props).modalContentBody;
      modalContentFooter = <></>;
      break;
  }
  return { modalContentBody, modalContentFooter };
}
