import React, { useEffect, useRef, useState } from 'react';
import SelectInput from '../../../../components/Input/SelectInput';
import { useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../../../../models/services/module.entreprise';
import { useDispatch } from 'react-redux';
import etablissementApi from '../../../../services/apiHandlers/etablissementApi';
import ButtonElement from '../../../../components/button/button';
import { catalogueApi } from '../../../../services/apiHandlers/catalogueApi';

export default function ChooseEtablissement({
  onConfirm,
}: {
  onConfirm?: () => void;
}) {
  const dispatch = useDispatch();

  const [idEtab, setidEtab] = useState<number | undefined>();
  const [idEntr, setidEntr] = useState<number | undefined>();
  const [companies_list, setcompanies_list] = useState<any[]>([]);
  const [Etablist, setEtablist] = useState([]);
  const ref = useRef<HTMLInputElement>(null);
  const fetchEtablissementslist = async () => {
    localStorage.setItem('idCompany', '' + idEntr);
    const response = await catalogueApi.getListPv(idEntr);
    setEtablist(response?.data?.data?.result);
  };

  const fetchCompanies = async () => {
    const response = await catalogueApi.getListCompanies();
    let data = response.data?.data?.result;
    setcompanies_list(data);
  };
  useEffect(() => {
    fetchCompanies();
  }, []);
  React.useEffect(() => {
    if (idEntr) {
      setEtablist([]);
      fetchEtablissementslist();
    }
  }, [idEntr]);
  React.useEffect(() => {
    if (idEtab) localStorage.setItem('idEtablishement', idEtab + '');
  }, [idEtab]);
  return (
    <div className="fl_col gp16">
      <div className="fl_col gp16">
        <SelectInput
          options={companies_list?.map((_) => _.nom)}
          placeholder={''}
          isRequired={false}
          label={'Entreprise'}
          name="entreprise"
          target="reference"
          onSelect={(selectValue) => {
            setidEntr(companies_list?.find((_) => _?.nom == selectValue)?.id);
          }}
        />
        {idEntr && (
          <SelectInput
            defaultValue={
              (Etablist?.find((_: any) => _?.id == idEtab) as any)
                ?.nom ?? ''
            }
            options={Etablist?.map((_: any) => _?.nom)}
            placeholder={''}
            isRequired={false}
            label={'établissement'}
            name="établissement"
            // onSelect={onSelectNaf}
            target="reference"
            onSelect={(selectValue) => {
              setidEtab(
                (
                  Etablist?.find(
                    (_: any) => _?.nom == selectValue,
                  ) as any
                )?.id,
              );
            }}
          />
        )}
      </div>
      <ButtonElement
        className="w-full"
        type="TextButton"
        textSize="p18"
        text="Accéder"
        textColor="white-txt"
        buttonSize="full"
        radius="rd12"
        onClick={idEntr && idEtab ? onConfirm : undefined}
        buttonStyle="btn-primary"
      ></ButtonElement>
    </div>
  );
}
