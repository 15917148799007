//! AUTHENTIFICATION MIDDELWARE
import { AxiosResponse } from 'axios';
import { delay, put } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  failure,
  resetLoggedIn,
  forgetPasswordLinkFailure,
  forgetPasswordLinkSuccess,
  resetPasswordLinkSuccess,
  resetPasswordLinkFailure,
  toggleToast,
} from '../actions/actions';
import authApi from '../../services/apiHandlers/authApi';
// eslint-disable-next-line

export function* loginFailureSaga(
  action: serviceAuthModels.loginFailureAction,
) {
  try {
    if (action.code) {
      return action.code;
    }
  } catch (error) {
    return error;
  }
}
export function* startLoginSaga(action: serviceAuthModels.loginAction) {
  try {
    const body = {
      email: action.email,
      password: action.password,
      deviceNotificationId: action.deviceNotificationId,
      forceLogin: action.forceLogin,
    };
    const response: AxiosResponse = yield authApi.login(body);
    if (response?.data) {
      if (
        response.data?.result.claimsEntreprise.length > 0 ||
        response.data?.result.claimsEtablissement.length > 0 ||
        response.data?.result.claims.length > 0
      ) {
        yield put(loginSuccess(response.data?.result));
        storage.setItem('accessToken', response.data?.result?.token);
        storage.setItem('userId', response.data?.result?.user?.id);
      } else {
        yield put(toggleToast());
        yield delay(3000);
        yield put(toggleToast());
      }
    }
    // eslint-disable-next-line
  } catch (error: any) {
    const failureResponse: serviceAuthModels.FailureResponse = {
      message: error?.response?.data?.failureResponse?.codeFI?.message,
      code: error?.response?.data?.failureResponse?.codeFI?.code,
    };
    yield put(loginFailure(failureResponse));
  }
}

export function* logoutSaga() {
  try {
    const response: AxiosResponse = yield authApi.logout();
    if (response.data) {
      storage.setItem('accessToken', '');
      storage.setItem('userId', '');
      storage.setItem('connectionId', '');
      yield put(logoutSuccess());
      yield put(resetLoggedIn());
    }
    // eslint-disable-next-line
  } catch (error: any) {
    if (
      error.status === 400 &&
      error.response.data.failureResponse.codeFI === 808
    ) {
      yield put(resetLoggedIn());
    }
    yield put(failure(error));
  }
}

export function* forgetPasswordLinkSaga(action: {
  type: string;
  payload: string;
}) {
  try {
    const response: AxiosResponse = yield authApi.forgetPasswordLink(
      action.payload,
    );
    if (response.data) {
      yield put(forgetPasswordLinkSuccess(response.data));
    }
  } catch (error: any) {
    yield put(forgetPasswordLinkFailure(error));
  }
}
export function* resetPasswordLinkSaga(action: {
  type: string;
  payload: serviceAuthModels.IresetPasswordPaylod;
}) {
  try {
    const response: AxiosResponse = yield authApi.resetPasswordLink(
      action.payload,
    );
    if (response.data) {
      yield put(resetPasswordLinkSuccess(response.data));
    }
  } catch (error: any) {
    yield put(resetPasswordLinkFailure(error));
  }
}
