import { serviceEntrepriseModels } from '../models/services/module.entreprise';
import { createNewRedevance } from './createNewRedevance';
import {
  setEditFormDataFinancial,
  unsavedChangesRegister,
} from '../store/actions/actions';
import tabs_list from '../data/tabsList';
import { Dispatch } from 'redux';
interface addRedevanceProps<T> {
  newFormData: T;
  dispatch: Dispatch;
  key: number;
}

export const addRedevance = <
  T extends {
    payload: {
      financialInformation?: {
        royaltiesInformation?: serviceEntrepriseModels.royaltiesInformation[];
      };
    };
  },
>(
  props: addRedevanceProps<T>,
) => {
  if (
    !props.newFormData?.payload.financialInformation ||
    !props.newFormData?.payload.financialInformation?.royaltiesInformation ||
    props.newFormData?.payload.financialInformation?.royaltiesInformation
      .length < 6
  ) {
    // Create a new object based on the current state
    const updatedFormData = {
      payload: {
        ...props.newFormData?.payload,
        financialInformation: {
          ...props.newFormData?.payload.financialInformation,
          royaltiesInformation: [
            ...(props.newFormData?.payload.financialInformation
              ?.royaltiesInformation || []),
            createNewRedevance(),
          ],
        },
      },
    };
    const array = [
      {
        key: tabs_list[props.key].name,
        value: { type: 'action', payload: updatedFormData },
      },
    ];
    // Update the state with the new formData
    props.dispatch(unsavedChangesRegister(array));
  } else {
    console.log('Cannot add more than 6 royaltiesInformation');
    // [to do] display a message or handle the condition where limit is reached
  }
};
export const addRedevanceDetails = <
  T extends {
    royalties?: serviceEntrepriseModels.royaltiesInformation[] | [];
  },
>(
  props: addRedevanceProps<T>,
) => {
  // Check if the length of royaltiesInformation is less than 6
  const { newFormData, dispatch } = props;
  if (newFormData.royalties !== undefined && newFormData.royalties.length < 6) {
    // Create a new object based on the current state formData
    const updatedFormData: any = {
      ...newFormData,
      royalties: [...(newFormData.royalties || []), createNewRedevance()],
    };
 
    //Update the state with the new formData
    dispatch(setEditFormDataFinancial(updatedFormData));
  } else {
    console.log('Cannot add more than 6 royaltiesInformation');
    // [to do] display a message or handle the condition where limit is reached
  }
};
