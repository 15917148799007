import React, { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { languageData } from '../../i18n';
import ButtonElement from '../button/button';

const ModalColorPicker = ({
  onChangeColor,
  onClickConfirm,
  onClickClose,
  value,
}: {
  onChangeColor: (color: string) => void;
  onClickConfirm?: () => void;
  onClickClose?: () => void;
  value?: string;
}) => {
  const [background, setBackground] = useState(value);
  const [selectedColor, setSelectedColor] = useState('');
  const colors = [
    '#FF0000',
    '#0EA5E9',
    '#3B82F6',
    '#6467F2',
    '#EF4444',
    '#F97316',
    '#FACC15',
    '#2DD4BF',
    '#69E655',
    '#EC4899',
    '#F43F5E',
    '#D946EF',
    '#8B5CF6',
    '#10B981',
    '#84CC16',
    '#667085',
    '#BA6400',
  ];
  let colorCode = '#fff';

  const handleChangeComplete = (color: ColorResult) => {
    colorCode = color.hex;
    setBackground(color.hex);
    onChangeColor(color.hex);
  };

  const handleColorClick = (color: string) => {
    colorCode = color;
    setBackground(color);
    setSelectedColor(color);
    onChangeColor(color);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    colorCode = event.target.value;
    setBackground(event.target.value);
    onChangeColor(event.target.value);
  };
  return (
    <div className="ModalColorPickerContent">
      <div className="wrapper-color fl_col gp20">
        <div className="fl_col gp4">
          <SketchPicker
            color={background ?? '#fff'}
            onChangeComplete={handleChangeComplete}
            disableAlpha={true}
          />
          <input
            autoComplete="off"
            defaultValue={value}
            type="text"
            value={background}
            onChange={handleInputChange}
            placeholder="#ffffff"
          />
          <div className="ColorsSuggestion">
            {colors.map((color) => (
              <div
                className={`fieldColor ${selectedColor === color ? 'active-color' : ''}`}
                key={color}
                style={{ backgroundColor: color }}
                onClick={() => handleColorClick(color)}
              />
            ))}
          </div>
        </div>
        {/* <div className="fl_row gp20">
          <ButtonElement
            type={'TextButton'}
            text={languageData.global.Annuler}
            buttonSize={'small'}
            buttonStyle={'btn-black-light'}
            onClick={onClickClose}
          />
          <ButtonElement
            type={'TextButton'}
            text={languageData.global.Valider}
            buttonSize={'medium'}
            buttonStyle="btn-primary"
            onClick={onClickConfirm}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ModalColorPicker;
