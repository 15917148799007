import React from 'react';
import IconLoader from '../../assets/icons/IconLoader';
const Spinner: React.FC = () => {
  return (
    <div className="spinner-overlay">
      <IconLoader></IconLoader>
    </div>
  );
};

export default Spinner;
