import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  updatedabonnement: null,
  loading: false,
  error: null,
};

const updateAbonnement = (
  state = initialState,
  action: {
    type: string;
    payload: serviceEtablissmentMachineTypeModels.DataAbonnementById;
  },
) => {
  switch (action.type) {
    case actionTypes.UPDATE_ABONNEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_ABONNEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedabonnement: action.payload,
        error: null,
      };
    case actionTypes.UPDATE_ABONNEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default updateAbonnement;
