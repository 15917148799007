import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { resetEditResponse } from '../../../store/actions/actions';
const useResetResponse = () => {
  const dispatch = useDispatch();

  const resetResponse = useCallback(() => {
    setTimeout(() => {
      dispatch(resetEditResponse());
    }, 5000);
  }, []);

  return resetResponse;
};
export default useResetResponse;
