// utils.ts

export const extractDigits = (value: string): string => {
  return value.replace(/\D/g, '');
};

export const formatPhoneNumber = (
  value: string,
  phoneCode?: string,
  owner_mobile_number?: string,
): string => {
  const extractedDigits = extractDigits(value);
  //const { formData, setFormData } = props;
  const currentPhoneNumber = owner_mobile_number || '';

  if (phoneCode) {
    return currentPhoneNumber.startsWith(phoneCode)
      ? extractedDigits
      : phoneCode + extractedDigits;
  }
  return extractedDigits;
};
