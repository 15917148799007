import React from "react";

function IconLoader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      style={{}}
    >
      <g fill="none" strokeLinecap="round" strokeWidth="9">
        <circle
          cx="50"
          cy="50"
          r="32"
          stroke="#2a66c6"
          strokeDasharray="50.26548245743669 50.26548245743669"
        >
          <animateTransform
            attributeName="transform"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;360 50 50"
          ></animateTransform>
        </circle>
        <circle
          cx="50"
          cy="50"
          r="22"
          stroke="#ff2424"
          strokeDasharray="34.55751918948772 34.55751918948772"
          strokeDashoffset="34.558"
        >
          <animateTransform
            attributeName="transform"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;-360 50 50"
          ></animateTransform>
        </circle>
      </g>
    </svg>
  );
}

export default IconLoader;
