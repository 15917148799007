import {
  Badges,
  GeneralCodeTva,
  Tags,
} from './../../../models/services/module.catalogue.d';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  tags: Tags[];
  tagSuccess: boolean;
} = {
  tags: [],
  tagSuccess: false,
};
function reducer(
  state = initialState,
  action: { type: string; tags?: Tags[]; item?: string },
) {
  switch (action.type) {
    case actionTypes.SUCCESS_GET_TAG: {
      return {
        ...state,
        tags: action.tags,
      };
    }
    case actionTypes.POST_TAG_SUCCESS: {
      return {
        ...state,
        tagSuccess: true,
      };
    }
    case actionTypes.CLEAR_STORE_ITEM: {
      let key = action?.item;
      let obj: Record<string, null> = {};
      obj[key as string] = null;
      return {
        ...state,
        ...obj,
      };
    }
    default:
      return state;
  }
}
export default reducer;
