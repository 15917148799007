import { serviceFormesJuridiquesModels } from '../../models/services/module.fomesJuridiques';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceFormesJuridiquesModels.FormesJuridiquesState = {
  formesJuridiques: [],
};

function reducer(
  state = initialState,
  action: serviceFormesJuridiquesModels.getFormesJuridiquesAction,
) {
  switch (action.type) {
    case actionTypes.GET_FORMES_JURIDIQUES: {
      return { ...state };
    }
    case actionTypes.SET_FORMES_JURIDIQUES: {
      return { ...state, formesJuridiques: action.payload };
    }
    default:
      return state;
  }
}

export default reducer;
