import { Composition } from '../../../models/services/module.catalogue';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  compositions: Composition[];
  compositionsChange: number;
  deletedIngredients: Composition[];
  dragNeedModalCount: number;
} = {
  compositions: [],
  compositionsChange: 0,
  deletedIngredients: [],
  dragNeedModalCount: 0,
};
function reducer(
  state = initialState,
  action: {
    composition: Composition;
    compositions: Composition[];
    type: string;
    compositionsChange: number;
  },
) {
  switch (action.type) {
    case actionTypes.DRAG_NEW_COMPOSITION: {
      const compositionExists = state.compositions.some(
        (composition) =>
          (composition.idIngredient ?? composition.id) ===
          action.composition.id,
      );
      return !compositionExists
        ? {
            ...state,
            compositions: [...state.compositions, action.composition] ?? [],
            compositionsChange: state.compositionsChange + 1,
            dragNeedModalCount: state.dragNeedModalCount + 1,
          }
        : state;
    }
    case actionTypes.unDRAG_NEW_COMPOSITION: {
      return {
        ...state,
        compositions:
          state.compositions.filter(
            (composition) =>
              (composition.idIngredient ?? composition.id) !==
              (action.composition.idIngredient ?? action.composition.id),
          ) ?? [],
        deletedIngredients: [
          ...(state.deletedIngredients ?? []),
          action.composition,
        ],
      };
    }
    case actionTypes.UPDATE_COMPOSITIONS: {
      return action.compositions.reduce((newState, composition) => {
        const compositionExists = newState.compositions.some(
          (c) =>
            (c.idIngredient ?? c.id) ===
            (composition.idIngredient ?? composition.id),
        );
        return !compositionExists
          ? {
              ...newState,
              compositions: [...newState.compositions, { ...composition }],
              compositionsChange: newState.compositionsChange + 1,
              dragNeedModalCount: state.dragNeedModalCount + 1,
            }
          : newState;
      }, state);
    }
    case actionTypes.EDIT_SUCCESS: {
      return {
        ...state,
        compositions: [],
        deletedIngredients: [],
      };
    }
    case actionTypes.UPDATE_DELETED_INGREDIENTS: {
      return {
        ...state,

        deletedIngredients: action.compositions,
      };
    }
    case actionTypes.SELECT_COMPOSITION: {
      return {
        ...state,
        compositionsChange: state.compositionsChange + 1,

        compositions: action.compositions,
        //  dragNeedModalCount:state.dragNeedModalCount+1
      };
    }
    case actionTypes.UNDRAG_ALL_COMPOSITION: {
      return {
        ...state,
        deletedIngredients: [
          ...state.deletedIngredients,
          ...state.compositions,
        ],
        compositions: [],
      };
    }
    case actionTypes.CLEAR_SEARCH_FILED: {
      return {
        ...state,
        dragNeedModalCount: 0,
      };
    }

    default:
      return state;
  }
}
export default reducer;
