import validateEmail from './EmailValidation';

const isObjectEmptyEtablissement = <T extends object>(obj: T): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      console.log('obj', obj);
      if (
        key === 'fixed_line_telephone_number' ||
        key === 'swifT_BIC' ||
        key === 'companyId' ||
        key === 'share_capital' ||
        key === 'royaltiesInformation'
      ) {
        continue; // Skip these fields
      }
      const value = obj[key];
      // Check if value is an object (nested object)
      if (typeof value === 'object' && value !== null) {
        // Recursively check nested object
        if (isObjectEmptyEtablissement(value)) {
          return true;
        }
      } else {
        if (key === 'email') {
          return !validateEmail(value as string);
        }
        // Check for empty string, 0, undefined, or null
        if (
          value === '' ||
          value === 0 ||
          value === undefined ||
          value === null
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

export default isObjectEmptyEtablissement;
