import React from 'react';
import StepWizzard from '../StepWizzard';
import FormCard from '../FormCard';
import flagsAndNamesSeparator from '../../../../../utils/FlagsAndNamesSeparator';
import InputField from '../../../../../components/Input/Input';
import { languageData } from '../../../../../i18n';
import { getError } from '../../../../../utils/GetErrors';
import { getErrorMessage } from '../../../../../utils/GetErrorMessages';
import SelectInput from '../../../../../components/Input/SelectInput';
import SelectInput2 from '../../../../../components/Input/CountrySelect/SelectInput2';
import { serviceEtablissementModels } from '../../../../../models/services/module.etablissement';
import { useSelector } from 'react-redux';
import { useUnsavedChanges } from '../../useUnsavedChanges';
type onSelectType = (
  selectedValue: string,
  selectedIcon?: string | undefined,
  reference?: string | undefined,
  field?: string,
) => void;
interface Step1GeneralInfoProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: onSelectType;
}

export const Step1GeneralInfo: React.FC<Step1GeneralInfoProps> = ({
  handleChange,
  onSelect,
}) => {
  const validationRequested = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.validationRequestedEtablissement,
  );
  const { countriesCodes, countriesNames, countriePhoneCodes, flags } =
    flagsAndNamesSeparator();
  const { newFormData } = useUnsavedChanges();
  return (
    <>
      <FormCard
        labelCard={languageData.global.generalInformation}
        grid={4}
        gap="gp24"
      >
        <InputField
          isRequired={true}
          value={
            newFormData?.payload?.generalInformation?.commercial_Name ?? ''
          }
          type="text"
          name="commercial_Name"
          label={languageData.global.Form.commercialNameLabel}
          onChange={handleChange}
          style="form"
          isDisabled={false}
          haserror={
            validationRequested
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'commercial_Name',
                  isRequired: true,
                  fieldValue:
                    newFormData?.payload?.generalInformation?.commercial_Name,
                })
              : false
          }
          errorMsg={
            validationRequested
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'commercial_Name',
                  isRequired: true,
                  fieldValue:
                    newFormData?.payload?.generalInformation?.commercial_Name,
                })
              : ''
          }
        />

        <InputField
          isRequired={true}
          value={
            newFormData?.payload?.generalInformation?.email ?? ''}
          type="text"
          name="email"
          label={languageData.global.email_address}
          onChange={handleChange}
          style="form"
          isDisabled={false}
          haserror={
            validationRequested ||
            (newFormData?.payload?.generalInformation &&
              newFormData?.payload?.generalInformation?.email &&
              newFormData?.payload?.generalInformation?.email !== '')
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'email',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.email,
                })
              : false
          }
          errorMsg={
            validationRequested ||
            (newFormData?.payload?.generalInformation &&
              newFormData?.payload?.generalInformation?.email &&
              newFormData?.payload?.generalInformation?.email !== '')
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'email',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.email,
                })
              : ''
          }
        />

        <SelectInput2
          isRequired={false}
          value={
            newFormData?.payload?.generalInformation
              ?.fixed_line_telephone_number ?? ''}
          label={languageData.global.landline_optional}
          name="fixed_line_telephone_number"
          onSelect={(selectedValue: string) =>
            onSelect(selectedValue, 'fixed_line_telephone_number')
          }
          target="reflags"
          reference={countriePhoneCodes}
          icons={flags}
          options={countriesCodes}
          onChange={handleChange}
          isDisabled={false}
        />

        <SelectInput2
          isRequired={true}
          value={
            newFormData?.payload?.generalInformation
              ?.mobile_telephone_number ?? ''}
          label={languageData.global.mobile}
          name="mobile_telephone_number"
          onSelect={(selectedValue: string) =>
            onSelect(selectedValue, 'mobile_telephone_number')
          }
          target="reflags"
          icons={flags}
          reference={countriePhoneCodes}
          options={countriesCodes}
          onChange={handleChange}
          isDisabled={false}
          haserror={
            validationRequested
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'mobile_telephone_number',
                  isRequired: true,
                  fieldValue:
                    newFormData?.payload?.generalInformation
                      ?.mobile_telephone_number,
                })
              : false
          }
          errorMsg={
            validationRequested
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'mobile_telephone_number',
                  isRequired: true,
                  fieldValue:
                    newFormData?.payload?.generalInformation
                      ?.mobile_telephone_number,
                })
              : ''
          }
        />
        <InputField
          isRequired={false}
          value={
            newFormData?.payload?.generalInformation?.share_capital ?? ''}
          type="text"
          name="share_capital"
          label={languageData.global.social_capital}
          onChange={handleChange}
          style="form"
          isDisabled={false}
          haserror={
            validationRequested
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'share_capital',
                  isRequired: false,
                  fieldValue:
                    newFormData?.payload?.generalInformation?.share_capital,
                })
              : false
          }
          errorMsg={
            validationRequested
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'share_capital',
                  isRequired: false,
                  fieldValue:
                    newFormData?.payload?.generalInformation?.share_capital,
                })
              : ''
          }
        />

        <InputField
          isRequired={true}
          value={
            newFormData?.payload?.generalInformation?.address ?? ''}
          type="text"
          name="address"
          label={languageData.global.adresse}
          onChange={handleChange}
          style="form"
          isDisabled={false}
          haserror={
            validationRequested
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'address',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.address,
                })
              : false
          }
          errorMsg={
            validationRequested
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'address',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.address,
                })
              : ''
          }
        />

        <SelectInput
          isRequired={true}
          value={
            newFormData?.payload?.generalInformation?.country ?? ''}
          name="country"
          label={languageData.global.country}
          options={countriesNames}
          icons={flags}
          target="search"
          onSelect={(selectedValue: string) =>
            onSelect(selectedValue, 'country')
          }
          placeholder={newFormData?.payload?.generalInformation?.country}
          onChange={handleChange}
          haserror={
            validationRequested
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'country',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.country,
                })
              : false
          }
          errorMsg={
            validationRequested
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'country',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.country,
                })
              : ''
          }
        />
        <InputField
          isRequired={true}
          value={newFormData?.payload?.generalInformation?.city ?? ''}
          name="city"
          label={languageData.global.state}
          type="text"
          style="form"
          isDisabled={false}
          onChange={handleChange}
          haserror={
            validationRequested
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'city',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.city,
                })
              : false
          }
          errorMsg={
            validationRequested
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'city',
                  isRequired: true,
                  fieldValue: newFormData?.payload?.generalInformation?.city,
                })
              : ''
          }
        />
        <InputField
          isRequired={true}
          value={newFormData?.payload?.generalInformation?.postal_code ?? ''}
          name="postal_code"
          type="text"
          label={languageData.global.postal_code}
          onChange={handleChange}
          style="form"
          isDisabled={false}
          haserror={
            validationRequested
              ? getError({
                  category: 'generalInformation',
                  fieldName: 'postal_code',
                  isRequired: true,
                  fieldValue:
                    newFormData?.payload?.generalInformation?.postal_code,
                })
              : false
          }
          errorMsg={
            validationRequested
              ? getErrorMessage({
                  category: 'generalInformation',
                  fieldName: 'postal_code',
                  isRequired: true,
                  fieldValue:
                    newFormData?.payload?.generalInformation?.postal_code,
                })
              : ''
          }
        />
      </FormCard>
    </>
  );
};
