import React from 'react';
import { OpenTab } from '../../../services/tabs/OpenTab';
import tabs_list from '../../../data/tabsList';
import usePagination from '../../../utils/UsePagination';
import { getAllEtablissement } from '../../../store/actions/actions';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import ListeDesEtablissementsContainer from './ListeDesEtablissementsContainer';
interface ListeDesEtablissementsProps {
  companyId: number;
  claimEtablissementAjout?: boolean;
  claimEtablissementModification?: boolean;
}
export default function ListeDesEtablissements(
  props: ListeDesEtablissementsProps,
) {
  console.log(
    'claimEtablissementModification',
    props.claimEtablissementModification,
  );
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const [state, setState] = React.useState({
    page: 1,
    pageSize: 9,
    isLoading: false,
  });
  const [paginationModel, setPaginationModel] =
    React.useState<servicePaginationModels.PaginationModelType>({
      companyId: activeTabContent.id,
      paginationModel: {
        page: state.page,
        pageSize: state.pageSize,
        nbPage: 0,
      },
    });

  const dispatch = useDispatch();
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const companies_list = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.etablissements,
  );
  const totalPagesNumbers = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.totalPagesNumbers,
  );

  const fetchEtablissementslist = React.useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    dispatch(getAllEtablissement(paginationModel));
    setState((prevState) => ({ ...prevState, isLoading: false }));
  }, [paginationModel]);

  const {
    handleNextClick,
    handlePreviousClick,
    handlePageChange,
    updatePaginationModel,
  } = usePagination(paginationModel);

  React.useEffect(() => {
    fetchEtablissementslist(); // Call fetchEtablissementslist with updated paginationModel
  }, [paginationModel]);

  React.useEffect(() => {
    setPaginationModel((prev: servicePaginationModels.PaginationModelType) => ({
      ...prev,
      companyId: activeTabContent.id,
      paginationModel: {
        ...prev.paginationModel,
        page: updatePaginationModel.page,
        pageSize: updatePaginationModel.pageSize,
      },
    }));
    setState((prevState) => ({
      ...prevState,
      page: updatePaginationModel.page,
      pageSize: updatePaginationModel.pageSize,
    }));
  }, [updatePaginationModel]);

  React.useEffect(() => {
    setPaginationModel(
      (prevPaginationModel: servicePaginationModels.PaginationModelType) => ({
        ...prevPaginationModel,
        companyId: props.companyId,
        paginationModel: {
          ...prevPaginationModel.paginationModel,
          nbPage: totalPagesNumbers,
        },
      }),
    );
  }, [totalPagesNumbers]);

  const handleClickOpenAddEtablissement = () => {
    OpenTab({
      key: tabs_list[1].tabContent.key,
      label: tabs_list[1].name,
      tabs: open_tabs,
      dispatch,
      companyId: '' + props.companyId,
    });
  };

  const handleClickOpenEtablissementDetails = (id: number, name: string) => {
    OpenTab({
      key: tabs_list[5].tabContent.key,
      label: tabs_list[5].name,
      tabs: open_tabs,
      dispatch,
      id: id,
      name: name,
      companyId: '' + props.companyId,
      isEtablishement: true,
    });
  };

  return (
    <ListeDesEtablissementsContainer
      list={companies_list?.establishements || []}
      counter={companies_list?.nbestablishements}
      handleClickOpenAddEtablissement={handleClickOpenAddEtablissement}
      handleClickOpenEtablissementDetails={handleClickOpenEtablissementDetails}
      handleNextClick={handleNextClick}
      handlePreviousClick={handlePreviousClick}
      currentPage={state.page}
      handlePageChange={handlePageChange}
      totalPages={totalPagesNumbers}
      claimEtablissementAjout={props.claimEtablissementAjout}
      claimEtablissementModification={props.claimEtablissementModification}
    />
  );
}
