import React, { ReactNode } from 'react';
import OptionsIconBlack from '../../assets/icons/OptionsIconBlack.svg';

/**
 * PermissionBox component description.
 *
 * @PermissionBox
 *
 */

interface PermissionBoxProps {
  children: ReactNode;
  name?: string;
  primary?: boolean;
  onClickMore?: () => void;
}

const PermissionBox: React.FC<PermissionBoxProps> = ({
  children,
  name,
  primary,
  onClickMore,
}) => {
  return (
    <div
      className={`PermissionBox white-bg ${primary ? 'primary mg10-b' : ''}`}
    >
      <div className="role-wrapper">
        <div
          style={{ backgroundColor: primary ? '#2a66c633' : '#eff1f6' }}
          className={`fl_row ai_c jc_s rd6 roles-header pd10-t-b pd12-r-l 
                ${primary ? '' : 'fl_row jc_s'}`}
        >
          <p
            className={`role-name ${primary ? 'p18 w-900 white-txt' : 'p16 w-900 black-txt'} `}
          >
            {' '}
            {name}
          </p>
          {onClickMore && (
            <>
              {/* <p className="showMoreTxt p12 w-900 primary-txt c-p" onClick={onClickMore}>
                Paramètres avancés
              </p>
              <img src={OptionsIconBlack} alt="" className="showMoreImg" onClick={onClickMore} /> */}
            </>
          )}
        </div>
        <div className="roles-listing fl_row pd42-r-l pd13-t-b fl_wrap gp20">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PermissionBox;
