import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceRolesModels } from '../../models/services/module.role';
import { getRolesListAction } from '../../store/actions/actions';

const useGetRolesLogic = () => {
  const dispatch = useDispatch();
  const roles = useSelector(
    (state: { role: serviceRolesModels.RolesState }) => state.role.roles,
  );

  useEffect(() => {
    dispatch(
      getRolesListAction({
        paginationModel: {
          page: 1,
          pageSize: 1000,
        },
      }),
    );
  }, []);
  const rolesOptions = useMemo(() => {
    if (roles) {
      return roles.map((item: serviceRolesModels.Role) => item.name);
    }
    return [];
  }, [roles]);
  return {
    roles,
    rolesOptions,
  };
};

export default useGetRolesLogic;
