import React from 'react';

interface Props {
  fillColor?: string;
}

const AllergeneIcon: React.FC<Props> = ({ fillColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.33366 7.69599C2.12254 7.69599 2.00033 7.85986 2.00033 8.00034C2.00033 8.14082 2.12254 8.30469 2.33366 8.30469C2.54478 8.30469 2.66699 8.14082 2.66699 8.00034C2.66699 7.85986 2.54478 7.69599 2.33366 7.69599ZM0.666992 8.00034C0.666992 7.03625 1.44021 6.30469 2.33366 6.30469C3.22711 6.30469 4.00033 7.03625 4.00033 8.00034C4.00033 8.96443 3.22711 9.69599 2.33366 9.69599C1.44021 9.69599 0.666992 8.96443 0.666992 8.00034Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6667 7.69599C13.4555 7.69599 13.3333 7.85986 13.3333 8.00034C13.3333 8.14082 13.4555 8.30469 13.6667 8.30469C13.8778 8.30469 14 8.14082 14 8.00034C14 7.85986 13.8778 7.69599 13.6667 7.69599ZM12 8.00034C12 7.03625 12.7732 6.30469 13.6667 6.30469C14.5601 6.30469 15.3333 7.03625 15.3333 8.00034C15.3333 8.96443 14.5601 9.69599 13.6667 9.69599C12.7732 9.69599 12 8.96443 12 8.00034Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00065 2.02802C7.78953 2.02802 7.66732 2.19189 7.66732 2.33237C7.66732 2.47285 7.78953 2.63672 8.00065 2.63672C8.21177 2.63672 8.33398 2.47285 8.33398 2.33237C8.33398 2.19189 8.21177 2.02802 8.00065 2.02802ZM6.33398 2.33237C6.33398 1.36828 7.1072 0.636719 8.00065 0.636719C8.8941 0.636719 9.66732 1.36828 9.66732 2.33237C9.66732 3.29646 8.8941 4.02802 8.00065 4.02802C7.1072 4.02802 6.33398 3.29646 6.33398 2.33237Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00065 13.3601C7.78953 13.3601 7.66732 13.5239 7.66732 13.6644C7.66732 13.8049 7.78953 13.9688 8.00065 13.9688C8.21177 13.9688 8.33398 13.8049 8.33398 13.6644C8.33398 13.5239 8.21177 13.3601 8.00065 13.3601ZM6.33398 13.6644C6.33398 12.7003 7.1072 11.9688 8.00065 11.9688C8.8941 11.9688 9.66732 12.7003 9.66732 13.6644C9.66732 14.6285 8.8941 15.3601 8.00065 15.3601C7.1072 15.3601 6.33398 14.6285 6.33398 13.6644Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.76729 11.782C3.6426 11.9067 3.6426 12.1089 3.76729 12.2336C3.89199 12.3583 4.09416 12.3583 4.21886 12.2336C4.34355 12.1089 4.34355 11.9067 4.21886 11.782C4.09416 11.6573 3.89199 11.6573 3.76729 11.782ZM2.80464 13.1962C2.14829 12.5399 2.14829 11.4757 2.80464 10.8194C3.461 10.163 4.52515 10.163 5.18151 10.8194C5.83786 11.4757 5.83786 12.5399 5.18151 13.1962C4.52515 13.8526 3.461 13.8526 2.80464 13.1962Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.781 3.76641C11.6563 3.8911 11.6563 4.09327 11.781 4.21797C11.9057 4.34266 12.1078 4.34266 12.2325 4.21797C12.3572 4.09327 12.3572 3.8911 12.2325 3.76641C12.1078 3.64171 11.9057 3.64171 11.781 3.76641ZM10.8183 5.18062C10.162 4.52427 10.162 3.46011 10.8183 2.80376C11.4747 2.1474 12.5388 2.1474 13.1952 2.80376C13.8515 3.46011 13.8515 4.52427 13.1952 5.18062C12.5388 5.83697 11.4747 5.83697 10.8183 5.18062Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.76729 3.76641C3.6426 3.8911 3.6426 4.09327 3.76729 4.21797C3.89199 4.34266 4.09416 4.34266 4.21886 4.21797C4.34355 4.09327 4.34355 3.8911 4.21886 3.76641C4.09416 3.64171 3.89199 3.64171 3.76729 3.76641ZM2.80464 5.18062C2.14829 4.52427 2.14829 3.46011 2.80464 2.80376C3.461 2.1474 4.52515 2.1474 5.18151 2.80376C5.83786 3.46011 5.83786 4.52427 5.18151 5.18062C4.52515 5.83697 3.461 5.83697 2.80464 5.18062Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.781 11.782C11.6563 11.9067 11.6563 12.1089 11.781 12.2336C11.9057 12.3583 12.1078 12.3583 12.2325 12.2336C12.3572 12.1089 12.3572 11.9067 12.2325 11.782C12.1078 11.6573 11.9057 11.6573 11.781 11.782ZM10.8183 13.1962C10.162 12.5399 10.162 11.4757 10.8183 10.8194C11.4747 10.163 12.5388 10.163 13.1952 10.8194C13.8515 11.4757 13.8515 12.5399 13.1952 13.1962C12.5388 13.8526 11.4747 13.8526 10.8183 13.1962Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00065 5.69599C6.68496 5.69599 5.66732 6.75529 5.66732 8.00034C5.66732 9.24539 6.68496 10.3047 8.00065 10.3047C9.31634 10.3047 10.334 9.24539 10.334 8.00034C10.334 6.75529 9.31634 5.69599 8.00065 5.69599ZM4.33398 8.00034C4.33398 5.93168 6.00263 4.30469 8.00065 4.30469C9.99867 4.30469 11.6673 5.93168 11.6673 8.00034C11.6673 10.069 9.99867 11.696 8.00065 11.696C6.00263 11.696 4.33398 10.069 4.33398 8.00034Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6667 8.69599H11V7.30469H12.6667V8.69599Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66732 11V12.6667H7.33398V11H8.66732Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33398 7.30469H5.00065V8.69599H3.33398V7.30469Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.33398 4.9987V3.33203H8.66732V4.9987H7.33398Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7808 5.17867L10.6023 6.35718L9.63965 5.39453L10.8182 4.21602L11.7808 5.17867Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6023 9.63891L11.7808 10.8174L10.8182 11.7801L9.63965 10.6016L10.6023 9.63891Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.21875 10.8164L5.39726 9.63789L6.35991 10.6005L5.1814 11.7791L4.21875 10.8164Z"
        fill="#616C82"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.39726 6.3582L4.21875 5.17969L5.1814 4.21704L6.35991 5.39555L5.39726 6.3582Z"
        fill="#616C82"
      />
    </svg>
  );
};

export default AllergeneIcon;
