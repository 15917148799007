import React, { useEffect, useRef } from 'react';

/**
 * SkeletonLoader component description.
 *
 * @SkeletonLoader
 *
 */

interface SkeletonLoaderProps {
  show?: boolean;
  type: 'input' | 'td' | 'card' | 'table';
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ show, type }) => {
  const bodyRef = useRef(document.body);

  const SkeletonContent: React.FC = () => {
    switch (type) {
      case 'input':
      case 'card':
        return <div className={`SkeletonLoader ${type}`}></div>;
      case 'table':
        return (
          <div className="skel-table-wrapper fl_col gp10">
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
            <div className="row-table">
              <div className={`SkeletonLoader ${type}`}></div>
            </div>
          </div>
        );
      default:
        return <div className="SkeletonLoader default"></div>;
    }
  };

  useEffect(() => {
    if (show) {
      bodyRef.current.className += ' SkeletonMode';
    } else {
      bodyRef.current.className = bodyRef.current.className
        .replace(' SkeletonMode', '')
        .trim();
    }
    return () => {
      bodyRef.current.className = bodyRef.current.className
        .replace(' SkeletonMode', '')
        .trim();
    };
  }, [show]);

  return show ? <SkeletonContent /> : null;
};

export default SkeletonLoader;
