import { AxiosResponse } from 'axios';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import { cartAction } from '../../../models/services/module.category';
import {
  carteConfig,
  filtredPvConfig,
  modalData,
} from '../../../models/services/module.cart';
import { put } from 'redux-saga/effects';
import { updateLoading } from '../../actions/catalogues/actions';
export function* postCart(action: cartAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postCart(
      action.payload as carteConfig,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result);
    }
  } catch (error: unknown) {
    NotificationErrorProvider(error);
  }
}
export function* getListPvFiltred(action: cartAction) {
  try {
    console.log('actioncc', action);
    const response: AxiosResponse = yield action?.filter?.actionTarget == 'CARD'
      ? catalogueApi.getListPvFiltredCard(action.filter as filtredPvConfig)
      : catalogueApi.getListPvFiltred(action.filter as filtredPvConfig);

    if (response.data) {
      action._ && action._(response.data?.data?.result ?? []);
    }
  } catch (error: unknown) {
    NotificationErrorProvider(error);
  }
}

export function* postAssignPv(action: cartAction) {
  try {
    yield put(updateLoading(true));
    const response: AxiosResponse =
      action.payloadAssign?.actionTarget == 'CARD'
        ? yield catalogueApi.postAssignPVCard(action.payloadAssign as modalData)
        : yield catalogueApi.postAssignPV(action.payloadAssign as modalData);
    if (response.data) {
      yield put(updateLoading(false));

      action._ && action._(response.data?.data?.result);
    }
  } catch (error: unknown) {
    yield put(updateLoading(false));

    NotificationErrorProvider(error);
  }
}
export function* dragUndrag(action: cartAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.dragUnDragPV(
      action.payloadAssign as modalData,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result?.cardCategories);
    }
  } catch (error: unknown) {
    NotificationErrorProvider(error);
  }
}
export function* getCardById(action: cartAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getCardbyID(
      action.id as string,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result[0]);
    }
  } catch (error: unknown) {
    NotificationErrorProvider(error);
  }
}
