import { serviceRedevanceModels } from '../../models/services/module.redevance';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceRedevanceModels.RedevanceState = {
  redevance: null,
  redevances: [],
};
// eslint-disable-next-line
function reducer(
  state = initialState,
  action: serviceRedevanceModels.redevanceAction,
) {
  switch (action.type) {
    case actionTypes.ADD_REDEVANCE: {
      return { ...state, redevance: action.payload };
    }

    default:
      return state;
  }
}

export default reducer;
