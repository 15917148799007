import {
  LevelArticle,
  Menu,
  level,
} from '../../../models/services/module.catalogue';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  subMenus: Menu[];
  subMenusChange: number;
  deletedsubMenu: Menu[];
  dragNeedModalCount: number;
  subMenusDragged: Menu[];
} = {
  subMenus: [],
  subMenusChange: 0,
  deletedsubMenu: [],
  dragNeedModalCount: 0,
  subMenusDragged: [],
};
function reducer(
  state = initialState,
  action: {
    subMenu: Menu;
    subMenus: Menu[];
    type: string;
    subMenuChange: number;
    subMenusDragged: Menu[];
  },
) {
  switch (action.type) {
    case actionTypes.DRAG_NEW_SUB_MENU: {
      const Exists = state.subMenus.some(
        (menu) =>
          (menu.idMenu ?? menu.id) ===
          (action.subMenu.idMenu ?? action.subMenu.id),
      );
      return !Exists
        ? {
            ...state,
            subMenus: [...state.subMenus, action.subMenu] ?? [],
            subMenusChange: state.subMenusChange + 1,
            dragNeedModalCount: state.dragNeedModalCount + 1,
            deletedsubMenu: [
              ...(state.deletedsubMenu.filter(
                (_) => _.id != action.subMenu.id,
              ) ?? []),
            ],
          }
        : state;
    }
    case actionTypes.unDRAG_NEW_SUB_MENU: {
      return {
        ...state,
        subMenus:
          state.subMenus.filter(
            (subMenu) => subMenu.id !== action.subMenu.id,
          ) ?? [],
        deletedsubMenu: [
          ...(state.deletedsubMenu.filter((_) => _.id != action.subMenu.id) ??
            []),
          action.subMenu,
        ],
      };
    }
    case actionTypes.UPDATE_SUB_MENU: {
      return action.subMenus.reduce((newState, composition) => {
        const compositionExists = newState.subMenus.some(
          (c) => c.id === composition.id,
        );
        return !compositionExists
          ? {
              ...newState,
              subMenus: [...newState.subMenus, { ...composition }],
              subMenusChange: newState.subMenusChange + 1,
              dragNeedModalCount: state.dragNeedModalCount + 1,
            }
          : newState;
      }, state);
    }
    case actionTypes.EDIT_SUCCESS_MENU_LEVELS: {
      return {
        ...state,
        subMenus: [],
        deletedsubMenu: [],
      };
    }
    case actionTypes.UPDATE_DELETED_SUB_MENU: {
      return {
        ...state,

        deletedsubMenu: action.subMenus,
      };
    }
    case actionTypes.SELECT_SUB_MENUS: {
      return {
        ...state,

        subMenus: action.subMenu,
        //  dragNeedModalCount:state.dragNeedModalCount+1
      };
    }
    case actionTypes.UNDRAG_ALL_SUB_MENUS: {
      return {
        ...state,
        deletedsubMenu: [...state.deletedsubMenu, ...state.subMenus],
        subMenus: [],
      };
    }
    case actionTypes.CLEAR_SEARCH_FILED: {
      return {
        ...state,
        dragNeedModalCount: 0,
      };
    }
    case actionTypes.UPDATE_ARTICLES_DRAGGED_SUB_MENU: {
      return {
        ...state,
        subMenusDragged: action.subMenusDragged,
      };
    }
    default:
      return state;
  }
}
export default reducer;
