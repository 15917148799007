import { CategorySingle } from '../../../models/services/module.category';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  categoriesCart: CategorySingle[];
  deletedcategoriesCart: CategorySingle[];
  categoryCartChange: number;
} = {
  categoriesCart: [],
  deletedcategoriesCart: [],
  categoryCartChange: 0,
};

function reducer(
  state = initialState,
  action: {
    category: CategorySingle;
    type: string;
    categoriesCart: CategorySingle[];
  },
) {
  switch (action.type) {
    case actionTypes.DRAG_NEW_CATEGORY_CART: {
      const Exists = state.categoriesCart.some(
        (category) => category.id === action.category.id,
      );
      return !Exists
        ? {
            ...state,
            categoriesCart: [...state.categoriesCart, action.category] ?? [],
            categoryCartChange: state.categoryCartChange + 1,
          }
        : state;
    }
    case actionTypes.unDRAG_NEW_CATEGORY_CART: {
      return {
        ...state,
        categoriesCart:
          state.categoriesCart.filter(
            (category) => (category?.idCategory??category.id) !== (action.category.idCategory??action.category.id),
          ) ?? [],
        deletedcategoriesCart: [
          ...(state.deletedcategoriesCart ?? []),
          action.category,
        ],
      };
    }
    case actionTypes.UPDATE_CATEGORY_CART: {
      return action.categoriesCart.reduce((newState, category) => {
        const categoryExists = newState.categoriesCart.some(
          (c) => c.id === category.id,
        );
        return !categoryExists
          ? {
              ...newState,
              categoriesCart: [...newState.categoriesCart, { ...category }],
              categoryCartChange: newState.categoryCartChange + 1,
            }
          : newState;
      }, state);
    }
    case actionTypes.EDIT_SUCCESS_CATEGORY_CART: {
      return {
        ...state,
        categoriesCart: [],
        categoryCartChange: 0,
      };
    }
    case actionTypes.UPDATE_DELETED_CATEGORY_CART: {
      return {
        ...state,
        categoriesCart: action.categoriesCart,
      };
    }
    case actionTypes.SELECT_CATEGORY_CARTS: {
      return {
        ...state,
        categoriesCart: action.categoriesCart,
      };
    }
    case actionTypes.UNDRAG_ALL_CATEGORY_CARTS: {
      return {
        ...state,
        deletedcategoriesCart: [
          ...state.deletedcategoriesCart,
          ...state.categoriesCart,
        ],
        categoriesCart: [],
      };
    }
    case actionTypes.CLEAR_SEARCH_FILED: {
      return {
        ...state,
        dragNeedModalCount: 0,
      };
      
    }
    case actionTypes.UPDATE_CATEGORY_CARD: {
      return {
        ...state,
        categoriesCart: action.categoriesCart,
      };
      
    }
    /*   case actionTypes.UPDATE_ARTICLES_DRAGGED_CATEGORY_CART: {
        return {
          ...state,
          categoriesCartDragged: action.categoriesCartDragged,
        };
      } */
    default:
      return state;
  }
}

export default reducer;
