import React from 'react';

/**
 * LabelInput component description.
 *
 * @LabelInput
 *
 */

interface LabelInputProps {
  label?: string;
  name?: string;
  value?: string;
}

const LabelInput: React.FC<LabelInputProps> = ({ label }) => {
  return (
    <>{label && <label className="p14 w-600 DarkBlueGray-txt label-field">{label}</label>}</>
  );
};

export default LabelInput;
