import React, { ReactNode } from 'react';

/**
 * RolesListBox component description.
 *
 * @RolesListBox
 *
 */

interface RolesListBoxProps {
  children: ReactNode;
}

const RolesListBox: React.FC<RolesListBoxProps> = ({ children }) => {
  return (
    <div className={`RolesListBox  rd12`}>
      <div className="box-wrapper pd30-t-b ">
        <div className="box-body fl_col gp10">{children}</div>
      </div>
    </div>
  );
};

export default RolesListBox;
