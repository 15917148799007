import React, { ReactNode } from 'react';

/**
 * TableSection component description.
 *
 * @TableSection
 *
 */

interface TableSectionProps {
  children: ReactNode;
  className?: string;
}

const TableSection: React.FC<TableSectionProps> = ({ children, className }) => {
  return (
    <div
      className={`TableSection fl_col gp10 h-full ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

export default TableSection;
