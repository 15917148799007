import {
    Article,
    ArticleSaved,
    UploadFolder,
  } from './../../../models/services/module.catalogue.d';
  import * as actionTypes from '../../actions/catalogues/actionTypes';
  const initialState: {
    folders: UploadFolder[];
    foldersSuccess: boolean;
  } = {
    folders: [],
    foldersSuccess: false,
  };
  
  function reducer(
    state = initialState,
    action: { type: string; folders?: ArticleSaved[]; item?: string },
  ) {
    switch (action.type) {
      case actionTypes.SUCCESS_GET_FOLDER: {
        return {
          ...state,
          folders: action.folders,
        };
      }
      case actionTypes.POST_FOLDER_SUCCESS: {
        return {
          ...state,
          foldersSuccess: true,
        };
      }
      case actionTypes.SUCCESS_GET_SUBFOLDER: {
        return {
          ...state,
          folders: action.folders,
        };
      }
      case actionTypes.POST_SUBFOLDER_SUCCESS: {
        return {
          ...state,
          foldersSuccess: true,
        };
      }
      case actionTypes.CLEAR_STORE_ITEM: {
        let key = action?.item;
        let obj: Record<string, null> = {};
        obj[key as string] = null;
        return {
          ...state,
          ...obj,
        };
      }
      default:
        return state;
    }
  }
  
  export default reducer;
  