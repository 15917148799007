import React, { useState, useRef, useEffect } from 'react';
import DropwDownArrowBlack from '../../assets/icons/DropwDownArrowBlack.svg';
import ClearFilterIconGrey from '../../assets/icons/ClearFilterIconGrey.svg';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';

interface MultiSelectProps {
  list?: string[];
  companies_list: serviceEntrepriseModels.companyListItem[];
  onChange: (selected: string[]) => void;
  name?: string;
  setCombineSearch: (value: any) => void;
  classList?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  list = [],
  companies_list,
  onChange,
  name = 'defaultName', // Providing a default name if it's missing
  setCombineSearch,
  classList = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleDocClick = (e: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const clearSelection = () => {
    setSelected([]);
    onChange([]); // Notify parent component of the cleared selection
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocClick, false);
    return () => {
      document.removeEventListener('mousedown', handleDocClick, false);
    };
  }, []);

  const toggleChangeListItem = (item: string) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected.includes(item)
        ? prevSelected.filter((selectedItem) => selectedItem !== item)
        : [...prevSelected, item];
      onChange(newSelected); // Notify parent component of the new selection
      return newSelected;
    });
  };

  const renderSelected = () => {
    if (!selected.length) return 'Aucun sélectionné';
    if (selected.length === list.length) return 'Tous sélectionnés';
    if (selected.length === 1) return selected[0];
    return `${selected.length} Sélectionnée(s)`;
  };

  const renderDropDownIcon = () => (
    <img className="drop-down-icon" src={DropwDownArrowBlack} alt="" />
  );

  const ClearFilterIcon = () => (
    <img
      className="clear-filter"
      src={ClearFilterIconGrey}
      alt="Clear"
      onClick={clearSelection}
    />
  );

  // Handle change in checkbox selection
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = e.target;
    
    // When an item is checked or unchecked, we call toggleChangeListItem
    if (checked) {
      toggleChangeListItem(value); // Adding the item to the selected array
    } else {
      toggleChangeListItem(value); // Removing the item from the selected array
    }

    console.log(`Name: ${name}, Checked: ${checked}, Value: ${value}`);
  };

  return (
    <div className={`MultiSelect multi-select ${classList}`} ref={wrapperRef}>
      <button
        className={`multi-select__button fl_row jc_s ai_c ${isOpen ? 'is-open' : ''}`}
        onClick={toggleIsOpen}
      >
        <div className="SelectedText">
          <p className="p14 w-500">{renderSelected()}</p>
        </div>
        <div className="fl_row ai_c gp20">
          {ClearFilterIcon()}
          {renderDropDownIcon()}
        </div>
      </button>
      {isOpen && (
        <div className="multi-select__list-wrapper">
          {list.map((item, index) => {
            const isChecked = selected.includes(item);
            return (
              <div
                key={index}
                tabIndex={0}
                className={`multi-select__list-item ${isChecked ? 'checked-item' : ''}`}
                onClick={() => toggleChangeListItem(item)}
                onKeyUp={(e) => e.key === 'Enter' && toggleChangeListItem(item)}
              >
                <input
                  tabIndex={-1}
                  id={item}
                  type="checkbox"
                  name={name}  // Ensuring each input has a `name` attribute
                  checked={isChecked}
                  value={item}
                  onChange={handleChange}  // Calling the handleChange function
                  readOnly
                />
                <label htmlFor={item}>{item}</label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
