import { serviceAppUserModels } from '../models/services/module.appUser';

// roleUtils.ts
export interface PDV {
  id: number;
  name: string;
}
export interface Access {
  channelId: number;
  isChannelChecked: string;
  pdv: PDV[];
}
export interface Role {
  id: number;
  name: string;
  access: Access[];
}

export const renderUserRoles = (roles: string[]): string[] => {
  if (!roles || roles.length === 0) {
    return [''];
  }
  return roles.map((role) => role);
};
export const calculateTotalPermissionProperty = (
  rowId: string,
  permissions: serviceAppUserModels.Permission[],
  property: 'chainesIds' | 'restaurantsIds',
) => {
  const relevantPermissions = permissions.filter(
    (permission) => permission.rowId === rowId,
  );
  const total = relevantPermissions.reduce((accumulator, permission) => {
    return accumulator + (permission[property]?.length || 0);
  }, 0);
  return total;
};
export const calculateTotalPermissionEdit = (
  row: serviceAppUserModels.Permission,
  property: 'chainesIds' | 'restaurantsIds',
) => {
  const total = row[property].length || 0;
  return total;
};
