import * as actionTypes from '../actions/actionTypes';
import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';

const initialState: serviceCampanyMachineTypeModels.MachineSuperTypesState = {
  MachineSuperTypes: null,
  loading: false,
  error: null,
};

export const machineSuperTypesReducer = (
  state = initialState,
  action: any,
): serviceCampanyMachineTypeModels.MachineSuperTypesState => {
  switch (action.type) {
    case actionTypes.FETCH_MACHINE_SUPER_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_MACHINE_SUPER_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        MachineSuperTypes: action.payload,
      };
    case actionTypes.FETCH_MACHINE_SUPER_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
