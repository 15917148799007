import { serviceAppUserModels } from '../../models/services/module.appUser';
import * as actionTypes from '../actions/actionTypes';

const initialState: serviceAppUserModels.AppUserState = {
  users: [],
  addUserSuccess: undefined,
  editUserSuccess: undefined,
  deleteUserSuccess: undefined,
  usedEmailError: undefined,
  addResponse: false,
  deleteResponse: false,
  nbUsers: 0,
  totalPagesNumbers: 0,
  userFormValidationRequested: false,
  userDetailsFormValidationRequested: false,
  checkUserEmailSuccess: undefined,
  emailInvalid: undefined,
  resetPasswordSucces: undefined,
  activeEmailError: undefined,
  userDetails: {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    isActive: false,
    roles: [   
    ],
  },
  userFormDataDetails: {
    user: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      isActive: false,
    },
    permissions: [
      {
        rowId: '1',
        rolesId: ['0'],
        isActive: false,
        chainesIds: [],
        restaurantsIds: [],
      },
    ],
  },
  userFormData: {
    user: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      isActive: true,
    },
    permissions: [
      {
        rowId: '1',
        rolesId: ['0'],
        isActive: false,
        chainesIds: [],
        restaurantsIds: [],
      },
    ],
  },
};
type Action =
  | serviceAppUserModels.AppUserAction
  | serviceAppUserModels.AppUserDetailsAction;
function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.GET_COMPANY_USERS_LIST: {
      return { ...state };
    }
    case actionTypes.GET_ETABLISSEMENT_USERS_LIST: {
      return { ...state };
    }
    case actionTypes.RESET_USERS_LIST: {
      return { ...state, users: [] };
    }
    case actionTypes.GET_USERS_LIST: {
      return { ...state };
    }
    case actionTypes.RESET_ADD_USER_RESPONSE: {
      return { ...state, addUserSuccess: undefined };
    }
    case actionTypes.GET_USERS_LIST_SUCCESS: {
      return { ...state, users: action.payload };
    }
    case actionTypes.SET_USERS_TOTAL_PAGE_NUMBER: {
      return { ...state, totalPagesNumbers: action.payload };
    }
    case actionTypes.SET_USERS_NUMBER: {
      return {
        ...state,
        nbUsers: action.payload,
      };
    }
    case actionTypes.ADD_USER: {
      return {
        ...state,
      };
    }
    case actionTypes.ADD_USER_SUCCESS: {
      return {
        ...state,
        addUserSuccess: true,
      };
    }
    case actionTypes.RESET_ADD_USER_SUCCESS: {
      return {
        ...state,
        addUserSuccess: undefined,
      };
    }
    case actionTypes.ADD_USER_FAILURE: {
      return {
        ...state,
        addUserSuccess: false,
      };
    }
    case actionTypes.USED_EMAIL_ACTION: {
      return {
        ...state,
        usedEmailError: true,
      };
    }
    case actionTypes.SET_USER_FORM_DATA: {
      return {
        ...state,
        userFormData: action.payload,
      };
    }
    case actionTypes.RESET_USER_FORM_DATA: {
      return {
        ...state,
        userFormData: {
          ...initialState.userFormData,
          permissions: [
            {
              rowId: '1',
              rolesId: ['0'],
              isActive: false,
              chainesIds: [],
              restaurantsIds: [],
            },
          ],
        },
      };
    }
    case actionTypes.SET_USER_FORM_VALIDATION_REQUESTED: {
      return {
        ...state,
        userFormValidationRequested: action.payload,
      };
    }
    case actionTypes.GET_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case actionTypes.SET_USER_FORM_DATA_DETAILS: {
      return {
        ...state,
        userFormDataDetails: action.payload,
      };
    }
    case actionTypes.RESET_USER_FORM_DATA_DETAILS: {
      return {
        ...state,
        userFormDataDetails: initialState.userFormDataDetails,
      };
    }
    case actionTypes.SET_USER_FORM_VALIDATION_REQUESTED_DETAILS: {
      return {
        ...state,
        userDetailsFormValidationRequested: action.payload,
      };
    }
    case actionTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        deleteUserSuccess: action.payload,
      };
    }
    case actionTypes.EDIT_USER_SUCCESS: {
      return {
        ...state,
        editUserSuccess: action.payload,
      };
    }
    case actionTypes.RESET_EDIT_USER_SUCCESS: {
      return {
        ...state,
        editUserSuccess: undefined,
      };
    }
    case actionTypes.CHECK_USER_EMAIL: {
      return {
        ...state,
      };
    }
    case actionTypes.CHECK_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        checkUserEmailSuccess: action.payload,
      };
    }
    case actionTypes.RESET_CHECK_USER_EMAIL: {
      return {
        ...state,
        checkUserEmailSuccess: undefined,
      };
    }
    case actionTypes.RESET_PASSWORD: {
      return {
        ...state,
      };
    }
    case actionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPasswordSucces: action.payload,
      };
    }
    case actionTypes.SET_ACTIVE_EMAIL_ERROR: {
      return {
        ...state,
        activeEmailError: action.payload,
      };
    }
    case actionTypes.RESET_ACTIVE_EMAIL_ERROR: {
      return {
        ...state,
        activeEmailError: undefined,
      };
    }
    case actionTypes.EMAIL_INVALID: {
      return {
        ...state,
        emailInvalid: action.payload,
      };
    }
    case actionTypes.RESET_EMAIL_INVALID: {
      return {
        ...state,
        emailInvalid: undefined,
      };
    }
    default:
      return state;
  }
}

export default reducer;
