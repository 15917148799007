import React from 'react';
import InputField from '../../Input/Input';
import SelectInput from '../../Input/SelectInput';
import ButtonElement from '../../button/button';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';

interface Props {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  handleSubmit?: any;
  calculeTheTotalTTC?: any;
  addAbonnment?: Partial<serviceEtablissmentMachineTypeModels.IaddAbonnment>;
  onClickClose?: () => void;
  haserror?: boolean;
  errorMsg?: string;
}
export default function SubscriptionsModalContent(props: Props) {
  return (
    <div className="SubscriptionsModalContent fl_col gp40">
      <div className="content-wrapper fl_col gp20">
        <div className="fl_col gp20">
          <InputField
            name="designation"
            isRequired={true}
            type="text"
            label={'Désignation'}
            // maxLength={9}
            style="form"
            value={props.addAbonnment?.designation}
            isDisabled={false}
            placeholder="Référence ou numéro de la facture"
            onBlur={props.onBlur}
            onChange={props.onChange}
            haserror={
              props.errorMessage == 'La désignation ne peut pas être vide'
                ? true
                : false
            }
            errorMsg={
              props.errorMessage == 'La désignation ne peut pas être vide'
                ? 'La désignation ne peut pas être vide'
                : ''
            }
          />
          <InputField
            name="description"
            isRequired={true}
            type="text"
            label={'Description (Détails de la facture)'}
            // maxLength={9}
            style="form"
            value={props.addAbonnment?.description}
            isDisabled={false}
            placeholder="Détails abonnement"
            onBlur={props.onBlur}
            onChange={props.onChange}
            haserror={
              props.errorMessage == 'La Description ne peut pas être vide'
                ? true
                : false
            }
            errorMsg={
              props.errorMessage == 'La Description ne peut pas être vide'
                ? 'La Description ne peut pas être vide'
                : ''
            }
          />
        </div>
        <div className="fl_row gp10">
          <InputField
            name="totalHT"
            isRequired={true}
            type="number"
            label={'Total HT'}
            maxLength={9}
            style="prefix"
            value={props.addAbonnment?.totalHT}
            isDisabled={false}
            placeholder="0.00"
            prefix="€"
            onChange={props.onChange}
            onBlur={props.onBlur}
            haserror={
              props.errorMessage ==
              'Total HT doit être supérieur à 0 et ne doit pas être vide'
                ? true
                : false
            }
            errorMsg={
              props.errorMessage ==
              'Total HT doit être supérieur à 0 et ne doit pas être vide'
                ? 'Total HT doit être supérieur à 0 et ne doit pas être vide'
                : ''
            }
          />
          <InputField
            name="vat"
            isRequired={true}
            type="number"
            label={'TVA'}
            maxLength={9}
            style="prefix"
            value={props.addAbonnment?.vat}
            isDisabled={false}
            placeholder="0.00"
            prefix="%"
            onChange={props.onChange}
            onBlur={props.onBlur}
            haserror={
              props.errorMessage ==
              'TVA doit être supérieure à 0 et ne doit pas être vide'
                ? true
                : false
            }
            errorMsg={
              props.errorMessage ==
              'TVA doit être supérieure à 0 et ne doit pas être vide'
                ? 'TVA doit être supérieure à 0 et ne doit pas être vide'
                : ''
            }
          />
          <InputField
            name="totalTTC"
            isRequired={true}
            type="number"
            label={'Total TTC'}
            maxLength={9}
            style="prefix"
            isDisabled={true}
            placeholder="0.00"
            value={props.calculeTheTotalTTC}
            prefix="€"
            onBlur={props.onBlur}
            onChange={props.onChange}
            // haserror={true}
            // errorMsg={props.errorMsg}
          />
        </div>
        <div className="fl_col">
          <SelectInput
            isRequired={true}
            label={'Intervalle'}
            isDisabled={true}
            name="intervalleId"
            target="dropdown"
            icons={[]}
            options={['Mensuel', 'Mensuel', 'Mensuel']}
            placeholder="Mensuel"
            onChange={props.onChange}
          ></SelectInput>
        </div>
      </div>
      <div className="modal-footer">
        <div className="footer-wrapper fl_row jc_fe gp20">
          <div className="fl-1">
            <ButtonElement
              type={'TextButton'}
              text="Retour"
              buttonSize={'medium'}
              buttonStyle="btn-primary-light"
              onClick={props.onClickClose}
            />
          </div>
          <div className="fl-2">
            <ButtonElement
              type={'TextButton'}
              text="Valider"
              buttonSize={'medium'}
              buttonStyle="btn-primary"
              onClick={() => {
                if (props.handleSubmit) {
                  props.handleSubmit();
                }
              }}
            />
          </div>
        </div>
      </div>
      {/* {props.errorMessage && (
        <div style={{ color: 'red' }}>{props.errorMessage}</div>
      )} */}
    </div>
  );
}
