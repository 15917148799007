import { LevelArticle, level } from '../../../models/services/module.catalogue';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  menuLevels: level[];
  menuLevelsChange: number;
  deletedMenuLevels: level[];
  dragNeedModalCount: number;
  levelArticlesDragged: LevelArticle[];
} = {
  menuLevels: [],
  menuLevelsChange: 0,
  deletedMenuLevels: [],
  dragNeedModalCount: 0,
  levelArticlesDragged: [],
};
function reducer(
  state = initialState,
  action: {
    level: level;
    menuLevels: level[];
    type: string;
    menuLevelsChange: number;
    levelArticlesDragged: LevelArticle[];
  },
) {
  switch (action.type) {
    case actionTypes.DRAG_NEW_MENU_LEVEL: {
      const Exists = state.menuLevels.some(
        (level) =>
          (level.idLevel ?? level.id) ===
          (action.level.idLevel ?? action.level.id),
      );
      return !Exists
        ? {
            ...state,
            menuLevels: [...state.menuLevels, action.level] ?? [],
            menuLevelsChange: state.menuLevelsChange + 1,
            dragNeedModalCount: state.dragNeedModalCount + 1,
            deletedMenuLevels: [
              ...(state.deletedMenuLevels.filter(
                (_) => _.id != action.level.id,
              ) ?? []),
            ],
          }
        : state;
    }
    case actionTypes.unDRAG_NEW_MENU_LEVEL: {
      return {
        ...state,
        menuLevels:
          state.menuLevels.filter((level) => level.id !== action.level.id) ??
          [],
        deletedMenuLevels: [
          ...(state.deletedMenuLevels.filter((_) => _.id != action.level.id) ??
            []),
          action.level,
        ],
      };
    }
    case actionTypes.UPDATE_MENU_LEVELS: {
      return action.menuLevels.reduce((newState, composition) => {
        const compositionExists = newState.menuLevels.some(
          (c) => c.id === composition.id,
        );
        return !compositionExists
          ? {
              ...newState,
              menuLevels: [...newState.menuLevels, { ...composition }],
              menuLevelsChange: newState.menuLevelsChange + 1,
              dragNeedModalCount: state.dragNeedModalCount + 1,
            }
          : newState;
      }, state);
    }
    case actionTypes.EDIT_SUCCESS_MENU_LEVELS: {
      return {
        ...state,
        menuLevels: [],
        deletedMenuLevels: [],
      };
    }
    case actionTypes.UPDATE_DELETED_MENU_LEVELS: {
      return {
        ...state,

        deletedMenuLevels: action.menuLevels,
      };
    }
    case actionTypes.SELECT_MENUS_LEVELS: {
      return {
        ...state,

        menuLevels: action.menuLevels,
        // menuLevelsChange: state.menuLevelsChange + 1,

        //  dragNeedModalCount:state.dragNeedModalCount+1
      };
    }
    case actionTypes.UNDRAG_ALL_MENUS_LEVELS: {
      return {
        ...state,
        deletedMenuLevels: [...state.deletedMenuLevels, ...state.menuLevels],
        menuLevels: [],
      };
    }
    case actionTypes.CLEAR_SEARCH_FILED: {
      return {
        ...state,
        dragNeedModalCount: 0,
      };
    }
    case actionTypes.UPDATE_LEVELS_ARTICLES_DRAGGED: {
      return {
        ...state,
        levelArticlesDragged: action.levelArticlesDragged,
        menuLevelsChange: state.menuLevelsChange + 1,
      };
    }
    default:
      return state;
  }
}
export default reducer;
