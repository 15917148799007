import React, { MouseEvent, useState } from 'react';
import TabItem from '../../../components/ProfileInfo/TabItem';
import HomeIconPrimary from '../../../assets/icons/HomeIconPrimary.svg';
import ArrowCircleRight from '../../../assets/icons/ArrowCircleRightIconPrimary.svg';
import { languageData } from '../../../i18n';
import HomeIconGrey from '../../../assets/icons/HomeIconGrey.svg';
import { useSelector } from 'react-redux';
export default function TabSwitcherEtablissements(props: any) {
  const onSwitchMiniTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    props.setActiveMiniTab(target.id);
    props.setActiveMiniTab(target.id === '' ? '1' : target.id);
  };
  const { claimsEtablissement, isAdmin } = useSelector(
    (state: { auth: { claimsEtablissement: string[]; isAdmin: boolean } }) =>
      state.auth,
  );

  const AddScroll = () => {
    const tab = document.querySelector(
      '.TabSwitcher .tab-wrapper .list-menu-tabs',
    ) as HTMLElement;
    if (tab) {
      tab.scrollBy(150, 0);
    }
  };
  const [showMenu, setShowMenu] = useState(false);

  const OpenMobileMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  return (
    <div className="TabSwitcherEtablissements TabSwitcher fl_row ai_c gp20">
      <div className="tab-wrapper ">
        <div
          className={`list-menu-tabs  fl_row ai_c ${showMenu ? 'Active-menu' : ''}`}
          onClick={OpenMobileMenu}
        >
          <TabItem
            id="1"
            name={languageData.global.TabsTitle.Fiche}
            icon={props.activeMiniTab === '1' ? HomeIconPrimary : HomeIconGrey}
            isActive={props.activeMiniTab === '1'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          />
          <TabItem
            id="2"
            name={languageData.global.TabsTitle.Informationfinancière}
            isActive={props.activeMiniTab === '2'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          />
          {/* <TabItem id="3" name={languageData.global.TabsTitle.Responsable} />
          <TabItem
            id="4"
            name={languageData.global.TabsTitle.Établissement}
            isActive={props.activeMiniTab === '4'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          /> */}

          {/* <TabItem
            id="5"
            name={languageData.global.TabsTitle.roles}
            isActive={props.activeMiniTab === '5'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          /> */}
          {/* <TabItem
            id="6"
            name={languageData.global.TabsTitle.users}
            isActive={props.activeMiniTab === '6'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          /> */}

          <TabItem
            id="8"
            name={languageData.global.TabsTitle.abonnements}
            isActive={props.activeMiniTab === '8'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          />
          <TabItem
            id="7"
            name={languageData.global.TabsTitle.Catalogue}
            isActive={props.activeMiniTab === '7'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          />
          <TabItem
            id="9"
            name={languageData.global.TabsTitle.factures}
            isActive={props.activeMiniTab === '9'}
            onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
          />
          {(isAdmin || claimsEtablissement?.includes('Licences.Affichage')) && (
            <TabItem
              id="10"
              name={languageData.global.TabsTitle.licences}
              isActive={props.activeMiniTab === '10'}
              onClick={(e: MouseEvent<HTMLDivElement>) => onSwitchMiniTab(e)}
            />
          )}
        </div>
      </div>
      <div className="slider-tab">
        <img
          loading="lazy"
          src={ArrowCircleRight}
          alt=""
          width={20}
          onClick={AddScroll}
        />
      </div>
    </div>
  );
}
