import React, { useState, useEffect } from 'react';
import SuccessIconWhite from '../../assets/icons/SuccessIconWhite.svg';
import InfoIconWhite from '../../assets/icons/InfoIconWhite.svg';
import WarningIconWhite from '../../assets/icons/WarningIconWhite.svg';
import ErrorIconWhite from '../../assets/icons/ErrorIconWhite.svg';
import CloseIconWhite from '../../assets/icons/CloseIconWhite.svg';

export interface ToastProps {
  type: 'error' | 'alert' | 'info' | 'success';
  description: string;
  className?: string;
  isVisible?: null | boolean;
  timeout?: number;
  onClose?: () => void;
}

const Toast: React.FC<ToastProps> = ({
  type,
  description,
  className = '',
  isVisible,
  timeout = 3000,
  onClose,
}) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (visible) {
      timeoutId = setTimeout(() => {
        setVisible(false);
        onClose?.();
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [visible, timeout, onClose]);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const getIcon = () => {
    switch (type) {
      case 'alert':
        return WarningIconWhite;
      case 'error':
        return ErrorIconWhite;
      case 'info':
        return InfoIconWhite;
      case 'success':
        return SuccessIconWhite;
      default:
        return 'default.png';
    }
  };

  return (
    <div
      className={`Toast rd12 fl_row ai_c ${type} ${className} ${
        visible ? 'isVisible' : ''
      }`}
    >
      <div className="toast-wrapper fl_row ai_s jc_s pd20 w-full">
        <div className="fl_row ai_c gp16">
          <img className="IconToast" src={getIcon()} alt={`Icon ${type}`} />
          <div className="fl_col gp4">
            <p className="white-txt p16 w-500">{type}</p>
            <p className="toast-description p14 white-txt">{description}</p>
          </div>
        </div>
        <img
          className="closeToast"
          src={CloseIconWhite}
          alt="Close Icon"
          onClick={() => {
            setVisible(false);
            onClose?.();
          }}
        />
      </div>
    </div>
  );
};

export default Toast;
