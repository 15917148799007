import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ChevronDownIcon from '../../assets/icons/ChevronDownIcon.svg';
import TextIconBleu from '../../assets/icons/TextIconBleu.svg';
import BackgroundColorIconBleu from '../../assets/icons/BackgroundColorIconBleu.svg';
import ModalColorPicker from '../Modal/ModalColorPicker';

/**
 * ColorPicker component description.
 *
 * @ColorPicker
 *
 */

interface ColorPickerProps {
  label?: string;
  value?: string;
  onChange?: (e: string) => void;
  defaultValue?: string;
  onClick?: () => void;
  type?: 'text' | 'background' | 'quantity';
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  value,
  onChange,
  defaultValue,
  onClick,
  type,
}) => {
  const [color, setColor] = useState(defaultValue ?? 'white');
  const colorPickerRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const HandelModalColorPicker = () => {
    onClick&&onClick()
    setShowColorPicker((prevState) => !prevState);
  };

  const handleClickOutsidee = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidee);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidee);
    };
  }, []);

  const handleColorChange = (color: string) => {
    onChange && onChange(color);
    setColor(color);
  };

  useEffect(() => {
    if (defaultValue) {
      setColor(defaultValue);
    }
  }, [defaultValue]);
  const GetColorInfo = () => {
    switch (type) {
      case 'background':
        return (
          <div className="fl_row gp4 ai_c">
            <img src={BackgroundColorIconBleu} alt="" />
            <label className="p12 w-500 DarkBlueGray-txt">Arièrre-plan</label>
            <p className="tooltip p12">Couleur du fond</p>
          </div>
        );

      case 'text':
        return (
          <div className="fl_row gp4 ai_c">
            <img src={TextIconBleu} alt="" />
            <label className="p12 w-500 DarkBlueGray-txt">Texte</label>
            <p className="tooltip p12">Couleur du désignation et description</p>
          </div>
        );

      case 'quantity':
        return (
          <div className="fl_row gp4 ai_c">
            <img src={BackgroundColorIconBleu} alt="" />
            <label className="p12 w-500 DarkBlueGray-txt">
              Bandeau quantité totale
            </label>
          </div>
        );
    }
  };

  return (
    <div className="ColorPicker">
      {showColorPicker && (
        <div className="ModalColorPicker-box" ref={componentRef}>
          <ModalColorPicker
            value={color}
            onChangeColor={(color) => handleColorChange(color)}
          />
        </div>
      )}
      <div
        className="field-wrapper fl_col gp10 ai_c"
        onClick={HandelModalColorPicker}
      >
        <div className="colorInfo">{GetColorInfo()}</div>
        <div className="fl_row color-field w-full">
          <div className="fl_row gp4 w-full">
            <div
              style={{
                backgroundColor: color ?? 'white',
                //  width:100
              }}
              //disabled
              // onClick={HandelModalColorPicker}
              ref={colorPickerRef}
              //type="color"
              id="colorpicker"
              //name="color"
              // value={color}
              //    onChange={handleColorChange}
            />
            {/* < input autoComplete="off"
              defaultValue={color}
              type="text"
              id="hexcolor"
              name="hexcolor"
              pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
              value={color}
              onChange={handleHexChange}
            /> */}
          </div>
          {/* <div className=" fl_row ai_c"> */}
          <img src={ChevronDownIcon} alt="" className="PenPicker" />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
