import { Badges } from './../../../models/services/module.catalogue.d';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  badges: Badges[];
  badgeSuccess: boolean;
} = {
  badges: [],
  badgeSuccess: false,
};
function reducer(
  state = initialState,
  action: { type: string; badges?: Badges[]; item?: string },
) {
  switch (action.type) {
    case actionTypes.SUCCESS_GET_BADGES: {
      return {
        ...state,
        badges: action.badges,
      };
    }
    case actionTypes.POST_BADGE_SUCCESS: {
      return {
        ...state,
        badgeSuccess: true,
      };
    }
    case actionTypes.CLEAR_STORE_ITEM: {
      let key = action?.item;
      let obj: Record<string, null> = {};
      obj[key as string] = null;
      return {
        ...state,
        ...obj,
      };
    }
    default:
      return state;
  }
}
export default reducer;
