import React, { ReactNode } from 'react';
import SkeletonLoader from '../SkeletonLoader';

interface Table {
  children?: ReactNode;
  className?: string;
  showSkeleton?: boolean;
}

const Table: React.FC<Table> = ({ children, className, showSkeleton }) => {
  return showSkeleton ? (
    <SkeletonLoader show type="table" />
  ) : (
    <table className={`TableContainer Table ${className ? className : ''}`}>
      {children}
    </table>
  );
};

export default Table;
