import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import BottomArrowGreyIcon from '../../../assets/icons/BottomArrowGreyIcon.svg';
import SearchIconGreyInp from '../../../assets/icons/SearchIconGreyInp.svg';
import InputField from '../Input';
import OptionItem from '../OptionItem';

interface SelectInputProps {
  label?: string;
  name?: string;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: string;
  isDisabled?: boolean;
  haserror?: boolean;
  errorMsg?: string;
  placeholder?: string;
  options: string[];
  icons?: string[];
  target: 'country' | 'dropdown' | 'search' | 'reference' | 'reflags';
  onKeyUp?: () => void;
  onSelect?: (
    selectedValue: string,
    selectedIcon?: string,
    reference?: string,
  ) => void;
  reference?: string[];
  isRequired?: boolean;
  defaultValue?: string | number;
  value?: string | number;
  showSkeleton?: boolean;
}

const SelectInput2: React.FC<SelectInputProps> = ({
  label,
  name,
  isDisabled,
  placeholder,
  icons,
  options,
  onChange,
  target,
  onSelect,
  haserror,
  errorMsg,
  reference,
  isRequired,
  defaultValue,
  showSkeleton,
  value,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputTxt, setInputTxt] = useState<string>('');
  const [inputIcon, setInputIcon] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue) {
      //setInputValue("115121212221");
    }
  }, [defaultValue]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setShowDropdown(false);

    if (onChange) {
      onChange(e);
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
    setShowDropdown(false);
  };

  const handleOptionClick = (
    optionValue: string,
    optionIcon?: string,
    ref?: string,
  ) => {
    setInputTxt(optionValue);
    setInputIcon(optionIcon as string);
    setShowDropdown(false);

    if (onSelect) {
      onSelect(optionValue, optionIcon, ref);
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const filteredItems = options
    .map((option, index) => {
      const referenceVal = reference ? reference[index] : undefined;
      // const optionLower = option.toLowerCase();
      // const referenceLower = referenceVal ? referenceVal.toLowerCase() : '';

      // if (
      //   optionLower.includes(inputValue) ||
      //   referenceLower.includes(inputValue)
      // ) {
      //   return {
      //     option,
      //     icon: icons && icons.length > index ? icons[index] : undefined,
      //     reference: referenceVal,
      //   };
      // }
      return {
        option,
        icon: icons && icons.length > index ? icons[index] : undefined,
        reference: referenceVal,
      };

      //return null;
    })
    .filter(Boolean);

  return (
    <div
      className={`InputFieldDropDown ${target}_field ${haserror ? 'haserror' : ''}`}
      ref={dropdownRef}
    >
      <div className="inp-select-field">
        {target === 'reflags' && (
          <div className="country-details fl_row ai_c gp20">
            <img
              className="icon icon-flag"
              src={require(
                `../../../data/flags/${inputTxt ? inputTxt : 'fr'}.svg`,
              )}
              alt=""
            />
            <input
              autoComplete="off"
              className="country-code"
              type="text"
              value={inputIcon ? inputIcon : '+33'}
            />
          </div>
        )}
        {target === 'dropdown' && (
          <img className="icon-dropdown" src={BottomArrowGreyIcon} alt="" />
        )}
        {target === 'search' && (
          <img className="icon-search" src={SearchIconGreyInp} alt="" />
        )}
        <div className="inp fl_col gp8">
          {target === 'reflags' ? (
            <InputField
              defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              name={name}
              type="number"
              placeholder={placeholder}
              label={label}
              style="form"
              isDisabled={isDisabled}
              //onClick={() => setShowDropdown((prevState) => !prevState)}
              haserror={haserror}
              isRequired={isRequired}
              showSkeleton={showSkeleton}
            />
          ) : (
            <InputField
              defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              name={name}
              type="text"
              placeholder={placeholder}
              label={label}
              style="form"
              isDisabled={isDisabled}
              // onClick={() => setShowDropdown((prevState) => !prevState)}
              haserror={haserror}
              isRequired={isRequired}
              showSkeleton={showSkeleton}
            />
          )}

          {haserror && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
        </div>

        <div className={`InputDropDownList ${showDropdown ? '' : 'hide'}`}>
          <ul className="fl_col gp10 pd10-t-b">
            {filteredItems.map(
              (item, index) =>
                item && (
                  <OptionItem
                    key={index}
                    target={target}
                    value={item.option}
                    icon={item.icon}
                    reference={item.reference}
                    onClick={(value, icon, ref) =>
                      handleOptionClick(value, icon, ref)
                    }
                  />
                ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectInput2;
