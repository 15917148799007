import React, { ReactNode } from 'react';

interface MobileTableContainerProps {
  children: ReactNode;
  className?: string;
}

const MobileTableContainer: React.FC<MobileTableContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`mobile-table w-full ${className ? className : ''}`}>
      <div className="table-wrapper w-full">
        <div className="table-listing fl_col gp10 w-full">{children}</div>
      </div>
    </div>
  );
};

export default MobileTableContainer;
