import React from 'react';
import MobileIconBlack from '../../assets/icons/MobileIconBlack.svg';
import PhoneIconBlack from '../../assets/icons/PhoneIconBlack.svg';
import SirenBarCodeIconBlack from '../../assets/icons/SirenBarCodeIconBlack.svg';
import AdresseIconBlack from '../../assets/icons/AdresseIconBlack.svg';
import PlaceHolderCompany from '../../assets/images/PlaceHolderCompany.png';
/**
 * HeaderEtablissementProfileInfo component description.
 *
 * @HeaderEtablissementProfileInfo
 *
 */

interface HeaderEtablissementProfileInfoProps {
  avatar?: string;
  name?: string;
  siren?: string;
  mobile?: string;
  fixe?: string;
  adresse?: string;
}

const HeaderEtablissementProfileInfo: React.FC<
  HeaderEtablissementProfileInfoProps
> = ({ avatar, name, siren, mobile, fixe, adresse }) => {
  return (
    <div className="HeaderProfileInfo HeaderEtablissementProfileInfo">
      <div className="header-wrapper fl_row ai_c gp17">
        <div className="avatar">
          <img src={avatar || PlaceHolderCompany} alt="" />
        </div>
        <div className="info fl_col gp10">
          <div className="info-general fl_col gp10">
            <p className="p18 w-700 name">{name}</p>
            <div className="info-wrapper fl_col gp10">
              {siren && (
                <div className="fl_row gp10">
                  <img src={SirenBarCodeIconBlack} alt="" />
                  <p className="siren">{siren}</p>
                </div>
              )}
            </div>
            <div className="contact-info fl_row gp10 fl_wrap">
              {fixe && (
                <div className="fixe fl_row ai_c gp10">
                  <img src={PhoneIconBlack} alt="" />
                  <p className="p16">{fixe}</p>
                </div>
              )}
              {mobile && (
                <div className="mobile fl_row ai_c gp10">
                  <img src={MobileIconBlack} alt="" />
                  <p className="p16">{mobile}</p>
                </div>
              )}
            </div>
            {adresse && (
              <div className="adresse fl_row ai_c gp10">
                <img src={AdresseIconBlack} alt="" />
                <p className="p14">{adresse}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};

export default HeaderEtablissementProfileInfo;
