import React, { ChangeEvent } from 'react';
import FieldName from '../Catalogue/FieldName';
import InputField from './../Input/InputCatalogue';
/**
 * ModalSubFamilyElement component description.
 *
 * @ModalSubFamilyElement
 *
 */
interface ModalSubFamilyElementProps {
  ModalSubFamilyLabel?: string;
  valueinp?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}
const ModalSubFamilyElement: React.FC<ModalSubFamilyElementProps> = ({
  ModalSubFamilyLabel,
  onChange,
  valueinp,
}) => {
  return (
    <div className="ModalSubFamilyElement ModalSubFamilyElement fl_col fl-1">
      {/* <label
        className="p12 w-500 DarkBlueGray-txt"
        htmlFor="ModalSubFamilyElement"
      >
        {ModalSubFamilyLabel}
      </label> */}
      {/* <input
        autoComplete="off"
        id="ModalSubFamilyElement"
        type="text"
        onChange={onChange}
        value={valueinp}
      /> */}

      <FieldName
        defaultValue={valueinp}
        id="ModalSubFamilyElement"
        requied
        onChange={onChange}
        label={ModalSubFamilyLabel}
        // placeholder={languageData.fr.screen.grillade}
      />
    </div>
  );
};

export default ModalSubFamilyElement;
