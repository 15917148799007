import React from 'react';

interface HomeIconGreyProps {
  strokeColor?: string;
}

const HomeIconGrey: React.FC<HomeIconGreyProps> = ({ strokeColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        stroke={strokeColor || '#475467'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.667 14.166h6.666M8.181 2.303L2.53 6.699c-.377.294-.566.44-.702.625-.12.163-.21.346-.265.542-.062.22-.062.46-.062.938v6.029c0 .933 0 1.4.182 1.757.16.313.414.568.728.728.357.182.823.182 1.757.182h9.666c.934 0 1.4 0 1.757-.182.314-.16.569-.415.728-.728.182-.357.182-.824.182-1.757v-6.03c0-.478 0-.717-.062-.937a1.665 1.665 0 00-.265-.542c-.136-.184-.325-.331-.702-.625L9.819 2.303c-.293-.228-.44-.342-.601-.385a.833.833 0 00-.436 0c-.161.043-.308.157-.6.385z"
      ></path>
    </svg>
  );
};

export default HomeIconGrey;
