//! AUTHENTIFICATION MIDDELWARE
import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import {
  failure,
  addEntrepriseSuccess,
  addEntrepriseFailure,
  setAllEntreprise,
  setCompanyByIdSheet,
  setCompanyByIdFinancialInfos,
  editEntrepriseSuccess,
  editEntrepriseFailure,
  setCompanyByIdInfos,
  resetLoggedIn,
  setAllEntrepriseWithEstablishements,
  resetPasswordSuccesAction,
  activateDeactivateCampanyFailure,
  activateDeactivateCampanySuccess,
} from '../actions/actions';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';
import entrepriseApi from '../../services/apiHandlers/entrepriseApi';
import { unauthorizedError } from '../../utils/unauthorizedError';

export function* addEntrepriseSaga(
  action: serviceEntrepriseModels.addEntrepriseAction,
) {
  try {
    const response: AxiosResponse = yield entrepriseApi.add(action.payload);
    if (response.data?.result?.company_id) {
      yield put(addEntrepriseSuccess(response.data.result.company_id));
    } else {
      yield put(addEntrepriseFailure());
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(addEntrepriseFailure());
  }
}
export function* getAllCompaniesSaga(
  action: serviceEntrepriseModels.getAllEntrepriseAction,
) {
  try {
    const response: AxiosResponse = yield entrepriseApi.getAllCompanies(
      action.payload,
    );

    if (response.data) {
      yield put(setAllEntreprise(response.data));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getAllCompaniesWithEstablishementsSaga(
  action: serviceEntrepriseModels.getAllEntrepriseAction,
) {
  try {
    const response: AxiosResponse =
      yield entrepriseApi.getAllCompaniesWithEstablishements(action.payload);

    if (response.data) {
      yield put(setAllEntrepriseWithEstablishements(response.data.result));
      // yield put(setCompaniesTotalPagesNumber(response.data.pagination.nbPage));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getCompanyByIdInfosSaga(
  action: serviceEntrepriseModels.getEntrepriseAction,
) {
  try {
    const response: AxiosResponse = yield entrepriseApi.getCompanyByIdInfos(
      action.payload.id,
    );

    if (response.data) {
      yield put(setCompanyByIdInfos(response.data.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getCompanyByIdSheetSaga(
  action: serviceEntrepriseModels.getEntrepriseAction,
) {
  try {
    const response: AxiosResponse = yield entrepriseApi.getCompanyByIdSheet(
      action.payload.id,
    );

    if (response.data) {
      yield put(setCompanyByIdSheet(response.data.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getCompanyByIdFinancialInfosSaga(
  action: serviceEntrepriseModels.getEntrepriseAction,
) {
  try {
    const response: AxiosResponse =
      yield entrepriseApi.getCompanyFinancialInfos(action.payload.id);

    if (response.data) {
      yield put(setCompanyByIdFinancialInfos(response.data.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* editCompanyFinancialInfoSaga(
  action: serviceEntrepriseModels.formDataFinancialInfosTypeAction,
) {
  try {
    const response: AxiosResponse =
      yield entrepriseApi.editCompanyFinancialInfos(action.payload);

    if (response.data) {
      yield put(setCompanyByIdFinancialInfos(response.data.result));
      yield put(editEntrepriseSuccess());
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
    yield put(editEntrepriseFailure());
  }
}
export function* editCompanyGeneralInfoSaga(
  action: serviceEntrepriseModels.formDataGeneralInfosTypeAction,
) {
  try {
    const response: AxiosResponse = yield entrepriseApi.editCompanyGenaralInfos(
      action.payload,
    );

    if (response.data) {
      yield put(setCompanyByIdSheet(response.data.result));
      yield put(editEntrepriseSuccess());
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
    yield put(editEntrepriseFailure());
  }
}
export function* activateDeactivateCampanySaga(action: {
  type: string;
  payload: {
    idCompany: number;
    data: boolean;
    _?: any;
  };
}) {
  const { idCompany, data, _ } = action.payload;
  try {
    const response: AxiosResponse =
      yield entrepriseApi.activateDeactivateCampany(idCompany, data);
    if (response.data) {
      yield put(activateDeactivateCampanySuccess(response.data.result));
      _ && _();
    }
  } catch (error: string | any) {
    yield put(activateDeactivateCampanyFailure(error.message as string));
  }
}
