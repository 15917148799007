import React, { ChangeEvent, LegacyRef, useState } from 'react';
import AmericanExpressLogo from '../../assets/icons/AmericanExpressLogo.svg';
import BottomArrowGreyIcon from '../../assets/icons/BottomArrowGreyIcon.svg';
import ErrorIconRed from '../../assets/icons/ErrorIconRed.svg';
import FrFlagCircle from '../../assets/icons/FrFlagCircle.svg';
import HidePwIcon from '../../assets/icons/HidePwIcon.svg';
import MastercardLogo from '../../assets/icons/MastercardLogo.svg';
import SearchIconGrey from '../../assets/icons/SearchIconGrey.svg';
import ShowPwIcon from '../../assets/icons/ShowPwIcon.svg';
import VisaLogo from '../../assets/icons/VisaLogo.svg';
import CheckboxInput from './CheckboxInput';
import LabelInput from './LabelInput';

/**
 * InputField component
 */

interface InputFieldProps {
  type?: 'text' | 'password' | 'number' | 'email' | 'tel' | 'date';
  placeholder?: string;
  label?: string;
  icon?: string;
  value?: string | number;
  style?:
    | 'auth'
    | 'search'
    | 'form'
    | 'inpflag'
    | 'inpdate'
    | 'selectInp'
    | 'checkbox'
    | 'counter'
    | 'card';
  className?: string;
  minLength?: number;
  maxLength?: number;
  radius?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  SelectType?: 'flags' | 'search';
  ischecked?: boolean;
  name?: string;
  id?: string;
  isRequired?: boolean;
  isPrincipal?: boolean;
  initialCounter?: number;
  prefix?: string;
  haserror?: boolean;
  errorMsg?: string;
  onClick?: () => void;
  onKeyUp?: () => any;
  cardType?: 'visa' | 'master' | 'express';
  maxLengthCardNumber?: number;
  reff?: LegacyRef<HTMLInputElement> | undefined;
  divref?: LegacyRef<HTMLDivElement> | undefined;
}

const InputField: React.FC<InputFieldProps> = ({
  type = 'text',
  placeholder,
  label,
  icon,
  value,
  style,
  className,
  radius,
  onChange,
  isDisabled,
  SelectType,
  ischecked,
  name,
  minLength,
  maxLength,
  id,
  isPrincipal,
  initialCounter,
  prefix,
  haserror,
  errorMsg,
  onClick,
  onKeyUp,
  isRequired,
  cardType,
  reff,
  maxLengthCardNumber = 16,
  divref,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const cardImg = () => {
    switch (cardType) {
      case 'express':
        return AmericanExpressLogo;
      case 'master':
        return MastercardLogo;
      case 'visa':
        return VisaLogo;
      default:
        return '';
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > maxLengthCardNumber) {
      e.target.value = value.substring(0, maxLengthCardNumber);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const renderContent = () => {
    switch (style) {
      case 'auth':
        return (
          <div>
            {label && <label>{label}</label>}
            {icon && <img className="icon" src={icon} alt="" />}
            <div className="inp">
              <input
                autoComplete="off"
                className={`${className ? className : ''} ${radius ? radius : ''}`}
                type={
                  type === 'password'
                    ? showPassword
                      ? 'text'
                      : 'password'
                    : type
                }
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
              />
              {haserror && <img src={ErrorIconRed} alt="" />}
            </div>
            {errorMsg && <p className="p14 errorMsg">{errorMsg}</p>}
            {type === 'password' && (
              <img
                src={showPassword ? ShowPwIcon : HidePwIcon}
                alt={showPassword ? 'Hide' : 'Show'}
                className="show-hide-pw"
                onClick={handlePasswordVisibilityToggle}
              />
            )}
          </div>
        );

      case 'search':
        return (
          <div>
            <div className="inp inp-search">
              <img src={SearchIconGrey} alt="" />
              <input
                autoComplete="off"
                ref={reff}
                name={name}
                className={`search-input p14 ${radius ? radius : ''}`}
                type="search"
                placeholder={placeholder}
                onChange={onChange}
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
              />
              {haserror && <img src={ErrorIconRed} alt="" />}
            </div>
            {errorMsg && <p className="p14 errorMsg">{errorMsg}</p>}
            {icon && <img className="icon" src={icon} alt="" />}
          </div>
        );

      case 'form':
        return (
          <div className="fl_col gp8">
            <LabelInput label={label} />
            <div className="inp">
              <input
                autoComplete="off"
                ref={reff}
                type={'text'}
                name={'name' + new Date().toDateString()}
                onChange={onChange}
                placeholder={placeholder}
                className={`p14 pd14-r-l ${isDisabled ? 'isDisabled' : ''} ${radius ? radius : 'rd8'}`}
                disabled={isDisabled}
                minLength={minLength}
                maxLength={maxLength}
                onClick={onClick}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
              />
              {haserror && <img src={ErrorIconRed} alt="ErrorIconRed" />}
            </div>
            {errorMsg && <p className="p14 errorMsg">{errorMsg}</p>}
          </div>
        );
      case 'inpflag':
        return (
          <div className="fl_col gp8">
            <LabelInput label={label} />
            <div className="inp">
              <input
                autoComplete="off"
                name={name}
                type={type}
                onChange={onChange}
                placeholder={placeholder}
                className={`p14 ${isDisabled ? 'isDisabled' : ''} ${radius || 'rd8'}`}
                disabled={isDisabled}
                onKeyPress={(e) => {
                  const allowedKeys = /^[0-9]*$/;
                  if (!allowedKeys.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                step="1"
                min="0"
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
              />
              {haserror && <img src={ErrorIconRed} alt="" />}
            </div>
            {errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
            <div className="flagdropdown fl_row gp8">
              <img className="flag" src={FrFlagCircle} alt="" />
              <img className="arrow" src={BottomArrowGreyIcon} alt="" />
            </div>
          </div>
        );

      case 'inpdate':
        return (
          <div className="fl_col gp8">
            <LabelInput label={label} />
            <div className="inp">
              <input
                autoComplete="off"
                name={name}
                type="date"
                placeholder={placeholder}
                className={`p14 pd14-r-l ${isDisabled ? 'isDisabled' : ''} ${radius || 'rd8'}`}
                disabled={isDisabled}
                min="0"
                minLength={minLength}
                maxLength={maxLength}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
              />
              {haserror && <img src={ErrorIconRed} alt="" />}
            </div>
            {errorMsg && <p className="p14 errorMsg">{errorMsg}</p>}
          </div>
        );
      case 'selectInp':
        return (
          <div className="fl_col gp8">
            <LabelInput label={label} />
          </div>
        );
      case 'checkbox':
        return (
          <CheckboxInput
            label={label}
            name={name}
            id={id}
            className={className}
            ischecked={ischecked}
            isPrincipal={isPrincipal}
          />
        );
      case 'card':
        return (
          <div className="fl_col gp8">
            <LabelInput label={label} />
            <div className="inp">
              <div className="card-img">
                <img src={cardImg()} alt="Card Icon" />
              </div>
              <input
                autoComplete="off"
                name={name}
                type="number"
                placeholder={placeholder}
                className={`p14 pd14-r-l ${isDisabled ? 'isDisabled' : ''} ${radius || 'rd8'}`}
                disabled={isDisabled}
                min="0"
                minLength={minLength}
                maxLength={maxLengthCardNumber}
                value={value}
                onKeyUp={onKeyUp}
                required={isRequired}
                onChange={handleInputChange}
              />
              {haserror && <img src={ErrorIconRed} alt="" />}
            </div>
            {errorMsg && <p className="p14 errorMsg">{errorMsg}</p>}
          </div>
        );
        return;
      default:
        return null;
    }
  };

  return (
    <div
      ref={divref}
      className={`input-field ${isRequired ? 'isRequired' : ''} ${haserror ? 'haserror' : ''}`}
    >
      <div className={`inp-wrapper ${style} ${icon ? 'InpICon' : ''}`}>
        {renderContent()}
      </div>
    </div>
  );
};

export default InputField;
