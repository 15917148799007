import EditItemIcon from '../../assets/icons/EditCircleGreyBg.svg';
import { ModalProps } from '../../models/services/module.modals';
export default function ItemModalController(props: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper">
      <div className="ItemImgBox">
        <img className="itemImg" src={props.itemImg} alt="" />
        <img
          src={EditItemIcon}
          alt=""
          className="EditItem"
          onClick={props.editItemImg}
        />
      </div>
      <div className="ItemName fl_col">
        <label className="p10" htmlFor="ItemName">
          {props.itemNameLabel}
        </label>
        <input
          autoComplete="off"
          id="ItemName"
          type="text"
          value={props.itemName}
        />
      </div>
    </div>
  );
  const modalContentFooter = (
    <div className="wrapperFooter w-full jc_s">
      {props.deleteitem && <button>{props.deleteTxt}</button>}
      <div className="fl_row gp12">
        {props.cancel && (
          <button onClick={props.onClickClose}>{props.cancelTxt}</button>
        )}
        {props.reject && (
          <button onClick={props.onClickClose}>{props.rejectTxt}</button>
        )}
        {props.save && <button>{props.saveTxt}</button>}
        {props.confirm && (
          <button onClick={props.onClickConfirm}>{props.confirmTxt}</button>
        )}
      </div>
    </div>
  );
  return {
    modalContentBody,
    modalContentFooter,
  };
}
