import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnsavedChanges } from './useUnsavedChanges';
import { serviceFormesJuridiquesModels } from '../../../models/services/module.fomesJuridiques';
import { getFormesJuridiques } from '../../../store/actions/actions';

const useLegalFormLogic = () => {
  const dispatch = useDispatch();
  const { newFormData } = useUnsavedChanges();
  const formes = useSelector(
    (state: {
      formesJuridiques: serviceFormesJuridiquesModels.FormesJuridiquesState;
    }) => state.formesJuridiques.formesJuridiques,
  );

  useEffect(() => {
    dispatch(getFormesJuridiques());
  }, []);
  const legalFormesOptions = useMemo(() => {
    if (formes) {
      return formes.map(
        (item: serviceFormesJuridiquesModels.FormesJuridiques) =>
          item.designation,
      );
    }
    return [];
  }, [formes]);

  const defaultValueLegalForm = (): string | number | undefined => {
    if (newFormData?.payload?.generalInformation?.legal_form_id) {
      const selectedItem = formes.find(
        (item: serviceFormesJuridiquesModels.FormesJuridiques) =>
          item.id === newFormData?.payload?.generalInformation?.legal_form_id,
      );

      return selectedItem ? selectedItem.designation : '';
    }

    return '';
  };

  return {
    legalFormesOptions,
    defaultValueLegalForm,
    formes,
  };
};

export default useLegalFormLogic;
