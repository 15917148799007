import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';
import {
  failure,
  getCodesNAFSuccess,
  resetLoggedIn,
  resetPasswordSuccesAction,
  verifyTaxesSuccess,
  verifyTaxesSuccessEtablissement,
} from '../actions/actions';
import taxesInfosApi from '../../services/apiHandlers/taxesInfosApi';
import { serviceTaxesModels } from '../../models/services/module.taxesInfos';
import { unauthorizedError } from '../../utils/unauthorizedError';

export function* verifyTaxesInfosSaga(
  action: serviceTaxesModels.getTaxesInfosAction,
) {
  try {
    const body = {
      vaT_Intracommunity: action?.payload?.vaT_Intracommunity,
      sireN_code: action?.payload?.sireN_code,
      naF_code_id: action?.payload?.naF_code_id,
    };

    const response: AxiosResponse = yield taxesInfosApi.verify(body);

    if (response.data) {
      yield put(verifyTaxesSuccess(response.data.result));
      return response.data.result;
    }
  } catch (error: any) {
    yield put(failure(error));
  }
}
////////////// entreprise
export function* verifyTaxesInfosEtablissementSaga(
  action: serviceTaxesModels.getTaxesInfosAction,
) {
  try {
    const response: AxiosResponse = yield taxesInfosApi.verifyEtablissement(
      action.payload,
    );

    if (response.data) {
      yield put(verifyTaxesSuccessEtablissement(response.data.result));
      return response.data.result;
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getCodesNAFSaga() {
  try {
    const response: AxiosResponse = yield call(taxesInfosApi.getCodesNAF);

    if (response.data) {
      yield put(getCodesNAFSuccess(response.data.result));
    }
  } catch (error: any) {
    yield put(failure(error));
  }
}
