import React from 'react';
import {
  getUsersListAction,
  resetAddUserSuccess,
  resetUserFormData,
  resetUserFormDataDetails,
} from '../../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import UserListTable from './UserListTable';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import Pagination from '../../components/Table/Pagination-table';
import { usePagination } from '../../pages/etablissement/pages/usePagination';

import { languageData } from '../../i18n';
import { ToastProps } from '../../components/Toast/Toast';
import ToastHandler from '../Etablissement/Toast/ToastHandler';

export default function UsersListContainer() {
  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);
  const dispatch = useDispatch();
  const totalPagesNumbers = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.totalPagesNumbers,
  );
  const initialData = {
    currentPage: 1,
    itemsPerPage: 10,
  };
  const emailError = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.usedEmailError,
  );

  const editUserSuccess = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.editUserSuccess,
  );
  const addUserSuccess = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.addUserSuccess,
  );
  const {
    currentPage,
    itemsPerPage,
    handlePreviousClick,
    handleNextClick,
    handlePageChange,
  } = usePagination(initialData);

  React.useEffect(() => {
    const data = {
      paginationModel: { page: currentPage, pageSize: itemsPerPage },
    };
    dispatch(getUsersListAction(data));
  }, [currentPage]);
  React.useEffect(() => {
    switch (addUserSuccess) {
      case true:
        showToast('success', languageData.global.Form.successMsg.addUser),
          dispatch(resetUserFormData());
        const data = { paginationModel: { page: 1, pageSize: 10 } };
        dispatch(getUsersListAction(data));
        dispatch(resetAddUserSuccess());
        break;
      case false:
        showToast(
          'error',
          emailError
            ? languageData.global.Form.errorMsg.usedEmail
            : languageData.global.Form.errorMsg.addError,
        );
        dispatch(resetAddUserSuccess());
        break;
      default:
        break;
    }
  }, [addUserSuccess]);
  React.useEffect(() => {
    switch (editUserSuccess) {
      case true:
        showToast('success', languageData.global.Form.successMsg.editUser),
          dispatch(resetUserFormDataDetails());
        break;
      case false:
        showToast(
          'error',
          emailError
            ? languageData.global.Form.errorMsg.usedEmail
            : languageData.global.Form.errorMsg.addError,
        );
        break;
      default:
        break;
    }
  }, [editUserSuccess]);
  const showToast = (type: ToastProps['type'], message: string) => {
    toastRef.current?.openToast(type, message);
  };

  return (
    <div className="UsersListContainer h-full">
      <div className="fl_col h-full jc_s gp10">
        <UserListTable />
        <Pagination
          totalPagesNumbers={totalPagesNumbers}
          nextpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleNextClick(currentPage)
          }
          prevpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handlePreviousClick(currentPage)
          }
          currentPage={currentPage}
          totalPages={totalPagesNumbers}
          handlePageChange={handlePageChange}
        />
      </div>
      <ToastHandler ref={toastRef} />
    </div>
  );
}
