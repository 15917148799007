import React from 'react';

interface CustomToggleElementProps {
  label: string;
  id: string;
  active?: boolean;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: number | string;
  icon?: string;
  slot?: number;
}

const CustomToggleElement: React.FC<CustomToggleElementProps> = ({
  label,
  id,
  active = false,
  onClick,
  onChange,
  name,
  value,
  icon,
  slot,
}) => {
  return (
    <div
      className={`CustomToggleElement ${active ? 'active' : 'inactive'}`}
      onClick={onClick}
    >
      <div className="element-wrapper fl_row gp6 ai_c">
        <img src={icon} alt="" />
        <div className="fl_row gp4 ai_c">
          <label className="p14 Independence-txt w-700" htmlFor={id}>
            {label}
          </label>
          <input
            className="hide"
            type="checkbox"
            name={name}
            id={id}
            checked={active}
            value={value}
            onChange={onChange}
          />
          <span className="slot-toggle">{slot}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomToggleElement;
