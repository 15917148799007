import * as actionTypes from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    success: false,
    data:{}
};


const forgetPasswordLinkReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.FORGET_PASSWORD_LINK_REQUEST:
            return { ...state, loading: true, error: null };
        case actionTypes.FORGET_PASSWORD_LINK_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case actionTypes.FORGET_PASSWORD_LINK_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:    
            return state;
    }
};
export default forgetPasswordLinkReducer