import { serviceEntrepriseModels } from '../models/services/module.entreprise';
import validateEmail from './EmailValidation';

interface FieldValidationParams {
  category?: string;
  fieldName?: string;
  isRequired?: boolean;
  fieldValue?: string | number | null | undefined;
}
interface validationParamsEditGeneralInfosCompany {
  fieldName?: string;
  isRequired?: boolean;
  fieldValue?: string | number | null | undefined;
}

export function getError(params: FieldValidationParams): boolean {
  const { category, fieldName, isRequired, fieldValue } = params;

  if (
    category === 'generalInformation'
    //  && validationRequested
  ) {
    // Check if the field is required and the value is empty or undefined
    if (fieldName === 'email') {
      const email = typeof fieldValue === 'string' ? fieldValue : '';
      const validatedEmail = validateEmail(email);
      return !validatedEmail;
    }
    if (
      isRequired &&
      (fieldValue === '' ||
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === 0)
    ) {
      //setIsValidForm(false);
      return true;
    }
  }
  if (
    category === 'financialInformation'
    //&& validationRequestedStep2
  ) {
    // Check if the field is required and the value is empty or undefined
    if (
      isRequired &&
      (fieldValue === '' ||
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === 0)
    ) {
      // setIsValidForm(false);
      return true;
    }
  }
  return false;
}
export function getErrorEditCompany(
  params: validationParamsEditGeneralInfosCompany,
): boolean {
  const { fieldName, isRequired, fieldValue } = params;

  // Check if the field is required and the value is empty or undefined
  if (
    isRequired &&
    (fieldValue === '' ||
      fieldValue === null ||
      fieldValue === undefined ||
      fieldValue === 0)
  ) {
    //setIsValidForm(false);
    return true;
  }
  if (fieldName === 'email') {
    const email = typeof fieldValue === 'string' ? fieldValue : '';
    const validatedEmail = validateEmail(email);

    return !validatedEmail;
  }

  return false;
}
export function getErrorRedevance(
  item: serviceEntrepriseModels.royaltiesInformation,
): boolean {
  // Check if the field is required and the value is empty or undefined
  if (item.isPrinciple === true && item.pct === 0) {
    return true;
  } else {
    type RoyaltiesInformationKey = keyof typeof item;
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const value = item[key as RoyaltiesInformationKey];

        // Check for empty string, 0, undefined, or null
        if (
          value === '' ||
          value === 0 ||
          value === undefined ||
          value === null
        ) {
          return true;
        }
      }
    }
  }

  return false;
}
