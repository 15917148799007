import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageData } from '../../i18n';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import {
  setUserFormData,
  setUserFormDataDetails,
} from '../../store/actions/actions';
import useGetCompaniesLogic from '../../utils/useGetCompaniesWithEstablishment';
import ItemList from '../List/ItemList';
import ItemsList from '../List/ItemsList';
import ButtonElement from '../button/button';
interface AccessFieldProps {
  showmodalAccess: () => void;
  rowId: string;
  type: 'add' | 'edit';
}
interface Company {
  id: number;
  nom: string;
  establishement?: any[];
}
const UserAccessModalContent: React.FC<AccessFieldProps> = ({
  showmodalAccess,
  rowId,
  type,
}) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [expandedCompanies, setExpandedCompanies] = React.useState<
    Record<number, boolean>
  >({});

  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormData,
  );
  const userFormDataDetails = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormDataDetails,
  );
  const initialUserFormDataDetails =
    React.useRef<serviceAppUserModels.AppUserPayload | null>(
      userFormDataDetails,
    );

  const appUser = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userDetails.roles,
  );
console.log("appUser",appUser)
  const toggleExpand = useCallback((companyId: number) => {
    setExpandedCompanies((prev) => {
      const newState = {
        ...prev,
        [companyId]: !prev[companyId],
      };
      return newState;
    });
  }, []);

  const initialUserFormData = React.useRef(userFormData);
  const updatedUserFormData =
    type === 'add' ? { ...userFormData } : { ...userFormDataDetails };

  const { companies } = useGetCompaniesLogic();
  const cancelChanges = () => {
    switch (type) {
      case 'add':
        initialUserFormData.current
          ? dispatch(setUserFormData(initialUserFormData.current))
          : null;
        break;
      case 'edit':
        initialUserFormDataDetails.current
          ? dispatch(setUserFormDataDetails(initialUserFormDataDetails.current))
          : null;
        break;
      default:
        break;
    }
    showmodalAccess();
  };
  const handleConfirm = () => {
    showmodalAccess();
  };

  const removeEmptyStringsFromArray = (array: string[]) => {
    return array.filter((item) => item.trim() !== '');
  };

  const handleCompanyChange = (selected: string) => {
    const permissionIndex = updatedUserFormData.permissions.findIndex(
      (p) => p.rowId === rowId,
    );

    if (permissionIndex === -1) {
      return;
    }

    const currentPermission = updatedUserFormData.permissions[permissionIndex];
    const existingSelected = currentPermission.chainesIds.includes(
      Number(selected),
    );

    const selectedCompany = companies.find(
      (item) => item.id.toString() === selected,
    );
    const isCurrentlyChecked = currentPermission.chainesIds.includes(
      Number(selected),
    );

    const associatedRestaurantIds =
      selectedCompany?.establishement?.map((res) => res.id) || [];

    const updatedPermission = {
      ...currentPermission,
      restaurantsIdsToDelete: !existingSelected ? [] : associatedRestaurantIds,
      rolesId: removeEmptyStringsFromArray(currentPermission.rolesId),
      chainesIds: getUpdatedChainesIds(
        currentPermission,
        Number(selected),
        existingSelected,
      ),
      restaurantsIds: getUpdatedRestaurantsIds(
        currentPermission,
        associatedRestaurantIds,
        existingSelected,
      ),
      chainesIdsToDelete: getUpdatedChainesIdsToDelete(
        currentPermission,
        Number(selected),
        existingSelected,
      ),
    };


    updateUserData(permissionIndex, updatedPermission);
  };

  const handleRestaurantChange = (selected: string, parentId: string) => {

    const permissionIndex = updatedUserFormData.permissions.findIndex(
      (p) => p.rowId === rowId,
    );

    if (permissionIndex === -1) {
      return;
    }

    const currentPermission = updatedUserFormData.permissions[permissionIndex];

    const existingSelected = currentPermission.restaurantsIds.includes(
      Number(selected),
    );

    const existingParent = currentPermission.chainesIds.includes(
      Number(parentId),
    );

    let updatedRestaurantsIds = existingSelected
      ? currentPermission.restaurantsIds.filter((id) => id !== Number(selected))
      : [...currentPermission.restaurantsIds, Number(selected)];

    // Add the selected restaurant ID to the delete list if it's being removed
    const updatedRestaurantsIdsToDelete = existingSelected
      ? [...(currentPermission.restaurantsIdsToDelete || []), Number(selected)]
      : currentPermission.restaurantsIdsToDelete || [];

    const associatedRestaurantIds =
      companies
        .find((item) => item.id.toString() === parentId)
        ?.establishement?.map((res) => res.id) || [];

    const allRestaurantsSelected = associatedRestaurantIds.every((id) =>
      updatedRestaurantsIds.includes(id),
    );

    let updatedChainesIds = currentPermission.chainesIds;

    if (
      allRestaurantsSelected &&
      !updatedChainesIds.includes(Number(parentId))
    ) {
      updatedChainesIds = [...updatedChainesIds, Number(parentId)];
    } else if (
      !allRestaurantsSelected &&
      updatedChainesIds.includes(Number(parentId))
    ) {
      updatedChainesIds = updatedChainesIds.filter(
        (id) => id !== Number(parentId),
      );
    }

    const updatedPermission = {
      ...currentPermission,
      restaurantsIds: updatedRestaurantsIds,
      restaurantsIdsToDelete: updatedRestaurantsIdsToDelete,
      chainesIds: updatedChainesIds,
      chainesIdsToDelete: getUpdatedChainesIdsToDelete(
        currentPermission,
        Number(parentId),
        existingParent,
      ),
    };

    updateUserData(permissionIndex, updatedPermission);
  };

  const getUpdatedPermissionForRemoval = (
    currentPermission: any,
    selected: string,
    parentId: string,
  ) => {
    const updatedRestaurantsIds = getUpdatedRestaurantsIds(
      currentPermission,
      [Number(selected)],
      true,
    );
    const updatedChainesIds = getUpdatedChainesIds(
      currentPermission,
      Number(parentId),
      true,
    );

    return {
      ...currentPermission,
      rolesId: removeEmptyStringsFromArray(currentPermission.rolesId),
      restaurantsIds: updatedRestaurantsIds,
      chainesIds: updatedChainesIds,
      restaurantsIdsToDelete: [
        ...(currentPermission.restaurantsIdsToDelete || []),
        Number(selected),
      ],
      chainesIdsToDelete:
        updatedRestaurantsIds.length === 0
          ? [...(currentPermission.chainesIdsToDelete || []), Number(parentId)]
          : currentPermission.chainesIdsToDelete || [],
    };
  };

  const getUpdatedPermissionForAddition = (
    currentPermission: any,
    selected: string,
    parentId: string,
    existingParent: boolean,
  ) => {
    return {
      ...currentPermission,
      rolesId: removeEmptyStringsFromArray(currentPermission.rolesId),
      restaurantsIds: [...currentPermission.restaurantsIds, Number(selected)],
      chainesIds: existingParent
        ? currentPermission.chainesIds
        : [...currentPermission.chainesIds, Number(parentId)],
      restaurantsIdsToDelete: currentPermission.restaurantsIdsToDelete || [],
      chainesIdsToDelete: currentPermission.chainesIdsToDelete || [],
    };
  };

  const updateUserData = (permissionIndex: number, updatedPermission: any) => {
    const updatedData = {
      ...updatedUserFormData,
      permissions: [
        ...updatedUserFormData.permissions.slice(0, permissionIndex),
        updatedPermission,
        ...updatedUserFormData.permissions.slice(permissionIndex + 1),
      ],
    };

    type === 'add'
      ? dispatch(setUserFormData(updatedData))
      : dispatch(setUserFormDataDetails(updatedData));
  };

  const getUpdatedChainesIds = (
    currentPermission: any,
    selectedId: number,
    existingSelected: boolean,
  ) => {
    return existingSelected
      ? currentPermission.chainesIds.filter((id: any) => id !== selectedId)
      : [...currentPermission.chainesIds, selectedId];
  };

  const getUpdatedRestaurantsIds = (
    currentPermission: any,
    associatedRestaurantIds: number[],
    existingSelected: boolean,
  ) => {
    return existingSelected
      ? currentPermission.restaurantsIds.filter(
          (id: any) => !associatedRestaurantIds.includes(id),
        )
      : [...currentPermission.restaurantsIds, ...associatedRestaurantIds];
  };

  const getUpdatedChainesIdsToDelete = (
    currentPermission: any,
    selectedId: number,
    existingSelected: boolean,
  ) => {
    return existingSelected
      ? [...(currentPermission.chainesIdsToDelete || []), selectedId]
      : currentPermission.chainesIdsToDelete || [];
  };

  const renderCheckAccess = (id: number): boolean => {
  
    const permission = updatedUserFormData?.permissions?.find(
      (p) => p.rowId == rowId,
    );
    return permission?.chainesIds?.includes(id) ?? false;
  };

  const renderCheckAccessEstablishement = (id: number) => {
    const permission = updatedUserFormData.permissions.find(
      (p) => p.rowId === rowId,
    );

    return permission?.restaurantsIds.includes(id);
  };
  const renderSelectedESNumber = (id: number) => {
    const permission = updatedUserFormData.permissions.find(
      (p) => p.rowId === rowId,
    );
    if (!permission) {
      return 0;
    }

    const restos = permission.restaurantsIds;

    const company = companies.find((company) => company.id === id);
    if (!company || !company.establishement) {
      return 0;
    }

    const existingRestosCount = company.establishement.filter(
      (establishmentId) => restos.includes(establishmentId.id),
    ).length;

    return existingRestosCount;
  };

  return (
    <div className="UserSettingsModalContent">
      <div className="modal-wrapper fl_col gp40">
        <div className="show02 h-full fl_col jc_s fl_col gp20">
          <div className="modal-content fl_col gp10">
            <div className="Search-filter">
              <input
                autoComplete="off"
                type="text"
                placeholder="Recherche..."
              />
            </div>
            <div className="Result">
              <div className="result-wrapper">
                {companies &&
                  companies.map((company: Company) => (
                    <div key={company.id}>
                      <ItemsList
                      
                       
                        label={company.nom}
                        slot={company.establishement?.length}
                        selectItemsSlot={renderSelectedESNumber(company.id)}
                        onChange={() => {
                          handleCompanyChange(company.id.toString());
                        }}
                        extend={!!expandedCompanies[company.id]}
                        isChecked={renderCheckAccess(company.id)}
                        onClick={(e: React.MouseEvent) => {
                          toggleExpand(company.id);
                        }}
                      >
                        {expandedCompanies[company.id] && (
                          <div style={{ marginLeft: 20 }}>
                            {company.establishement?.map((restaurant: any) => (
                              <div key={restaurant.id}>
                                <ItemList
                                  value={restaurant.commercial_Name}
                                
                                  isChannelChecked={renderCheckAccess(
                                    company.id,
                                  )}
                                  isChecked={renderCheckAccessEstablishement(
                                    restaurant.id,
                                  )}
                                  onChange={() =>
                                    handleRestaurantChange(
                                      restaurant.id.toString(),
                                      company.id.toString(),
                                    )
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </ItemsList>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="footer-wrapper fl_row jc_fe gp20">
              <ButtonElement
                type={'TextButton'}
                text={languageData.global.close}
                buttonSize={'medium'}
                buttonStyle="btn-primary-light"
                onClick={cancelChanges}
              />
              <ButtonElement
                type={'TextButton'}
                text={languageData.global.Valider}
                buttonSize={'medium'}
                buttonStyle="btn-primary"
                onClick={handleConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccessModalContent;
