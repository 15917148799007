import React from 'react';
import CheckBox from '../../../components/Catalogue/Checkbox';
import BinIconRedCircle from '../../../assets/icons/BinIconRedCircle.svg';

/**
 * EtatDeStockCard component description.
 *
 * @EtatDeStockCard
 *
 */

interface EtatDeStockCardProps {
  name: string;
  currentStatus?: 'exhausted' | 'sold-out-today' | 'in-stock';
  oldStatus?: 'exhausted' | 'sold-out-today' | 'in-stock';
  onClickDelete?: () => void;
  categorie?: boolean;
  oldVisible?: boolean;
  currentVisible?: boolean;
}

const EtatDeStockCard: React.FC<EtatDeStockCardProps> = ({
  name,
  currentStatus,
  onClickDelete,
  oldStatus,
  categorie,
  oldVisible,
  currentVisible,
}) => {
  const getStatusAlias = (
    status: 'exhausted' | 'sold-out-today' | 'in-stock' | undefined,
  ) => {
    if (status) {
      return (
        <span className={`p12 w-500 ${status}`}>
          {status === 'exhausted' ? (
            <span className="status-card exhausted">Epuisé</span>
          ) : status === 'in-stock' ? (
            <span className="status-card in-stock">En stock</span>
          ) : status === 'sold-out-today' ? (
            <span className="status-card sold-out-today">
              Epuisé Aujourd’hui
            </span>
          ) : (
            ''
          )}
        </span>
      );
    }
    return null;
  };
  const getStatusAliasCategorie = (status: boolean | undefined) => {
    if (status != undefined) {
      return (
        <span className={`p12 w-500 ${status ? 'in-stock' : 'exhausted'}`}>
          {status ? (
            <span className="in-stock">Visible</span>
          ) : (
            <span className="exhausted">Invisible</span>
          )}
        </span>
      );
    }
    return null;
  };
  return (
    <div className="EtatDeStockCard rd8">
      <div className="item-wrapper fl_row ai_c h-full gp4 pd4-r-l">
        <img
          className="c-ph"
          src={BinIconRedCircle}
          alt="delete icon"
          onClick={onClickDelete}
        />
        <div className="fl_row jc_s ai_c gp4">
          <div className="fl_col">
            <p className="p17 w-500 Independence-txt mx1">{name}</p>
            <del className="oldstatus">
              {categorie
                ? getStatusAliasCategorie(oldVisible)
                : getStatusAlias(oldStatus)}
            </del>
          </div>
          {categorie
            ? getStatusAliasCategorie(currentVisible)
            : getStatusAlias(currentStatus)}
        </div>
      </div>
    </div>
  );
};

export default EtatDeStockCard;
