import { serviceTaxesModels } from '../../models/services/module.taxesInfos';
import axiosInstance, { BaseUrl } from './apiGate';

const taxesInfosApi = {
  verify: async (body: serviceTaxesModels.TaxesInfos) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + '/v1/verifFiscalInfosCompany',
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCodesNAF: async () => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + '/v1/getCodesNAF?pageSize=700&page=1',
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  verifyEtablissement: async (body: serviceTaxesModels.TaxesInfos) => {
    try {
      const response = await axiosInstance.post(
        BaseUrl + '/v1/verifFiscalInfosEstablishement',
        body,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default taxesInfosApi;
