import React, { Dispatch, SetStateAction } from 'react';

/**
 * CounterInput component with increment and decrement functionality.
 *
 * @CounterInput
 *
 */

interface CounterInputProps {
  counter: number;
  prefix?: string;
  setCounter: Dispatch<SetStateAction<number>>;
  id?: string;
  onChangeCounter?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CounterInput: React.FC<CounterInputProps> = ({
  id,
  counter,
  setCounter,
  prefix,
  onChangeCounter,
}) => {
  const onChangePlus = () => {
    if (counter < 100) {
      setCounter((prevState: number) => prevState + 1);
      onChangeCounter &&
        onChangeCounter({
          target: { id: id, value: (counter + 1).toString() },
        } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  const onChangeMinus = () => {
    if (counter > 0) {
      setCounter((prevState: number) => prevState - 1);
      onChangeCounter &&
        onChangeCounter({
          target: { id: id, value: (counter - 1).toString() },
        } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  return (
    <div className="CounterInput w-full">
      <div className="counter-wrapper fl_row ai_c jc_s pd14-r-l rd10 gp20">
        <div className="fl_row ai_c value-field">
          <input
            autoComplete="off"
            id={id}
            className=" p12 w-300 AuroMetalSaurus-txt"
            type="number"
            value={counter}
            onChange={onChangeCounter}
          />
          {prefix && (
            <span className="prefix p12 w-300 AuroMetalSaurus-txt">
              {prefix}
            </span>
          )}
        </div>
        <div className="fl_row gp10 remote-counter">
          <button
            className="p20 pd5-r-l w-900 OxfordBlue02-txt"
            onClick={onChangeMinus}
          >
            -
          </button>
          <button
            className="p20 pd5-r-l w-900 OxfordBlue02-txt"
            onClick={onChangePlus}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default CounterInput;
