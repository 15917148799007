import React, { ReactNode } from 'react';
import LabelInput from './LabelInput';
import ChevronUpIcon from '../../assets/icons/ChevronUpIcon.svg';
/**
 * RadioBoxInput component description.
 *
 * @RadioBoxInput
 *
 */

interface RadioBoxInputProps {
  label: string;
  children: ReactNode;
  id: string | number;
  className?: string;
  accordion?: boolean;
  open?: boolean;
  addScroll?: boolean;
  value?: string;
}

const RadioBoxInput: React.FC<RadioBoxInputProps> = ({
  label,
  children,
  id,
  className,
  accordion,
  open,
  addScroll,
  value
}) => {
  return (
    <div className={`RadioBoxInput ${addScroll ? "" : "scroll"}`}>
      {accordion ? (
        <div className={`RadioAccordion w-full ${open ? 'open-element' : ''}`}>
          <div
            className={`label-inp fl_row jc_s  ${className ? className : ''}`}
          >
            <LabelInput label={label} value={value}/>
            <img src={ChevronUpIcon} alt="" />
          </div>
          <div className="radio-options fl_col gp20">{children}</div>
        </div>
      ) : (
        <div className="radio-wrapper fl_col gp12">
          <div className={`label-inp ${className ? className : ''}`}>
            <LabelInput label={label} value={value}/>
          </div>
          <div className="radio-options fl_col gp20">{children}</div>
        </div>
      )}
    </div>
  );
};

export default RadioBoxInput;
