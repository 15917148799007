import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  getBadgesFail,
  getBadgesSuccess,
  postBadgESuccess,
} from '../../actions/catalogues/actions';
import { badgeAction } from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
export function* startGetBadges() {
  try {
    const response: AxiosResponse = yield catalogueApi.getBadges();
    if (response.data) {
      yield put(getBadgesSuccess(response.data?.data?.result??[]));
    }
  } catch (error: any) {
    yield put(getBadgesFail(error));
  }
}
export function* startPostBadges(action: badgeAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postBadge(action.badge);
    if (response.data) {
      yield put(postBadgESuccess());
      action._ ? action._() : null;
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
