import React from 'react';

interface Props {
  strokeColor?: string;
}

const EcranIcon: React.FC<Props> = ({ strokeColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33301 9.33333L14.6663 9.33333"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path d="M5.33301 14H10.6663" stroke={strokeColor} strokeWidth="1.3" />
      <path
        d="M4.53301 12H11.4663C12.5864 12 13.1465 12 13.5743 11.782C13.9506 11.5903 14.2566 11.2843 14.4484 10.908C14.6663 10.4802 14.6663 9.92011 14.6663 8.8V5.2C14.6663 4.0799 14.6663 3.51984 14.4484 3.09202C14.2566 2.71569 13.9506 2.40973 13.5743 2.21799C13.1465 2 12.5864 2 11.4663 2H4.53301C3.4129 2 2.85285 2 2.42503 2.21799C2.0487 2.40973 1.74274 2.71569 1.55099 3.09202C1.33301 3.51984 1.33301 4.07989 1.33301 5.2V8.8C1.33301 9.92011 1.33301 10.4802 1.55099 10.908C1.74274 11.2843 2.0487 11.5903 2.42503 11.782C2.85285 12 3.4129 12 4.53301 12Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EcranIcon;
