import React from 'react';
import ModalElementName from '../ModalElementName';
import ModalElementColorTag from '../ModalElementColorTag';
import { ModalProps } from '../../../models/services/module.modals';
import BinIconRed from '../../../assets/icons/BinIconRed.svg';
import ColorPicker from '../../Catalogue/ColorPicker';
export default function TagModalController(props: ModalProps) {
  let modalContentBody;
  let modalContentFooter;
  interface CustomEvent {
    target: {
      value: any;
    };
  }
  switch (props.type) {
    case 'edit':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full">
          <ModalElementName
            hideDefault
            placeholder={props.placeholder}
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
          />
          {/* <ModalElementColorTag
            onChange={props.onChangeSecondInput}
            itemTagLabel={props.itemTagLabel}
            itemTag={props.initColor}
          /> */}
          <ColorPicker
            onChange={(e) => {
              props.onChangeSecondInput?.({
                target: { value: e },
              } as any);
            }}
            defaultValue={props.itemTag}
            // label={languageData.fr.addProduct.bck}
            type="text"
            // onChange={(e) =>
            //   onChangeField('generalBckColor', e.target.value)
            // }
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_s">
          {props.deleteitem && (
            <button
              className="fl_row gp8 btn_cat btn-delete"
              onClick={props.handeldelete}
            >
              <img src={BinIconRed} alt="" />
              {props.deleteitemTxt}
            </button>
          )}
          <div className="fl_row gp12">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={!props.itemName || props.itemName?.trim()?.length < 3}
                onClick={props.handleSave}
                className={`btn_cat  ${!props.itemName ? 'btn-disabledGrey' : props.itemName && props.itemName?.trim()?.length < 3 ? 'btn-lightGrey' : 'btn-primary'}`}
              >
                {props.saveTxt}
              </button>
            )}
          </div>
        </div>
      );
      break;

    case 'create':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full">
          <ModalElementName
            placeholder={props.placeholder}
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
            hideDefault
          />
          {/* <ModalElementColorTag
          <ModalElementColorTag
            onChange={props.onChangeSecondInput}
            itemTagLabel={props.itemTagLabel}
            itemTag={props.itemTag}
          /> */}
          <ColorPicker
            onChange={(e) => {
              props.onChangeSecondInput?.({
                target: { value: e },
              } as any);
            }}
            defaultValue={props.itemTag}
            // label={languageData.fr.addProduct.bck}
            type="text"
            // onChange={(e) =>
            //   onChangeField('generalBckColor', e.target.value)
            // }
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_fe">
          <div className="fl_row gp12">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={!props.itemName || props.itemName?.trim()?.length < 3}
                onClick={props.handleSave}
                className={`btn_cat ${!props.itemName ? 'btn-disabledGrey' : props.itemName && props.itemName?.trim()?.length < 3 ? 'btn-lightGrey' : 'btn-primary'}`}
              >
                {props.saveTxt}
              </button>
            )}
          </div>
        </div>
      );
      break;

    default:
      modalContentBody = null;
      modalContentFooter = null;
  }
  return { modalContentBody, modalContentFooter };
}
