import React, { ReactNode } from 'react';

/**
 * TableContent component description.
 *
 * @TableContent
 *
 */

interface TableContentProps {
  children: ReactNode;
  className?: string;
}

const TableContent: React.FC<TableContentProps> = ({ children, className }) => {
  return (
    <div className={`TableContent ${className ? className : ''}`}>
      {children}
    </div>
  );
};

export default TableContent;
