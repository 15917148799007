import React from 'react';
import MobileIconBlack from '../../assets/icons/MobileIconBlack.svg';
import PhoneIconBlack from '../../assets/icons/PhoneIconBlack.svg';
import SirenBarCodeIconBlack from '../../assets/icons/SirenBarCodeIconBlack.svg';
import AdresseIconBlack from '../../assets/icons/AdresseIconBlack.svg';
import PlaceHolderCompany from '../../assets/images/PlaceHolderCompany.png';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';
import { useSelector } from 'react-redux';
/**
 * HeaderProfileInfo component description.
 *
 * @HeaderProfileInfo
 *
 */

const HeaderProfileInfo: React.FC = (props) => {
  const companyInfos = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.companyInfos,
  );
  const completeAddress =
    companyInfos?.address +
    ', ' +
    companyInfos?.city +
    ', ' +
    companyInfos?.postal_code;
  return (
    <div className="HeaderProfileInfo HeaderCompanyProfileInfo">
      <div className="header-wrapper fl_row ai_c gp17">
        <div className="avatar">
          <img src={companyInfos?.logo || PlaceHolderCompany} alt="" />
        </div>
        <div className="info fl_col gp10">
          <div className="info-general fl_col gp10">
            <p className="p18 w-700 name">{companyInfos?.commercial_Name}</p>
            <div className="info-wrapper fl_col gp10">
              {companyInfos?.sireN_code && (
                <div className="fl_row gp10">
                  <img src={SirenBarCodeIconBlack} alt="" />
                  <p className="siren">{companyInfos?.sireN_code}</p>
                </div>
              )}

              <div className="contact-info fl_row gp10 fl_wrap fl_wrap">
                {companyInfos?.fixed_line_telephone_number && (
                  <div className="fixe fl_row ai_c gp10">
                    <img src={PhoneIconBlack} alt="" />
                    <p className="p16">
                      {companyInfos?.fixed_line_telephone_number}
                    </p>
                  </div>
                )}
                {companyInfos?.mobile_telephone_number && (
                  <div className="mobile fl_row ai_c gp10">
                    <img src={MobileIconBlack} alt="" />
                    <p className="p16">{companyInfos?.mobile_telephone_number}</p>
                  </div>
                )}
              </div>

              {completeAddress && (
                <div className="adresse fl_row ai_c gp10">
                  <img src={AdresseIconBlack} alt="" />
                  <p className="p14">{completeAddress}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderProfileInfo;
