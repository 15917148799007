import { useEffect, MutableRefObject } from 'react';
import { addSubmitSucceeded } from '../../../utils/AddSubmitSucceded';
import { ToastProps } from '../../../components/Toast/Toast';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { useDispatch, useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import { serviceTaxesModels } from '../../../models/services/module.taxesInfos';
import { languageData } from '../../../i18n';
interface ToastRef {
  openToast: (type: ToastProps['type'], title: string) => void;
  closeToast: () => void;
}
const useToast = (toastRef: MutableRefObject<ToastRef | null>) => {
  const dispatch = useDispatch();
  const addSuccess = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.addSuccess,
  );
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const unsavedChangesData = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.unsavedChanges,
  );
  const verifResponse = useSelector(
    (state: { taxesInfos: serviceTaxesModels.TaxesInfosState }) =>
      state.taxesInfos.taxesInfosResponse,
  );
  const validationRequestedFiscal = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.validationRequestedFiscal,
  );
  useEffect(() => {
    toastRef.current?.closeToast();
    if (addSuccess === true) {
      addSubmitSucceeded({
        toastRef,
        tabName: 'Ajouter une entreprise',
        open_tabs,
        dispatch,
        unsavedChangesData,
      });
    } else if (addSuccess === false) {
      toastRef.current?.openToast('error', 'error');
    }
  }, [addSuccess, toastRef]);
  useEffect(() => {
    toastRef.current?.closeToast();
    if (verifResponse.sirenValid === false && validationRequestedFiscal) {
      toastRef.current?.openToast(
        'error',
        languageData.global.Form.errorMsg.sirenNotValid,
      );
    }
  }, [verifResponse, toastRef]);
};

export default useToast;
