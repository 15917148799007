import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { postScreenFail } from '../../actions/catalogues/actions';
import { screenAction } from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
export function* startGetScreens(action: screenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getScreens(action.exptInvisible);
    if (response.data) {
      action?._ ? action._(response.data?.data?.result??[]) : null;
    }
  } catch (error) {
    yield put(postScreenFail());
  }
}
export function* startPostScreen(action: screenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postScreen(
      action.screen,
      action.deletedDivisions && action.deletedDivisions.length > 0
        ? action.deletedDivisions?.map((_) => _.id as string)
        : [],
    );
    if (response.data) {
      action?._ ? action._() : null;
    }
  } catch (error) {
    NotificationErrorProvider(error);
    yield put(postScreenFail());
  }
}
export function* startGetPurshaseMethod(action: screenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getPurshasesModes();
    if (response.data) {
      action?._ ? action._(response.data?.data?.result??[]) : null;
    }
  } catch (error) {
    NotificationErrorProvider(error);
    yield put(postScreenFail());
  }
}
export function* startGetScreenById(action: screenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getScreenById(
      action.id as number,
    );
    if (response.data) {
      action?._ ? action._(response.data?.data?.result[0]) : null;
    }
  } catch (error) {
    NotificationErrorProvider(error);
    yield put(postScreenFail());
  }
}
export function* startDeleteScreen(action: screenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.DeleteScreen(
      ('' + action.id) as string,
    );
    if (response.data) {
      action?._ ? action._() : null;
    }
  } catch (error) {
    NotificationErrorProvider(error);
  }
}
