import React from 'react';
import { useState } from 'react';
import RoleslistContainer from './RoleslistContainer';
import RolePermission from './RolePermissions';
import { useSelector } from 'react-redux';

export default function HandleRoleContainer() {
  return (
    <div className='h-full'>
      {' '}
      <RoleslistContainer />
    </div>
  );
}
