import React from 'react';

interface EntrepriseIconProps {
  strokeColor?: string;
}
const EntrepriseIcon: React.FC<EntrepriseIconProps> = ({ strokeColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C4 1.44772 4.44772 1 5 1H19C19.5523 1 20 1.44772 20 2V22C20 22.5523 19.5523 23 19 23H5C4.44772 23 4 22.5523 4 22V2ZM6 3V21H18V3H6Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14C8 13.4477 8.44772 13 9 13H15C15.5523 13 16 13.4477 16 14V22C16 22.5523 15.5523 23 15 23H9C8.44772 23 8 22.5523 8 22V14ZM10 15V21H14V15H10Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 12H7.5V10H10.5V12Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 9H7.5V7H10.5V9Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6H7.5V4H10.5V6Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 12H13.5V10H16.5V12Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 9H13.5V7H16.5V9Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 6H13.5V4H16.5V6Z"
        fill={strokeColor || '#344054'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 21H22V23H2V21Z"
        fill={strokeColor || '#344054'}
      />
    </svg>
  );
};

export default EntrepriseIcon;
