import React from 'react';
import { ModalProps } from '../../../models/services/module.modals';
import ModalElementImage from '../ModalElementImage';
import ModalElementName from '../ModalElementName';
import BinIconRed from '../../../assets/icons/BinIconRed.svg';
export default function AnyModalController({ ...props }: ModalProps) {
  let modalContentBody;
  let modalContentFooter;
  switch (props.type) {
    case 'edit':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full">
          <ModalElementImage
            onClickRemove={props.onRemoveImage}
            editItem={props.editItem}
            itemImg={props.itemImg}
          />
          <ModalElementName
          hideDefault
            tooltip={props.tooltip}
            placeholder={props.placeholder}
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_s">
          {props.deleteitem && (
            <button
              className="fl_row gp8 btn_cat btn-delete"
              onClick={props.handeldelete}
            >
              <img src={BinIconRed} alt="" />
              {props.deleteitemTxt}
            </button>
          )}
          <div className="fl_row gp12">
            {props.cancel && (
              <button
                className="btn_cat btn-lightGrey"
                onClick={props.closeModal}
              >
                {props.cancelTxt}
              </button>
            )}
            {props.reject && (
              <button onClick={props.closeModal}>{props.rejectTxt}</button>
            )}
            {props.save && (
              <button
                id="save"
                disabled={!props.itemName || props.itemName?.trim()?.length < 3}
                onClick={props.handleSave}
                className={`btn_cat ${!props.itemName ? '' : props.itemName && props.itemName?.trim()?.length < 3 ? '' : 'btn-primary'}`}
              >
                {props.saveTxt}
              </button>
            )}
            {props.confirm && <button>{props.confirmTxt}</button>}
          </div>
        </div>
      );
      break;
    case 'create':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full">
          <ModalElementImage
            onClickRemove={props.onRemoveImage}
            itemImg={props.itemImg}
            editItem={props.editItem}
          />
          <ModalElementName
          hideDefault
           tooltip={props.tooltip}
           placeholder={props.placeholder}
            onChange={props.onChange}
            itemNameLabel={props.itemNameLabel}
            itemName={props.itemName}
          />
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full ">
          <div className="fl_row jc_fe">
            <div className="fl_row gp12">
              {props.cancel && (
                <button
                  className="btn_cat btn-lightGrey"
                  onClick={props.closeModal}
                >
                  {props.cancelTxt}
                </button>
              )}
              {props.save && (
                <button
                  id="save"
                  disabled={!props.itemName || props.itemName?.trim()?.length < 3}
                  onClick={props.handleSave}
                  className={`btn_cat ${!props.itemName ? '' : props.itemName && props.itemName?.trim()?.length < 3 ? '' : 'btn-primary'}`}
                  >
                  {props.saveTxt}
                </button>
              )}
            </div>
          </div>
        </div>
      );
      break;
    case 'delete':
      modalContentBody = (
        <div className="body-wrapper fl_row gp20 w-full">
          {props.description &&
            props.description.map((desc, index) => <p key={index}>{desc}</p>)}
        </div>
      );
      modalContentFooter = (
        <div className="wrapperFooter w-full jc_s">
          {props.cancel && (
            <button
              className="btn_cat btn-lightGrey"
              onClick={props.closeModal}
            >
              {props.cancelTxt}
            </button>
          )}
          {props.deleteitem && (
            <button
              className="fl_row gp8 btn_cat btn-delete-full fl-1"
              onClick={props.handeldelete}
            >
              {props.deleteitemTxt}
            </button>
          )}
        </div>
      );
      break;
    default:
      modalContentBody = null;
      modalContentFooter = null;
  }
  return { modalContentBody, modalContentFooter };
}
