import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  getBadgesFail,
  getBadgesSuccess,
  getTvaFail,
  getTvaSuccess,
  postBadgESuccess,
  postTvaSuccess,
} from '../../actions/catalogues/actions';
import {
  badgeAction,
  tvaAction,
} from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
export function* startGetTva(action: tvaAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getTva();
    if (response.data) {
      yield put(getTvaSuccess(response.data?.data?.result??[]));
      action._ ? action._(response.data?.data?.result??[]) : null;
    }
  } catch (error: any) {
    yield put(getTvaFail(error));
  }
}

export function* startPostTva(action: tvaAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postTva(action.tva);
    if (response.data) {
      yield put(postTvaSuccess());
      action._ ? action._() : null;
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startDeleteTva(action: tvaAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteTva(action.tva.id);
    if (response.data) {
      yield put(postTvaSuccess());
      action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
