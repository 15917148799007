import React from 'react';
import { ModalProps } from '../../models/services/module.modals';
import { languageData } from '../../i18n';
import ButtonElement from '../button/button';
export default function AlertModalController(props: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper fl_col gp5">
      {props.description &&
        props.description.map((desc, index) => <p key={index}>{desc}</p>)}
    </div>
  );
  const modalContentFooter = (
    <div className="wrapperFooter">
      <div className="fl_row gp12">
        {props.cancel && (
          <ButtonElement
            type={'TextButton'}
            text={languageData.global.Modal.cancel}
            buttonSize={'small'}
            buttonStyle={'btn-black-light'}
            onClick={props.onClickClose}
          />
        )}
        {props.reject && (
          <ButtonElement
            type={'TextButton'}
            text={languageData.global.Modal.reject}
            buttonSize={'small'}
            buttonStyle={'btn-black-light'}
            onClick={props.onClickClose}
          />
        )}
        {props.save && (
          <button className="btn btn-primary">{props.saveTxt}</button>
        )}
        {props.confirm && (
          <ButtonElement
            type={'TextButton'}
            text={languageData.global.Modal.confirm}
            buttonSize={'small'}
            buttonStyle="btn-primary-light"
            onClick={props.onClickConfirm}
          />
        )}
      </div>
    </div>
  );
  return {
    modalContentBody,
    modalContentFooter,
  };
}
