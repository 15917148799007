import React, { ChangeEvent, useEffect, useState } from 'react';
import ItemStock from '../../../../container/Catalogue/EtatDeStock/ItemStock';
import Modal from '../../../../components/Modal/Modal';
import EditStockModalContent from '../../../../container/Catalogue/EtatDeStock/EditStockModalContent';
import EtatDeStockCard from '../../../../container/Catalogue/EtatDeStock/EtatDeStockCard';
import {
  Menu,
  getArticleParams,
} from '../../../../models/services/module.catalogue';
import { useDispatch } from 'react-redux';
import {
  NotificationErrorProvider,
  NotificationSuccessProvider,
} from '../../../../utils/Notificationprovider';
import { startGetMenuEtatDeStock } from '../../../../store/actions/catalogues/actions';
import { catalogueApi } from '../../../../services/apiHandlers/catalogueApi';
import StockFilterToggle from './StockFilterToggle';
import { CategorySingle } from '../../../../models/services/module.category';
export default function ListingEtatDeStockCategories({
  idEstablishment,
}: {
  idEstablishment: string;
}) {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [currentActiveToggle, setcurrentActiveToggle] = useState(-1);
  const [searchValue, setsearchValue] = useState<string>();
  const [CategoriesList, setCategoriesList] = useState<CategorySingle[]>([]);
  const [loading, setloading] = useState(false);
  const [meta, setmeta] = useState<{
    visible: number;
    all: number;
    invisible: number;
  }>({
    all: 0,
    visible: 0,
    invisible: 0,
  });
  const [ActiveStatus, setActiveStatus] = useState<
    'exhausted' | 'sold-out-today' | 'in-stock'
  >('in-stock');
  const [paramsArticle, setparamsArticle] = useState<getArticleParams>({});
 
  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setsearchValue(e.target.value);
  };
  const onSelectArticle = (id: number, checked: boolean) =>
    setCategoriesList(
      CategoriesList?.map((article) => ({
        ...article,
        selected: article.id === id ? checked : article.selected,
      })),
    );
  const fetchCategories = async () => {
    setloading(true);
    const response = await catalogueApi?.getCategoriesEtatDeStock({
      IdEstablishment: Number(idEstablishment),
      EtatStock:
        currentActiveToggle == 0
          ? true
          : currentActiveToggle == 1
            ? false
            : undefined,
    });
    setloading(false);

    setCategoriesList(response?.data?.data?.result?.categories);
    let a = response?.data?.data?.result?.categories;
    if (currentActiveToggle == -1)
      setmeta({
        all: (a as any)?.length,
        visible: (a as any)?.filter((_: any) => _?.visible).length,
        invisible: (a as any)?.filter((_: any) => !_?.visible).length,
      });
  };
  useEffect(() => {
    if (currentActiveToggle != undefined) {
      fetchCategories();
    }
  }, [currentActiveToggle]);

  const selectedCount =
    (CategoriesList ?? [])?.filter((_) => _.selected)?.length ?? 0;
  const selectedArticles = (CategoriesList ?? [])?.filter((_) => _.selected);
  const updateEtatDeStock = async () => {
    try {
      const response = await catalogueApi?.updateCategoriesEtatDeStock({
        IdEstablishment: Number(idEstablishment),
        Visible: ActiveStatus == 'exhausted' ? false : true,
        categoryIds: selectedArticles?.map((_) => _.id),
      });
      NotificationSuccessProvider('edit', 'Categorie');
      setloading(true);

      fetchCategories();
      setShowModalEdit(false);
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const onChangeToggle = (n: number) => {
    setcurrentActiveToggle(n);
  };
  return (
    <div className="ListingEtatDeStockContainer">
      <Modal
        version="custom"
        content={
          <EditStockModalContent
            isCategorie
            activeStatus={ActiveStatus}
            setActiveStatus={(s) => setActiveStatus(s)}
            slot={selectedCount}
            onClickCancel={() => setShowModalEdit(false)}
            onClickEdit={updateEtatDeStock}
          >
            {selectedArticles?.map((_) => (
              <EtatDeStockCard
                categorie
                name={_.designation}
                oldVisible={_.visible}
                currentVisible={ActiveStatus == 'exhausted' ? false : true}
                onClickDelete={() => onSelectArticle(_.id, false)}
              />
            ))}
          </EditStockModalContent>
        }
        // title={""}
        visibility={showModalEdit}
        maxWidth={720}
        // height={480}
        close
      />

      <div className="content-container ">
        <div className="filter-section fl_row jc_s pd16-t-b pd24-r-l ai_e">
          <StockFilterToggle
            counts={meta}
            isForCategorie
            active={currentActiveToggle}
            onchange={onChangeToggle}
          ></StockFilterToggle>

          <button
            className={`edit-stock-btn ${selectedCount > 0 ? 'has-items' : ''}`}
            onClick={
              selectedCount > 0 ? () => setShowModalEdit(true) : undefined
            }
          >
            Modifier état de stock •{' '}
            <span className="w-600 pd10-l">{selectedCount}</span>
          </button>
        </div>
        {!loading ? (
          <div className="listing-items pd12-t-b pd16-r-l ">
            {CategoriesList?.length == 0 && (
              <span>Pas de categories pour le moment</span>
            )}
            {CategoriesList?.map((_) => (
              <ItemStock
                className={`${_.visible ? '' : 'invisible'}`}
                categorie
                visible={_.visible}
                onClickCheck={(e) => {
                  onSelectArticle(_.id, e.target.checked);
                }}
                selected={_.selected}
                name={_.designation}
              />
            ))}
          </div>
        ) : (
          <div className="fl_row w-full h-full jc_c">
            <span>Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
}
