import { useDispatch, useSelector } from 'react-redux';
import SelectInput2 from '../../components/Input/CountrySelect/SelectInput2';
import InputField from '../../components/Input/Input';
import { languageData } from '../../i18n';
import { setUserFormData } from '../../store/actions/actions';
import FormCard from '../Etablissement/FormWizzard/FormCard';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import flagsAndNamesSeparator from '../../utils/FlagsAndNamesSeparator';
import validateEmail from '../../utils/EmailValidation';
import { missingRequiredField } from './useUserFormValidation';

export default function AddUserProfileCard() {
  const dispatch = useDispatch();
  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormData,
  );
  const userFormValidationRequested = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormValidationRequested,
  );
  const { countriesCodes, countriePhoneCodes, flags } =
    flagsAndNamesSeparator();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const payload = {
      ...userFormData,
      user: {
        ...userFormData.user,
        [name]: value,
      },
    };
    dispatch(setUserFormData(payload));
  };
  const onSelect = (selectedValue: string) => {
    const payload = {
      ...userFormData,
      user: {
        ...userFormData.user,
        phoneNumber: selectedValue,
      },
    };
    dispatch(setUserFormData(payload));
  };
  return (
    <div className="profile-infos fl_col gp20">
      <div className="header-block">
        <p className="p16 w-600 Nevada-txt pd22-t-b">
          {languageData.global.user.profile}
        </p>
      </div>
      <FormCard grid={2} gap="gp20">
        <InputField
          name="lastName"
          isRequired={true}
          style="form"
          label={languageData.global.user.lastname}
          onChange={handleChange}
          haserror={
            userFormValidationRequested &&
            missingRequiredField(userFormData.user.lastName)
          }
          errorMsg={languageData.global.Form.errorMsg.requiredFieldMissing}
        />
        <InputField
          name="firstName"
          isRequired={true}
          style="form"
          label={languageData.global.user.firstname}
          onChange={handleChange}
          haserror={
            userFormValidationRequested &&
            missingRequiredField(userFormData.user.firstName)
          }
          errorMsg={languageData.global.Form.errorMsg.requiredFieldMissing}
        />
        <InputField
          name="email"
          isRequired={true}
          style="form"
          label={languageData.global.Form.email}
          onChange={handleChange}
          haserror={
            (userFormValidationRequested &&
              !validateEmail(userFormData.user.email)) ||
            (userFormValidationRequested &&
              missingRequiredField(userFormData.user.email))
          }
          errorMsg={
            userFormData.user.email.length !== 0
              ? languageData.global.Form.errorMsg.emailNotValid
              : languageData.global.Form.errorMsg.requiredFieldMissing
          }
        />
        <SelectInput2
          defaultValue={userFormData?.user.phoneNumber ?? ''}
          isRequired={false}
          label={languageData.global.Form.mobileLabel}
          name="phoneNumber"
          onSelect={(selectedValue: string) => onSelect(selectedValue)}
          target="reflags"
          icons={flags}
          reference={countriePhoneCodes}
          options={countriesCodes}
          onChange={handleChange}
          isDisabled={false}
          haserror={false}
        />
      </FormCard>
    </div>
  );
}
