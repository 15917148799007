import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TableHeader from '../../../components/Table/TableHeader';
import PermissionsConfigurationContainer from './PermissionsConfigurationContainer';
import RolesListContainer from './RolesListContainer';
import { languageData } from '../../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { RolesSectionList } from '../../../data/RolesSectionList';
import Modal from '../../../components/Modal/Modal';
import { serviceRolesModels } from '../../../models/services/module.role';
import {
  updateRoleRequest,
  deleteRole,
  getRolesListAction,
} from '../../../store/actions/actions';
import FieldsAccessRightsModalContent from '../../../components/Modal/FieldsAccessRightsModalContent';
import { addRole } from '../../../store/actions/actions';
import tabs_list from '../../../data/tabsList';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import { CloseTab } from '../../../services/tabs/CloseTab';
import ToastHandler from '../Toast/ToastHandler';
import Toast, { ToastProps } from '../../../components/Toast/Toast';
import { usePagination } from '../../../pages/etablissement/pages/usePagination';

interface Role {
  id: string;
  name: string;
  active: boolean;
}

interface PermissionValue {
  value: string;
  isChecked: boolean;
}

interface SubSection {
  title: string;
  values: PermissionValue[];
}

interface RolePermission {
  section: string;
  subSection: SubSection[];
}

interface RolePermissionsData {
  role: Role;
  rolePermissions: RolePermission[];
}

/**
 *
 *
 *
 */

interface sectionList {
  id: string;
  name: string;
  isActive: boolean;
}
[];

export default function RolePermissionsContainer() {
  const [sectionList, setSectionList] = useState(RolesSectionList);

  const [modalFiche, setmodalFiche] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentSection, setCurrentSection] = useState('Entreprise');

  const [modalVisible, setModalVisible] = useState(false);
  const { roles, roleById, deleteResponse } = useSelector((state: any) => ({
    roles: state.role.roles,
    deleteResponse: state.role.deleteResponse,
    roleById: state.role.roleById,
  }));

  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);

  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );


  const initialRolePermissions = {
    role: {
      id: '',
      name: '',
      active: true,
    },
    rolePermissions: [
      {
        section: 'Entreprise',
        subSection: [],
      },
      {
        section: 'Etablissement',
        subSection: [],
      },
      {
        section: 'Roles et utilisateurs',
        subSection: [],
      },
    ],
  };

  const [rolePermissions, setRolePermissions] = useState<RolePermissionsData>(
    initialRolePermissions,
  );

  const dispatch = useDispatch();
  const activeTabContent = useSelector(
    (state: any) => state.tabs.activeTabContent,
  );

  const paginationConfig = useMemo(
    () => ({
      currentPage: 1,
      itemsPerPage: 10,
    }),
    [],
  );

  const { currentPage, itemsPerPage } = usePagination(paginationConfig);

  const pagination = useMemo(
    () => ({
      paginationModel: {
        page: currentPage,
        pageSize: itemsPerPage,
      },
    }),
    [currentPage, itemsPerPage],
  );

  const fetchRoles = useCallback(() => {
    //to get
    dispatch(getRolesListAction(pagination));
  }, [dispatch, pagination]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);
  const selectedRole = roles.find(
    (role: any) => role.id === activeTabContent.id,
  );

  useEffect(() => {
    if (selectedRole) {
      setRolePermissions((prevPermissions) => ({
        ...prevPermissions,
        role: {
          ...prevPermissions.role,
          id: selectedRole.id,
          name: selectedRole.name,
          active: selectedRole.active,
        },
      }));
    }
  }, [activeTabContent.id]);

  // React.useEffect(() => {
  //   switch (deleteResponse) {
  //     case true:
  //       showToast('success', languageData.global.Form.successMsg.editUser);

  //       break;
  //     case false:
  //       showToast('error', languageData.global.Form.errorMsg.addError);
  //       break;
  //     default:
  //       break;
  //   }
  // }, [deleteResponse, languageData]);

  const showToast = (type: ToastProps['type'], message: string) => {
    toastRef.current?.openToast(type, message);
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;
    if (error && name === 'name' && value.trim()) {
      setError(false);
      setErrorMessage('');
    }
    setRolePermissions((prevPermissions) => {
      return {
        ...prevPermissions,
        role: {
          ...prevPermissions.role,
          [name]: updatedValue,
        },
      };
    });
  };

  const handleModalFiche = () => {
    setmodalFiche((prev) => !prev);
  };

  const onChangePermissionsSection = (id: string) => {
    const tabChanged = sectionList?.map((item: sectionList) => ({
      ...item,
      isActive: item.id === id,
    }));
    setSectionList(tabChanged);

    const selectedSection = sectionList.find((item) => item.id === id);

    if (selectedSection) {
      setCurrentSection(selectedSection.name);
    }
  };
  const handleAddRole = () => {
    dispatch(
      addRole(rolePermissions, () => {
        fetchRoles();
      }),
    );
    closeTab(true);
  };

  const handleUpdateRole = () => {
    dispatch(
      updateRoleRequest(
        rolePermissions as serviceRolesModels.UpdateRolePayload,
      ),
    );
    fetchRoles();
    closeTab();
  };

  const onClickSave = () => {
    if (!rolePermissions.role.name.trim()) {
      setError(true);
      setErrorMessage(languageData.global.Form.errorMsg.requiredFieldMissing);
      return;
    }
    setError(false);
    setErrorMessage('');
    if (activeTabContent?.id) {
      handleUpdateRole();
    } else {
      handleAddRole();
    }
  };
  const handleDeleteClick = (roleId: string) => {
    if (roleId) {
      setModalVisible(true); // Show the modal
    }
  };

  const handleConfirmDelete = (roleId: string) => {
    dispatch(deleteRole(roleId));
    fetchRoles();
    closeTab();
    setModalVisible(false); // Hide the modal
  };

  const closeTab = (withKey?: boolean) => {
    CloseTab({
      open_tabs,
      name: !withKey ? roleById.name : activeTabContent.key,
      id: activeTabContent.id,
      key: tabs_list[7].tabContent.key,
      dispatch,
    });
  };

  return (
    <div className="RolePermissionsContainer">
      <Modal
        version={'custom'}
        content={<FieldsAccessRightsModalContent />}
        title={'Fiche'}
        visibility={modalFiche}
        maxWidth={700}
        save
        cancel
        height={450}
        onClickClose={handleModalFiche}
      />
      <div className="SectionRolesContent container-wrapper  pd10-t-b fl_col gp10">
        <TableHeader
          roleById={selectedRole}
          className="header-rolesOptions"
          title="User AZERT"
          type="role"
          textAdd={languageData.global.Modal.save}
          onChange={handleRoleChange}
          onClickSave={onClickSave}
          labelSwitcher={
            rolePermissions?.role?.active ? 'Activer' : 'Désactiver'
          }
          onClickDelete={() => handleDeleteClick(selectedRole.id)}
          hideDelete={roleById?.canbeDeleted}
          isDefault={selectedRole?.isDefault}
          rolePermissions={rolePermissions}
          haserror={error}
          errorMsg={errorMessage}
        />

        <div className="role-section">
          <div className="section-wrapper fl_row gp20 fl_wrap">
            <div className="roles-list">
              <RolesListContainer
                sectionList={sectionList}
                onClick={onChangePermissionsSection}
              />
            </div>

            <div className="roles-configuration fl-1">
              <PermissionsConfigurationContainer
                currentSection={currentSection}
                claims={roleById.claims}
                setRolePermissions={setRolePermissions}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        description={[languageData.global.user.deleteDescription]}
        title={languageData.global.user.deleteConfirm}
        visibility={modalVisible}
        maxWidth={544}
        height={181}
        version={'Alert'}
        onClickConfirm={() => handleConfirmDelete(selectedRole.id)}
        onClickClose={() => setModalVisible(false)}
        confirm
        cancel
      />
      <ToastHandler ref={toastRef} />
      
    </div>
  );
}
