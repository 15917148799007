import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageData } from '../../i18n';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import { serviceRolesModels } from '../../models/services/module.role';
import {
  setUserFormData,
  setUserFormDataDetails,
} from '../../store/actions/actions';
import ItemList from '../List/ItemList';
import ButtonElement from '../button/button';

interface RoleFieldProps {
  showRoleModal: () => void;
  rowId: string;
  roles?: serviceRolesModels.Role[];
  type: string;
  cancelChanges: () => void;
}

const UserRoleModalContent: React.FC<RoleFieldProps> = ({
  showRoleModal,
  rowId,
  roles,
  type,
  cancelChanges,
}) => {
  const dispatch = useDispatch();

  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormData,
  );

  const userFormDataDetails = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormDataDetails,
  );

  const updatedUserFormData =
    type === 'add' ? { ...userFormData } : { ...userFormDataDetails };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;

    if (rowId === '1') {
      const currentPermission = updatedUserFormData.permissions[0];

      if (currentPermission.rolesId.includes(id)) {
        const updatedRolesId = currentPermission.rolesId.filter(
          (role) => role !== id,
        );

        updatedUserFormData.permissions[0] = {
          ...currentPermission,
          rolesId: updatedRolesId,
          rolesIdToDelete: [...(currentPermission.rolesIdToDelete || []), id], // Add to rolesIdToDelete
          isActive: false,
        };
      } else {
        updatedUserFormData.permissions[0] = {
          ...currentPermission,
          rolesId: [...currentPermission.rolesId.filter((i) => i !== '0'), id],
          isActive: true,
        };
      }
    } else {
      const permissionIndex = updatedUserFormData.permissions.findIndex(
        (permission) => permission.rowId === rowId,
      );

      if (permissionIndex !== -1) {
        const currentPermission =
          updatedUserFormData.permissions[permissionIndex];

        if (currentPermission.rolesId.includes(id)) {
          const updatedRolesId = currentPermission.rolesId.filter(
            (role) => role !== id,
          );

          updatedUserFormData.permissions[permissionIndex] = {
            ...currentPermission,
            rolesId: updatedRolesId,
            rolesIdToDelete: [...(currentPermission.rolesIdToDelete || []), id], // Add to rolesIdToDelete
            isActive: false,
          };
        } else {
          updatedUserFormData.permissions[permissionIndex] = {
            ...currentPermission,
            rolesId: [
              ...currentPermission.rolesId.filter((i) => i !== '0'),
              id,
            ],
            isActive: true,
          };
        }
      } else {
        const newPermission = {
          rolesId: [id],
          rowId: id,
          isActive: true,
          chainesIds: [],
          restaurantsIds: [],
          chainesIdsToDelete: [],
          restaurantsIdsToDelete: [],
          rolesIdToDelete: [],
        };
        updatedUserFormData.permissions = [
          ...updatedUserFormData.permissions,
          newPermission,
        ];
      }
    }

    // Dispatch updates based on type
    type === 'add'
      ? dispatch(setUserFormData(updatedUserFormData))
      : dispatch(setUserFormDataDetails(updatedUserFormData));
  };

  const handleConfirm = () => {
    showRoleModal();
  };

  const renderIsRoleChecked = (item: string) => {
    const index = updatedUserFormData.permissions.findIndex(
      (permission) => permission.rowId === rowId,
    );
    if (index !== -1) {
      return updatedUserFormData.permissions[index].rolesId.includes(item);
    }
    return false;
  };
  const handleCancel = () => {
    //cancelChanges();
    showRoleModal();
  };
  return (
    <div className="UserSettingsModalContent">
      <div className="modal-wrapper fl_col gp40">
        <div className="show02 h-full fl_col jc_s fl_col gp20">
          <div className="modal-content fl_col gp10">
            <div className="Search-filter">
              <input
                autoComplete="off"
                type="text"
                placeholder="Recherche..."
              />
            </div>
            <div className="Result">
              <div className="result-wrapper">
                {roles &&
                  roles.map((role: serviceRolesModels.Role) => (
                    <ItemList
                      key={role.id}
                      value={role.name}
                      name={role.name}
                      id={role.id}
                      onChange={handleChange}
                      isChecked={renderIsRoleChecked(role.id)}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="footer-wrapper fl_row jc_fe gp20">
              <ButtonElement
                type={'TextButton'}
                text={languageData.global.close}
                buttonSize={'medium'}
                buttonStyle="btn-primary-light"
                onClick={handleCancel}
              />
              <ButtonElement
                type={'TextButton'}
                text={languageData.global.Valider}
                buttonSize={'medium'}
                buttonStyle="btn-primary"
                onClick={handleConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRoleModalContent;
