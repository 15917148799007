import React, { Dispatch, SetStateAction } from 'react';
import { languageData } from '../../i18n';
import { serviceRolesModels } from '../../models/services/module.role';
import { getErrorEditCompany } from '../../utils/GetErrors';
import { getErrorMessageEditCompany } from '../../utils/GetErrorMessages';
import InputField from '../../components/Input/Input';
import SelectInput from '../../components/Input/SelectInput';
interface NewRoleModalContentProps {
  setFormData: Dispatch<SetStateAction<serviceRolesModels.rolesAction>>;
  formData: serviceRolesModels.rolesAction;
  requestedValidation: boolean;
}

export default function NewRoleModalContent(props: NewRoleModalContentProps) {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        props.setFormData((prevState) => ({
          ...prevState,
          payload: {
            ...prevState.payload,
            name: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const onSelect = (selectedValue: string) => {
    props.setFormData((prevState) => ({
      ...prevState,
      payload: {
        ...prevState.payload,
        active:
          selectedValue === `${languageData.global.status.Active}`
            ? true
            : false,
      },
    }));
  };
  const activeDefaultValue = () => {
    props.formData.payload.active
      ? languageData.global.status.Active
      : languageData.global.status.deactivated;

    switch (props.formData.payload.active) {
      case true:
        return languageData.global.status.Active;
        break;
      case false:
        return languageData.global.status.Active;
        break;
      default:
        return '';
    }
  };

  return (
    <div className="NewRoleModalContent">
      <div className="content-wrapper">
        <div className="fl_row w-full gp20">
          <div className="fl-1">
            <InputField
              isRequired={true}
              type="text"
              name="name"
              value={props.formData.payload.name}
              onChange={onChange}
              label={languageData.global.TableHeaders.name}
              style="form"
              isDisabled={false}
              haserror={
                props.requestedValidation &&
                getErrorEditCompany({
                  fieldName: 'name',
                  isRequired: true,
                  fieldValue: props.formData.payload.name,
                })
              }
              errorMsg={getErrorMessageEditCompany({
                fieldName: 'name',
                fieldValue: props.formData.payload.name,
              })}
            />
          </div>
          <div className="fl-1">
            <SelectInput
              defaultValue={activeDefaultValue()}
              isRequired={true}
              label={languageData.global.TableHeaders.status}
              name="active"
              onChange={onChange}
              onSelect={onSelect}
              target="dropdown"
              options={[
                `${languageData.global.status.Active}`,
                `${languageData.global.status.deactivated}`,
              ]}
              haserror={
                props.requestedValidation &&
                getErrorEditCompany({
                  fieldName: 'active',
                  isRequired: true,
                  fieldValue: props.formData.payload.active,
                })
              }
              errorMsg={getErrorMessageEditCompany({
                fieldName: 'active',
                fieldValue: props.formData.payload.active,
              })}
            ></SelectInput>
          </div>
        </div>
      </div>
    </div>
  );
}
