import * as actionTypes from '../actions/actionTypes';

const initialState = {
  code: null,
  logged: false,
  errorMsg: '',
  claimsEntreprise: [],
  claimsEtablissement: [],
  isAdmin: null,
  claims: [],
  user: null,
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.LOGIN: {
      return {
        ...state,
        body: {
          email: action.email,
          password: action.password,
          deviceNotificationId: action.connectionId,
          forceLogin: action.forceLogin,
        },
      };
    }
    case actionTypes.UPDATE_USER_SECTION_SUCCESS: {
      return {
        ...state,
        user: {
          ...(state.user ?? {}),
          ...action.payload,
        },
      };
    }
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        claimsEntreprise: action.payload.claimsEntreprise,
        claimsEtablissement: action.payload.claimsEtablissement,
        isAdmin: action.payload.isAdmin,
        claims: action.payload.claims,
        logged: true,
      };
    }
    case actionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        code: action.payload.code,
        errorMsg: action.payload.message,
      };
    }
    case actionTypes.LOGOUT: {
      return {};
    }
    case actionTypes.CANCEL_LOGIN: {
      return {
        ...state,
        code: action.code,
      };
    }
    case actionTypes.RESET_LOGGED_IN: {
      return {
        ...state,
        logged: false,
      };
    }
    case actionTypes.FAILURE: {
      return {
        ...state,
        code: action?.error?.response?.data?.failureResponse?.codeFI?.code
          ? action?.error?.response?.data?.failureResponse?.codeFI?.code
          : action?.error?.response?.status,
      };
    }

    case actionTypes.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: '',
      };
    default:
      return state;
  }
}

export default reducer;
