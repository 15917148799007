import { serviceEntrepriseModels } from '../models/services/module.entreprise';
import tabs_list from '../data/tabsList';
import {
  setEditFormDataFinancial,
  unsavedChangesRegister,
} from '../store/actions/actions';
import { Dispatch } from 'redux';
interface UpdateRedevanceProps<T> {
  id: string;
  checked?: boolean;
  value?: string;
  name?: string;
  newFormData: T;
  dispatch: Dispatch;
  key: number;
}
interface UpdateRedevanceDetailsProps {
  formData: serviceEntrepriseModels.formDataFinancialInfosTypeActionPayload;
  dispatch: Dispatch;
  id: string;
  checked?: boolean;
  value?: string;
  name?: string;
}
const updateRoyaltiesInformation = (
  royaltiesInformation: serviceEntrepriseModels.royaltiesInformation[],
  id: string,
  checked?: boolean,
  value?: string,
  name?: string,
) => {
  return royaltiesInformation.map((item: any) => {
    if (item.key === id) {
      return {
        ...item,
        ...(typeof checked !== 'undefined' &&
          typeof checked !== 'object' && { isActive: checked }),
        ...(typeof value !== 'undefined' &&
          typeof value !== 'object' &&
          value !== 'on' && { pct: parseInt(value, 10) }),
        ...(typeof name !== 'undefined' &&
          typeof name !== 'object' && { name }),
      };
    }
    return item;
  });
};
export const UpdateRedevance = <
  T extends {
    payload: {
      financialInformation?: {
        royaltiesInformation?: serviceEntrepriseModels.royaltiesInformation[];
      };
    };
  },
>({
  id,
  checked,
  value,
  name,
  newFormData,
  dispatch,
  key,
}: UpdateRedevanceProps<T>) => {
  if (newFormData?.payload?.financialInformation?.royaltiesInformation) {
    const updatedRoyalties = updateRoyaltiesInformation(
      newFormData?.payload?.financialInformation?.royaltiesInformation,
      id,
      checked,
      value,
      name,
    );
    const array = [
      {
        key: tabs_list[key].name,
        value: {
          type: 'action',
          payload: {
            payload: {
              ...newFormData?.payload,
              financialInformation: {
                ...newFormData?.payload?.financialInformation,
                royaltiesInformation: updatedRoyalties,
              },
            },
          },
        },
      },
    ];
    dispatch(unsavedChangesRegister(array));
  } else {
    return;
  }
};
/**********************details */
export const UpdateRedevanceDetails = ({
  formData,
  dispatch,
  id,
  checked,
  value,
  name,
}: UpdateRedevanceDetailsProps) => {
  if (formData.royalties) {
    const updatedRoyalties = updateRoyaltiesInformation(
      formData.royalties,
      id,
      checked,
      value,
      name,
    );

    const updatedFormData = createUpdatedFormDataDetails(
      formData,
      updatedRoyalties,
    );
    dispatch(setEditFormDataFinancial(updatedFormData.payload));
  } else {
    return;
  }
};
const createUpdatedFormDataDetails = (
  formData: serviceEntrepriseModels.formDataFinancialInfosTypeActionPayload,
  updatedRoyalties: serviceEntrepriseModels.royaltiesInformation[],
): serviceEntrepriseModels.formDataFinancialInfosTypeAction => {
  return {
    type: 'action',
    payload: {
      ...formData,
      royalties: updatedRoyalties,
    },
  };
};
// Utility function to update royalties
export const updateRoyalties = (
  royalties?: serviceEntrepriseModels.royaltiesInformation[] | [],
) => {
  return (royalties || []).map((royalty) => {
    if (!royalty.hasOwnProperty('editItem')) {
      royalty.editItem = true;
    }
    if (!royalty.hasOwnProperty('deleteItem')) {
      royalty.deleteItem = false;
    }
    return royalty;
  });
};
