import React from 'react';

interface Props {
  strokeColor?: string;
}

const MenusIcon: React.FC<Props> = ({ strokeColor }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3H3"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.66667H3"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8.33333H3"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11H3"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66667 7.66667V5L7.33333 6.33333L9 5V7.66667"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.66667H5.66667"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33333 1H11.6667V13H2.33333V1Z"
        stroke={strokeColor}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenusIcon;
