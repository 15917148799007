import {
  Badges,
  Family,
  GeneralCodeTva,
  Tags,
} from '../../models/services/module.catalogue';
export interface Option {
  label: string;
  value: number;
  icon?: string;
  color?:string
}
export function convertToOptions(
  families: Family[] | GeneralCodeTva[] | Tags[] | Badges[],
): Option[] {
  return (families ?? []).map((family) => ({
    label: family.name,
    value: family.id,
    icon: (family as Badges).url,
    color: (family as Tags).color,
  }));
}
