import React from 'react';
import MobileTableCard from '../../../components/Table/MobileTableCard';
import Td from '../../../components/Table/Td-table';

type TdProps = {
  item?: any;
  id?: number;
  iDate?: string;
  isInProcessing?: boolean;
  reference: string;
  paymentDate: string;
  totalTTC: number;
  isPaid?: boolean;
  convertDate: any;
  setShowInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  CalendarSendDarkIcon: string;
  CheckedCalendarGreenIcon: string;
  setFacture?: React.Dispatch<React.SetStateAction<any>>;
};

export default function MobileTableBilling({
  item,
  id,
  reference,
  setShowInvoice,
  CalendarSendDarkIcon,
  iDate,
  convertDate,
  CheckedCalendarGreenIcon,
  paymentDate,
  totalTTC,
  setFacture,
}: TdProps) {
  return (
    <MobileTableCard key={id} expand={true} className="paid-bill">
      <div className="table-header fl_row ai_c jc_s">
        <div className="left-side fl_row gp15 ai_c">
          <div className="fl_row jc_s">
            <Td
              style="bold"
              type="reference"
              value={reference !== null ? reference : 'N/A'}
              width={40}
              align="left"
              mobile
              paid={true}
            />
          </div>
        </div>
        <div className="right-side fl_row gp20 ai_c jc_fe">
          <Td
            type="viewBill"
            width={2}
            onClick={() => {
              setShowInvoice(true);
              setFacture && setFacture(item);
            }}
            mobile
          />
        </div>
      </div>
      <div className="table-body fl_col gp5">
        <div className="tbody-col fl_row gp10">
          <div className="fl_row emission-date gp5">
            <img src={CalendarSendDarkIcon} alt="" />
            <Td
              className=""
              type="text"
              value={iDate !== null ? convertDate(iDate) : 'N/A'}
              width={6}
              align="center"
              mobile
            />
          </div>
          <div className="payment-date fl_row gp5">
            <img src={CheckedCalendarGreenIcon} alt="" />
            <Td
              className=""
              type="text"
              value={paymentDate !== null ? convertDate(paymentDate) : 'N/A'}
              width={6}
              align="center"
              mobile
            />
          </div>

          <Td
            className="stats"
            type="text"
            value={totalTTC}
            width={6}
            align="center"
            mobile
          />
        </div>
      </div>
    </MobileTableCard>
  );
}
