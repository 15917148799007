import React from 'react';
import logo from '../../assets/icons/LogoFrenchInnov.svg';
/**
 * Invoice component description.
 *
 * @Invoice
 *
 */

interface InvoiceProps {
  InvoiceNumber: string;
  emissionDate: string;
  periodOfUseStart: string;
  periodOfUseEnd: string;
  withdrawalDate: string;
  transmitterName: string;
  transmitterAdresse: string;
  transmitterPhone: string;
  addressedToName: string;
  addressedToAdresse: string;
  addressedToTvaNumber: string;
}

const InvoiceHeader: React.FC<InvoiceProps> = ({
  InvoiceNumber,
  emissionDate,
  periodOfUseStart,
  periodOfUseEnd,
  withdrawalDate,
  transmitterName,
  transmitterAdresse,
  transmitterPhone,
  addressedToName,
  addressedToAdresse,
  addressedToTvaNumber,
}) => {
  return (
    <div className="InvoiceHeader">
      <div className="Invoice-wrapper">
        <div className="content-Invoice fl_col gp24">
          <div className="header-Invoice fl_row jc_s">
            <div className="fl_col gp8">
              <p className="p32 w-700 uper">Facture</p>
              <p className="p14 w-700 OxfordBlue01-txt">#{InvoiceNumber}</p>
            </div>
            <img src={logo} alt="" />
          </div>

          <div className="table-container fl_row">
            <div className="section-table emissionDate-section fl_col  jc_s pd16 fl-1 fl_col gp16">
              <div className="fl_row jc_s">
                <div className="fl_col gp6">
                  <p className="p10 w-900 label-item cap">émission</p>
                  <p className="p10 OxfordBlue01-txt w-700">{emissionDate}</p>
                </div>
                <div className="fl_col gp6">
                  <p className="p10 w-900 label-item cap">Prélèvement</p>
                  <p className="p10 OxfordBlue01-txt w-700">{withdrawalDate}</p>
                </div>
              </div>
              <div className="fl_col gp6">
                <p className="p10 w-900 label-item">
                  Période d'utilisation
                </p>
                <p className="p10 OxfordBlue01-txt w-400">
                  Du <span className="w-900">{periodOfUseStart}</span> au{' '}
                  <span className="w-900">{periodOfUseEnd}</span>
                </p>
              </div>
            </div>
            <div className="section-table transmitterAdresse-section fl_col  jc_s pd16 fl-1">
              <div className="fl_col gp8">
                <p className="p10 w-900 label-item cap">émetteur</p>
                <p className="p10 OxfordBlue01-txt w-700">{transmitterName}</p>
                <p className="p10 OxfordBlue01-txt">{transmitterAdresse}</p>
                <p className="p10 OxfordBlue01-txt">Tél.:{transmitterPhone}</p>
              </div>
            </div>
            <div className="section-table addressedTo-section fl_col  jc_s pd16 fl-1">
              <p className="p10 w-900 label-item ">Adressé à</p>
              <p className="p10 OxfordBlue01-txt w-700">{addressedToName}</p>
              <p className="p10 OxfordBlue01-txt">{addressedToAdresse}</p>
              <p className="p10 OxfordBlue01-txt">
                Numéro TVA: {addressedToTvaNumber}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
