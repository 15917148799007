import {
  removeTab,
  selectTab,
  setActiveTab,
  setActiveTabContent,
  updateTabs,
} from '../../store/actions/actions';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { Dispatch } from 'redux';

/**
 * The function `CloseTab` is used to remove a tab from a list of open tabs in a TypeScript
 * React application, and it also handles selecting the previous tab if the removed tab was active.
 */
interface closeTabProps {
  open_tabs: serviceTabsModels.Tab[];
  name: string;
  dispatch: Dispatch;
  id?: any;
  key?: string;
}
export function getFirstPart(input: string): string {
  let firstPart = input.split('-')[0].trim();
  let firstSpaceRemoved = firstPart.replace(/^\s/, '');
  let result = firstSpaceRemoved.replace(/\s$/, '');
  return result;
}

export const CloseTab = async (props: closeTabProps) => {
  const { dispatch, open_tabs, id, key, name } = props;

  const tabIndex = open_tabs.findIndex((tab: serviceTabsModels.Tab) => {
    return id
      ? tab.id === id &&
          tab.tabContent.key === key &&
          getFirstPart(tab.name) === getFirstPart(name)
      : tab.tabContent.key === key &&
          getFirstPart(tab.name) === getFirstPart(name);
  });

  if (tabIndex === -1) return;

  const isClosingActiveTab = open_tabs[tabIndex].isActive;

  const updatedTabs = open_tabs.filter((_, index) => index !== tabIndex);

  const setTabActive = (tab: serviceTabsModels.Tab) => {
    dispatch(
      setActiveTab({ id: tab.id, name: tab.name, companyId: tab.companyId }),
    );
    dispatch(
      setActiveTabContent({
        key: tab.index,
        id: tab.id,
        value: tab.tabContent?.value,
        companyId: tab.companyId,
        isEtablishement: tab.isEtablishement,
      }),
    );
  };

  if (isClosingActiveTab) {
    if (updatedTabs.length > 0) {
      const newActiveTab = updatedTabs[Math.max(0, tabIndex - 1)];
      setTabActive(newActiveTab);

      updatedTabs[updatedTabs.indexOf(newActiveTab)].isActive = true;
    } else {
      dispatch(setActiveTab({ id: null, name: '' }));
      dispatch(setActiveTabContent({ key: '', id: undefined, value: null }));
    }
  }

  dispatch(updateTabs(updatedTabs));
};
