function checkCartType(firstNumber: string) {
  switch (firstNumber) {
    case '4':
      return 'visa';
    case '5':
      return 'master';
    case '3':
      return 'express';
    default:
      return 'unknown';
  }
}

export default checkCartType;
