import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import * as actionTypes from '../actions/actionTypes';
const initialState = {
  loading: false,
  error: null,
  success: false,
  responseCreateData: null,
};
export interface createAbonnementResponse {
  id: number;
  designation: string;
  intervalle: Intervalle;
  iDate: string;
  isActive: boolean;
}

export interface Intervalle {
  id: number;
  designation: string;
  isActive: boolean;
}
const AddabonnementReducer = (
  state = initialState,
  action: { type: string; payload: createAbonnementResponse },
) => {
  switch (action.type) {
    case actionTypes.CREATE_ABONNEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case actionTypes.CREATE_ABONNEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        responseCreateData: action.payload,
      };
    case actionTypes.CREATE_ABONNEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};

export default AddabonnementReducer;
