import { serviceEtablissementModels } from '../models/services/module.etablissement';

export const initialState: serviceEtablissementModels.Etablissement = {
  companyId: undefined,
  generalInformation: {
    commercial_Name: '',
    share_capital: '',
    mobile_telephone_number: '',
    fixed_line_telephone_number: '',
    email: '',
    address: '',
    country: '',
    city: '',
    postal_code: '',
  },
  financialInformation: {
    owner: '',
    iban: '',
    swifT_BIC: '',
    card_number: undefined,
    royaltiesInformation: [
      // {
      //   name: 'Principal',
      //   pct: 0,
      //   isPrinciple: true,
      //   isActive: true,
      //   editItem: false,
      //   deleteItem: false,
      //   key: 'Principal',
      // },
    ],
  },
  fiscalInformation: {
    sireT_code: '',
    naF_code_id: 0,
    vaT_Intracommunity: '',
  },
};
export const formErrorsInitialState = {
  generalInformation: {
    commercial_Name: {
      required: true,
      hasError: false,
      errorMsg: '',
    },

    share_capital: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    mobile_telephone_number: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    fixed_line_telephone_number: {
      required: false,
      hasError: false,
      errorMsg: '',
    },
    email: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    address: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    country: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    city: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    postal_code: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
  },
  financialInformation: {
    owner: {
      required: true,
      hasError: false,
      errorMsg: '',
    },

    iban: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    swifT_BIC: {
      required: false,
      hasError: false,
      errorMsg: '',
    },
    card_number: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    royaltiesInformation: [
      // {
      //   name: 'Principal',
      //   pct: 0,
      //   isPrinciple: true,
      //   isActive: true,
      //   edititem: false,
      //   deleteItem: false,
      // },
    ],
  },
  fiscalInformation: {
    sireT_code: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    naF_code_id: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    vaT_Intracommunity: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
  },
};
