import React from 'react';

interface Props {
  strokeColor?: string;
}

const TvaIcon: React.FC<Props> = ({ strokeColor = "#616C82" }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2033_19191)">
        <path d="M5.99967 5.9987H6.00634" stroke={strokeColor} strokeWidth="1.3"/>
        <path d="M9.99967 9.9987H10.0063" stroke={strokeColor} strokeWidth="1.3"/>
        <path d="M10.6663 5.33203L5.33301 10.6654" stroke={strokeColor} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.33301 5.9987C6.33301 6.18279 6.18377 6.33203 5.99967 6.33203C5.81558 6.33203 5.66634 6.18279 5.66634 5.9987C5.66634 5.8146 5.81558 5.66536 5.99967 5.66536C6.18377 5.66536 6.33301 5.8146 6.33301 5.9987Z" stroke={strokeColor} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.333 9.9987C10.333 10.1828 10.1838 10.332 9.99967 10.332C9.81558 10.332 9.66634 10.1828 9.66634 9.9987C9.66634 9.8146 9.81558 9.66536 9.99967 9.66536C10.1838 9.66536 10.333 9.8146 10.333 9.9987Z" stroke={strokeColor} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.6663 7.9987C14.6663 11.6806 11.6816 14.6654 7.99967 14.6654C4.31778 14.6654 1.33301 11.6806 1.33301 7.9987C1.33301 4.3168 4.31778 1.33203 7.99967 1.33203C11.6816 1.33203 14.6663 4.3168 14.6663 7.9987Z" stroke={strokeColor} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2033_19191">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TvaIcon;
