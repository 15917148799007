import React, { ReactNode } from 'react';
import AnyModalController from './AnyModalController';
import TvaModalController from './tvaModalController';
import TagModalController from './tagModalController';
import FamilyModalController from './familyModalController';
import EditArticleModalController from './editArticleModalController';
import EditScreenModalController from './editScreenModalController';
import UnsavedChangeModalController from './unsavedChnageModalController';
import AddEtapeModalController from './AddEtapeModalController';
import { ModalProps } from '../../../models/services/module.modals';
import MachineModalController from './MacgineModalController';
export default function ItemModalController(props: ModalProps) {
  const { target } = props;
  let ModalContent: {
    modalContentBody: JSX.Element | null;
    modalContentFooter: JSX.Element | null;
  };
  switch (target) {
    case 'any':
      ModalContent = AnyModalController(props);
      break;
    case 'tva':
      ModalContent = TvaModalController(props);
      break;
    case 'tag':
      ModalContent = TagModalController(props);
      break;
    case 'family':
      ModalContent = FamilyModalController(props);
      break;
    case 'machine':
      ModalContent = MachineModalController(props);
      break;
    case 'editarticle':
      ModalContent = EditArticleModalController(props);
      break;
    case 'editscreen':
      ModalContent = EditScreenModalController(props);
      break;
    case 'unsavedchanges':
      ModalContent = UnsavedChangeModalController(props);
      break;
    case 'AddEtape':
      ModalContent = AddEtapeModalController(props);
      break;
    default:
      ModalContent = AnyModalController(props);
  }
  return ModalContent;
}
