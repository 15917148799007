import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: false,
  abonnementClient: false,
};

const clientAbonnementReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_LIST_ABONNEMENTS_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_LIST_ABONNEMENTS_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        abonnementClient: action.payload,
      };
    case actionTypes.GET_LIST_ABONNEMENTS_CLIENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
export default clientAbonnementReducer;
