import React, { ReactNode } from 'react';
interface GridItemsProps {
  children: ReactNode;
  grid?: number;
  gap?: number;
  enableScroll?: boolean;
}

const GridItems: React.FC<GridItemsProps> = ({ children, grid, gap, enableScroll }) => {
  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${grid}, 1fr)`,
        gap: `${gap}px`,
        overflowY: enableScroll ? 'auto' : 'unset'
      }}

      className="GridItems gp12"
    >
      {children}
    </div>
  );
}
export default GridItems;