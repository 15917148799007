import React from 'react';
import AddProductIconBlue from '../../assets/icons/AddProductIconBleuWithBg.svg';
import MenuPlaceHolder from '../../assets/images/MenuPlaceHolder.png';
/**
 * CartNavItem component description.
 *
 * @CartNavItem
 *
 */

interface CartNavItemProps {
  name: string;
  img?: string;
  OnClickAdd?: () => void;
  
}

const CartNavItem: React.FC<CartNavItemProps> = ({ name, img, OnClickAdd }) => {
  return (
    <div className="CartNavItem">
      <div className="item-wrapper fl_col ai_c">
        <img
          src={AddProductIconBlue}
          alt=""
          className="remove-item"
          onClick={OnClickAdd}
        />
        <img
          width={150}
          src={img || MenuPlaceHolder}
          alt=""
          className="item-img"
        />
        <div className="item-footer">
          <p className="p14 Independence-txt w-700">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default CartNavItem;
