import React from 'react';
import Step2 from './FormSteps/Step2';
import Step3 from './FormSteps/Step3';
import Step1 from './FormSteps/Step1';
import { useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../../models/services/module.tabs';
export default function FormWizzardContent() {
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  return (
    <div className="FormWizzardContent fl_col gp10">
      <Step1 /> <Step2 /> <Step3 open_tabs={open_tabs} />
    </div>
  );
}
