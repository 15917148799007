import { ArticleAllergen } from '../../../models/services/module.catalogue';
import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState: {
  allergenes: ArticleAllergen[];
  allergernChange: number;
  deletedAllergenes: ArticleAllergen[];
} = {
  allergenes: [],
  allergernChange: 0,
  deletedAllergenes: [],
};

function reducer(
  state = initialState,
  action: {
    allergene: ArticleAllergen;
    allergenes: ArticleAllergen[];
    type: string;
    allergernChange: number;
  },
) {
  switch (action.type) {
    case actionTypes.ALLERGEN_DRAGGED: {
      const compositionExists = state.allergenes.some(
        (allergen) =>
          (allergen.idAllergen ?? allergen.id) ===
          (action.allergene.idAllergen ?? action.allergene.id),
      );
      return !compositionExists
        ? {
            ...state,
            allergenes: [...state.allergenes, action.allergene] ?? [],
            allergernChange: state.allergernChange + 1,
          }
        : state;
    }
    case actionTypes.ALLERGEN_UNDRAGGED: {
      return {
        ...state,
        allergenes:
          state.allergenes.filter(
            (allergen) =>
              (allergen.idAllergen ?? allergen.id) !==
              (action.allergene.idAllergen ?? action.allergene.id),
          ) ?? [],
        deletedAllergenes: action.allergene.idAllergen
          ? [...state.deletedAllergenes, action.allergene]
          : state.deletedAllergenes,
      };
    }
    case actionTypes.UPDATE_ALLERGENS: {
      return {
        ...state,
        allergenes: action.allergenes.reduce(
          (unique: ArticleAllergen[], allergen: ArticleAllergen) => {
            return unique.some((c) => c.id === allergen.id)
              ? unique
              : [...unique, allergen];
          },
          [],
        ),
      };
    }
    case actionTypes.EDIT_SUCCESS: {
      return {
        ...state,
        allergenes: [],
        deletedAllergenes: [],
      };
    }
    case actionTypes.UPDATE_DELETED_ALGS: {
      return {
        ...state,
        deletedAllergenes: action.allergenes,
      };
    }

    default:
      return state;
  }
}
export default reducer;
