import * as actionTypes from '../actions/actionTypes';
const initialState = {
  loading: false,
  abonnementStatus: null,
  error: null,
};

export const abonnementStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_ABONNEMENT_STATUS_REQUEST:
      return { ...state, loading: true, error: null };
    case actionTypes.UPDATE_ABONNEMENT_STATUS_SUCCESS:
      return {
        ...state,
        abonnementStatus: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_ABONNEMENT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    default:
      return state;
  }
};
