import { AxiosError, AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  getTagFail,
  getTagSuccess,
  postTagSuccess,
} from '../../actions/catalogues/actions';
import { tagAction } from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
export function* startGetTags(action: tagAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getTags();
    if (response.data) {
      yield put(getTagSuccess(response.data?.data?.result??[]));
      action._(response.data?.data?.result);
    }
  } catch (error: unknown) {
    yield put(getTagFail(error));
  }
}
export function* startPostTags(action: tagAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postTags(action.tag);
    if (response.data) {
      yield put(postTagSuccess());
      action._();
    }
  } catch (error: unknown) {
    NotificationErrorProvider(error);
  }
}
export function* startDeleteTags(action: tagAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteTag(action.tag.id);
    if (response.data) {
      yield put(postTagSuccess());
      action._();
    }
  } catch (error: unknown) {
    NotificationErrorProvider(error);
  }
}
