import React, { useState } from 'react';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Tr from '../../../components/Table/Tr-table';
import Thead from '../../../components/Table/Thead-table';
import Table from '../../../components/Table/Table';
import TableRightArrow from '../../../assets/icons/TableRightArrowPrimaryColor.svg';
import TableArrowDropDownGrey from '../../../assets/icons/TableArrowDropDownGrey.svg';
import Pagination from '../../../components/Table/Pagination-table';
import { languageData } from '../../../i18n';
import MobileTableContainer from '../../../components/Table/MobileTableContainer';
import MobileTableCard from '../../../components/Table/MobileTableCard';
import TableHeader from '../../../components/Table/TableHeader';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import { useSelector } from 'react-redux';
interface ListeDesEtablissementsContainerProps {
  list: serviceEtablissementModels.etablissementList[];
  counter: number;
  handleClickOpenAddEtablissement: () => void;
  handleClickOpenEtablissementDetails: (id: number, name: string) => void;
  handleNextClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handlePreviousClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handlePageChange?: (pageNumber: number) => void;
  currentPage?: number;
  totalPages?: number;
  claimEtablissementAjout?: boolean;
  claimEtablissementModification?: boolean;
}
type ExpandedCardState = {
  [key: string]: boolean;
};
export default function ListeDesEtablissementsContainer(
  props: ListeDesEtablissementsContainerProps,
) {
  const [expandedCard, setExpandedCard] = useState<ExpandedCardState>({});
  const toggleExpand = (userId: number) => {
    setExpandedCard({ [userId]: !expandedCard[userId] });
  };
  const claimsUser = useSelector((state: any) => state.auth?.claims);

  return (
    <div className="ListeDesEtablissementsContainer fl_col gp6 h-full">
      <TableHeader
        slot={props.counter}
        type="add"
        title={languageData.global.etablissement}
        description={languageData.global.manageEtablissment}
        onClickAdd={props.handleClickOpenAddEtablissement}
        textAdd={languageData.global.add}
        claimEtablissementAjout={props.claimEtablissementAjout}
        claimEtablissementModification={props.claimEtablissementModification}
        claimsUser={claimsUser}
      />
      {/* <CardContainer type="standard">
        <div className="table-header">
          <div className="header-wrapper fl_row jc_s ai_c rd12 fl_wrap gp20">
            <div className="table-info fl_col gp10">
              <div className="fl_row gp40">
                <p className="p18 w-900 DarkJungleGreen-txt">
                  {languageData.global.etablissement}
                </p>
                <span className="counter p12 w-500"> {props.counter}</span>
              </div>
              <p className="p14 Independence-txt">
                {languageData.global.manageEtablissment}
              </p>
            </div>
            <div
              className="btn-add gp8"
              onClick={props.handleClickOpenAddEtablissement}
            >
              <img src={AddElementIcon} alt="" />
              <p className="p16 w-700 primary-txt">{languageData.global.add}</p>
            </div>
          </div>
        </div>
      </CardContainer> */}
      <div className="TabContent h-full fl_col jc_s gp10">
        <div className="fl_col h-full">
          <div className="fl_col white-bg rd12 h-full">
            <div className="large-screen-table" style={{ overflowX: 'auto' }}>
              <Table className="Table TableEntreprises rd12">
                <Thead>
                  <Tr>
                    <Td
                      type="text"
                      value={languageData.global.TableHeaders.name}
                    />
                    <Td
                      type="text"
                      value={languageData.global.TableHeaders.status}
                    />
                    <Td
                      type="filter"
                      value={languageData.global.TableHeaders.telephone}
                    />
                    <Td
                      type="text"
                      value={languageData.global.TableHeaders.email}
                      className="item-email"
                    />
                    <Td
                      type="text"
                      value={languageData.global.TableHeaders.address}
                    />
                    {/* <Td
                    type="text"
                    value={languageData.global.TableHeaders.responsible}
                  /> */}
                    <Td type="empty" />
                  </Tr>
                </Thead>
                <Tbody>
                  {props.list.map((item) => (
                    <Tr key={item.id}>
                      <Td
                        className="item-name"
                        type="text"
                        value={item.commercial_Name}
                      />
                      <Td type="status" value={item?.status?.name} />
                      <Td
                        className="item-mobile"
                        type="text"
                        value={item.mobile_telephone_number}
                        center
                      />
                      <Td
                        className="item-email"
                        type="text"
                        value={item.email}
                      />
                      <Td
                        className="item-adresse"
                        type="text"
                        value={item.address}
                      />
                      <Td type="empty" />
                      <Td
                        type="nav"
                        onClick={() => {
                          props.handleClickOpenEtablissementDetails(
                            item.id,
                            item.commercial_Name,
                          );

                          localStorage.setItem('idEtablishement', item.id + '');
                        }}
                      />
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
            <MobileTableContainer>
              {props.list.map((item) => (
                <MobileTableCard
                  expand={expandedCard[item.id] || false}
                  key={item.id}
                >
                  <div className="table-header fl_row ai_c jc_s">
                    <div className="left-side fl_row gp15 ai_c">
                      <img
                        className="arrow-expend"
                        src={TableArrowDropDownGrey}
                        alt=""
                        onClick={() => toggleExpand(item.id)}
                        loading="lazy"
                      />
                      <div className="main-informations fl_col gp10">
                        <Td type="text" value={item.commercial_Name} mobile />
                        <Td type="text" value={item.responsible} mobile />
                      </div>
                    </div>
                    <div className="right-side fl_row gp15 ai_c">
                      <Td type="status" value={item?.status?.name} mobile />
                      <img
                        loading="lazy"
                        src={TableRightArrow}
                        alt=""
                        onClick={() =>
                          props.handleClickOpenEtablissementDetails(
                            item.id,
                            item.commercial_Name,
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="table-body fl_col gp5">
                    <div className="tbody-col fl_row jc_s">
                      <div className="label">
                        <Td
                          type="text"
                          value={languageData.global.TableHeaders.pointDevente}
                        />
                      </div>
                      <Td type="text" value={item.nbEstablishement} mobile />
                    </div>
                    <div className="tbody-col fl_row jc_s">
                      <div className="label">
                        <Td
                          type="text"
                          value={languageData.global.TableHeaders.mobile}
                          mobile
                        />
                      </div>
                      <Td
                        type="text"
                        value={item.mobile_telephone_number}
                        mobile
                      />
                    </div>
                    <div className="tbody-col fl_row jc_s">
                      <div className="label">
                        <Td
                          type="text"
                          value={languageData.global.TableHeaders.email}
                          mobile
                        />
                      </div>
                      <Td type="text" value={item.email} mobile />
                    </div>
                  </div>
                </MobileTableCard>
              ))}
            </MobileTableContainer>
          </div>
        </div>
        <Pagination
          totalPagesNumbers={props.totalPages}
          nextpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            props.handleNextClick(e)
          }
          prevpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            props.handlePreviousClick(e)
          }
          handlePageChange={props.handlePageChange}
          currentPage={props.currentPage}
          totalPages={props.totalPages}
        />
      </div>
    </div>
  );
}
