import { serviceTabsModels } from '../../models/services/module.tabs';
import { Dispatch } from 'redux';
import { WithUpdateTab } from './WithUpdateTab';
/* functional component named `OpenTab` that handles open tabs */
interface OpenTabProps {
  label?: string;
  dispatch: Dispatch;
  tabs: serviceTabsModels.Tab[];
  id?: any;
  name?: string;
  key?: string;
  miniTab?: string;
  companyName?: string;
  companyId?: string;
  isEtablishement?:boolean
}
function getFirstPart(input: string): string {
  let firstPart = input.split('-')[0].trim();
  let firstSpaceRemoved = firstPart.replace(/^\s/, '');
  let result = firstSpaceRemoved.replace(/\s$/, '');
  return result;
}

export const OpenTab = (props: OpenTabProps) => {
  
  let tabIndex;
  if (props.id) {
    tabIndex = props.tabs.findIndex((tab: serviceTabsModels.Tab) => {
     
      return (
        tab.id == props.id &&
        tab.tabContent.key == props.key &&
        getFirstPart(tab.name) ==
          (props.companyName ? props.label : getFirstPart(props.name ?? ''))
      );
    });
  } else {
    tabIndex = props.tabs.findIndex(
      (tab: serviceTabsModels.Tab) => tab.name === props.label,
    );
  }
  if (tabIndex === -1) {
    const updatedTabs = props.tabs.map((tab: serviceTabsModels.Tab) => ({
      ...tab,
      isActive: false,
    }));
    WithUpdateTab({
      tabIndex: tabIndex,
      label: props.label,
      dispatch: props.dispatch,
      updatedTabs: updatedTabs,
      id: props.id,
      name: props.name,
      miniTab: props.miniTab,
      companyName: props.companyName,
      companyId: props.companyId,
      isEtablishement:props.isEtablishement,
      
    });
  } else {
    const updatedTabs = props.tabs.map((tab: serviceTabsModels.Tab) =>
      getFirstPart(tab.name) === props.label
        ? { ...tab, isActive: true }
        : tab.id === props.id &&
            tab.tabContent.key === props.key &&
            tab.name === props.name
          ? { ...tab, isActive: true }
          : { ...tab, isActive: false },
    );
    //  props.dispatch(updateTabs(updatedTabs));
    WithUpdateTab({
      tabIndex: tabIndex,
      label: props.label,
      dispatch: props.dispatch,
      updatedTabs: updatedTabs,
      id: props.id,
      name: props.name,
      miniTab: props.miniTab,
      companyId: props.companyId,
      isEtablishement:props.isEtablishement
    });
  }
};
