import * as actionTypes from '../actions/actionTypes';
interface State {
  bankAccountTypes: string[];
}
const initialState: State = {
  bankAccountTypes: [],
};

// eslint-disable-next-line
function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_BANK_ACCOUNT_TYPES: {
      return { ...state };
    }

    case actionTypes.GET_BANK_ACCOUNT_TYPES_SUCCESS: {
      return {
        ...state,
        bankAccountTypes: action.payload,
      };
    }
    default:
      return state;
  }
}

export default reducer;
