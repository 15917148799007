import React from 'react';
import { ModalProps } from '../../../models/services/module.modals';
import { languageData } from '../../../i18n';
import ModalEditScreenArticle from '../ModalEditScreen';
import EnleverRedIcon from '../../../assets/icons/enleverRedIcon.svg';
export default function EditScreenModalController(props: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper fl_col gp20 w-full jc_c ai_c">
      <ModalEditScreenArticle
        handleSelectEcran={props.handleSelectEcrans}
        screens={props.screens}
        articleChanges={props.articleChanges}
      />
    </div>
  );

  const modalContentFooter = (
    <div className="wrapperFooter w-full ai_c jc_s">
      <div className="elever fl_row gp4 ai_c c-p" onClick={props.onUndrag}>
       {/*  <img src={EnleverRedIcon} alt="" />
        <p className="p14 w-700 Carnelian-txt">
          {languageData.fr.modalsCatalogues.undrag}
        </p> */}
      </div>
      <div className="">
        <div className="fl_row gp12 w-full">
          {props.cancel && (
            <button
              className="btn_cat btn-lightGrey"
              onClick={props.closeModal}
            >
              {props.cancelTxt}
            </button>
          )}
          {props.save && (
            <button
              id="save"
              disabled={!props.hasChange}
              onClick={props.handleSave}
              className={props.hasChange?`btn_cat fl-1 ${'btn-primary'}`:'btn_cat fl-1 btn-disabledGrey'}
            >
              {props.saveTxt}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return {
    modalContentBody,
    modalContentFooter,
  };
}
