import React from 'react';
import { languageData } from '../../../i18n';
import InputField from '../../Input/Input';
import ButtonElement from '../../button/button';
import RadioBoxInput from '../../Input/RadioBoxInput';
import RadioBoxInputOption from '../../Input/RadioBoxInputOption';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';

/**
 * LicenceAffectionModalContent component description.
 *
 * @LicenceAffectionModalContent
 *
 */

interface LicenceAffectionModalContentProps {
  onClickSave?: () => void;
  CloseModal?: () => void;
  EtablissemntListMachinesSuper: any;
  handleRadioChange?: (id: number) => void;
  resultData?: serviceEtablissmentMachineTypeModels.Iresult;
}

const LicenceAffectionModalContent: React.FC<
  LicenceAffectionModalContentProps
> = ({
  onClickSave,
  CloseModal,
  EtablissemntListMachinesSuper,
  handleRadioChange,
  resultData,
}) => {
  const isChecked = (id: number) => resultData?.listTypes?.id === id;

  return (
    <div className="LicenceAffectionModalContent fl_col gp20">
      <div className="content-wrapper fl_col gp16">
        {EtablissemntListMachinesSuper?.map(
          (item: serviceEtablissmentMachineTypeModels.superTypes) => (
            <RadioBoxInput
              className="isRequired"
              label={item?.superType?.designation}
              id={item?.superType?.id}
              accordion
              open
            >
              {item?.machineTypes.map(
                (
                  machineType: serviceEtablissmentMachineTypeModels.machineTypes,
                ) => (
                  <RadioBoxInputOption
                    label={machineType.designation}
                    id={machineType.designation}
                    value={machineType.id}
                    onChange={() =>
                      handleRadioChange && handleRadioChange(machineType.id)
                    }
                    checked={isChecked && isChecked(machineType.id)}
                    name={item?.superType?.designation}
                  />
                ),
              )}
            </RadioBoxInput>
          ),
        )}
      </div>
      <div className="fl_row jc_fe gp20">
        <div className="">
          <ButtonElement
            className="w-full"
            type={'TextButton'}
            text={languageData.fr.Modal.cancel}
            buttonSize={'small'}
            onClick={CloseModal}
          />
        </div>

        <div className="">
          <ButtonElement
            className="w-full"
            type={'TextButton'}
            text="Sauvegarder"
            buttonSize={'small'}
            buttonStyle="btn-primary"
            onClick={onClickSave}
          />
        </div>
      </div>
    </div>
  );
};

export default LicenceAffectionModalContent;
