import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { serviceRolesModels } from '../../models/services/module.role';
import {
  addRoleSuccess,
  deleteRole,
  getRolesListAction,
  getEstablishementRolesListAction,
  resetRolesList,
} from '../../store/actions/actions';
import usePagination from '../../utils/UsePagination';
import { OpenTab } from '../../services/tabs/OpenTab';
import tabs_list from '../../data/tabsList';
import Modal from '../../components/Modal/Modal';
import TableHeader from '../../components/Table/TableHeader';
import Table from '../../components/Table/Table';
import Thead from '../../components/Table/Thead-table';
import Tr from '../../components/Table/Tr-table';
import Td from '../../components/Table/Td-table';
import Tbody from '../../components/Table/Tbody-table';
import AddRoleModal from './AddRole';
import Toast from '../../components/Toast/Toast';
import { languageData } from '../../i18n';

interface UserRolesContainerProps {
  companyId?: number;
  establishmentId?: number;
}
export default function UserRolesContainer(props: UserRolesContainerProps) {
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [showEditRole, setShowEditRole] = useState(false);
  const [showDeleteRole, setShowDeleteRole] = useState(false);
  const [openAddRole, setOpenAddRole] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  // const [isLoading, setIsLoading] = useState(true); // New state for loading indicator
  const [paginationModel, setPaginationModel] =
    useState<servicePaginationModels.PaginationModelType>({
      companyId: props.companyId,
      establishmentId: props.establishmentId,
      paginationModel: {
        page: page,
        pageSize: pageSize,
        nbPage: 0,
      },
    });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetRolesList());
  }, []);
  const HandelDeleteRole = (id: number) => {
    setShowDeleteRole(true);
    setSelectedItemId(id);
  };

  const HandelAddRole = () => {
    setOpenAddRole(true);
  };
  const activeTabContent = useSelector(
    (state: any) => state.tabs.activeTabContent,
  );
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const roles_list = useSelector(
    (state: { role: serviceRolesModels.RolesState }) => state.role.roles,
  );
  const roleList = useMemo(() => {
    if (roles_list) {
      return roles_list.map((item: any) => item);
    }

    return [];
  }, [roles_list]);
  const nbRoles = useSelector(
    (state: { role: serviceRolesModels.RolesState }) => state.role.nbRoles,
  );

  const deleteResponse = useSelector(
    (state: { role: serviceRolesModels.RolesState }) =>
      state.role.deleteResponse,
  );
  const totalPagesNumbers = useSelector(
    (state: { role: serviceRolesModels.RolesState }) =>
      state.role.totalPagesNumbers,
  );
  const addSuccess = useSelector(
    (state: { role: serviceRolesModels.RolesState }) =>
      state.role.addRoleSuccess,
  );

  const fetchRoleslist = async () => {
    // if (activeTabContent?.value?.type.name === 'EtablissementProfileInfo') {
    //   const data = {
    //     ...paginationModel,
    //     establishmentId: activeTabContent?.id,
    //   };
    //   dispatch(getEstablishementRolesListAction(data));
    // } else {
    const data = {
      ...paginationModel,
      //companyId: activeTabContent?.id,
    };
    dispatch(getRolesListAction(data));
    // }
  };

  const {
    handleNextClick,
    handlePreviousClick,

    updatePaginationModel,
  } = usePagination(paginationModel);

  useEffect(() => {
    fetchRoleslist();
  }, [paginationModel]);

  useEffect(() => {
    setPaginationModel({
      companyId: props.companyId,
      establishmentId: props.establishmentId,
      paginationModel: {
        page: updatePaginationModel.page,
        nbPage: updatePaginationModel.nbPage,
        pageSize: updatePaginationModel.pageSize,
      },
    });
    setPage(updatePaginationModel.page);
    setPageSize(updatePaginationModel.pageSize);
  }, [updatePaginationModel]);
  useEffect(() => {
    setPaginationModel((prevPaginationModel: any) => ({
      ...prevPaginationModel.companyId,
      ...prevPaginationModel.paginationModel,
      paginationModel: {
        ...prevPaginationModel.paginationModel,
        nbPage: totalPagesNumbers,
      },
    }));
  }, [totalPagesNumbers]);
  useEffect(() => {
    if (addSuccess) {
      fetchRoleslist();
    }
  }, [addSuccess]);
  const handleDeleteRole = (id: number) => {
    dispatch(deleteRole({ id }));
  };

  const handleClickOpenRolePermissionsDetails = (id: number, name: string) => {
    setSelectedItemId(id);
    OpenTab({
      label: tabs_list[7].name,
      tabs: open_tabs,
      dispatch,
      id: id,
      name: name,
    });
  };

  return (
    <div className="UserRolesContainer">
      {showEditRole && (
        <Modal
          version="custom"
          title={languageData.global.role.titleAddRole}
          //content={<NewRoleModalContent />}
          cancel
          save
          close
          visibility={showEditRole}
          maxWidth={771}
        />
      )}
      {showDeleteRole && (
        <Modal
          version="Alert"
          type="confirm"
          title={languageData.global.role.deleteSure}
          visibility={showDeleteRole}
          cancel
          close={true}
          description={[languageData.global.role.deleteDescription]}
          confirm={true}
          onClickConfirm={() =>
            handleDeleteRole(selectedItemId ? selectedItemId : 0)
          }
        />
      )}
      <AddRoleModal
        openAddRole={openAddRole}
        setOpenAddRole={setOpenAddRole}
        companyId={props.companyId}
        establishmentId={props.establishmentId}
        fetchRoleslist={fetchRoleslist}
        paginationModel={paginationModel}
      />

      <div className="UserRoles-wrapper fl_col gp5">
        <div className="UsersRole-table fl_col gp10">
          <TableHeader
            slot={nbRoles}
            type="add"
            title={languageData.global.TabsTitle.roles}
            description={languageData.global.manageRoles}
            onClickAdd={HandelAddRole}
            textAdd={languageData.global.add}
            className="desktop-table-header"
          />
          <Table className="Table TableUserRoles rd12">
            <Thead>
              <Tr>
                <Td type="text" value={languageData.global.TableHeaders.name} />
                <Td
                  type="text"
                  value={languageData.global.TableHeaders.status}
                  className="status-label"
                />
                <Td type="empty" />
              </Tr>
            </Thead>
            <Tbody>
              {roleList &&
                roleList.map((role, key) => {
                  return (
                    <Tr key={key}>
                      <Td style="bold" type="text" value={role.name} />
                      <Td
                        className="status-role"
                        flex="flex-end"
                        type="status"
                        status={role.active}
                      />
                      <Td
                        type="rolePermission"
                        onClickDeleteRole={() => HandelDeleteRole(role.id)}
                        onClickEditRole={() =>
                          handleClickOpenRolePermissionsDetails(
                            role.id,
                            role.name,
                          )
                        }
                      />
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </div>
        {/* <Pagination
          currentPageNumber={paginationModel.paginationModel.page}
          totalPagesNumbers={paginationModel.paginationModel.nbPage}
          nextpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleNextClick(e)
          }
          prevpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handlePreviousClick(e)
          }
        /> */}
      </div>
      <Toast
        type={deleteResponse === true ? 'success' : 'error'}
        description={deleteResponse ? 'success' : 'error'}
        isVisible={deleteResponse}
        timeout={4000}
      />
    </div>
  );
}
