import * as actionTypes from '../actions/actionTypes';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';

// Initial state
const initialState: serviceEtablissmentMachineTypeModels.MachineTypesState = {
  loading: false,
  data: null,
  error: null,
};

// Reducer function
const machineTypesSuperReducer = (
  state = initialState,
  action: any,
): serviceEtablissmentMachineTypeModels.MachineTypesState => {
  switch (action.type) {
    case actionTypes.AFFECT_MACHINE_TO_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.AFFECT_MACHINE_TO_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response,
      };
    case actionTypes.AFFECT_MACHINE_TO_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default machineTypesSuperReducer;
