import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import { folderAction } from '../../../models/services/module.catalogue';
import {
  getFolderFail,
  getFolderSuccess,
  getSubfolderFail,
  getSubfolderSuccess,
  postFolderSuccess,
  postSubfolderSuccess,
} from '../../actions/catalogues/actions';

export function* startGetFolders(action: folderAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllFolders();
    if (response.data) {
      yield put(getFolderSuccess(response.data?.data?.result));
      action._ ? action._(response.data?.data?.result??[]) : null;
    }
  } catch (error: any) {
    yield put(getFolderFail(error));
  }
}
export function* startPostFolder(action: folderAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.addFolder(action.folder);

    if (response.data) {
      yield put(postFolderSuccess());
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startDeleteFolder(action: folderAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteFolder(
      parseInt(action.id as string),
      action.isSub,
    );
    if (response.data) {
      yield put(postFolderSuccess());
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startGetSubfolders(action: folderAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getFoldersOfFolder(
      action.id,
    );
    if (response.data) {
      yield put(
        getSubfolderSuccess(
          response.data?.data?.result[0].inverseIdParentNavigation,
        ),
      );
      action._
        ? action._(response.data?.data?.result[0].inverseIdParentNavigation??[])
        : null;
    }
  } catch (error: any) {
    yield put(getSubfolderFail(error));
  }
}

export function* startPostSubfolder(action: folderAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.addFolder(action.folder);

    if (response.data) {
      yield put(postSubfolderSuccess());
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
