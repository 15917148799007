import React from 'react';
import ArrowRightIconGrey from '../assets/icons/ArrowRightIconGrey.svg';
import SaveButton from '../assets/icons/SaveButtonGrey.svg';
import SaveButtonActive from '../assets/icons/SaveButtonPrimary.svg';
import CloseBtn from '../assets/icons/CloseBtnBleuIcon.svg';
import DeleteIcon from '../assets/icons/delete-Button.svg';
/**
 * Breadcrumbs component description.
 *
 * @Breadcrumbs
 *
 */
interface BreadcrumbsProps {
  mainpage: string;
  subpage?: string;
  activeSave?: boolean;
  onClickSave?: () => void;
  onClickDelete?: () => void;
  onDelete?: () => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  mainpage,
  subpage,
  activeSave,
  onClickSave,
  onClickDelete,
  onDelete,
}) => {
  return (
    <div className="Breadcrumbs fl_row ai_c pd20-r-l jc_s">
      <div className="page-path fl_row gp8">
        <p className="p14 Nevada-txt">{mainpage}</p>
        {subpage && (
          <>
            <img src={ArrowRightIconGrey} alt="" />
            <p className="p14">{subpage}</p>
          </>
        )}
      </div>
      <div className="actions fl_row gp4">
        {onDelete && <img src={DeleteIcon} alt="" onClick={onDelete} />}
        {onClickSave && (
          <img
            src={activeSave ? SaveButtonActive : SaveButton}
            alt=""
            onClick={activeSave ? onClickSave : undefined}
          />
        )}
        {onClickDelete && <img src={CloseBtn} alt="" onClick={onClickDelete} />}
      </div>
    </div>
  );
};

export default Breadcrumbs;
