import * as actionTypes from '../../actions/catalogues/actionTypes';
const initialState = {
  badges: null,
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SUCCESS_GET_BADGES: {
      return {
        ...state,
        badges: action.badges,
      };
    }
    default:
      return state;
  }
}
export default reducer;
