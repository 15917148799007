import React from 'react';
import SelectInput2 from '../../components/Input/CountrySelect/SelectInput2';
import InputField from '../../components/Input/Input';
import FormCard from '../Etablissement/FormWizzard/FormCard';
import flagsAndNamesSeparator from '../../utils/FlagsAndNamesSeparator';
interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userProfile: {
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
  };
}
export default function ProfileUserUpdate({ onChange, userProfile }: Props) {
  const { countriesCodes, countriePhoneCodes, flags } =
    flagsAndNamesSeparator();
  return (
    <div className="profile-infos fl_col gp20">
      <div className="header-block">
        <p className="p16 w-600 Nevada-txt pd22-t-b">User Profile</p>
      </div>
      <FormCard grid={2} gap="gp20">
        <InputField
          value={userProfile.lastName}
          name="lastName"
          isRequired={true}
          style="form"
          label="Last Name"
          onChange={onChange}
          haserror={!userProfile.lastName}
          errorMsg="This field is required."
        />
        <InputField
          value={userProfile.firstName}
          name="firstName"
          isRequired={true}
          style="form"
          label="First Name"
          onChange={onChange}
          haserror={!userProfile.firstName}
          errorMsg="This field is required."
        />
        <InputField
          value={userProfile.email}
          name="email"
          isRequired={true}
          style="form"
          label="Email"
          onChange={onChange}
            haserror={
              !userProfile.email || !/\S+@\S+\.\S+/.test(userProfile.email)
            }
          errorMsg="This field is required or the email is not valid."
        />
        <SelectInput2
          //defaultValue="+123456789"
          value={userProfile.phoneNumber}
          placeholder="+123456789"
          isRequired={false}
          label="Mobile"
          name="phoneNumber"
          onSelect={() => {
            alert;
          }}
          target="reflags"
          icons={flags}
          reference={countriePhoneCodes}
          options={countriesCodes}
          onChange={onChange}
          isDisabled={false}
          haserror={false}
        />
      </FormCard>
    </div>
  );
}
