import { serviceTaxesModels } from '../models/services/module.taxesInfos';

export const isValidFiscalInformation = (
  verifResponse: serviceTaxesModels.taxesInfosResponse,
) => {
  return (
    verifResponse?.siretExist === false &&
    verifResponse?.siretValid === true &&
    verifResponse?.tvaExist === false
  );
};
export const isErrorConditionMet = (
  verifResponse: serviceTaxesModels.taxesInfosResponse,
) => {
  return (
    verifResponse?.siretExist === true ||
    verifResponse?.siretValid === false ||
    verifResponse?.tvaExist === true
  );
};
export const isValidFiscalInformationCompany = (
  verifResponse: serviceTaxesModels.taxesInfosResponse,
) => {
  return (
    verifResponse?.sirenValid === true 
  );
};