import React, { ReactNode, useState } from 'react';
import BottomArrowGreyIcon from '../assets/icons/BottomArrowGreyIcon.svg';
interface CardContainerProps {
  children: ReactNode;
  ClassName?: string;
  type?: 'form' | 'standard';
  collapse?: boolean;
  label?: string;
}

const CardContainer: React.FC<CardContainerProps> = ({
  children,
  ClassName,
  collapse,
  type,
  label,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed((prevState) => !prevState);
  };
  return (
    <div
      className={`CardContainer ${
        collapse ? 'fl_col gp12' : ''
      } ${isCollapsed ? 'collapsed' : ''} ${type} ${
        ClassName ? ClassName : ''
      }`}
    >
      {collapse && (
        <div className="label fl_row gp20" onClick={handleCollapseToggle}>
          <img src={BottomArrowGreyIcon} alt="Toggle collapse" />
          <p className="p16 w-700">{label}</p>
        </div>
      )}
      {children}
    </div>
  );
};

export default CardContainer;
