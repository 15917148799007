import React from 'react';
import { ModalProps } from '../../../models/services/module.modals';
import UploadFileContainer from '../../../container/Catalogue/UploadFile';

export default function ModalUploadFileController({
  closeModal,
  onSelectImage,
}: ModalProps) {
  const modalContentBody = (
    <UploadFileContainer onClose={closeModal} onSelectImage={onSelectImage} />
  );
  return {modalContentBody}
}
