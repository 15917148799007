import React from 'react';
import CreditCardForm from '../../CreditCard/CreditCardForm';
import { languageData } from '../../../i18n';

/**
 * ValidatePayModalContent component description.
 *
 * @ValidatePayModalContent
 *
 */

interface ValidatePayModalContentProps {
  total: number;
  prefix: string;
  invoiceNumber?: number;
  onClickPay?: () => void;
}

const ValidatePayModalContent: React.FC<ValidatePayModalContentProps> = ({
  total,
  prefix,
  onClickPay,
  invoiceNumber,
}) => {
  return (
    <div className="ValidatePayModalContent">
      <div className="content-wrapper fl_row gp20">
        <div className="left-side fl-1 fl_col gp30">
          <div className="fl_col pd20-l">
            <p className="title-side p16 OxfordBlue01-txt w-700">
              Factures FIO{invoiceNumber}
            </p>
            <div className="fl_col gp10">
              <p className="p12">TOTAL TTC</p>
              <p className="price p20 w-900 fl_row ai_e gp5">
                {' '}
                <span>
                  <b>{prefix}</b> {total}
                </span>{' '}
                <span className="w-400 Nevada-txt p16">/mois</span>
              </p>
            </div>
          </div>
          <p className="p12 Nevada-txt">
            En confirmant votre abonnement, vous autorisez FRENCHINNOV à débiter
            votre compte pour les paiements à venir, conformément à ses
            conditions.
          </p>
        </div>
        <div className="right-side fl-1">
          <p className="title-side p16 w-700 OxfordBlue01-txt">
            Coordonnées bancaire
          </p>
          <div className="fl_col">
            <CreditCardForm
              errorText={languageData.global.credit_card_rejected}
              onClickPay={onClickPay}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatePayModalContent;
