import React from 'react';
import ModalAddEtape from '../ModalAddEtape';
import { ModalProps } from '../../../models/services/module.modals';
export default function AddEtapeModalController(props: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper fl_col gp5">
      <ModalAddEtape
      hideNbrMax={props.hideNbrMax}
        onClickPurshaseMethod={props.onClickPurshaseMethod}
        purshaseMethods={props.purshaseMethods}
        articleChanges={props.articleChanges}
        onChangeFieldArticleLevel={props.onChangeFieldArticleLevel}
        screens={props.screens}
      />
    </div>
  );
  const modalContentFooter = (
    <div className="wrapperFooter">
      <div className="fl_row jc_fe gp12">
        {props.cancel && (
          <button
            className="btn medium btn-primary-light"
            onClick={props.closeModal}
          >
            {'Annuler'}
          </button>
        )}
        {props.confirm && (
          <button
            className={`${props.articleChanges?.purshaseMethod?.length == 0 ? 'btn medium btn-disabledGrey' : 'btn medium btn-primary'}`}
            disabled={props.articleChanges?.purshaseMethod?.length == 0}
            onClick={props.handleSave}
          >
            {props.saveTxt}
          </button>
        )}
      </div>
    </div>
  );
  return { modalContentBody, modalContentFooter };
}
