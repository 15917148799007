import React from 'react';
import PlusIconPrimary from '../../../assets/icons/PlusIconPrimary.svg';
/**
 * HeaderTypeMachine component description.
 *
 * @HeaderTypeMachine
 *
 */

interface HeaderTypeMachineProps {
  title: string;
  slot?: number;
  onClickAdd?: () => void;
}

const HeaderTypeMachine: React.FC<HeaderTypeMachineProps> = ({
  title,
  slot,
  onClickAdd,
}) => {
  return (
    <div className="HeaderTypeMachine fl_row ai_c jc_s w-full">
      <div className="label-type fl_row ai_c gp10">
        <p className="p18 DarkBlueGray-txt w-500">{title}</p>
        <span className="slot">{slot ? slot : 0}</span>
      </div>
      <div className="btn-add-type fl_row ai_c" onClick={onClickAdd}>
        <img src={PlusIconPrimary} alt="" />
        <p className="p14">Nouveau</p>
      </div>
    </div>
  );
};

export default HeaderTypeMachine;
