import React from 'react';
import EditElementCard from '../../../assets/icons/EditElementCard.svg';
import BinIconRed from '../../../assets/icons/BinIconRed.svg';
/**
 * TypeMachineICard component description.
 *
 * @TypeMachineICard
 *
 */

interface TypeMachineICardProps {
  label?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  affected?: boolean;
}

const TypeMachineICard: React.FC<TypeMachineICardProps> = ({
  label,
  onClickEdit,
  onClickDelete,
  affected,
}) => {
  return (
    <div className="TypeMachineICard fl_row jc_s ai_c">
      <div className="fl_row gp10 ai_c">
        {affected && (
          <div className="status-affected">
            <span></span>
          </div>
        )}
        <label className="p16 w-900">{label}</label>
      </div>
      <div className="tools fl_row gp10 ai_c">
        {onClickDelete && !affected && (
          <img
            className="c-p"
            src={BinIconRed}
            alt=""
            onClick={onClickDelete}
          />
        )}
        {onClickEdit && (
          <img
            className="c-p"
            src={EditElementCard}
            alt=""
            onClick={onClickEdit}
          />
        )}
      </div>
    </div>
  );
};

export default TypeMachineICard;
