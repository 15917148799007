import React from 'react';
import { languageData } from '../../i18n';
import MarketplaceIconGrey from '../../assets/icons/MarketplaceIconGrey.svg';
import FilterDropdownIcon from '../../assets/icons/FilterDropdownIcon.svg';
import PlusIconBlack from '../../assets/icons/PlusIconBlack.svg';
import TrashIconRed from '../../assets/icons/TrashIconRed.svg';
import PermissionValue from '../../components/Permissions/PermissionItem';
import { useDispatch, useSelector } from 'react-redux';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import { calculateTotalPermissionProperty } from '../../utils/roleUtils';
import { serviceRolesModels } from '../../models/services/module.role';
import { setUserFormData } from '../../store/actions/actions';
import Modal from '../../components/Modal/Modal';
interface rowProps {
  rowId: string;
  showRoleModal: () => void;
  showmodalAccess: (rowId: string) => void;
  handleAddPermissionRow: () => void;
  handleDeletePermissionRow: () => void;
  checker?: boolean;
  roles?: serviceRolesModels.Role[];
}
export default function PermissionRoleRow(props: rowProps) {
  const [rowId, setRowId] = React.useState<string>('1');
  const [modalDelete, SetmodalDelete] = React.useState<boolean>(false);
  const [roleToDelete, setRoleToDelete] = React.useState<string>();
  const dispatch = useDispatch();

  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormData,
  );
  const userFormValidationRequested = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormValidationRequested,
  );
  const enableAccessField = () => {
    const enabled =
      userFormData?.permissions[0]?.rolesId[0] !== '0' ? true : false;
    return enabled;
  };
  const hasError = (type: 'access' | 'role') => {
    if (userFormValidationRequested) {
      const currentPermissions = userFormData.permissions.find(
        (item) => item.rowId === props.rowId,
      );

      if (!currentPermissions) {
        return false;
      }

      switch (type) {
        case 'role':
          // Check if there are no roles or the first role is '0'
          return (
            currentPermissions.rolesId.length === 0 ||
            currentPermissions.rolesId[0] === '0'
          );

        case 'access':
          // Check if both chainesIds and restaurantsIds are empty
          return (
            currentPermissions.chainesIds.length === 0 &&
            currentPermissions.restaurantsIds.length === 0
          );

        default:
          return false;
      }
    } else {
      return false;
    }
  };
 
  const showmodalDelete = (id: string) => {
    SetmodalDelete((prev) => !prev);
    setRowId(rowId);
    setRoleToDelete(id);
  };
  const handleDeleteRole = () => {
    const updatedPermissions = userFormData?.permissions.map((row) => {
      if (row.rowId === rowId) {
        const updatedRoles = row.rolesId.filter(
          (role) => role !== roleToDelete,
        );
        return { ...row, rolesId: updatedRoles };
      }
      return row;
    });
    const updatedForm = {
      ...userFormData,
      permissions: updatedPermissions,
    };
    dispatch(setUserFormData(updatedForm));
    SetmodalDelete((prev) => !prev);
  };
  return (
    <div className="AddUser">
      <div className="permissions-listing fl_col gp20 pd8-r-l">
        <div className="permission-card fl_row gp20">
          <div className="fields-wrapper fl_row gp40">
            <div className="role-field role-row fl_col gp5">
              <div
                className={`field-container fl_row gp4 fl_wrap fl-1 ${hasError('role') ? 'error-table' : ''}`}
              >
                {(() => {
                  const currentPermissions = userFormData.permissions.find(
                    (item) => item.rowId === props.rowId,
                  );

                  if (!currentPermissions) {
                    return null;
                  }

                  const rolesToDisplay = currentPermissions.rolesId
                    .slice(0, 2)
                    .map((roleId) =>
                      props.roles?.find((role) => role.id === roleId),
                    );

                  const rolesToDisplayLength =
                    currentPermissions.rolesId.length - 2;

                  return (
                    <>
                      {rolesToDisplay.map(
                        (role) =>
                          role && (
                            <PermissionValue
                              key={role.id}
                              type="role"
                              name={role.name}
                              onClickRemove={() => showmodalDelete(role.id)}
                            />
                          ),
                      )}
                      {rolesToDisplayLength > 0 ? (
                        <span
                          style={{ marginRight: '10px' }}
                          onClick={props.showRoleModal}
                          className="rolesToDisplay p12 slot  c-p"
                        >
                          {rolesToDisplayLength} +
                        </span>
                      ) : undefined}
                    </>
                  );
                })()}

                <div className="showmore" onClick={props.showRoleModal}>
                  <img src={FilterDropdownIcon} alt="" width={15} />
                </div>
              </div>
              
            {hasError('role') && (
              <p className="p12 errorMsg pd10-l">Ce champs est obligatoire</p>
            )}
            </div>
          </div>
          <div
            className={`access-field access-row fl_col gp5 fl-1 ${!enableAccessField() ? 'fullyDisabled' : ''}`}
          >
            <div className="field-wrapper fl_row gp40 h-full jc_s w-full gp5">
              <div
                className={`field-container fl_row gp4 fl_wrap fl-1 ${hasError('access') ? 'error-table' : ''}`}
              >
                <PermissionValue
                  type="access"
                  slot={calculateTotalPermissionProperty(
                    props.rowId,
                    userFormData.permissions,
                    'chainesIds',
                  )}
                  name={languageData.global.user.Entreprises}
                  icon={MarketplaceIconGrey}
                />
                <PermissionValue
                  type="access"
                  slot={calculateTotalPermissionProperty(
                    props.rowId,
                    userFormData.permissions,
                    'restaurantsIds',
                  )}
                  name={languageData.global.user.Etablissements}
                  icon={MarketplaceIconGrey}
                />
                <div
                  className="showmore"
                  onClick={() => props.showmodalAccess(props.rowId)}
                >
                  <img src={FilterDropdownIcon} alt="" width={15} />
                </div>
              </div>
              <div className="tools fl_row gp10">
                {props.rowId == '1' && enableAccessField() ? (
                  <img
                    title="Ajouter nouveau role"
                    className="add c-p"
                    src={PlusIconBlack}
                    alt=""
                    width={20}
                    onClick={props.handleAddPermissionRow}
                  />
                ) : (
                  <></>
                )}
                {props.rowId !== '1' ? (
                  <img
                    className="delete c-p"
                    src={TrashIconRed}
                    alt=""
                    width={20}
                    onClick={props.handleDeletePermissionRow}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            {hasError('access') && (
              <p className="p12 errorMsg pd10-l">Ce champs est obligatoire</p>
            )}
          </div>
        </div>
        <Modal
          description={[languageData.global.user.deleteDescription]}
          title={languageData.global.user.deleteConfirm}
          visibility={modalDelete}
          maxWidth={544}
          height={181}
          version={'Alert'}
          onClickConfirm={handleDeleteRole}
          onClickClose={() => SetmodalDelete((prev) => !prev)}
          confirm
          cancel
        />
      </div>
    </div>
  );
}
