import { ChangeEvent, useEffect, useState } from 'react';
import {
  NotificationErrorProvider,
  NotificationSuccessProvider,
} from '../../utils/Notificationprovider';
import { ArticleAllergen, Image } from '../../models/services/module.catalogue';
import { useDispatch } from 'react-redux';
import { scrollToBottomOfTable } from '../../utils/catalogueHelper/articleHelper';
import {
  allergenDragged,
  getAllergen,
  postAllergen,
  updateAllergens,
  updateDeletedAlgs,
} from '../../store/actions/catalogues/actions';
import { useSelector } from 'react-redux';
import AllergenesNav from '../../components/Catalogue/navbar/AllergenesNav';
import { languageData } from '../../i18n';
import Modal from '../../components/Modal/Catalogue/CatalogueModal';
import { useLocation } from 'react-router-dom';
export default function AllergenesNavbarContainer() {
  const [uplaodImage, setuplaodImage] = useState(false);
  const allergenes = useSelector(
    (state: { allergenesStates: { allergenes: ArticleAllergen[] } }) =>
      state.allergenesStates.allergenes,
  );
  const [selectedAllergene, setselectedAllergene] =
    useState<Partial<ArticleAllergen>>();
  const allergernChange = useSelector(
    (state: { allergenesStates: { allergernChange: number } }) =>
      state.allergenesStates.allergernChange,
  );
  const [EditModal, setShowEditModal] = useState(false);
  const [allerges, setallerges] = useState<ArticleAllergen[]>();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathEdit = location.pathname.includes('edit');
  const deletedAllergenes = useSelector(
    (state: { allergenesStates: { deletedAllergenes: ArticleAllergen[] } }) =>
      state.allergenesStates.deletedAllergenes,
  );
  const withDisabledAllerges = (
    allergenes: ArticleAllergen[],
    allergenesSaved: ArticleAllergen[],
  ): ArticleAllergen[] =>
    allergenesSaved?.map((allergen) => ({
      ...allergen,
      selected: false,
      disabled: new Set(allergenes?.map((alg) => alg.idAllergen ?? alg.id)).has(
        allergen.idAllergen ?? allergen.id,
      ),
    }));
  const fetchAllerges = (callBack?: (arr: ArticleAllergen[]) => void) => {
    try {
      dispatch(
        getAllergen((_) => {
          !callBack
            ? setallerges(
                withDisabledAllerges(allergenes, _ as ArticleAllergen[]),
              )
            : callBack(_ as ArticleAllergen[]);
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  useEffect(() => {
    setallerges(
      withDisabledAllerges(allergenes, allerges as ArticleAllergen[]),
    );
  }, [allergenes]);

  useEffect(() => {
    fetchAllerges();
  }, []);
  const onDrag = (alg: ArticleAllergen) => {
    let existAllergen = deletedAllergenes.findIndex(
      (_) => _.idAllergen == alg.id,
    );
    dispatch(
      allergenDragged(
        existAllergen > -1 ? deletedAllergenes[existAllergen] : alg,
      ),
    );
    dispatch(
      updateDeletedAlgs(
        deletedAllergenes.filter((_) => _.idAllergen != alg.id),
      ),
    );
  };
  const onDragSelected = (allerges: ArticleAllergen[]) => {
    let dragged: ArticleAllergen[] = [];
    allerges.forEach((alg) => {
      let existAllergen = deletedAllergenes.findIndex(
        (_) => _.idAllergen == alg.id,
      );
      dragged.push(existAllergen > -1 ? deletedAllergenes[existAllergen] : alg);
    });
    dispatch(updateAllergens([...allergenes, ...dragged]));
    let updatedDeleted = deletedAllergenes.filter(
      (_) =>
        dragged.findIndex(
          (__) => (__.idAllergen ?? __.id) != (__.idAllergen ?? __.id),
        ) > -1,
    );
    dispatch(updateDeletedAlgs(updatedDeleted));
  };
  useEffect(() => {
    if (allergernChange > 0)
      setTimeout(() => {
        scrollToBottomOfTable('allergenesTable');
      }, 100);
  }, [allergernChange]);
  const onSelect = (
    article: ArticleAllergen,
    checked: boolean,
    all?: boolean,
  ) => {
    if (allerges)
      setallerges(
        all
          ? allerges.map((c) => ({ ...c, selected: checked }))
          : allerges.map((c) =>
              c.id === article.id ? { ...c, selected: checked } : c,
            ),
      );
  };
  const onSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    fetchAllerges((arr) =>
      setallerges(
        withDisabledAllerges(
          allergenes,
          arr.filter((a) => a.name.toLowerCase().includes(value.toLowerCase())),
        ),
      ),
    );
  };

  const closeAllModal = () => {
    setselectedAllergene(undefined);
    setShowEditModal(false);
  };
  const handleChangeEditAllergen = (
    key: keyof ArticleAllergen,
    value: string,
  ) => {
    const updatedAllergen: Partial<ArticleAllergen> = { ...selectedAllergene };
    (updatedAllergen as any)[key] = value;
    setselectedAllergene(updatedAllergen as ArticleAllergen);
  };
  const handleEditAllergene = async () => {
    try {
      dispatch(
        postAllergen(selectedAllergene as ArticleAllergen, () => {
          NotificationSuccessProvider('add','Allergène');
          fetchAllerges();
          closeAllModal();
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };

  const onOpenfileManager = () => {
    setuplaodImage(true);
  };
 
  const handleOpenNewAlg = () => {
    setShowEditModal(true);
  };
  const closeModal = () => {
    setuplaodImage(false);
  };
  const onSelectImage = (img: Image) => {
    console.log({ img });

    let a = selectedAllergene as ArticleAllergen;
    a = {
      ...a,
      idImageUpload: img.id,
      url: img.url,
    };

    setselectedAllergene(a);
    setuplaodImage(false);
  };
  return (
    <div className="h-full">
      <AllergenesNav
        onNewClick={handleOpenNewAlg}
        allChecked={
          allerges?.filter((article) => article.selected).length ==
          allerges?.length
        }
        onSearch={onSearch}
        topAddEnabled={
          allerges && allerges?.filter((ar) => ar.selected).length > 0
        }
        onDragSelected={() =>
          onDragSelected(
            (allerges as ArticleAllergen[]).filter((ar) => ar.selected),
          )
        }
        onSelect={onSelect}
        onDrag={onDrag}
        allerges={allerges as ArticleAllergen[]}
      ></AllergenesNav>
      <Modal
        closeModal={closeAllModal}
        onChange={(e) => handleChangeEditAllergen('name', e.target.value)}
        version="Item"
        type="create"
        target="allergen"
        title={languageData.fr.modalsCatalogues.addAllergen}
        close={true}
        saveTxt={languageData.fr.Modal.save}
        save={true}
        cancel={true}
        cancelTxt={languageData.fr.Modal.cancel}
        visibility={EditModal}
        deleteitem={true}
        deleteitemTxt={languageData.fr.Modal.delete}
        itemName={selectedAllergene?.name}
        itemNameLabel={languageData.fr.modalsCatalogues.designation}
        itemImg={selectedAllergene ? selectedAllergene.url : ''}
        handleSave={handleEditAllergene}
        editItem={onOpenfileManager}
      />
      <Modal
        closeModal={closeModal}
        onSelectImage={onSelectImage}
        version={'uploadFile'}
        type={'upload'}
        title={languageData.fr.fileManager.fileManager}
        visibility={uplaodImage}
        close
      />
    </div>
  );
}
