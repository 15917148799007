import React, { useState } from 'react';
import InactiveStatusBlack from '../../assets/icons/InactiveStatusBlack.svg';
import PendingIconBlack from '../../assets/icons/PendingIconBlack.svg';
import ActiveIconStatusGreen from '../../assets/icons/ActiveIconStatusGreen.svg';
import WarningIconOrange from '../../assets/icons/WarningIconOrange.svg';
import ButtonElement from '../button/button';
import { languageData } from '../../i18n';

/**
 * ToggleButton component description.
 *
 * @ToggleButton
 *
 */

interface ToggleButtonProps {
  statusToggle?: 'inactive' | 'pending' | 'active';
  onClick?: () => void;
  enablePopupActivation?: boolean;
  onClickvalidate?: () => void;
  isLoading?: boolean;
  errorState?: boolean;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  statusToggle,
  onClick,
  enablePopupActivation,
  onClickvalidate,
  isLoading,
  errorState,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const HandlePopup = () => {
    setShowPopup((prevState) => !prevState);
  };
  const ToggleContent = () => {
    switch (statusToggle) {
      case 'inactive':
        return (
          <>
            <div className="toggleWrapper fl_row gp4" onClick={HandlePopup}>
              <p className="p14">Activer</p>
              <img src={InactiveStatusBlack} alt="" />
            </div>
            {enablePopupActivation && showPopup && (
              <div className="validation-popup">
                <div className="popup-wrapper fl_row ai_s gp16">
                  <img src={WarningIconOrange} alt="" />
                  <div className="fl_col jc_s gp32">
                    <div className="fl_col gp4">
                      <p className="p18">
                        {languageData.fr.Abonnements.activatedAbonnment}{' '}
                      </p>
                      <p className="p14">
                        {languageData.fr.Abonnements.abonnmentDescription}
                      </p>
                    </div>
                    <div className="footer-wrapper fl_row jc_fe gp20">
                      <div className="fl-1">
                        <ButtonElement
                          type={'TextButton'}
                          text="Annuler"
                          buttonSize={'medium'}
                          buttonStyle="btn-primary-light"
                          onClick={HandlePopup}
                        />
                      </div>
                      <div className="fl-2" onClick={onClick}>
                        <ButtonElement
                          type={'TextButton'}
                          text="Valider"
                          buttonSize={'medium'}
                          buttonStyle="btn-primary"
                          onClick={onClickvalidate}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                    {errorState && (
                      <p style={{ color: 'red', textAlign: 'center' }}>
                        {languageData.fr.messages.failure}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        );

      case 'pending':
        return (
          <div className="toggleWrapper fl_row gp4">
            <p className="p14">{languageData.fr.Abonnements.pending}</p>
            <img src={PendingIconBlack} alt="" />
            <div className="tooltip">
              <p className="p10">{languageData.fr.Abonnements.En_attente}</p>
            </div>
          </div>
        );

      case 'active':
        return (
          <div className="toggleWrapper fl_row gp4" onClick={HandlePopup}>
            <p className="p14">{languageData.fr.Abonnements.activate}</p>
            <img src={ActiveIconStatusGreen} alt="" />
            <div className="tooltip">
              <p className="p10">
                {languageData.fr.Abonnements.activatedAbonnment}
              </p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="ToggleButton">
      <div className={`type-btn fl_row ai_c type-${statusToggle}`}>
        {ToggleContent()}
      </div>
    </div>
  );
};

export default ToggleButton;
