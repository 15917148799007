import { AxiosResponse } from 'axios';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import {
  MenuPayload,
  menuAction,
} from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
export function* startGetAllMenu(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllMenusList();
    if (response.data) {
      action._ && action._(response.data?.data?.result ?? []);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startGetAllMenuStock(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllMenusListStock({
      IdEstablishment: action?.IdEstablishment as number,
      EtatStock: action?.EtatStock as number,
    });
    if (response.data) {
      action._ && action._(response.data?.data?.result ?? []);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startPostMenu(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postMenu(
      action.menu as MenuPayload,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error) {
    console.log('ddddddd');
    NotificationErrorProvider(error);
  }
}
export function* startGetMenubyId(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getMenuById(
      action.id ?? '',
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startDeleteMenubyId(action: menuAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteMenuById(
      action.id ?? '',
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
