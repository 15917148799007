import { Dispatch } from 'redux';
import { serviceTabsModels } from '../../models/services/module.tabs';
import {
  addTab,
  selectTab,
  setActiveTab,
  setActiveTabContent,
  updateTabs,
} from '../../store/actions/actions';
import tabs_list from '../../data/tabsList';
import { debug } from 'console';

interface WithUpdateTabProps {
  tabIndex: number;
  label?: string;
  dispatch: Dispatch;
  updatedTabs: serviceTabsModels.Tab[];
  id?: number;
  name?: string;
  miniTab?: string;
  companyName?: string;
  companyId?: string;
  isEtablishement?: boolean;
}
export const WithUpdateTab = (props: WithUpdateTabProps) => {
  const body = tabs_list.find((tab) => tab.name === props.label);

  const getTabName = (label?: string, id?: number, name?: string): string =>
    name
      ? name
      : `${label ?? ''}${props.companyName ? ' - ' + props.companyName : ''}`;

  props.dispatch(updateTabs(props.updatedTabs));
  if (!body) {
    return;
  }
  {
    props.tabIndex === -1
      ? props.dispatch(
          addTab({
            ...body,
            id: props.id,
            miniTab: props.miniTab,
            name: getTabName(props.label, props.id, props.name),
            isActive: true,
            isChanged: false,
            companyId: props.companyId,
            isEtablishement: props.isEtablishement,
            tabContent: {
              ...body.tabContent,
              key: props.companyName ?? body.tabContent.key,
            },
          }),
        )
      : null;
  }
  props.dispatch(
    selectTab({
      id: props.id,
      name: body?.name,
    }),
  );

  props.dispatch(
    setActiveTab({
      id: props.id,
      name: body?.name,
      tabContent: {
        ...body.tabContent,
        key: props.companyName ?? props.name ?? body.name,
      },
    }),
  );
  props.dispatch(
    setActiveTabContent({
      id: props.id,
      miniTab: props.miniTab,
      key: props.companyName ?? props.name ?? body.name,
      value: body?.tabContent?.value,
      companyId: props.companyId,
      isEtablishement: props?.isEtablishement,
    }),
  );
};
