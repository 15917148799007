import React from 'react';
import CheckBox from '../../../components/Catalogue/Checkbox';

/**
 * ItemStock component description.
 *
 * @ItemStock
 *
 */

interface ItemStockProps {
  name: string;
  status?: 'exhausted' | 'sold-out-today' | 'in-stock';
  onClickCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected?: boolean;
  categorie?: boolean;
  visible?: boolean;
  className?: string;
}

const ItemStock: React.FC<ItemStockProps> = ({
  name,
  status,
  onClickCheck,
  selected,
  categorie,
  visible,
  className,
}) => {
  return (
    <div className={`ItemStock rd8 ${className}`}>
      <div
        className={`item-wrapper fl_row ai_c  h-full gp16 pd8-t-b ${status + '-type'}`}
      >
        <div className="fl_col gp4 fl-1">
          <div className="fl_row ai_c gp10 header-card pd8-r-l">
            <CheckBox
              boxType="squareBox"
              onChange={onClickCheck}
              isChecked={selected}
            />
            <p className="p17 w-500 Independence-txt mx1">{name}</p>
          </div>
          {(status || categorie) && (
            <div className="fl_row jc_fe pd8">
              <span
                className={`p12 w-500 status-card ${categorie ? (visible ? 'in-stock' : 'exhausted') : status}`}
              >
                {categorie
                  ? visible
                    ? 'Visible'
                    : 'Invisible'
                  : status === 'exhausted'
                    ? 'Epuisé'
                    : status == 'sold-out-today'
                      ? 'Epuisé Aujourd’hui'
                      : 'En Stock'}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemStock;
