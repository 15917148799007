export const START_GET_BADGES = 'START_GET_BADGES';
export const FAIL_GET_BADGES = 'FAIL_GET_BADGES';
export const SUCCESS_GET_BADGES = 'SUCCESS_GET_BADGES';
export const POST_BADGE = 'POST_BADGE';
export const POST_BADGE_SUCCESS = 'POST_BADGE_SUCCESS';
export const POST_BADGE_FAIL = 'POST_BADGE_FAIL';
// allergen actions
export const START_GET_ALLERGEN = 'START_GET_ALLERGEN';
export const FAIL_GET_ALLERGEN = 'FAIL_GET_ALLERGEN';
export const SUCCESS_GET_ALLERGEN = 'SUCCESS_GET_ALLERGEN';
export const POST_ALLERGEN = 'POST_ALLERGEN';
export const DELETE_ALLERGEN = 'DELETE_ALLERGEN';
export const POST_ALLERGEN_SUCCESS = 'POST_ALLERGEN_SUCCESS';
export const POST_ALLERGEN_FAIL = 'POST_ALLERGEN_FAIL';
// tva actions
export const START_GET_TVA = 'START_GET_TVA';
export const FAIL_GET_TVA = 'FAIL_GET_TVA';
export const SUCCESS_GET_TVA = 'SUCCESS_GET_TVA';
export const POST_TVA = 'POST_TVA';
export const DELETE_TVA = 'DELETE_TVA';
export const POST_TVA_SUCCESS = 'POST_TVA_SUCCESS';
export const POST_TVA_FAIL = 'POST_TVA_FAIL';
// tag actions
export const START_GET_TAG = 'START_GET_TAG';
export const FAIL_GET_TAG = 'FAIL_GET_TAG';
export const SUCCESS_GET_TAG = 'SUCCESS_GET_TAG';
export const POST_TAG = 'POST_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const POST_TAG_SUCCESS = 'POST_TAG_SUCCESS';
export const POST_TAG_FAIL = 'POST_TAG_FAIL';
//family actions
export const START_GET_FAMILY = 'START_GET_FAMILY';
export const FAIL_GET_FAMILY = 'FAIL_GET_FAMILY';
export const SUCCESS_GET_FAMILY = 'SUCCESS_GET_FAMILY';
export const POST_FAMILY = 'POST_FAMILY';
export const DELETE_FAMILY = 'DELETE_FAMILY';
export const POST_FAMILY_SUCCESS = 'POST_FAMILY_SUCCESS';
export const POST_FAMILY_FAIL = 'POST_FAMILY_FAIL';
// subFamily actions
export const START_GET_SUBFAMILY = 'START_GET_SUBFAMILY';
export const FAIL_GET_SUBFAMILY = 'FAIL_GET_SUBFAMILY';
export const SUCCESS_GET_SUBFAMILY = 'SUCCESS_GET_SUBFAMILY';
export const POST_SUBFAMILY = 'POST_SUBFAMILY';
export const DELETE_SUBFAMILY = 'DELETE_SUBFAMILY';
export const POST_SUBFAMILY_SUCCESS = 'POST_SUBFAMILY_SUCCESS';
export const POST_SUBFAMILY_FAIL = 'POST_SUBFAMILY_FAIL';
// folder actions
export const START_GET_FOLDER = 'START_GET_FOLDER';
export const FAIL_GET_FOLDER = 'FAIL_GET_FOLDER';
export const SUCCESS_GET_FOLDER = 'SUCCESS_GET_FOLDER';
export const POST_FOLDER = 'POST_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const POST_FOLDER_SUCCESS = 'POST_FOLDER_SUCCESS';
export const POST_FOLDER_FAIL = 'POST_FOLDER_FAIL';
// subfolder actions
export const START_GET_SUBFOLDER = 'START_GET_SUBFOLDER';
export const FAIL_GET_SUBFOLDER = 'FAIL_GET_SUBFOLDER';
export const SUCCESS_GET_SUBFOLDER = 'SUCCESS_GET_SUBFOLDER';
export const POST_SUBFOLDER = 'POST_SUBFOLDER';
export const DELETE_SUBFOLDER = 'DELETE_SUBFOLDER';
export const POST_SUBFOLDER_SUCCESS = 'POST_SUBFOLDER_SUCCESS';
export const POST_SUBFOLDER_FAIL = 'POST_SUBFOLDER_FAIL';
// article actions
export const START_GET_ARTICLE = 'START_GET_ARTICLE';
export const FAIL_GET_ARTICLE = 'FAIL_GET_ARTICLE';
export const SUCCESS_GET_ARTICLE = 'SUCCESS_GET_ARTICLE';
export const POST_ARTICLE = 'POST_ARTICLE';
export const POST_ARTICLE_SUCCESS = 'POST_ARTICLE_SUCCESS';
export const POST_ARTICLE_FAIL = 'POST_ARTICLE_FAIL';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const START_GET_ARTICLE_ID = 'START_GET_ARTICLE_ID';
//Search
export const CHANGE_SEARCH_FILED = 'CHANGE_SEARCH_FILED';
export const CLEAR_SEARCH_FILED = 'CLEAR_SEARCH_FILED';
//New Modal
export const NEW_MODAL_SHOWN = 'NEW_MODAL_SHOWN';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const CART_UPDATE="CART_UPDATE"
//compositions
export const DRAG_NEW_COMPOSITION = 'DRAG_NEW_COMPOSITION';
export const unDRAG_NEW_COMPOSITION = 'unDRAG_NEW_COMPOSITION';
export const UPDATE_COMPOSITIONS = 'UPDATE_COMPOSITIONS';
export const SELECT_COMPOSITION = 'SELECT_COMPOSITION';
export const UNDRAG_ALL_COMPOSITION = 'UNDRAG_ALL_COMPOSITION';
//UI
export const CHANGE_SIDE_BAR_MODE = 'CHANGE_SIDE_BAR_MODE';
export const CHANGE_SIDE_BAR_MENU_MODE = 'CHANGE_SIDE_BAR_MENU_MODE';
export const REDUCE_MAIN_SIDE_BAR="REDUCE_MAIN_SIDE_BAR"
export const SET_COLOR_PICKER="SET_COLOR_PICKER"

//ALLERGERNS CONFIG
export const ALLERGEN_DRAGGED = 'ALLERGEN_DRAGGED';
export const ALLERGEN_UNDRAGGED = 'ALLERGEN_UNDRAGGED';
export const UPDATE_ALLERGENS = 'UPDATE_ALLERGENS';
export const UPDATE_DELETED_ALGS = 'UPDATE_DELETED_ALGS';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const UPDATE_DELETED_INGREDIENTS = 'UPDATE_DELETED_INGREDIENTS';
//CLEAR
export const CLEAR_STORE_ITEM = 'CLEAR_STORE_ITEM';
//IMAGE
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';
export const GET_IMAGE = 'GET_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
//PURSHASE METHOD
export const START_GET_PURSAHSE_METHOD = 'START_GET_PURSAHSE_METHOD';
export const PURSAHSE_METHOD_SUCCESS = 'PURSAHSE_METHOD_SUCCESS';
export const PURSAHSE_METHOD_FAIL = 'PURSAHSE_METHOD_FAIL';
//SCREEN
export const START_GET_SCREEN = 'START_GET_SCREEN';
export const START_GET_SCREEN_SUCCESS = 'START_GET_SCREEN_SUCCESS';
export const START_GET_SCREEN_FAIL = 'START_GET_SCREEN_FAIL';
export const START_POST_SCREEN = 'START_POST_SCREEN';
export const START_POST_SCREEN_SUCCESS = 'START_POST_SCREEN_SUCCESS';
export const START_POST_SCREEN_FAIL = 'START_POST_SCREEN_FAIL';
export const START_DELETE_SCREEN = 'START_DELETE_SCREEN';
export const START_GET_SCREEN_BY_ID = 'START_GET_SCREEN_BY_ID';
//levels
export const START_GET_LEVELS = 'START_GET_LEVELS';
export const START_POST_LEVELS = 'START_POST_LEVELS';
export const START_DELETE_LEVELS = 'START_DELETE_LEVELS';
export const START_GET_LEVEL_BY_ID = 'START_GET_LEVEL_BY_ID';
//MENUS
export const START_GET_MENUS = 'START_GET_MENUS';
export const START_POST_MENUS = 'START_POST_MENUS';
export const START_DELETE_MENUS = 'START_DELETE_MENUS';
export const START_GET_MENU_BY_ID = 'START_GET_MENU_BY_ID';
export const DELETE_MENU = 'DELETE_MENU';
export const START_GET_MENUS_STOCK="START_GET_MENUS_STOCK"
//CATEGORIES
export const START_GET_CATEGORIES = 'START_GET_CATEGORIES';
export const START_POST_CATEGORY = 'START_POST_CATEGORY';
export const START_GET_CATEGORY_BY_ID = 'START_GET_CATEGORY_BY_ID';
export const START_DELETE_CATEGORY = 'START_DELETE_CATEGORY';

//menu levels
export const EDIT_SUCCESS_MENU_LEVELS = 'EDIT_SUCCESS_MENU_LEVELS';
export const DRAG_NEW_MENU_LEVEL = 'DRAG_NEW_MENU_LEVEL';
export const unDRAG_NEW_MENU_LEVEL = 'unDRAG_NEW_MENU_LEVEL';
export const UPDATE_MENU_LEVELS = 'UPDATE_MENU_LEVELS';
export const UPDATE_DELETED_MENU_LEVELS = 'UPDATE_DELETED_MENU_LEVELS';
export const SELECT_MENUS_LEVELS = 'SELECT_MENUS_LEVELS';
export const UNDRAG_ALL_MENUS_LEVELS = 'UNDRAG_ALL_MENUS_LEVELS';
export const UPDATE_LEVELS_ARTICLES_DRAGGED = 'UPDATE_LEVELS_ARTICLES_DRAGGED';
//submenu
export const EDIT_SUCCESS_SUB_MENU = 'EDIT_SUCCESS_SUB_MENU';
export const DRAG_NEW_SUB_MENU = 'DRAG_NEW_SUB_MENU';
export const unDRAG_NEW_SUB_MENU = 'unDRAG_NEW_SUB_MENU';
export const UPDATE_SUB_MENU = 'UPDATE_SUB_MENU';
export const UPDATE_DELETED_SUB_MENU = 'UPDATE_DELETED_SUB_MENU';
export const SELECT_SUB_MENUS = 'SELECT_SUB_MENUS';
export const UNDRAG_ALL_SUB_MENUS = 'UNDRAG_ALL_SUB_MENUS';
export const UPDATE_ARTICLES_DRAGGED_SUB_MENU =
  'UPDATE_ARTICLES_DRAGGED_SUB_MENU';
//category_cart
export const EDIT_SUCCESS_CATEGORY_CART = 'EDIT_SUCCESS_CATEGORY_CART';
export const DRAG_NEW_CATEGORY_CART = 'DRAG_NEW_CATEGORY_CART';
export const unDRAG_NEW_CATEGORY_CART = 'unDRAG_NEW_CATEGORY_CART';
export const UPDATE_CATEGORY_CART = 'UPDATE_CATEGORY_CART';
export const UPDATE_DELETED_CATEGORY_CART = 'UPDATE_DELETED_CATEGORY_CART';
export const SELECT_CATEGORY_CARTS = 'SELECT_CATEGORY_CARTS';
export const UNDRAG_ALL_CATEGORY_CARTS = 'UNDRAG_ALL_CATEGORY_CARTS';
export const UPDATE_ARTICLES_DRAGGED_CATEGORY_CART =
  'UPDATE_ARTICLES_DRAGGED_CATEGORY_CART';
//cart
export const POST_CART = 'POST_CART';
export const GET_LIST_PV_FILTRED = 'GET_LIST_PV_FILTRED';
export const POST_ASSIGN_PV = 'POST_ASSIGN_PV';
export const DRAG_UNDRAG_PV = 'DRAG_UNDRAG_PV';
export const GET_CARD_BY_ID = 'GET_CARD_BY_ID';
export const UPDATE_CATEGORY_CARD="UPDATE_CATEGORY_CARD"
