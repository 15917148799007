import React from 'react';

interface ToggleElementProps {
  label: string;
  id: string;
  active?: boolean;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: number | string;
  slot?: number;
  stock?: boolean;
}

const ToggleElement: React.FC<ToggleElementProps> = ({
  label,
  id,
  active = false,
  onClick,
  onChange,
  name,
  value,
  slot,
  stock,
}) => {
  // Function to handle custom value logic
  const handleValue = () => {
    switch (label) {
      case 'active':
        return 'Activé';
      case 'deactivated':
        return 'Désactivé';
      case 'in_approval':
        return 'Tout';
    }
  };

  const sanitizeLabel = (label: string): string =>
    label.replace(/[^\w]/g, '').replace(/\s+/g, '').toLowerCase();

  return (
    <div
      className={` ${stock ? 'ToggleElementStock' : 'ToggleElement'} ${active ? 'active' : ''} ${sanitizeLabel(label) + '-type'}`}
      onClick={onClick}
    >
      {stock ? (
        <>
          <div className="element-wrapper fl_row gp10 ai_c">
            <label className="p14 Independence-txt w-700 uper" htmlFor={id}>
              {label}
            </label>
            <input
              type="checkbox"
              name={name}
              id={id}
              checked={active}
              value={value}
              onChange={onChange}
            />
            <p className="slot">{slot}</p>
          </div>
        </>
      ) : (
        <>
          <label className="p14 Independence-txt w-700 uper" htmlFor={id}>
            {handleValue() || label}
          </label>
          <input
            type="checkbox"
            name={name}
            id={id}
            checked={active}
            value={value}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

export default ToggleElement;
