import React, { useState } from 'react';
import CustomToggleElement from '../../../../components/Input/CustomToggleElement';
import AlliconFilter from '../../../../assets/icons/All-iconFilter.svg';
import UnsubscribeCalendarFilter from '../../../../assets/icons/UnsubscribeCalendarFilter.svg';
import ExpiredCalendarFilter from '../../../../assets/icons/ExpiredCalendarFilter.svg';
import ActiveCalendarFilter from '../../../../assets/icons/ActiveCalendarFilter.svg';

interface TableFilterProps {
  activeFilter?: 'all' | 'active' | 'expired' | 'Unsubscribed';
  abonnementClient?: any;
  setShowAbonnment?: any;
}

const TableFilter: React.FC<TableFilterProps> = ({
  activeFilter = 'all',
  abonnementClient,
  setShowAbonnment,
}) => {
  const [activeToggle, setActiveToggle] = useState<
    'all' | 'active' | 'expired' | 'Unsubscribed'
  >(activeFilter);

  const handleToggleClick = (
    filter: 'all' | 'active' | 'expired' | 'Unsubscribed',
  ) => {
    setActiveToggle(filter);
  };

  return (
    <div className="TableFilter">
      <div className="filter-wrapper fl_row jc_s">
        <p className="p18 w-900 DarkJungleGreen-txt">Abonnements</p>
        <div className="filter-box">
          <div className="filter-wrapper fl_row gp20">
            <CustomToggleElement
              label={'Tous'}
              id={'Tous'}
              icon={AlliconFilter}
              slot={abonnementClient?.result?.nbtotalAbonnement}
              active={activeToggle === 'all'}
              onClick={() => {
                setShowAbonnment('Tous');
                handleToggleClick('all');
              }}
            />
            <CustomToggleElement
              label={'Activé'}
              id={'Activé'}
              icon={ActiveCalendarFilter}
              slot={abonnementClient?.result?.nbActiveAbonnement}
              active={activeToggle === 'active'}
              onClick={() => {
                setShowAbonnment('Activé');
                handleToggleClick('active');
              }}
            />
            <CustomToggleElement
              label={'Expiré'}
              id={'Expiré'}
              icon={ExpiredCalendarFilter}
              slot={abonnementClient?.result?.nbExpiredAbonnement}
              active={activeToggle === 'expired'}
              onClick={() => {
                setShowAbonnment('Expiré');
                handleToggleClick('expired');
              }}
            />
            <CustomToggleElement
              label={'Désabonné'}
              id={'Désabonné'}
              icon={UnsubscribeCalendarFilter}
              slot={abonnementClient?.result?.nbCanceledAbonnement}
              active={activeToggle === 'Unsubscribed'}
              onClick={() => {
                setShowAbonnment('Désabonné');
                handleToggleClick('Unsubscribed');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
