import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import * as actionTypes from '../actions/actionTypes';
const initialState = {
  intervallesAbonnement: [],
  loading: false,
  error: null,
};

const intervallesAbonnementReducer = (
  state = initialState,
  action: {
    type: string;
    payload: serviceEtablissmentMachineTypeModels.DataSuccess;
  },
) => {
  switch (action.type) {
    case actionTypes.GET_LIST_INTERVALLES_ABONNEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_LIST_INTERVALLES_ABONNEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        intervallesAbonnement: action.payload,
      };
    case actionTypes.GET_LIST_INTERVALLES_ABONNEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default intervallesAbonnementReducer;
