import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AddSubFolderIcon from '../../assets/icons/AddSubFolderIcon.svg';
import CreateFolderIcon from '../../assets/icons/CreateFolderIcon.svg';
import DeleteIcon from '../../assets/icons/DeleteIcon.svg';
import Modal from '../../components/Modal/Catalogue/CatalogueModal';
import FolderItemUploadFile from '../../components/UploadFile/FolderItemUploadFile';
import HeaderUploadFile from '../../components/UploadFile/HeaderUploadFile';
import ImageItemUploadFile from '../../components/UploadFile/ImageItemUploadFile';
import ButtonElement from '../../components/button/button';
import { languageData } from '../../i18n';
import { Image, UploadFolder } from '../../models/services/module.catalogue';
import {
  deleteFolder,
  deleteUploadImg,
  getFolder,
  getSubfolder,
  getUploadImage,
  postFolder,
  uploadImage,
} from '../../store/actions/catalogues/actions';
import {
  NotificationErrorProvider,
  NotificationSuccessProvider,
} from '../../utils/Notificationprovider';
import { downloadImage } from '../../utils/catalogueHelper/download';
/**
 * UploadFileContainer component description.
 *
 * @UploadFileContainer
 *
 */
interface UploadFileContainerProps {
  title?: string;
  onSelectImage?: (image: Image) => void;
  onClose?: () => void;
}
const acceptedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/gif',
  'image/tiff',
];

const UploadFileContainer: React.FC<UploadFileContainerProps> = ({
  title,
  onSelectImage,
  onClose,
}) => {
  const [folders, setfolders] = useState<UploadFolder[]>();
  const [subFolders, setSubFolders] = useState<UploadFolder[]>();
  const [subFolder, setsubFolder] = useState<UploadFolder>();
  const [folder, setfolder] = useState<UploadFolder>();
  const [images, setimages] = useState<Image[]>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<Image>();
  const [showAlertDelete, setshowAlertDelete] = useState(false);
  const [showAlertDeleteImage, setshowAlertDeleteImage] = useState(false);
  const dispatch = useDispatch();
  const fetchFolders = async (callBack?: (folders: UploadFolder[]) => void) => {
    try {
      dispatch(
        getFolder((_) => {
          const foldersList: UploadFolder[] = _ as UploadFolder[];
          setfolders(foldersList ?? []);
          if (foldersList) callBack ? callBack(foldersList) : null;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const appendFolder = (isSubFolder?: boolean) => {
    if (!isSubFolder) {
      let fold: UploadFolder = { editing: true } as UploadFolder;
      setSubFolders([]);
      setfolder(fold as UploadFolder);
      setfolders((prv) => [...(prv ?? ([] as UploadFolder[])), fold]);
    } else if (folder) {
      let fold: UploadFolder = {
        idParent: folder.id,
        editing: true,
      } as UploadFolder;
      setsubFolder(fold);
      setSubFolders((prv) => [...(prv ?? ([] as UploadFolder[])), fold]);
    }
  };

  const checkSupportedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the first file from the input

    if (file) {
      const isFileTypeValid = acceptedFileTypes.includes(file.type);
      return isFileTypeValid;
    }
    return false;
  };
  const handleCloseAlert = () => {
    setshowAlertDelete(false);
    setshowAlertDeleteImage(false);
  };
  const handleDeleteFolder = async (id: number, isSub?: boolean) => {
    try {
      dispatch(
        deleteFolder(id, isSub ? true : false, () => {
          NotificationSuccessProvider('delete', 'Dossier');
          setimages([]);
          handleCloseAlert();
          fetchFolders();
          subFolder
            ? fetchSubFoldersOfFolder(('' + folder?.id) as string)
            : fetchFolders();
          setsubFolder(undefined);
          setfolder(undefined);
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const onEnterAddFolder = async () => {
    try {
      dispatch(
        postFolder(subFolder ?? (folder as UploadFolder), () => {
          (subFolder || folder)?.editing
            ? NotificationSuccessProvider('edit', 'Dossier')
            : NotificationSuccessProvider('add', 'Dossier');
          fetchFolders();
          subFolder
            ? fetchSubFoldersOfFolder(
                '' + folder?.id,
                (foldersList: UploadFolder[]) =>
                  setsubFolder(
                    foldersList.length > 0 && subFolder
                      ? (foldersList[
                          foldersList?.findIndex(
                            (f) => f.name == subFolder.name ?? '',
                          )
                        ] as UploadFolder)
                      : ({} as UploadFolder),
                  ),
              )
            : fetchFolders((foldersList: UploadFolder[]) =>
                setfolder(
                  foldersList.length > 0 && folder
                    ? (foldersList[
                        foldersList?.findIndex(
                          (f) => f.name == folder.name ?? '',
                        )
                      ] as UploadFolder)
                    : ({} as UploadFolder),
                ),
              );
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const onChangeNameFolder = (newName: string) => {
    let f = folder as UploadFolder;
    f.name = newName;
    setfolder(f);
  };
  const onChangeNameSubFolder = (newName: string) => {
    let f = subFolder as UploadFolder;
    f.name = newName;
    setsubFolder(f);
  };
  const cancelEdit = () => {
    if (folder?.id) {
      setfolder({
        ...folder,
        editing: false,
      });
      setsubFolder({
        ...subFolder,
        editing: false,
      } as UploadFolder);
    } else {
      setfolder(undefined);
      let fs = folders;
      fs?.splice(-1);
      setfolders(fs);
      setimages(undefined);
    }
  };
  const onSelect = (folder: UploadFolder, isSubFolder?: boolean) => {
    isSubFolder ? setsubFolder(folder) : setfolder(folder);
    if (!isSubFolder) {
      setSubFolders([]);
      setsubFolder(undefined);
    }
  };
  const onRename = () => {
    let f = subFolder ? (subFolder as UploadFolder) : (folder as UploadFolder);
    f.editing = true;
    subFolder ? setsubFolder({ ...f }) : setfolder({ ...f });
  };
  useEffect(() => {
    fetchFolders();
  }, []);
  const fetchImagesOfFolder = async (path: string) => {
    try {
      dispatch(
        getUploadImage(path, (_) => {
          if (_) {
            let f = _;
            setimages([...(f as Image[])]);
          } else {
            setimages(undefined);
          }
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubFoldersOfFolder = async (
    id: string,
    callBack?: (subFolders: UploadFolder[]) => void,
  ) => {
    try {
      dispatch(
        getSubfolder(parseInt(id), (_) => {
          if (_) {
            setSubFolders(_);
            if (callBack) {
              callBack(_ as UploadFolder[]);
            }
          } else {
            setSubFolders(undefined);
          }
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (event?.target?.files && folder) {
        const file = event?.target?.files[0];
        if (checkSupportedFile(event)) {
          dispatch(
            uploadImage(
              subFolder ? subFolder.id : (folder?.id as number),
              subFolder ? subFolder.name : (folder?.name as string),
              file,
              () => {
                NotificationSuccessProvider('add', 'Image');
                fetchImagesOfFolder(subFolder ? subFolder.name : folder.name);
              },
            ),
          );
        } else {
          NotificationErrorProvider({
            response: {
              status: 400,
              data: {
                failureResponse: {
                  codeFI: {
                    message: 'FILE_NOT_SUPPORTED',
                  },
                },
              },
            },
          });
        }
      }
    } catch (error) {
      NotificationErrorProvider(error);
      console.log(error);
    }
  };
  const handleAddImage = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current?.click();
    }
  };
  useEffect(() => {
    let folde = subFolder ? subFolder : folder;
    if ((folde as UploadFolder)?.id && !folde?.editing) {
      fetchImagesOfFolder(folde?.name as string);
      subFolder ? null : fetchSubFoldersOfFolder(('' + folde?.id) as string);
      setSelectedImage(undefined);
    } else {
      setimages(undefined);
    }
  }, [folder, subFolder]);

  const handleOnSelect = (image: Image) => {
    setSelectedImage(image);
  };
  const handleOnDeleteImage = async () => {
    try {
      dispatch(
        deleteUploadImg(selectedImage?.id as number, () => {
          NotificationSuccessProvider('delete', 'Image');
          handleCloseAlert();
          setSelectedImage(undefined);
          fetchImagesOfFolder(folder?.name as string);
        }),
      );
    } catch (error) {
      NotificationErrorProvider(error);
    }
  };
  const handleDownloadImage = (src: string) => {
    downloadImage(src);
  };
  const handleSelectImage = () => {
    if (onSelectImage) onSelectImage(selectedImage as Image);
  };
  const doWithConfirmation = () => {
    setshowAlertDelete(true);
  };
  const doWithConfirmationImage = () => {
    setshowAlertDeleteImage(true);
  };

  return (
    <div className="UploadFilecontainer w-full">
      <div className="container-wrapper fl_col gp10 h-full jc_s">
        <div className="fl_col">
          <div className="container-header pd20-r">
            <HeaderUploadFile
              selectedFolder={folder != undefined || subFolder != undefined}
              onClose={onClose}
              disabled={!selectedImage}
              onClickAdd={handleAddImage}
              onClickRemove={doWithConfirmationImage}
              onClickDownload={() =>
                selectedImage
                  ? handleDownloadImage(selectedImage?.url ?? '')
                  : undefined
              }
            />
            <div className="fl_row">
              <div className=""></div>
              <div className="fl-1"></div>
            </div>
          </div>
          <div className="container-body">
            <div className="left-side">
              <div className="header-side fl_row jc_s pd8-r-l">
                {/*  <button onClick={onRename}>
                {languageData.fr.modalsFamille.rename}
              </button> */}
                <button
                  style={{ cursor: 'pointer' }}
                  onClick={() => appendFolder()}
                >
                  {languageData.fr.modalsFamille.new}
                </button>
              </div>
              <div className="folders-listing">
                {folders &&
                  folders.map((f) => (
                    <FolderItemUploadFile
                      hasSubFolder={f && f?.nbSubFolders > 0}
                      selected={!subFolder && folder?.id == f.id}
                      cancelEdit={cancelEdit}
                      onChangeNameFolder={onChangeNameFolder}
                      name={f.name}
                      edited={
                        !f.id || (folder && folder.editing && f.id == folder.id)
                      }
                      onEnterAddFolder={onEnterAddFolder}
                      onSelect={() => onSelect(f)}
                    >
                      {folder?.id == f.id &&
                        subFolders?.map((f) => (
                          <FolderItemUploadFile
                            type="SubFolder"
                            selected={subFolder?.id == f.id}
                            cancelEdit={cancelEdit}
                            onChangeNameFolder={onChangeNameSubFolder}
                            name={f.name}
                            edited={
                              !f.id ||
                              (subFolder &&
                                subFolder.editing &&
                                f.id == subFolder.id)
                            }
                            onEnterAddFolder={onEnterAddFolder}
                            onSelect={() => onSelect(f, true)}
                          ></FolderItemUploadFile>
                        ))}
                    </FolderItemUploadFile>
                  ))}
              </div>
            </div>
            <div className="right-side">
              <div className="files-listing">
                {images &&
                  images.map((img) => (
                    <ImageItemUploadFile
                      onSelect={() => handleOnSelect(img)}
                      src={img.url}
                      selected={selectedImage && selectedImage?.id == img.id}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container-footer fl_row jc_s gp12 pd20-r pd20-t">
          {folder && !folder.idParent && !subFolder && !folder.editing ? (
            <div className="fl_row gp16">
              <img
                className="cursor-p"
                src={AddSubFolderIcon}
                alt=""
                onClick={() => appendFolder(true)}
              />
              <img
                className="cursor-p"
                src={DeleteIcon}
                alt=""
                onClick={doWithConfirmation}
              />
              <img
                className="cursor-p"
                src={CreateFolderIcon}
                alt=""
                onClick={onRename}
              />
            </div>
          ) : subFolder != undefined && !subFolder.editing ? (
            <div className="fl_row gp16">
              <img
                className="cursor-p"
                src={DeleteIcon}
                alt=""
                onClick={doWithConfirmation}
              />
              <img
                className="cursor-p"
                src={CreateFolderIcon}
                alt=""
                onClick={onRename}
              />
            </div>
          ) : (
            <div className="fl_row gp16">
              {' '}
              <div className="cursor-p" />
            </div>
          )}

          <div className="fl_row gp12">
            <ButtonElement
              type={'TextButton'}
              text={languageData.fr.modalsFamille.cancel}
              buttonSize={'medium'}
              buttonStyle="btn-grey-light "
              textColor="black-txt"
              onClick={onClose}
            />

            <ButtonElement
              isDisabled={!selectedImage}
              type={'TextButton'}
              text={languageData.fr.modalsFamille.select}
              buttonSize={'medium'}
              buttonStyle="btn-primary"
              onClick={handleSelectImage}
            />
            <input
              autoComplete="off"
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept=".jpg, .jpeg, .png, .bmp, .gif, .tiff" // Specify accepted file types
            />
          </div>
        </div>
      </div>
      <Modal
        target="badge"
        version={'Item'}
        type={'delete'}
        title={languageData.fr.modalsCatalogues.confirmDelete}
        visibility={showAlertDelete}
        close={true}
        cancel={true}
        cancelTxt={languageData.fr.Modal.cancel}
        deleteitem={true}
        deleteitemTxt={languageData.fr.Modal.delete}
        handeldelete={() =>
          handleDeleteFolder(
            (subFolder ? subFolder.id : folder?.id) as number,
            subFolder != undefined,
          )
        }
        withoutOverlay
        closeModal={handleCloseAlert}
        description={[languageData.fr.Modal.confirmDelete]}
      />
      <Modal
        target="badge"
        version={'Item'}
        type={'delete'}
        title={languageData.fr.modalsCatalogues.confirmDelete}
        visibility={showAlertDeleteImage}
        close={true}
        cancel={true}
        cancelTxt={languageData.fr.Modal.cancel}
        deleteitem={true}
        deleteitemTxt={languageData.fr.Modal.delete}
        handeldelete={handleOnDeleteImage}
        withoutOverlay
        closeModal={handleCloseAlert}
        description={[languageData.fr.Modal.confirmDelete]}
      />
    </div>
  );
};

export default UploadFileContainer;
