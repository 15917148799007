import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import {
  addRoleFailure,
  addRoleSuccess,
  failure,
  getRolesListSuccess,
  resetLoggedIn,
  setRolesNumber,
  setRolesTotalPagesNumber,
  getRolesId,
  newRoleChanged,
  deleteRoleSuccess,
  resetPasswordSuccesAction,
} from '../actions/actions';
import roleApi from '../../services/apiHandlers/roleApi';
import { serviceRolesModels } from '../../models/services/module.role';
import { unauthorizedError } from '../../utils/unauthorizedError';

export function* getRolesListSaga(action: serviceRolesModels.rolesAction) {
  try {
    const response: AxiosResponse = yield roleApi.getRoles(action.payload);
    if (response?.data) {
      yield put(getRolesListSuccess(response?.data?.result.roles));
      yield put(setRolesNumber(response?.data?.result.nbTotal));
      yield put(setRolesTotalPagesNumber(response.data.pagination.nbPage));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}
export function* getRolesIdSaga(action: serviceRolesModels.rolesAction) {
  try {
    const response: AxiosResponse = yield roleApi.getRoleById(action.payload);
    if (response?.data) {
      yield put(getRolesId(response?.data?.result));
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(failure(error));
  }
}

// export function* getEstablishementRolesListSaga(
//   action: serviceRolesModels.rolesAction,
// ) {
//   try {
//     const response: AxiosResponse = yield roleApi.getRolesEstablishement(
//       action.payload,
//     );
//     if (response?.data) {
//       yield put(getRolesListSuccess(response?.data?.result.roles));
//       yield put(setRolesNumber(response?.data?.result.nbTotal));
//       yield put(setRolesTotalPagesNumber(response.data.pagination.nbPage));
//     }
//   } catch (error: any) {
//     if  ((error.response.status === 400 &&
//       error.response.data.failureResponse.codeFI.code === 808) || error.response.status === 401 ) {
//       yield put(resetLoggedIn());
//     }
//     yield put(failure(error));
//   }
// }

export function* updateRoleSaga(action: {
  type: string;
  payload: serviceRolesModels.UpdateRolePayload;
}) {
  try {
    const response: AxiosResponse = yield roleApi.updateRole(action.payload);
    if (response.data) {
      yield put(addRoleSuccess(response.data.result));
      yield put(newRoleChanged(false));
    } else {
      yield put(addRoleFailure());
    }
  } catch (error) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(addRoleFailure());
  }
}
export function* addRoleSaga(action: serviceRolesModels.rolesAction) {
  try {
    const response: AxiosResponse = yield roleApi.addRole(action.payload);
    if (response.data?.result?.role_id) {
      yield put(addRoleSuccess(response.data.result.role_id));

      action._ && action._();

      yield put(newRoleChanged(false));
    } else {
      yield put(addRoleFailure());
    }
  } catch (error: any) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(addRoleFailure());
  }
}

//create a delete role with https://fi-ne-dev-bomobile-01.azurewebsites.net/v1/deleteRole

export function* deleteRoleSaga(action: serviceRolesModels.rolesAction) {
  try {
    const response: AxiosResponse = yield roleApi.deleteRole(action.payload);
    if (response.data) {
      yield put(addRoleSuccess(response.data.result));
      yield put(deleteRoleSuccess(true));
      yield put(newRoleChanged(false));
    } else {
      yield put(addRoleFailure());
    }
  } catch (error) {
    if (unauthorizedError(error)) {
      yield put(resetLoggedIn());
      yield put(resetPasswordSuccesAction(false));
    }
    yield put(addRoleFailure());
    yield put(deleteRoleSuccess(false));
  }
}
