import CountriesFlagsAndCodes, {
  CountriesFlagsAndCodesItem,
} from '../data/CountriesFlagsAndCode';

const flagsAndNamesSeparator = () => {
  const flags: string[] = [];
  const countriesCodes: string[] = [];
  const countriesNames: string[] = [];
  const countriePhoneCodes: string[] = [];

  CountriesFlagsAndCodes.forEach((item: CountriesFlagsAndCodesItem) => {
    flags.push(item.flag);
    countriesCodes.push(item.code);
    countriesNames.push(item.country);
    countriePhoneCodes.push(item.phoneCode);
  });

  return { flags, countriesCodes, countriesNames, countriePhoneCodes };
};

export default flagsAndNamesSeparator;
