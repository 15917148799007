import { Dispatch } from 'redux';
import { serviceTabsModels } from '../models/services/module.tabs';
import { unsavedChangesRegister, updateTabs } from '../store/actions/actions';

interface addSubmitSucceededProps {
  toastRef: React.RefObject<{
    openToast: (
      type: 'error' | 'alert' | 'info' | 'success',
      title: string,
    ) => void;
    closeToast: () => void;
  }>;
  open_tabs: serviceTabsModels.Tab[];
  tabName: string;
  dispatch: Dispatch;
  unsavedChangesData: serviceTabsModels.unsavedChangesDataType[];
}
export const addSubmitSucceeded = (props: addSubmitSucceededProps) => {
  props.toastRef?.current?.openToast('success', 'success');

  const tabIndex = props.open_tabs.findIndex(
    (item) => item.name === props.tabName,
  );

  if (tabIndex !== -1) {
    const updatedTabs = [...props.open_tabs];
    updatedTabs[tabIndex] = {
      ...updatedTabs[tabIndex],
      isChanged: false,
    };

    props.dispatch(updateTabs(updatedTabs));

    const updatedData = props.unsavedChangesData?.filter(
      (item) => item.key !== props.tabName,
    );

    props.dispatch(unsavedChangesRegister(updatedData || []));
  }
};
