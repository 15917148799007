import React, { ChangeEvent, useEffect, useRef } from 'react';
import ButtonElement from '../../../components/button/button';
import PlusIconWhite from '../../../assets/icons/PlusIconWhite.svg';
import InputField from '../../../components/Input/InputCatalogue';
import SearchIconPrimary from '../../../assets/icons/SearchIconPrimary.svg';
import { useLocation } from 'react-router-dom';
export default function TopSearchBarCatalogueContainer({
  onChange,
  onShowModal,
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onShowModal: (value: boolean) => void;
}) {
  const location = useLocation();
  const refInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    let currentPath = location.pathname;

    if (currentPath.includes('catalogue')) {
      if (refInput.current) {
        console.log('GGGGG');
        refInput.current.value = '';
      }
    }
  }, [location.pathname]);
  return (
    <div className="TopSearchBarCatalogueContainer fl_col rd8">
      <div className="bar-wrapper pd8 fl_row jc_s ai_c">
        <div className="search-inp fl_row">
          <InputField
            reff={refInput}
            type="text"
            name="searchCatalogue"
            placeholder="Recherche..."
            // value=""
            icon={SearchIconPrimary}
            onChange={onChange}
            style="search"
            className="rd20 p10 br1LightGrey"
          />
        </div>
        <div className="add-btn fl_row">
          <ButtonElement
            onClick={() => onShowModal(true)}
            className="btnSearch"
            icon={PlusIconWhite}
            radius="rd2"
            textSize="p15 w-500"
            text="Nouveau"
            type="IconButton"
            buttonStyle="btn-primary"
            buttonSize={'medium'}
            textColor="white-txt"
          />
        </div>
      </div>
    </div>
  );
}
