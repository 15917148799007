import React from 'react';

interface Props {
  strokeColor?: string;
}

const CategoriesIcon: React.FC<Props> = ({ strokeColor  }) => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 1.66797H11.7245C12.0693 1.66797 12.3572 1.93089 12.3885 2.27428L13 9.0013H3.66667" stroke={strokeColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 1.66797L3.66667 9.0013" stroke={strokeColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 1.66797L1 9.0013H3.66667" stroke={strokeColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.66667 4.33464H10.3333" stroke={strokeColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.33333 6.33464H9.66667" stroke={strokeColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default CategoriesIcon;
