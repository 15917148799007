import React, { ReactNode } from 'react';

interface Thead {
  children: ReactNode;
}

const Thead: React.FC<Thead> = ({ children }) => {
  return <thead>{children}</thead>;
};

export default Thead;
