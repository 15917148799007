import { serviceAppUserModels } from '../../models/services/module.appUser';
import { serviceCampanyMachineTypeModels } from '../../models/services/module.CampanyMachineType';
import { serviceEntrepriseModels } from '../../models/services/module.entreprise';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import { serviceEtablissementModels } from '../../models/services/module.etablissement';
import { serviceFormesJuridiquesModels } from '../../models/services/module.fomesJuridiques';
import { serviceRedevanceModels } from '../../models/services/module.redevance';
import { serviceRolesModels } from '../../models/services/module.role';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { serviceTaxesModels } from '../../models/services/module.taxesInfos';
import { StepItem } from '../../utils/addCompanyStepsDataInitialState';
import * as actionTypes from './actionTypes';
/*
 * +--------------------------+
 * | Authentification section |
 * +--------------------------+
 */
/**

 */
export const initApp = (version: number) => {
  return {
    type: actionTypes.INIT_APP,
    version,
  };
};
export const login = (
  email: string,
  password: string,
  deviceNotificationId: string | null,
  forceLogin: boolean,
) => {
  return {
    type: actionTypes.LOGIN,
    email,
    password,
    deviceNotificationId,
    forceLogin,
  };
};
export const loginSuccess = (payload: any) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload,
  };
};
export const loginFailure = (error: serviceAuthModels.FailureResponse) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error,
  };
};
export const clearErrorMessage = () => ({
  type: actionTypes.CLEAR_ERROR_MESSAGE,
});
export const detectedError = (code: number) => {
  return {
    type: actionTypes.DETECTED_ERROR,
    code,
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};
export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
  };
};
export const failure = (error: Error) => {
  return {
    type: actionTypes.FAILURE,
    error,
  };
};
// export const loggedUser = (user: User) => {
//   return {
//     type: actionTypes.LOGGED_USER,
//     user,
//   };
// };
export const cancelLogin = (code: number | null) => {
  return {
    type: actionTypes.CANCEL_LOGIN,
    code,
  };
};
export const resetLoggedIn = () => {
  return {
    type: actionTypes.RESET_LOGGED_IN,
  };
};
/*
 * +--------------------------+
 * | TABS section |
 * +--------------------------+
 */
/**

 */
export const addTab = (payload: serviceTabsModels.Tab) => {
  return {
    type: actionTypes.ADD_TAB,
    payload,
  };
};
export const openTab = (payload: serviceTabsModels.tabId) => {
  return {
    type: actionTypes.OPEN_TAB,
    payload,
  };
};
export const removeTab = (payload: serviceTabsModels.RemoveTabType) => {
  return {
    type: actionTypes.REMOVE_TAB,
    payload,
  };
};
export const selectTab = (payload: serviceTabsModels.tabLabel) => {
  return {
    type: actionTypes.SELECT_TAB,
    payload,
  };
};
export const setActiveTab = (payload: serviceTabsModels.tabLabel) => {
  return {
    type: actionTypes.SET_ACTIVE_TAB,
    payload,
  };
};
export const setActiveTabContent = (payload: serviceTabsModels.tabContent) => {
  return {
    type: actionTypes.SET_ACTIVE_TAB_CONTENT,
    payload,
  };
};
export const updateTabs = (updated_tabs: serviceTabsModels.Tab[]) => {
  return {
    type: actionTypes.UPDATE_TABS,
    payload: updated_tabs,
  };
};
export const resetTabs = (tabs: serviceTabsModels.Tab[]) => {
  return {
    type: actionTypes.RESET_TABS,
    payload: tabs,
  };
};
export const unsavedChangesRegister = (
  payload: serviceTabsModels.unsavedChangesDataType[],
) => {
  return {
    type: actionTypes.UNSAVED_CHANGES_DATA,
    payload,
  };
};

/*
 * +--------------------------+
 * | ENTRPRISE section |
 * +--------------------------+
 */
/**

 */
export const addEntreprise = (payload: serviceEntrepriseModels.Entreprise) => {
  return {
    type: actionTypes.ADD_ENTREPRISE,
    payload,
  };
};
export const addCompanyFormDataAction = (
  payload: serviceEntrepriseModels.Entreprise,
) => {
  return {
    type: actionTypes.ADD_ENTREPRISE_FORM_DATA,
    payload,
  };
};
export const resetCompanyFormDataAction = () => {
  return {
    type: actionTypes.RESET_ENTREPRISE_FORM_DATA,
  };
};
export const addCompanyCurrentStep = (payload: number) => {
  return {
    type: actionTypes.ADD_COMPANY_STEP,
    payload,
  };
};
export const addEntrepriseInfoGeneral = (
  payload: serviceEntrepriseModels.Entreprise,
) => {
  return {
    type: actionTypes.ADD_ENTREPRISE_GENERAL_INFO,
    payload,
  };
};
export const addEntrepriseSuccess = (payload: number) => {
  return {
    type: actionTypes.ADD_ENTREPRISE_SUCCESS,
    payload,
  };
};
export const addEntrepriseFailure = () => {
  return {
    type: actionTypes.ADD_ENTREPRISE_FAILURE,
  };
};
export const resetEntrepriseAddSuccessState = () => {
  return {
    type: actionTypes.RESET_ADD_SUCCESS_STATE,
  };
};
export const editEntrepriseSuccess = () => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_SUCCESS,
  };
};
export const editEntrepriseFailure = () => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_FAILURE,
  };
};
export const editEntrepriseGeneralInfo = (
  payload: serviceEntrepriseModels.generalInformation,
) => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_GENERAL_INFO,
    payload,
  };
};
export const editEntrepriseFinancialInfo = (
  payload: serviceEntrepriseModels.financialInformation,
) => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_FINANCIAL_INFO,
    payload,
  };
};
export const removeEntreprise = (
  entrepriseId: serviceEntrepriseModels.entrepriseId,
) => {
  return {
    type: actionTypes.REMOVE_ENTREPRISE,
    entrepriseId,
  };
};
export const getEntreprise = (
  entrepriseId: serviceEntrepriseModels.entrepriseId,
) => {
  return {
    type: actionTypes.GET_ENTREPRISE,
    entrepriseId,
  };
};
export const getAllEntreprise = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_ALL_ENTREPRISE,
    payload,
  };
};
export const setCompaniesTotalPagesNumber = (payload: number) => {
  return {
    type: actionTypes.SET_ALL_ENTREPRISE_TOTAL_PAGE_NUMBER,
    payload,
  };
};

export const setAllEntreprise = (
  entreprises: serviceEntrepriseModels.setAllEntrepriseAction,
) => {
  return {
    type: actionTypes.SET_ALL_ENTREPRISE,
    entreprises,
  };
};
export const getCompanyByIdInfos = (
  payload: serviceEntrepriseModels.entrepriseId,
) => {
  return {
    type: actionTypes.GET_COMPANY_INFOS,
    payload,
  };
};
export const getCompanyByIdSheet = (
  payload: serviceEntrepriseModels.entrepriseId,
) => {
  return {
    type: actionTypes.GET_COMPANY_SHEET,
    payload,
  };
};
export const setCompanyByIdInfos = (
  payload: serviceEntrepriseModels.generalInformation,
) => {
  return {
    type: actionTypes.SET_COMPANY_INFOS,
    payload,
  };
};
export const setCompanyByIdSheet = (
  payload: serviceEntrepriseModels.Entreprise,
) => {
  return {
    type: actionTypes.SET_COMPANY_SHEET,
    payload,
  };
};
export const getCompanyByIdFinancialInfos = (
  payload: serviceEntrepriseModels.entrepriseId,
) => {
  return {
    type: actionTypes.GET_COMPANY_FINANCIAL_INFOS,
    payload,
  };
};
export const setCompanyByIdFinancialInfos = (
  payload: serviceEntrepriseModels.financialInformation,
) => {
  return {
    type: actionTypes.SET_COMPANY_FINANCIAL_INFOS,
    payload,
  };
};
export const resetEditResponse = () => {
  return {
    type: actionTypes.RESET_EDIT_RESPONSE,
  };
};
export const selectExistingEntrepriseCreateEtablissment = (payload: number) => {
  return {
    type: actionTypes.SELECT_COMPANY_EXISTING_CREATE_ETABLISSEMENT,
    payload,
  };
};

export const resetExistingEntrepriseCreateEtablissment = () => {
  return {
    type: actionTypes.RESET_COMPANY_EXISTING_CREATE_ETABLISSEMENT,
  };
};
/****************** add entreprise form states */
export const setValidationRequested = (payload: boolean) => {
  return {
    type: actionTypes.ADD_COMPANY_VALIDATION_REQUESTED,
    payload,
  };
};
export const setValidationRequestedStep2 = (payload: boolean) => {
  return {
    type: actionTypes.ADD_COMPANY_VALIDATION_REQUESTED_STEP2,
    payload,
  };
};
export const setValidationRequestedFiscal = (payload: boolean) => {
  return {
    type: actionTypes.ADD_COMPANY_VALIDATION_REQUESTED_FISCAL,
    payload,
  };
};
export const setStepsData = (payload: StepItem[]) => {
  return {
    type: actionTypes.ADD_COMPANY_STEPS_DATA,
    payload,
  };
};
export const setEditFormDataFiche = (
  payload: serviceEntrepriseModels.formDataGeneralInfosTypeActionPayload,
) => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_FORM_DATA_FICHE,
    payload,
  };
};
export const setValidationRequestedEditFicheTab = (payload: boolean) => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED,
    payload,
  };
};
export const setEditFormDirty = (payload: boolean) => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_FORM_SET_DIRTY,
    payload,
  };
};
export const setEditFormDataFinancial = (
  payload: serviceEntrepriseModels.formDataFinancialInfosTypeActionPayload,
) => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_FORM_DATA_FINANCIAL,
    payload,
  };
};
export const setValidationRequestedEditFinancialTab = (payload: boolean) => {
  return {
    type: actionTypes.EDIT_ENTREPRISE_FORM_VALIDATION_REQUESTED_FINANCIAL,
    payload,
  };
};
export const setAllEntrepriseWithEstablishementsAction = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_ALL_ENTREPRISE_ESTABLISHEMENTS,
    payload,
  };
};
export const setAllEntrepriseWithEstablishements = (
  payload: serviceEntrepriseModels.companyEstablishementListItem,
) => {
  return {
    type: actionTypes.GET_ALL_ENTREPRISE_ESTABLISHEMENTS_SUCCESS,
    payload,
  };
};
/***************** */
/*
 * +--------------------------+
 * | ETABLISSEMENT section |
 * +--------------------------+
 */
/**

 */
export const addEtablissement = (
  payload: serviceEtablissementModels.Etablissement,
) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT,
    payload,
  };
};
export const addEtablissementFormDataAction = (
  payload: serviceEtablissementModels.Etablissement,
) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_FORM_DATA,
    payload,
  };
};
export const resetEtablissementFormDataAction = () => {
  return {
    type: actionTypes.RESET_ETABLISSEMENT_FORM_DATA,
  };
};
export const addEtablissementCurrentStep = (payload: number) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_STEP,
    payload,
  };
};
export const addEtablissementInfoGeneral = (
  payload: serviceEtablissementModels.Etablissement,
) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_GENERAL_INFO,
    payload,
  };
};
export const addEtablissementSuccess = (payload: number) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_SUCCESS,
    payload,
  };
};
export const addEtablissementFailure = () => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_FAILURE,
  };
};
export const resetEtablissementAddSuccessState = () => {
  return {
    type: actionTypes.RESET_ADD_ETABLISSEMENT_SUCCESS_STATE,
  };
};
export const editEtablissementSuccess = () => {
  return {
    type: actionTypes.EDIT_ETABLISSEMENT_SUCCESS,
  };
};
export const editEtablissementFailure = () => {
  return {
    type: actionTypes.EDIT_ETABLISSEMENT_FAILURE,
  };
};
export const editEtablissementGeneralInfo = (
  payload: serviceEtablissementModels.generalInformation,
) => {
  return {
    type: actionTypes.EDIT_ETABLISSEMENT_GENERAL_INFO,
    payload,
  };
};
export const editEtablissementFinancialInfo = (
  payload: serviceEtablissementModels.financialInformation,
) => {
  return {
    type: actionTypes.EDIT_ETABLISSEMENT_FINANCIAL_INFO,
    payload,
  };
};
export const removeEtablissement = (
  entrepriseId: serviceEtablissementModels.etablissementId,
) => {
  return {
    type: actionTypes.REMOVE_ETABLISSEMENT,
    entrepriseId,
  };
};
export const getEtablissement = (
  entrepriseId: serviceEtablissementModels.etablissementId,
) => {
  return {
    type: actionTypes.GET_ETABLISSEMENT,
    entrepriseId,
  };
};
export const getAllEtablissement = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_ALL_ETABLISSEMENT,
    payload,
  };
};
export const setEtablissementsTotalPagesNumber = (payload: number) => {
  return {
    type: actionTypes.SET_ALL_ETABLISSEMENT_TOTAL_PAGE_NUMBER,
    payload,
  };
};

export const setAllEtablissement = (
  establishements: serviceEtablissementModels.setAllEstablishementAction,
) => {
  return {
    type: actionTypes.SET_ALL_ETABLISSEMENT,
    establishements,
  };
};
export const getEtablissementByIdInfos = (
  payload: serviceEtablissementModels.etablissementId,
) => {
  return {
    type: actionTypes.GET_ETABLISSEMENT_INFOS,
    payload,
  };
};
export const getEtablissementByIdSheet = (
  payload: serviceEtablissementModels.etablissementId,
) => {
  return {
    type: actionTypes.GET_ETABLISSEMENT_SHEET,
    payload,
  };
};
export const setEtablissementByIdInfos = (
  payload: serviceEtablissementModels.generalInformation,
) => {
  return {
    type: actionTypes.SET_ETABLISSEMENT_INFOS,
    payload,
  };
};
export const setEtablissementByIdSheet = (
  payload: serviceEtablissementModels.Etablissement,
) => {
  return {
    type: actionTypes.SET_ETABLISSEMENT_SHEET,
    payload,
  };
};
export const getEtablissementByIdFinancialInfos = (
  payload: serviceEtablissementModels.etablissementId,
) => {
  return {
    type: actionTypes.GET_ETABLISSEMENT_FINANCIAL_INFOS,
    payload,
  };
};
export const setEtablissementByIdFinancialInfos = (
  payload: serviceEtablissementModels.financialInformation,
) => {
  return {
    type: actionTypes.SET_ETABLISSEMENT_FINANCIAL_INFOS,
    payload,
  };
};
export const resetEditEtablissementResponse = () => {
  return {
    type: actionTypes.RESET_EDIT_ETABLISSEMENT_RESPONSE,
  };
};
export const etablissementStateAction = (payload: boolean) => {
  return {
    type: actionTypes.ETABLISSEMENT_STATE,
    payload,
  };
};
/****************** add etablissement form states */
export const setValidationRequestedEtablissement = (payload: boolean) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_VALIDATION_REQUESTED,
    payload,
  };
};
export const setValidationRequestedStep2Etablissement = (payload: boolean) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_VALIDATION_REQUESTED_STEP2,
    payload,
  };
};
export const setValidationRequestedFiscalEtablissement = (payload: boolean) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_VALIDATION_REQUESTED_FISCAL,
    payload,
  };
};
export const setStepsDataEtablissement = (payload: StepItem[]) => {
  return {
    type: actionTypes.ADD_ETABLISSEMENT_STEPS_DATA,
    payload,
  };
};
/*
 * +------------------------------------------------+
 * | Verification  taxes information section |
 * +------------------------------------------------+
 */
/**

 */
export const verifyTaxesInfos = (
  payload: serviceTaxesModels.TaxesInfosPayload,
) => {
  return {
    type: actionTypes.VERIFY_TAXES_INFOS,
    payload,
  };
};
export const resetVerifResponse = () => {
  return {
    type: actionTypes.RESET_VERIF_RESPONSE,
  };
};
export const verifyTaxesSuccess = (
  taxesInfos: serviceTaxesModels.TaxesInfos,
) => {
  return {
    type: actionTypes.VERIFY_TAXES_INOFS_RESPONSE,
    taxesInfos,
  };
};
export const getCodesNAF = () => {
  return {
    type: actionTypes.GET_CODES_NAF,
  };
};
export const getCodesNAFSuccess = (payload: serviceTaxesModels.CodesNAF) => {
  return {
    type: actionTypes.GET_CODES_NAF_SUCCESS,
    payload,
  };
};
/*
 * +----------------------------------------------------------+
 * | Verification taxes information section Etablissement |
 * +----------------------------------------------------------+
 */
/**

 */
export const verifyTaxesInfosEtablissement = (
  payload: serviceTaxesModels.TaxesInfosPayload,
) => {
  return {
    type: actionTypes.VERIFY_TAXES_INFOS_ETABLISSEMENT,
    payload,
  };
};
export const resetVerifResponseEtablissement = () => {
  return {
    type: actionTypes.RESET_VERIF_RESPONSE_ETABLISSEMENT,
  };
};
export const verifyTaxesSuccessEtablissement = (
  taxesInfos: serviceTaxesModels.TaxesInfos,
) => {
  return {
    type: actionTypes.VERIFY_TAXES_INOFS_RESPONSE_ETABLISSEMENT,
    taxesInfos,
  };
};

/*
/*
 * +------------------------------------------------+
 * | Redevance section |
 * +------------------------------------------------+
 */
/**

 */
export const addRedevance = (payload: serviceRedevanceModels.Redevance) => {
  return {
    type: actionTypes.ADD_REDEVANCE,
    payload,
  };
};
export const addRedevanceSuccess = (
  payload: serviceRedevanceModels.Redevance,
) => {
  return {
    type: actionTypes.ADD_ENTREPRISE_SUCCESS,
    payload,
  };
};
/*
 * +------------------------------------------------+
 * |Forme Judidiques section |
 * +------------------------------------------------+
 */
/**

 */
export const getFormesJuridiques = () => {
  return {
    type: actionTypes.GET_FORMES_JURIDIQUES,
  };
};
export const setFormesJuridiques = (
  payload: serviceFormesJuridiquesModels.FormesJuridiques,
) => {
  return {
    type: actionTypes.SET_FORMES_JURIDIQUES,
    payload,
  };
};
/*
 * +------------------------------------------------+
 * | bank info section |
 * +------------------------------------------------+
 */
/**

 */

/*
 * +------------------------------------------------+
 * | Roles section |
 * +------------------------------------------------+
 */
/**

 */

export const getRolesListAction = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_ROLES_LIST,
    payload,
  };
};

export const getEstablishementRolesListAction = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_ETABLISSEMENT_ROLES_LIST,
    payload,
  };
};
export const resetRolesList = () => {
  return {
    type: actionTypes.RESET_ROLES_LIST,
  };
};

export const getRolesListSuccess = (payload: serviceRolesModels.Role[]) => {
  return {
    type: actionTypes.GET_ROLES_LIST_SUCCESS,
    payload,
  };
};
export const setRolesNumber = (payload: number) => {
  return {
    type: actionTypes.SET_ROLES_NUMBER,
    payload,
  };
};
export const setRolesTotalPagesNumber = (payload: number) => {
  return {
    type: actionTypes.SET_ROLES_TOTAL_PAGE_NUMBER,
    payload,
  };
};

export const addRole = (payload: any, _?: () => void) => {
  return {
    type: actionTypes.ADD_ROLE,
    payload,
    _,
  };
};
export const addRoleSuccess = (payload: serviceRolesModels.Role) => {
  return {
    type: actionTypes.ADD_ROLE_SUCCESS,
    payload,
  };
};
export const addRoleFailure = () => {
  return {
    type: actionTypes.ADD_ROLE_FAILURE,
  };
};
export const resetAddResponse = () => {
  return {
    type: actionTypes.RESET_ADD_RESPONSE,
  };
};

export const deleteRole = (payload: any) => {
  return {
    type: actionTypes.DELETE_ROLE_REQUEST,
    payload,
  };
};
export const deleteRoleSuccess = (result: boolean) => ({
  type: actionTypes.DELETE_ROLE_SUCCESS,
  payload: result,
});
export const deleteRoleFailure = () => ({
  type: actionTypes.DELETE_ROLE_FAILURE,
});

export const newRoleChanged = (payload: boolean) => {
  return {
    type: actionTypes.ADD_NEW_ROLE,
    payload,
  };
};
export const getRolesId = (payload: any) => {
  return {
    type: actionTypes.GET_ROLES_ID,
    payload,
  };
};
export const startGetRoleById = (payload: any) => {
  return {
    type: actionTypes.START_GET_ROLES_ID,
    payload,
  };
};

export const storeRolesId = (payload: string) => {
  return {
    type: actionTypes.STORE_ID,
    payload,
  };
};
export const updateRoleRequest = (
  payload: serviceRolesModels.UpdateRolePayload,
) => ({
  type: actionTypes.UPDATE_ROLE_REQUEST,
  payload,
});

export const updateRoleSuccess = (role: any) => ({
  type: actionTypes.UPDATE_ROLE_SUCCESS,
  role,
});

export const updateRoleFailure = () => ({
  type: actionTypes.UPDATE_ROLE_FAILURE,
});

/*
 * +------------------------------------------------+
 * | Users section |
 * +------------------------------------------------+
 */
/**

 */

export const getCompanyUsersListAction = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_COMPANY_USERS_LIST,
    payload,
  };
};
export const getEstablishementUsersListAction = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_ETABLISSEMENT_USERS_LIST,
    payload,
  };
};
export const resetUsersList = () => {
  return {
    type: actionTypes.RESET_USERS_LIST,
  };
};
export const getUsersListAction = (
  payload: servicePaginationModels.PaginationModelType,
) => {
  return {
    type: actionTypes.GET_USERS_LIST,
    payload,
  };
};
export const getUsersListSuccess = (
  payload: serviceAppUserModels.AppUserPayload[],
) => {
  return {
    type: actionTypes.GET_USERS_LIST_SUCCESS,
    payload,
  };
};
export const setUsersNumber = (payload: number) => {
  return {
    type: actionTypes.SET_USERS_NUMBER,
    payload,
  };
};
export const setUsersTotalPagesNumber = (payload: number) => {
  return {
    type: actionTypes.SET_USERS_TOTAL_PAGE_NUMBER,
    payload,
  };
};

export const addUser = (
  payload: serviceAppUserModels.AppUserPayload,
  _?: () => void,
) => {
  return {
    type: actionTypes.ADD_USER,
    payload,
    _,
  };
};
export const addUserSuccess = (payload: serviceAppUserModels.AppUserAction) => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,
    payload,
  };
};
export const resetAddUserSuccess = () => {
  return {
    type: actionTypes.RESET_ADD_USER_SUCCESS,
  };
};
export const addUserFailure = () => {
  return {
    type: actionTypes.ADD_USER_FAILURE,
  };
};
export const resetAddUserResponse = () => {
  return {
    type: actionTypes.RESET_ADD_USER_RESPONSE,
  };
};

export const deleteUser = (payload: string) => {
  return {
    type: actionTypes.DELETE_USER,
    payload,
  };
};
export const deleteUserSuccess = (payload: boolean) => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    payload,
  };
};
export const setUserFormData = (
  payload: serviceAppUserModels.AppUserPayload,
) => {
  return {
    type: actionTypes.SET_USER_FORM_DATA,
    payload,
  };
};
export const resetUserFormData = () => {
  return {
    type: actionTypes.RESET_USER_FORM_DATA,
  };
};
export const setUserFormValidationRequested = (payload: boolean) => {
  return {
    type: actionTypes.SET_USER_FORM_VALIDATION_REQUESTED,
    payload,
  };
};
export const usedEmailErrorAction = () => {
  return {
    type: actionTypes.USED_EMAIL_ACTION,
  };
};
export const getUserByIdAction = (payload: string) => {
  return {
    type: actionTypes.GET_USER_BY_ID,
    payload,
  };
};
export const getUserByIdSuccess = (
  payload: serviceAppUserModels.AppUserDetails,
) => {
  return {
    type: actionTypes.GET_USER_BY_ID_SUCCESS,
    payload,
  };
};
export const setUserFormDataDetails = (
  payload: serviceAppUserModels.AppUserPayload,
) => {
  return {
    type: actionTypes.SET_USER_FORM_DATA_DETAILS,
    payload,
  };
};
export const resetUserFormDataDetails = () => {
  return {
    type: actionTypes.RESET_USER_FORM_DATA_DETAILS,
  };
};
export const setUserFormValidationRequestedDetails = (payload: boolean) => {
  return {
    type: actionTypes.SET_USER_FORM_VALIDATION_REQUESTED_DETAILS,
    payload,
  };
};
export const editUser = (
  payload: serviceAppUserModels.AppUserPayload,
  _?: () => void,
) => {
  return {
    type: actionTypes.EDIT_USER,
    payload,
    _,
  };
};
export const editUserSuccess = (payload: boolean) => {
  return {
    type: actionTypes.EDIT_USER_SUCCESS,
    payload,
  };
};
export const resetEditUserSuccess = () => {
  return {
    type: actionTypes.RESET_EDIT_USER_SUCCESS,
  };
};
export const checkUserByEmail = (payload: string) => {
  return {
    type: actionTypes.CHECK_USER_EMAIL,
    payload,
  };
};
export const checkUserByEmailSuccess = (payload: boolean) => {
  return {
    type: actionTypes.CHECK_USER_EMAIL_SUCCESS,
    payload,
  };
};
export const resetcheckUserEmail = () => {
  return {
    type: actionTypes.RESET_CHECK_USER_EMAIL,
  };
};
export const resetPassword = (
  payload: serviceAppUserModels.setPasswordBody,
) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    payload,
  };
};
export const resetPasswordSuccesAction = (payload: boolean) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    payload,
  };
};
export const setActiveEmailError = (payload: boolean) => {
  return {
    type: actionTypes.SET_ACTIVE_EMAIL_ERROR,
    payload,
  };
};
export const resetActiveEmailError = (payload: boolean) => {
  return {
    type: actionTypes.RESET_ACTIVE_EMAIL_ERROR,
    payload,
  };
};
export const checkUserByEmailInvalid = (payload: boolean) => {
  return {
    type: actionTypes.EMAIL_INVALID,
    payload,
  };
};
export const resetCheckUserByEmailInvalid = () => {
  return {
    type: actionTypes.RESET_EMAIL_INVALID,
  };
};

/*
/*
 * +------------------------------------------------+
 * | CombineEtablissement section |
 * +------------------------------------------------+
 */
/**

 */
export const combineEtablissementAction = (payload: boolean) => {
  return {
    type: actionTypes.COMBINE_ETABLISSEMENT,
    payload,
  };
};
/*
/*
 * +------------------------------------------------+
 * | TypeMachine section |
 * +------------------------------------------------+
 */
/**

 */

export const fetchCompanyMachineTypesRequest = (
  params: serviceCampanyMachineTypeModels.FetchCompanyMachineTypeParams,
  _?: (machines: serviceCampanyMachineTypeModels.MachineType[]) => void,
) => ({
  type: actionTypes.FETCH_COMPANY_MACHINE_TYPES_REQUEST,
  payload: { ...params, _ },
});

export const fetchCompanyMachineTypesSuccess = (
  data: serviceCampanyMachineTypeModels.MachineTypeResponse,
) => ({
  type: actionTypes.FETCH_COMPANY_MACHINE_TYPES_SUCCESS,
  payload: data,
});

export const fetchCompanyMachineTypesFailure = (error: any) => ({
  type: actionTypes.FETCH_COMPANY_MACHINE_TYPES_FAILURE,
  payload: error,
});

/*
/*
 * +------------------------------------------------+
 * | MachineSuperType section |
 * +------------------------------------------------+
 */
/**

 */

export const fetchMachineSuperTypesRequest = () => ({
  type: actionTypes.FETCH_MACHINE_SUPER_TYPES_REQUEST,
});

export const fetchMachineSuperTypesSuccess = (
  data: serviceCampanyMachineTypeModels.FetchMachineSuperTypesSuccessAction,
) => ({
  type: actionTypes.FETCH_MACHINE_SUPER_TYPES_SUCCESS,
  payload: data,
});

export const fetchMachineSuperTypesFailure = (error: any) => ({
  type: actionTypes.FETCH_MACHINE_SUPER_TYPES_FAILURE,
  payload: error,
});
/*
/*
 * +------------------------------------------------+
 * | Create MachineType section |
 * +------------------------------------------------+
 */
/**

 */

export const addCompanyMachineTypeRequest = (
  payload: serviceCampanyMachineTypeModels.AddCompanyMachineTypeRequestPayload,
  _?: () => void,
) => ({
  type: actionTypes.ADD_COMPANY_MACHINE_TYPE_REQUEST,

  payload,
  _,
});

export const addCompanyMachineTypeSuccess = (
  payload: serviceCampanyMachineTypeModels.AddCompanyMachineTypeSuccessPayload,
) => ({
  type: actionTypes.ADD_COMPANY_MACHINE_TYPE_SUCCESS,
  payload,
});

export const addCompanyMachineTypeFailure = (
  payload: serviceCampanyMachineTypeModels.AddCompanyMachineTypeFailurePayload,
) => ({
  type: actionTypes.ADD_COMPANY_MACHINE_TYPE_FAILURE,
  payload,
});
/*
/*
 * +------------------------------------------------+
 * | Update MachineType section |
 * +------------------------------------------------+
 */
/**

 */
export const updateCompanyMachineTypeRequest = (
  machineTypeId: number,
  body: string,
  _?: () => void,
  toDelete?: boolean,
) => ({
  type: actionTypes.UPDATE_COMPANY_MACHINE_TYPE_REQUEST,
  payload: { machineTypeId, body, _, toDelete },
});

export const updateCompanyMachineTypeSuccess = (data: any) => ({
  type: actionTypes.UPDATE_COMPANY_MACHINE_TYPE_SUCCESS,
  payload: data,
});

export const updateCompanyMachineTypeFailure = (error: string) => ({
  type: actionTypes.UPDATE_COMPANY_MACHINE_TYPE_FAILURE,
  payload: error,
});

export const syncEstablishmentMachinesRequest = (establishementId: number) => ({
  type: actionTypes.SYNC_ESTABLISHMENT_MACHINES_REQUEST,
  payload: { establishementId: establishementId },
});

export const syncEstablishmentMachinesSuccess = (
  data: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState,
) => ({
  type: actionTypes.SYNC_ESTABLISHMENT_MACHINES_SUCCESS,
  payload: data,
});

export const syncEstablishmentMachinesFailure = (error: string) => ({
  type: actionTypes.SYNC_ESTABLISHMENT_MACHINES_FAILURE,
  payload: error,
});

export const EstablishmentListMachinesRequest = (
  establishementId: number,
  page?: number,
  pageSize?: number,
) => ({
  type: actionTypes.ESTABLISHMENT_List_MACHINES_REQUEST,
  payload: { establishementId: establishementId, page, pageSize },
});

export const EstablishmentListMachinesSuccess = (
  data: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState,
) => ({
  type: actionTypes.ESTABLISHMENT_List_MACHINES_SUCCESS,
  payload: data,
});

export const EstablishmentListMachinesFailure = (error: string) => ({
  type: actionTypes.ESTABLISHMENT_List_MACHINES_FAILURE,
  payload: error,
});
/*
/*
 * +------------------------------------------------+
 * |  ListMachinesuperType section |
 * +------------------------------------------------+
 */
/**

 */

export const EstablishmentListMachinesSuperRequest = (
  establishementId: number,
  _?: (
    list: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState[],
  ) => void,
) => ({
  type: actionTypes.ESTABLISHMENT_List_MACHINES_SUPER_REQUEST,
  payload: { establishementId: establishementId },
  _,
});

export const EstablishmentListMachinesSuperSuccess = (
  data: serviceEtablissmentMachineTypeModels.SyncEstablishmentMachinesState,
) => ({
  type: actionTypes.ESTABLISHMENT_List_MACHINES_SUPER_SUCCESS,
  payload: data,
});

export const EstablishmentListMachinesSuperFailure = (error: string) => ({
  type: actionTypes.ESTABLISHMENT_List_MACHINES_SUPER_FAILURE,
  payload: error,
});

export const affectMachineToTypesRequest = (
  payload: serviceEtablissmentMachineTypeModels.AffectMachineToTypesPayload,
  _?: () => void,
) => ({
  type: actionTypes.AFFECT_MACHINE_TO_TYPES_REQUEST,
  payload,
  _,
});

export const affectMachineToTypesSuccess = (
  response: serviceEtablissmentMachineTypeModels.AffectMachineToTypesSuccessAction,
) => ({
  type: actionTypes.AFFECT_MACHINE_TO_TYPES_SUCCESS,
  response,
});

export const affectMachineToTypesFailure = (
  error: serviceEtablissmentMachineTypeModels.AffectMachineToTypesFailureAction,
) => ({
  type: actionTypes.AFFECT_MACHINE_TO_TYPES_FAILURE,
  error,
});

/****** */
export const getListFilterEtablissementsRequest = (
  params: serviceEtablissmentMachineTypeModels.PayloadEtablissement,
) => ({
  type: actionTypes.GET_LIST_ESTABLISSEMENTS_REQUEST_ALL,
  payload: params,
});

export const getListFilterEtablissementsSuccess = (data: any) => ({
  type: actionTypes.GET_LIST_ESTABLISSEMENTS_SUCCESS_ALL,
  payload: data,
});

export const getListFilterEtablissementsFailure = (error: string) => ({
  type: actionTypes.GET_LIST_ESTABLISSEMENTS_FAILURE_ALL,
  payload: error,
});
/******************************* */
//get GelListIntervallesAbonnment
export const getListIntervallesAbonnementRequest = () => ({
  type: actionTypes.GET_LIST_INTERVALLES_ABONNEMENT_REQUEST,
});

export const getListIntervallesAbonnementSuccess = (
  data: serviceEtablissmentMachineTypeModels.DataSuccess,
) => ({
  type: actionTypes.GET_LIST_INTERVALLES_ABONNEMENT_SUCCESS,
  payload: data,
});

export const getListIntervallesAbonnementFailure = (error: any) => ({
  type: actionTypes.GET_LIST_INTERVALLES_ABONNEMENT_FAILURE,
  payload: error,
});

export const getAbonnementByIdRequest = (abonnementId: number) => ({
  type: actionTypes.GET_ABONNEMENT_BY_ID_REQUEST,
  payload: { abonnementId },
});

export const getAbonnementByIdSuccess = (
  data: serviceEtablissmentMachineTypeModels.DataAbonnementById,
) => ({
  type: actionTypes.GET_ABONNEMENT_BY_ID_SUCCESS,
  payload: data,
});

export const getAbonnementByIdFailure = (error: any) => ({
  type: actionTypes.GET_ABONNEMENT_BY_ID_FAILURE,
  payload: error,
});
export const resetAbonnementState = () => ({
  type: actionTypes.RESET_ABONNEMENT_STATE,
});
interface ICreatAbonnementdata {
  designation: string;
  description: string;
  totalHT: string;
  totalTTC: number;
  vat: string;
  intervalleId: number;
}
export const createAbonnementRequest = (
  establishementId: number,
  data: ICreatAbonnementdata,
  _?: () => void,
) => ({
  type: actionTypes.CREATE_ABONNEMENT_REQUEST,
  payload: { establishementId, data, _ },
});

export const createAbonnementSuccess = (
  response: serviceEtablissmentMachineTypeModels.createAbonnementResponse,
) => ({
  type: actionTypes.CREATE_ABONNEMENT_SUCCESS,
  payload: response,
});

export const createAbonnementFailure = (error: any) => ({
  type: actionTypes.CREATE_ABONNEMENT_FAILURE,
  payload: error,
});

//get list abonnment

export const fetchAbonnementDataRequest = (
  establishementId: number,
  page?: number,
  pageSize?: number,
) => ({
  type: actionTypes.FETCH_ABONNEMENT_DATA_REQUEST,
  payload: { establishementId, page, pageSize },
});

export const fetchAbonnementDataSuccess = (
  response: serviceEtablissmentMachineTypeModels.createAbonnementResponse,
) => ({
  type: actionTypes.FETCH_ABONNEMENT_DATA_SUCCESS,
  payload: response,
});

export const fetchAbonnementDataFailure = (error: string) => ({
  type: actionTypes.FETCH_ABONNEMENT_DATA_FAILURE,
  payload: error,
});
//update abonnment

export const updateAbonnementRequest = (
  abonnementId: number,
  data: ICreatAbonnementdata,
  _?: () => void,
) => ({
  type: actionTypes.UPDATE_ABONNEMENT_REQUEST,
  payload: { abonnementId, data, _ },
});

export const updateAbonnementSuccess = (
  response: serviceEtablissmentMachineTypeModels.createAbonnementResponse,
) => ({
  type: actionTypes.UPDATE_ABONNEMENT_SUCCESS,
  payload: response,
});

export const updateAbonnementFailure = (error: string) => ({
  type: actionTypes.UPDATE_ABONNEMENT_FAILURE,
  payload: error,
});

// export const getExistingCompanysRequest = () => ({
//   type: actionTypes.FETCH_DROPDOWN_LIST_OF_CAMPANY_REQUEST,
// });

export const getListStatutsEstablishements = () => ({
  type: actionTypes.GET_LIST_STATUTS_ESTABLISSEMENTS_REQUEST,
});

export const getListStatutsEstablishementsSuccess = (response: any) => ({
  type: actionTypes.GET_LIST_STATUTS_ESTABLISSEMENTS_SUCCESS,
  payload: response,
});

export const getListStatutsEstablishementsFailure = (error: string) => ({
  type: actionTypes.GET_LIST_STATUTS_ESTABLISSEMENTS_FAILURE,
  payload: error,
});

/*
 * +--------------------------+
 * | Update User section |
 * +--------------------------+
 */
/**

 */
interface IupdateUser {
  lastName?: string;
  email?: string;
  firstName?: string;
  phoneNumber?: string;
}
export const updateUserRequest = (payload: IupdateUser) => ({
  type: actionTypes.UPDATE_USER_SECTION_REQUEST,
  payload: payload,
});

export const updateUserSuccess = (response: IupdateUser) => ({
  type: actionTypes.UPDATE_USER_SECTION_SUCCESS,
  payload: response,
});

export const updateUserFailure = (error: string) => ({
  type: actionTypes.UPDATE_USER_SECTION_FAILURE,
  payload: error,
});

/*
 * +--------------------------+
 * | Update User Password section |
 * +--------------------------+
 */
/**

 */

interface IupdateUserPassword {
  oldPassword: string;
  password: string;
}

export const updateUserPassworRequest = (payload: IupdateUserPassword) => ({
  type: actionTypes.UPDATE_USER_PASSWORD_SECTION_REQUEST,
  payload: payload,
});

export const updateUserPassworSuccess = (response: IupdateUser) => ({
  type: actionTypes.UPDATE_USER_PASSWORD_SECTION_SUCCESS,
  payload: response,
});

export const updateUserPassworFailure = (error: string) => ({
  type: actionTypes.UPDATE_USER_PASSWORD_SECTION_FAILURE,
  payload: error,
});
/************ */
// update abonment status

export const updateAbonnementStatusRequest = (
  idAbonnement: number,
  data: boolean,
  _?: () => void,
) => ({
  type: actionTypes.UPDATE_ABONNEMENT_STATUS_REQUEST,
  payload: { idAbonnement, data, _ },
});

export const updateAbonnementStatusSuccess = (response: any) => ({
  type: actionTypes.UPDATE_ABONNEMENT_STATUS_SUCCESS,
  payload: response,
});

export const updateAbonnementStatusFailure = (error: string) => ({
  type: actionTypes.UPDATE_ABONNEMENT_STATUS_FAILURE,
  payload: error,
});

/****** *****/
// get list facture

export const getListFacutreRequest = (
  idEstablishement: number,
  page?: number,
  pageSize?: number,
) => ({
  type: actionTypes.GET_LIST_FACTURE_REQUEST,
  payload: { idEstablishement, page, pageSize },
});

export const getListFacutreSuccess = (
  response: serviceEtablissmentMachineTypeModels.ListFacutre[],
  pagination: {
    currentPage: number;
    pageSize: number;
    nbPage: number;
  },
) => ({
  type: actionTypes.GET_LIST_FACTURE_SUCCESS,
  payload: { response, pagination },
});

export const getListFacutreFailure = (error: string) => ({
  type: actionTypes.GET_LIST_FACTURE_FAILURE,
  payload: error,
});
/********* ********/
//get facture by id

export const getFactureByIdRequest = (idFacture: number) => ({
  type: actionTypes.GET_FACTURE_BY_ID_REQUEST,
  payload: { idFacture },
});

export const getFactureByIdSuccess = (
  response: serviceEtablissmentMachineTypeModels.ListFacutreDetails,
) => ({
  type: actionTypes.GET_FACTURE_BY_ID_SUCCESS,
  payload: response,
});

export const getFactureByIdFailure = (error: string) => ({
  type: actionTypes.GET_FACTURE_BY_ID_FAILURE,
  payload: error,
});

//get List Abonnements Client

export const getListAbonnementsClientRequest = (establishementId: number) => ({
  type: actionTypes.GET_LIST_ABONNEMENTS_CLIENT_REQUEST,
  payload: { establishementId },
});

export const getListAbonnementsClientSuccess = (
  response: serviceEtablissmentMachineTypeModels.ListAbonnementsClient[],
) => ({
  type: actionTypes.GET_LIST_ABONNEMENTS_CLIENT_SUCCESS,
  payload: response,
});

export const getListAbonnementsClientFailure = (error: string) => ({
  type: actionTypes.GET_LIST_ABONNEMENTS_CLIENT_FAILURE,
  payload: error,
});
//activate Deactivate Establishement

export const activateDeactivateEstablishementRequest = (
  idEstablishement: number,
  data: boolean,
  _?: () => void,
) => ({
  type: actionTypes.ACTIVATE_DEACTIVATE_ESTABLISSEMENT_REQUEST,
  payload: { idEstablishement, data, _ },
});

export const activateDeactivateEstablishementSuccess = (response: any) => ({
  type: actionTypes.ACTIVATE_DEACTIVATE_ESTABLISSEMENT_SUCCESS,
  payload: response,
});

export const activateDeactivateEstablishementFailure = (error: string) => ({
  type: actionTypes.ACTIVATE_DEACTIVATE_ESTABLISSEMENT_FAILURE,
  payload: error,
});

//activate Deactivate Campany

export const activateDeactivateCampanyRequest = (
  idCompany: number | undefined,
  data: boolean,
  _?: () => void,
) => ({
  type: actionTypes.ACTIVATE_DEACTIVATE_CAMPANY_REQUEST,
  payload: { idCompany, data, _ },
});

export const activateDeactivateCampanySuccess = (response: any) => ({
  type: actionTypes.ACTIVATE_DEACTIVATE_CAMPANY_SUCCESS,
  payload: response,
});

export const activateDeactivateCampanyFailure = (error: string) => ({
  type: actionTypes.ACTIVATE_DEACTIVATE_CAMPANY_FAILURE,
  payload: error,
});

// forget password link

export const forgetPasswordLinkRequest = (payload: string) => {
  return {
    type: actionTypes.FORGET_PASSWORD_LINK_REQUEST,
    payload: payload,
  };
};

export const forgetPasswordLinkSuccess = (response: any) => {
  return {
    type: actionTypes.FORGET_PASSWORD_LINK_SUCCESS,
    payload: response,
  };
};

export const forgetPasswordLinkFailure = (error: string) => {
  return {
    type: actionTypes.FORGET_PASSWORD_LINK_FAILURE,
    payload: error,
  };
};

// reset password link
export const resetPasswordLinkRequest = (
  payload: serviceAuthModels.IresetPasswordPaylod,
) => {
  return {
    type: actionTypes.RESET_PASSWORD_LINK_REQUEST,
    payload: payload,
  };
};

export const resetPasswordLinkSuccess = (response: any) => {
  return {
    type: actionTypes.RESET_PASSWORD_LINK_SUCCESS,
    payload: response,
  };
};

export const resetPasswordLinkFailure = (error: string) => {
  return {
    type: actionTypes.RESET_PASSWORD_LINK_FAILURE,
    payload: error,
  };
};

export const toggleToast = () => ({
  type: actionTypes.TOGGLE_TOAST,
});
export const clearError = () => ({
  type: actionTypes.CLEAR_ERROR,
});
