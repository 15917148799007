import ToggleElement from '../../../../components/Input/ToggleElement';
import ToggleSelect from '../../../../components/Input/ToggleSelect';

export default function StockFilterToggle({
  active,
  onchange,
  isForMenu,
  isForCategorie,
  counts,
}: {
  active: number;
  onchange: (newStatus: number) => void;
  isForMenu?: boolean;
  isForCategorie?: boolean;
  counts: {
    epuise?: number;
    stock?: number;
    epuise_today?: number;
    visible?: number;
    invisible?: number;
    all?: number;
  };
}) {
  return (
    <div className="StockFilterToggle">
      <ToggleSelect className="stockToggle">
        <ToggleElement
          onChange={(e) => onchange(-1)}
          stock
          label={'Tous'}
          id={'Tous'}
          active={active == -1}
          slot={isForCategorie ? counts.all : counts?.all ?? 0}
        />
        <ToggleElement
          onChange={(e) => onchange(0)}
          stock
          label={isForCategorie ? 'Visible' : 'En Stock'}
          id={'En Stock'}
          slot={isForCategorie ? counts.visible : counts?.stock ?? 0}
          active={active == 0}
        />
        <ToggleElement
          onChange={(e) => onchange(1)}
          stock
          label={isForCategorie ? 'Invisible' : 'Epuisé'}
          id={'Epuisé'}
          slot={isForCategorie ? counts?.invisible : counts.epuise}
          active={active == 1}
        />
        {!(isForMenu || isForCategorie) && (
          <ToggleElement
            onChange={(e) => onchange(2)}
            stock
            label={'Epuisé aujourd’hui'}
            id={'Epuisé aujourd’hui'}
            slot={
              isForCategorie ? counts?.invisible : counts?.epuise_today ?? 0
            }
            active={active == 2}
          />
        )}
      </ToggleSelect>
    </div>
  );
}
