import {
  Article,
  ArticleAllergen,
  ArticleSaved,
  Composition,
} from '../../models/services/module.catalogue';
export function checkRequiredFields<T extends object, K extends keyof T>(
  model: T,
  requiredFields: K[],
): boolean {
  return requiredFields.every((field) => {
    const value = model[field];
    if (value === undefined || value === '') {
      return false;
    }
    if (typeof value === 'string') {
      return value.trim() !== '';
    }
    return true;
  });
}
export function hasObjectChanged(
  obj1: Record<string, any> | null | undefined,
  obj2: Record<string, any> | null | undefined,
): boolean {
  if (obj1 === obj2) return false;

  // If either object is null or undefined, they are different
  if (!obj1 || !obj2) return true;

  const keys1 = Object.keys(obj1);
  if (keys1.length !== Object.keys(obj2).length) return true;

  return keys1.some(
    (key) =>
      obj1[key] !== obj2[key] &&
      (typeof obj1[key] !== 'object' ||
        typeof obj2[key] !== 'object' ||
        hasObjectChanged(obj1[key], obj2[key])),
  );
}

export const initNewArticle = (): Partial<Article> => {
  return {
    visible: true,
    showNameDescription: true,
    displayCaisseImage: true,
    minNbrSelectionComposition: 1,
    maxNbrSelectionComposition: 1,
    displaySecondScreen: true,
  };
};
export const requiredFieldsArticle: Array<keyof Article> = [
  'idFamily',
  'idSubFamily',
  'name',
  'idTvaDelivery',
  'idTvaEmporter',
  'idTvaOnSite',
];
let i = 0;
export function convertArticleToComposition(
  article: ArticleSaved,
): Composition {
  return {
    id: article.id,
    idArticle: article.id as number,
    idComposition: i++,
    showIngredient: true,
    isActif: true,
    inclus: false,
    maxNbrSelection: '' + 1,
    priceOnSite: article.priceOnSite ? '' + article.priceOnSite : undefined,
    priceEmporter: article.priceEmporter
      ? '' + article.priceEmporter
      : undefined,
    priceDelivery: article.priceDelivery
      ? '' + article.priceDelivery
      : undefined,
    name: article.name,
    url: article.url,
    unite: '0.00',

    selected: false,
  };
}
export const convertArticlesToCompositions = (
  articles: ArticleSaved[],
): Composition[] => {
  return articles.map((article) => convertArticleToComposition(article));
};
export function scrollToBottomOfTable(tableContainerId: string) {
  const tableContainer = document.getElementById(tableContainerId);
  if (tableContainer) {
    const tableBottomElement = tableContainer.querySelector(
      'tbody tr:last-child',
    );
    if (tableBottomElement) {
      tableBottomElement.scrollIntoView({ behavior: 'smooth', block: 'end' });

      // Add 100 pixels offset to the scroll position
    }
  }
}
export const removeIdFields = (
  compositions: Composition[],
): Omit<Composition, 'id'>[] =>
  compositions.map(({ id, ...rest }) => ({
    ...rest,
    idComposition: rest.idArticle,
  }));
export const formatEditComposition = (
  compositions: Composition[],
): Composition[] =>
  compositions.map((c) => {
    const idComposition = c?.idIngredient ?? c.id ?? 0;
    const updatedComposition = { ...c, idComposition };
    if (c.idIngredient === undefined) {
      delete updatedComposition.id;
    }
    return updatedComposition;
  });
export const removeIdFieldsAllergn = (
  algs: ArticleAllergen[],
): { idAllergen: number }[] => algs.map((alg) => ({ idAllergen: alg.id }));
export const formatEditFieldsAllergn = (
  algs: ArticleAllergen[],
): { idAllergen?: number; id?: number }[] =>
  algs.map((alg) =>
    alg.id && alg.idAllergen
      ? { idAllergen: alg.idAllergen, id: alg.id }
      : { idAllergen: alg.id },
  );
export function isValidFloatNumber(value: string): boolean {
  return (
    typeof value === 'number' ||
    (typeof value === 'string' && !isNaN(parseFloat(value)))
  );
}
export function formatTva(taux: number | string): string {
  const numericTaux = parseFloat(taux.toString());
  if (numericTaux === 0) {
    return '00.00';
  }
  const formattedValue = numericTaux.toFixed(2);
  const [integerPart, decimalPart] = formattedValue.split('.');
  const paddedIntegerPart = integerPart.padStart(2, '0');
  return `${paddedIntegerPart}.${decimalPart}`;
}
export function formatTvaWithoutZero(taux: number | string): string {
  const numericTaux = parseFloat(taux.toString());
  if (numericTaux === 0) {
    return '00.00';
  }
  const formattedValue = numericTaux.toFixed(2);
  let [integerPart, decimalPart] = formattedValue.split('.');
  if (integerPart === '0' && decimalPart) {
    return `${integerPart}.${decimalPart} %`;
  }
  if (integerPart.startsWith('0') && integerPart.length > 1) {
    integerPart = integerPart.slice(1);
  }
  return `${integerPart}.${decimalPart} %`;
}
export const removeDuplicates = (
  compositions: Composition[],
): Composition[] => {
  const compositionsWithId = compositions.filter(
    (composition) => composition.id !== undefined,
  );
  const uniqueMap = new Map();
  for (const composition of compositionsWithId) {
    if (!uniqueMap.has(composition.id)) {
      uniqueMap.set(composition.id, composition);
    }
  }
  const uniqueCompositions = Array.from(uniqueMap.values());
  const compositionsWithoutId = compositions.filter(
    (composition) => composition.id === undefined,
  );

  return uniqueCompositions.concat(compositionsWithoutId);
};
export function CdeepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false; // Different number of keys
  for (let key of keys1) {
    if (!keys2.includes(key) || !CdeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}
