import React from 'react';

interface EtablissementIconProps {
  strokeColor?: string;
}

const EtablissementIcon: React.FC<EtablissementIconProps> = ({
  strokeColor,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={strokeColor || '#2A66C6'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.54217 3.21265C3.66907 2.78967 4.05839 2.5 4.5 2.5H19.5C19.9416 2.5 20.3309 2.78967 20.4578 3.21265L21.9578 8.21265C21.9858 8.30587 22 8.40268 22 8.5C22 10.7091 20.2091 12.5 18 12.5C16.8053 12.5 15.7329 11.9762 15 11.1458C14.2671 11.9762 13.1947 12.5 12 12.5C10.8053 12.5 9.73295 11.9762 9 11.1458C8.26706 11.9762 7.19469 12.5 6 12.5C3.79086 12.5 2 10.7091 2 8.5C2 8.40268 2.01421 8.30587 2.04217 8.21265L3.54217 3.21265ZM10 8.5C10 9.60457 10.8954 10.5 12 10.5C13.1046 10.5 14 9.60457 14 8.5C14 7.94772 14.4477 7.5 15 7.5C15.5523 7.5 16 7.94772 16 8.5C16 9.60457 16.8954 10.5 18 10.5C19.0601 10.5 19.9275 9.67524 19.9957 8.6324L18.756 4.5H5.24403L4.00431 8.63239C4.07246 9.67524 4.93992 10.5 6 10.5C7.10457 10.5 8 9.60457 8 8.5C8 7.94772 8.44772 7.5 9 7.5C9.55228 7.5 10 7.94772 10 8.5Z"
      />
      <path
        fill={strokeColor || '#2A66C6'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 19.5V11H3V20.5C3 21.0523 3.44772 21.5 4 21.5H20C20.5523 21.5 21 21.0523 21 20.5V11H19V19.5H5Z"
      />
    </svg>
  );
};

export default EtablissementIcon;
