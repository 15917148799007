import React from 'react';
import { ModalProps } from '../../models/services/module.modals';
import { languageData } from '../../i18n';
import ButtonElement from '../button/button';
export default function CustomModalController(props: ModalProps) {
  const modalContentBody = (
    <div className="body-wrapper fl_col gp5 w-full">{props.content}</div>
  );
  const modalContentFooter = (
    <div style={{ paddingTop: '56px' }} className="wrapperFooter">
      <div className="fl_row gp12 jc_fe">
        {props.cancel && (
          <ButtonElement
            type={'TextButton'}
            text={languageData.global.Modal.cancel}
            buttonSize="large"
            onClick={props.onClickClose}
            buttonStyle="btn-primary-light"
          />
        )}
        {props.save && (
          <ButtonElement
            type={'TextButton'}
            text={languageData.global.Modal.save}
            buttonSize="large"
            buttonStyle="btn-primary"
            onClick={props.handleSave}
          />
        )}
      </div>
    </div>
  );
  return {
    modalContentBody,
    modalContentFooter,
  };
}
