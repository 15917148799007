import React, { ReactNode } from 'react';

interface Tbody {
  children: ReactNode;
}

const Tbody: React.FC<Tbody> = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export default Tbody;
