import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  factureDetails: null,
  error: null,
};

const factureReducerDetails = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_FACTURE_BY_ID_REQUEST:
      return { ...state, loading: true };
    case actionTypes.GET_FACTURE_BY_ID_SUCCESS:
      return { ...state, loading: false, factureDetails: action.payload };
    case actionTypes.GET_FACTURE_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default factureReducerDetails;
