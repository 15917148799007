/**
 * The function `compareObjects` compares two objects based on their keys and values to determine if
 * they are equal.
 * @param obj1 - `Record<string, any>` - This type indicates that `obj1` is an object where the keys
 * are strings and the values can be of any type.
 * @param obj2 - obj2 is a variable representing an object with key-value pairs.
 * @returns The `compareObjects` function is returning a boolean value. It returns `true` if the two
 * input objects `obj1` and `obj2` have the same keys and values for each key, and `false` otherwise.
 */
export default function compareObjects(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): boolean {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if the values for each key are equal
  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    // Handle arrays
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (
        value1.length !== value2.length ||
        !value1.every((val, index) => compareObjects(val, value2[index]))
      ) {
        return false;
      }
    }
    // Handle primitive types and functions
    else if (
      typeof value1 !== 'object' ||
      value1 === null ||
      typeof value2 !== 'object' ||
      value2 === null
    ) {
      if (value1 !== value2) {
        return false;
      }
    } else {
      // Recursive call for nested objects
      if (!compareObjects(value1, value2)) {
        return false;
      }
    }
  }

  // Objects are the same
  return true;
}
