import {
  Composition,
  Level,
  LevelArticle,
  LevelArticleTypeOrderPrice,
  Menu,
  MenuObjectPayload,
  TypeOrderMenuLevel,
} from '../../models/services/module.catalogue';
import surplaceIcons from '../../assets/icons/SurplaceIcon.svg';
import aemporterIcons from '../../assets/icons/AemporterIcon.svg';
import livraisonIcons from '../../assets/icons/LivraisonIcon.svg';
import driveIcons from '../../assets/icons/DriveIcon.svg';
import { Category } from '../../models/services/module.category';
export const getMethodIcon = (method: string) => {
  switch (method.toLowerCase()) {
    case 'sur place':
      return surplaceIcons;
    case 'a emporter':
      return aemporterIcons;
    case 'livraison':
      return livraisonIcons;
    case 'drive':
      return driveIcons;
    default:
      return surplaceIcons;
  }
};
export const requiredFieldsLevlMenus: Array<keyof MenuObjectPayload> = ['name'];
export const requiredFieldsLevlCategory: Array<keyof Category> = ['name'];

export const requiredFieldsLevl: Array<keyof Level> = ['name'];
export function transformComposition(
  compositions: Composition[],
): Partial<LevelArticle>[] {
  return compositions.map((composition) => {
    return {
      ...composition,
      id: composition.id == composition.idArticle ? 0 : composition.id,
      idArticle: composition.idArticle,
      levelArticleScreens: composition.screens?.map((_) => ({
        id: _.screenId ? _.id : 0,
        screenId: _.screenId ?? _.id,
      })),
      levelArticleTypeOrderPrices: composition.levelArticleTypeOrderPrices,
    };
  });
}
const getPrice = (name: string, composition: Composition) => {
  return name == 'Sur place'
    ? composition.priceOnSite
    : name == 'Livraison'
      ? composition.priceDelivery
      : composition.priceEmporter;
};
export function containsPurshaseMethodIgnoreCase(
  list: string[],
  searchTerm: string,
): boolean {
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  return list.some((item) => item.toLowerCase() === lowerCaseSearchTerm);
}
export function containsPurshaseMethodObject(
  list: TypeOrderMenuLevel[],
  id: number,
): boolean {
  return list.some((item) => item.id === id);
}
