import React from 'react';
import RightArrowIconGrey from '../../assets/icons/RightArrowIconGrey';

interface MenuItemLeftNavProps {
  label: string;
  icon?: React.ReactNode;
  isActive: boolean;
  //  onClick?: () => void;
}
const MenuItemCatalogueComponent: React.FC<MenuItemLeftNavProps> = ({
  label,
  icon,
  isActive,
  // onClick,
}) => {
  const renderIcon = () => {
    if (icon) {
      return React.cloneElement(icon as React.ReactElement, {
        strokeColor: isActive ? '#2A66C6' : '#475467',
      });
    }
  };
  const renderRightArrow = () => {
    return React.cloneElement(<RightArrowIconGrey />, {
      fillColor: isActive ? '#2A66C6' : '#475467',
    });
  };

  return (
    <div className={`MenuItemLeftNav rd8 pd8 ${isActive ? 'active' : ''}`}>
      <div className="menu-wrapper fl_row gp12 w-full jc_s ai_c ">
        <div className="fl_row ai_c gp8">
          <div className="menuIcon fl_row">{renderIcon()}</div>
          <p
            className="label p15 w-500 DarkJungleGreen-txt pd2-t-b"
            style={{ color: '#475467' }}
          >
            {label}
          </p>
        </div>
        {renderRightArrow()}
      </div>
    </div>
  );
};

export default MenuItemCatalogueComponent;
