import React from 'react';

interface Props {
  strokeColor?: string;
}

const TagIcon: React.FC<Props> = ({ strokeColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8289 7.05739C10.3082 7.57809 9.46396 7.57809 8.94326 7.05739C8.42256 6.53669 8.42256 5.69247 8.94326 5.17177C9.46396 4.65108 10.3082 4.65108 10.8289 5.17177C11.3496 5.69247 11.3496 6.53669 10.8289 7.05739Z"
        stroke={strokeColor}
        strokeWidth="1.3"
      />
      <path
        d="M6.2738 3.12737C6.83175 2.56943 7.60237 2.27988 8.38341 2.33471L11.0263 2.52026C12.3459 2.61291 13.3879 3.65491 13.4806 4.97455L13.6661 7.61743C13.721 8.39847 13.4314 9.16909 12.8735 9.72704L8.49656 14.1039C7.441 15.1595 5.74108 15.171 4.69968 14.1296L1.87126 11.3012C0.829858 10.2598 0.841338 8.55984 1.8969 7.50428L6.2738 3.12737Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TagIcon;
