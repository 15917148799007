import { StepItem } from './addCompanyStepsDataInitialState';

export interface StepsProperties {
  activePartOne?: boolean;
  activePartTwo?: boolean;
  donePartOne?: boolean;
  donePartTwo?: boolean;
  isActive?: boolean;
  isDone?: boolean;
}
export const propertiesData = [
  {
    name: 'Entreprise',
    properties: {
      activePartOne: false,
      activePartTwo: true,
      donePartOne: true,
      donePartTwo: false,
      isActive: true,
      isDone: false,
    },
  },
  {
    name: 'Etablissement',
    properties: {
      activePartOne: false,
      activePartTwo: false,
      donePartOne: true,
      donePartTwo: true,
      isActive: false,
      isDone: true,
    },
  },
  {
    name: 'En approbation',
    properties: {
      isActive: true,
      isDone: false,
    },
  },
];
export const updateStepProperties = (stepsData: StepItem[], number: number) => {
  const updatedStepsData = [...stepsData];
  const entrepriseStep = updatedStepsData.find((step) => step.Name === 'Entreprise');

  if (entrepriseStep) {
    switch (number) {
      case 1:

        Object.assign(entrepriseStep, {
          activePartOne: false,
          activePartTwo: true,
          donePartOne: true,
          donePartTwo: false,
        });
        break;
      case 2:

        Object.assign(entrepriseStep, {
          isDone: true,
          activePartOne: true,
          activePartTwo: true,
          donePartOne: true,
          donePartTwo: true,
        });


        const approvalStep = updatedStepsData.find((step) => step.Name === 'En approbation');
        if (approvalStep) {
          Object.assign(approvalStep, {
            isActive: true,
            isDone: true,
          });
        }
        break;
      default:

        break;
    }
  }

  return updatedStepsData;
};
