import { call, put } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
  getListFilterEtablissementsFailure,
  getListFilterEtablissementsSuccess,
} from '../actions/actions';
import etablissementApi from '../../services/apiHandlers/etablissementApi';
import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';

export function* fetchFilteredListEtablissementsSaga(
  action: serviceEtablissmentMachineTypeModels.actionPayload,
) {
  
  try {
    const response: AxiosResponse =
      yield etablissementApi.filteredListEtablissement(action.payload);
    if (response.data) {
      yield put(getListFilterEtablissementsSuccess(response.data));
    }
  } catch (error: any) {
    yield put(getListFilterEtablissementsFailure(error.message));
  }
}
