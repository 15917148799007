import React, { ReactNode } from 'react';
import HomeIconGrey from '../assets/icons/HomeIconGrey';
import ListeDesEntreprises from '../pages/etablissement/pages/ListeDesEntreprises';

interface TabPayload {
  key: string;
  value: ReactNode;
}
interface Tab {
  name: string;
  icon: JSX.Element;
  isPinned: boolean;
  index: string;
  onClose?: () => void;
  isActive?: boolean;
  isChanged: boolean;
  tabContent: TabPayload;
}

const initial_tabs: Tab[] = [
  {
    name: 'Entreprise(s)',
    icon: <HomeIconGrey />,
    isPinned: false,
    isActive: true,
    isChanged: false,
    index: '3',
    tabContent: {
      key: 'Entreprise(s)',
      value: <ListeDesEntreprises />,
    },
  },
];

export default initial_tabs;
