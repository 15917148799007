import { AxiosResponse } from 'axios';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  CategoryPayLoad,
  categoryAction,
} from '../../../models/services/module.category';
export function* startGetAllCategory(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getCategoryList(action.noEmpty);
    if (response.data) {
      action._ && action._(response.data?.data?.result??[]);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* postCategory(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postCategory(
      action.category as CategoryPayLoad,
    );
    if (response.data) {
      action._ && action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* getCategoryById(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getCategoryById(
      action.id as string,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* deleteCategoryById(action: categoryAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteCategoryById(
      action.id as string,
    );
    if (response.data) {
      action._ && action._(response.data?.data?.result[0]);
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}