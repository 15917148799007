import { useDispatch, useSelector } from 'react-redux';
import {
  addEtablissementCurrentStep,
  resetEtablissementAddSuccessState,
  resetVerifResponseEtablissement,
  setStepsDataEtablissement,
  setValidationRequestedEtablissement,
  setValidationRequestedFiscalEtablissement,
  unsavedChangesRegister,
  updateTabs,
} from '../../../../../store/actions/actions';
import tabs_list from '../../../../../data/tabsList';
import { serviceTabsModels } from '../../../../../models/services/module.tabs';
import stepsDataInitialState from '../../../../../utils/addEtablissementStepsDataInitialState';

const useHandleCancel = () => {
  const dispatch = useDispatch();
  const stepsData = stepsDataInitialState;
  const unsavedChangesData = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.unsavedChanges,
  );
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const handleCancel = () => {
    dispatch(setValidationRequestedEtablissement(false));
    dispatch(setValidationRequestedFiscalEtablissement(false));
    dispatch(resetEtablissementAddSuccessState());
    dispatch(resetVerifResponseEtablissement());

    // Function to set steps data
    dispatch(setStepsDataEtablissement(stepsData));
    dispatch(addEtablissementCurrentStep(1));

    // Function to handle unsaved changes
    const filteredUnsavedChangesData = unsavedChangesData.filter(
      (item) => item.key !== tabs_list[1].name,
    );
    //dispatch(unsavedChangesRegister(filteredUnsavedChangesData));

    // Function to update tab changes
    const tabIndex = open_tabs.findIndex(
      (item) => item.name === tabs_list[1].name,
    );
    if (tabIndex !== -1) {
      const updatedTabs = [...open_tabs];
      updatedTabs[tabIndex] = {
        ...updatedTabs[tabIndex],
        isChanged: false,
      };
      dispatch(updateTabs(updatedTabs));
    }
  };

  return handleCancel;
};

export default useHandleCancel;
