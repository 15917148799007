import { serviceRolesModels } from '../../models/services/module.role';
import axiosInstance, { BaseUrl } from './apiGate';

const roleApi = {
  addRole: async (body: serviceRolesModels.Role) => {
    try {
      const response = await axiosInstance.post(
          BaseUrl + `/v1/createRole`,
        body,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getRoles: async (body: any) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/v1/getListRoles?page=${body.paginationModel.page}&pageSize=${body.paginationModel.pageSize}`,
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getRoleById: async (id: string) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl + `/v1/getDetailsRole?id=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  updateRole: async (body: serviceRolesModels.UpdateRolePayload) => {
    try {
      const response = await axiosInstance.put(
        BaseUrl + `/v1/updateRole`,
        body,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteRole: async (id: serviceRolesModels.roleId) => {
    try {
      const response = await axiosInstance.delete(
        BaseUrl + `/v1/deleteRole?id=${id}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // getRolesEstablishement: async (body: any) => {
  //   try {
  //     const response = await axiosInstance.get(
  //       BaseUrl +
  //         `/v1/getListRoles?page=${body.paginationModel.page}&pageSize=${body.paginationModel.pageSize}&idEstablishement=${body.establishmentId}`,
  //     );

  //     return response.data;
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
};
export default roleApi;
