import { serviceEtablissmentMachineTypeModels } from '../../models/services/module.EtablisementMachineType';
import * as actionTypes from '../actions/actionTypes';
const initialState = {
  loading: false,
  ListAbonnement: null,
  error: null,
};

const ListAbonnementReducer = (
  state = initialState,
  action: { type: string; payload: serviceEtablissmentMachineTypeModels.createAbonnementResponse },
) => {
  switch (action.type) {
    case actionTypes.FETCH_ABONNEMENT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_ABONNEMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ListAbonnement: action.payload,
      };
    case actionTypes.FETCH_ABONNEMENT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ListAbonnementReducer;
