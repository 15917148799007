import React from 'react';
import MenuItemCatalogueComponent from '../../../container/Catalogue/MenuItems.Catalogue.component';
import DoubleArrowRightGrey from '../../../assets/icons/doubleleftarrow.svg';
import { Link, useLocation } from 'react-router-dom';
import {
  iconMap,
  routeLabels,
  routes,
  routesTable,
} from '../../../navigation/routes/catalogue.routes';
export default function MenuCatalogueComponents({
  HandelMenuReduce,
  changeCurrentPath,
  currentPath,
}: {
  HandelMenuReduce: () => void;
  changeCurrentPath: (path: string) => void;
  currentPath: string;
}) {
  const location = useLocation();
  return (
    <div className="MenuItems fl_col gp8 pd10-t-b pd8-l">
      <div className="menuRemote fl_row ai_c jc_fe pd10">
        <img onClick={HandelMenuReduce} src={DoubleArrowRightGrey} alt="" />
      </div>
      {routeLabels.map((label, index) => {
        const path = Object.keys(routes).find(
          (key) => routesTable[key as keyof typeof routesTable] === label,
        );
        const IconComponent = iconMap[label];
        if (!IconComponent) return null;
        return (
          <div
            className="c-ph"
            key={index}
            onClick={() => changeCurrentPath(path ?? '')}
          >
            <MenuItemCatalogueComponent
              icon={<IconComponent />}
              label={label == 'ECRANS' ? 'POSTE DE TRAVAIL' : label}
              isActive={
                path ? currentPath.startsWith(`/catalogue${path}`) : false
              }
            />
          </div>
        );
      })}
    </div>
  );
}
