import React from 'react';
import { languageData } from '../../i18n';
import Modal from '../../components/Modal/Modal';
import UserRoleModalContent from '../../components/Modal/UserRoleModalContent';
import UserAccessModalContent from '../../components/Modal/UserAccessModalContent';
import { useDispatch, useSelector } from 'react-redux';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import { setUserFormData } from '../../store/actions/actions';
import PermissionRoleRow from './PermissionRoleRow';
import { v4 as uuidv4 } from 'uuid';
import useGetRolesLogic from '../Roles/useGetRolesLogic';
interface permissionCardProps {
  cancelChanges: () => void;
}
export default function UserPermissionsCard(props: permissionCardProps) {
  const dispatch = useDispatch();
  const [modalRole, SetmodalRole] = React.useState<boolean>(false);
  const [rowId, setRowId] = React.useState<string>('1');
  const [modalAccess, SetmodalAccess] = React.useState<boolean>(false);
  const showRoleModal = (rowId?: string) => {
    SetmodalRole((prev) => !prev);
    setRowId(rowId ?? '');
  };
  const showmodalAccess = (rowId?: string) => {
    SetmodalAccess((prev) => !prev);
    setRowId(rowId ?? '');
  };
  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormData,
  );
  const { roles } = useGetRolesLogic();
  const handleAddPermissionRow = () => {
    const newPermission = {
      rolesId: [''],
      rowId: uuidv4(),
      isActive: true,
      chainesIds: [],
      restaurantsIds: [],
      chainesIdsToDelete: [],
      restaurantsIdsToDelete: [],
    };

    const updatedForm = {
      ...userFormData,
      permissions: [...userFormData.permissions, newPermission],
    };

    dispatch(setUserFormData(updatedForm));
  };
  const handleDeletePermissionRow = (selected: string) => {
    const updatedForm = {
      ...userFormData,
      permissions: userFormData.permissions.filter(
        (item) => item.rowId !== selected,
      ),
    };
    dispatch(setUserFormData(updatedForm));
  };
  return (
    <div className="AddUser">
      <Modal
        version="custom"
        content={
          <UserRoleModalContent
            showRoleModal={showRoleModal}
            rowId={rowId}
            roles={roles}
            type={'add'}
            cancelChanges={props.cancelChanges}
          />
        }
        title={languageData.global.user.affectRole}
        visibility={modalRole}
        maxWidth={771}
        height={455}
      />
      <Modal
        version="custom"
        content={
          <UserAccessModalContent
            showmodalAccess={showmodalAccess}
            rowId={rowId}
            type={'add'}
          />
        }
        title={languageData.global.user.grantAccess}
        visibility={modalAccess}
        maxWidth={771}
        height={455}
      />
      <div className="PermissionsContainer fl_col gp20">
        <div className="header-block">
          <p className="p16 w-600 Nevada-txt pd22-t-b">
            {languageData.global.user.permissions}
          </p>
        </div>
        <div className="fl_col permissionContent pd10-t-b gp10">
          <div className="header-listing fl_row gp40">
            <p className="p14 w-700 OxfordBlue02-txt role-row">
              {languageData.global.role.roles}
            </p>
            <p className="p14 w-700 OxfordBlue02-txt fl-1 access-row">
              {languageData.global.user.access}
            </p>
          </div>
          <div className="content-listing fl_col ">
            {userFormData &&
              userFormData?.permissions?.map((row, index) => {
                return (
                  <PermissionRoleRow
                    key={index}
                    rowId={row.rowId}
                    roles={roles}
                    showRoleModal={() => showRoleModal(row.rowId)}
                    showmodalAccess={() => showmodalAccess(row.rowId)}
                    handleAddPermissionRow={handleAddPermissionRow}
                    handleDeletePermissionRow={() =>
                      handleDeletePermissionRow(row.rowId)
                    }
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
