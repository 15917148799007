import { serviceRolesModels } from '../models/services/module.Add.role';

type Item = {
  section?: string;
  title?: string;
  values?: serviceRolesModels.Value[];
  subSection?: Item[];
};
interface IcheckBox {
  label: string;
  value: string;
}

export const filterNestedArray = (
  array: Item[],
  filters: string[],
): serviceRolesModels.Value[] => {
  if (!Array.isArray(array)) return [];

  const filterItem = (item: Item, filter: string): boolean =>
    item.section === filter ||
    item.title === filter ||
    (item.subSection?.some(
      (sub) => sub.section === filter || sub.title === filter,
    ) ??
      false);

  const applyFilter = (acc: Item[], filter: string): Item[] => {
    const filtered = acc.filter((item) => filterItem(item, filter));
    return filtered.length > 0 && filtered[0].subSection
      ? filtered[0].subSection
      : filtered;
  };

  const flattenValues = (item: Item): serviceRolesModels.Value[] =>
    item.values || [];

  return filters.reduce(applyFilter, array).flatMap(flattenValues);
};

export const checkboxes: IcheckBox[] = [
  { label: 'Affichage', value: 'Affichage' },
  { label: 'Ajout', value: 'Ajout' },
  { label: 'Modification', value: 'Modification' },
  { label: 'Synchroniser ', value: 'Synchroniser' },
  { label: 'Suppression', value: 'Suppression' },
  { label: 'Changement de statut', value: 'Changement de statut' },
];

export const permissionBoxesConfig = [
  { name: 'Géneral', key: 'generalItems', primary: true },
  { name: 'Fiche', key: 'ficheItems', onClickMore: () => alert('More') },
  {
    name: 'Information financière',
    key: 'informationFinanciereItems',
    onClickMore: () => alert('More'),
  },
  {
    name: 'Licences',
    key: 'LicencesItems',
    onClickMore: () => alert('More'),
  },
  {
    name: 'Roles et utilisateurs',
    key: 'RolesItems',
    onClickMore: () => alert('More'),
  },
  {
    name: 'Utilisateurs',
    key: 'UtilisateursItems',
    onClickMore: () => alert('More'),
  },
];

export const getFilteredCheckboxes = (section: string, category: string) => {
  if (section === 'Roles et utilisateurs') {
    // If section is 'Roles', return the specific items
    return checkboxes.filter((checkbox) =>
      ['Ajout', 'Affichage', 'Modification', 'Suppression'].includes(
        checkbox.value,
      ),
    );
  }
  if (section === 'Utilisateurs') {
    // If section is 'Roles', return the specific items
    return checkboxes.filter((checkbox) =>
      ['Ajout', 'Affichage', 'Modification', 'Suppression'].includes(
        checkbox.value,
      ),
    );
  }

  // For other sections and categories
  if (
    (section === 'Entreprise' ||
      section === 'Etablissement' ||
      section === 'Roles et utilisateurs') &&
    category === 'generalItems'
  ) {
    return checkboxes.filter((checkbox) =>
      ['Affichage', 'Ajout', 'Modification'].includes(checkbox.value),
    );
  } else if (
    category === 'ficheItems' ||
    category === 'informationFinanciereItems'
  ) {
    return checkboxes.filter((checkbox) =>
      ['Affichage', 'Modification'].includes(checkbox.value),
    );
  } else if (category === 'LicencesItems') {
    return checkboxes.filter((checkbox) =>
      ['Affichage', 'Synchroniser', 'Modification'].includes(checkbox.value),
    );
  }

  return checkboxes;
};
