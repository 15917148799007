import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LeftNavbarCatalogueComponent from '../../../components/Catalogue/navbar/LeftNavbar.Catalogue.component';
import { routes } from '../../../navigation/routes/catalogue.routes';
import {
  changeSearchValue,
  clearSearchValue,
  setNewModalShown,
} from '../../../store/actions/catalogues/actions';
import {
  convertPathToId,
  isUrlInFormat,
  showSearchBar,
} from '../../../utils/catalogueHelper/routeHelper';
import TopSearchBarCatalogueContainer from '../Navbar/TopSearchBar.Catalogue.container';
export default function CatalogueDashboardLayout() {
  const dispatch = useDispatch();
  const sidebarMode = useSelector(
    (state: { uiCatalogue: { sideBarMode: 'basic' | 'ing' | 'alg' } }) =>
      state.uiCatalogue.sideBarMode,
  );
  const [currentPathState, setcurrentPath] = useState('/catalogue/articles');
  const handleChangeSearchField = (text: string) => {
    dispatch(changeSearchValue(text));
  };
  const [ComponentToRender, setComponentToRender] =
    useState<React.ReactNode | null>(null);
  const handleClearSearchValue = () => {
    dispatch(clearSearchValue());
  };
  const handleAddModalVisibilty = (value: boolean) => {
    dispatch(setNewModalShown(value));
  };
  /*   useEffect(() => {
    let currentPath = location.pathname;
    if (currentPath.includes('catalogue') && currentPath != currentPathState) {
      setcurrentPath(currentPath);
    }
  }, [location.pathname]); */
  const handleCloseAddModal = () => handleAddModalVisibilty(false);
  const changeCurrentPath = (path: string) => {
    setcurrentPath('/catalogue' + path);
  };
  useEffect(() => {
    if (currentPathState) {
      handleClearSearchValue();
      handleCloseAddModal();
      let currentPath = currentPathState;
      console.log({ currentPath, index: 0 });

      currentPath = currentPath.replace('/catalogue', '');
      console.log({ currentPath, index: 10 });
      console.log('currentPath', isUrlInFormat(currentPath));
      if (currentPath.includes('?duplicate=true')) {
        currentPath = currentPath.replace('?duplicate=true', '');
      }
      currentPath = isUrlInFormat(currentPath)
        ? convertPathToId(currentPath)
        : currentPath;

      currentPath = currentPath === '' ? '/' : currentPath;
      /*  document.title =
        'GLOSSAIRE | ' + currentPath.toLocaleUpperCase().replace('/', ''); */
      console.log({ currentPath, index: 20 });
      const Component = routes[currentPath || '/'] || routes['/'];
      setComponentToRender(() => (
        <Component
          changeCurrentPath={changeCurrentPath}
          currentPath={currentPathState}
        ></Component>
      ));
    }
  }, [currentPathState]);
  console.log({ currentPathState });
  return (
    <div className="DashboardLayoutCatalogue fl_col">
      {/* Your tab items */}
      <section className="fl_row AliceBlue-bg">
        <LeftNavbarCatalogueComponent
          currentPath={currentPathState}
          type={sidebarMode}
          changeCurrentPath={changeCurrentPath}
        />
        <div className="BodyContent wrapper fl_col gp10 ">
          <main className="TabsComponents mg10-t-b AliceBlue-bg pd15-r-l fl_col gp10">
            {showSearchBar(currentPathState) && (
              <TopSearchBarCatalogueContainer
                onShowModal={handleAddModalVisibilty}
                onChange={(e) => handleChangeSearchField(e.target.value)}
              />
            )}
            {ComponentToRender}
          </main>
        </div>
      </section>
    </div>
  );
}
