import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import TableHeader from '../../components/Table/TableHeader';
import Tbody from '../../components/Table/Tbody-table';
import Td from '../../components/Table/Td-table';
import Thead from '../../components/Table/Thead-table';
import Tr from '../../components/Table/Tr-table';
import { languageData } from '../../i18n';
import { OpenTab } from '../../services/tabs/OpenTab';
import tabs_list from '../../data/tabsList';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTabsModels } from '../../models/services/module.tabs';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import { renderUserRoles } from '../../utils/roleUtils';
import MobileTableCard from '../../components/Table/MobileTableCard';
import MobileTableContainer from '../../components/Table/MobileTableContainer';
import TableRightArrow from '../../assets/icons/TableRightArrowPrimaryColor.svg';
import TableArrowDropDownGrey from '../../assets/icons/TableArrowDropDownGrey.svg';
import EmailIconBlack from '../../assets/icons/EmailIconBlack.svg';
import RoleIconGrey from '../../assets/icons/RoleIconGrey.svg';
import Preloader from '../../components/Preloader';
import EmptyTable from '../../components/Table/EmptyTable';
import PageTable from '../Table/PageTable';
import TableSection from '../Table/TableSection';
import TableContent from '../Table/TableContent';
import DesktopTableContainer from '../Table/DesktopTableContainer';
type ExpandedCardState = {
  [key: string]: boolean;
};
export default function UserListTable() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [expandedCard, setExpandedCard] = useState<ExpandedCardState>({});
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const users_list = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.users,
  );
  useEffect(() => {
    if (users_list.length > 0) {
      setLoading(false);
    }
  }, [users_list]);

  const userList = React.useMemo(() => {
    if (users_list) {
      return users_list.map((item: serviceAppUserModels.userListItem) => item);
    }
    return [];
  }, [users_list]);

  const onClickAddUser = () => {
    OpenTab({
      key: tabs_list[11].tabContent.key,
      label: tabs_list[10].name,
      tabs: open_tabs,
      dispatch,
    });
  };

  const onClickEditUser = (id: string, name: string) => {
    OpenTab({
      key: tabs_list[11].tabContent.key,
      label: tabs_list[11].name,
      tabs: open_tabs,
      dispatch,
      id: id,
      name: name,
    });
  };

  const claimsUser = useSelector((state: any) => state.auth?.claims);

  const toggleExpand = (userId: string) => {
    setExpandedCard({ [userId]: !expandedCard[userId] });
  };

  // if (loading) {
  //   return <Preloader />;
  // }

  return (
    <PageTable className="ListeDesEntreprisesContainer UsersList-wrapper">
      <TableSection className="">
        {/* <div className=" fl_col gp5 h-full"> */}
        {/* <div className="UsersList-table-desktop fl_col gp10 h-full"> */}
        <TableHeader
          type="add"
          title={languageData.global.TabsTitle.users}
          onClickAdd={onClickAddUser}
          textAdd={languageData.global.add}
          className="desktop-table-header"
          claimUserFio={claimsUser?.includes('FioManagerMobile.USERS')}
        />
        <TableContent>
          <DesktopTableContainer className="Users-list-table UsersList-table-desktop">
            <Table className="Table rd12">
              <Thead>
                <Tr>
                  <Td
                    type="text"
                    value={languageData.global.TableHeaders.user}
                    width={5}
                    align="left"
                    className="user-name"
                  />
                  <Td
                    type="text"
                    value={languageData.global.TableHeaders.status}
                    width={3}
                    align="center"
                    flex="center"
                  />
                  <Td
                    type="text"
                    value={languageData.global.email_address}
                    width={17.6}
                    align="center"
                    flex="center"
                    className="user-email"
                  />
                  <Td
                    type="filter"
                    value={languageData.global.TableHeaders.role}
                    width={35}
                    align="left"
                    flex="flex-start"
                  />
                  <Td
                    type="text"
                    value={languageData.global.user.access}
                    width={4}
                    align="center"
                    flex="center"
                  />
                  <Td type="empty" width={5} align="center" flex="center" />
                </Tr>
              </Thead>
              {userList.length > 0 ? (
                <Tbody>
                  {userList &&
                    userList.map((user, key) => (
                      <Tr key={key}>
                        <Td
                          style="bold"
                          type="text"
                          value={user.firstName + ' ' + user.lastName}
                          width={13}
                          className="user-name"
                        />
                        <Td
                          type="status"
                          value={user.isActive}
                          width={3}
                          align="center"
                          flex="center"
                        />
                        <Td
                          type="text"
                          value={user.email}
                          width={17.6}
                          align="center"
                          flex="center"
                          className="user-email"
                        />
                        <Td
                          type="userRole"
                          roleName={renderUserRoles(user.rolesNames)}
                          width={35}
                          align="center"
                          flex="center"
                          roleBg={['#c2c2ee66', '#c2c2ee66', '#c2c2ee66']}
                          roleTextColors={['#5925DC', '#175CD3', '#5925DC']}
                        />
                        <Td
                          type="access"
                          entrepriseSlot={user?.nbAccessCompanies}
                          etablissementSlot={user?.nbAccessEstablishements}
                          width={4}
                          align="center"
                          flex="center"
                        />
                        <Td
                          type="editUser"
                          width={5}
                          onClickEditUser={() =>
                            onClickEditUser(
                              user.id,
                              user.firstName + ' ' + user.lastName,
                            )
                          }
                          flex="center"
                        />
                      </Tr>
                    ))}
                </Tbody>
              ) : (
                <EmptyTable title="Utilisateur(s) introuvable(s)" />
              )}
            </Table>
          </DesktopTableContainer>
          {userList.length > 0 ? (
            <MobileTableContainer className="UsersList-table-mobile">
              {userList &&
                userList.map((user, key) => (
                  <MobileTableCard
                    expand={expandedCard[user.id] || false}
                    key={key}
                    className={user.isActive == true ? '' : 'inActive'}
                  >
                    <div className="table-header fl_row ai_c jc_s">
                      <div className="left-side fl_row gp15 ai_c">
                        <img
                          className="arrow-expand"
                          src={TableArrowDropDownGrey}
                          alt=""
                          onClick={() => toggleExpand(user.id)}
                          loading="lazy"
                        />
                        <div className="main-informations fl_col gp10">
                          <Td
                            style="bold"
                            type="text"
                            value={user.firstName + ' ' + user.lastName}
                            width={13}
                            mobile
                          />
                        </div>
                      </div>
                      <div className="right-side fl_row gp15 ai_c">
                        <img
                          loading="lazy"
                          src={TableRightArrow}
                          alt=""
                          onClick={() =>
                            onClickEditUser(
                              user.id,
                              user.firstName + ' ' + user.lastName,
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="table-body fl_col gp5 over-h">
                      <div className="tbody-col fl_row gp10">
                        <div className="label">
                          <div className="iconlabel">
                            <img src={EmailIconBlack} alt="" />
                          </div>
                        </div>
                        <Td type="text" value={user.email} mobile />
                      </div>
                      <div className="tbody-col fl_row gp10">
                        <div className="label">
                          <div className="iconlabel">
                            <img src={RoleIconGrey} alt="" />
                          </div>
                        </div>
                        <div className="fl_row ai_c gp20">
                          <div className="role-mobile fl_row ai_c gp5">
                            <Td
                              type="userRole"
                              roleName={renderUserRoles(user.rolesNames)}
                              width={100}
                              align="center"
                              flex="center"
                              roleBg={['#c2c2ee66', '#c2c2ee66', '#c2c2ee66']}
                              roleTextColors={['#5925DC', '#175CD3', '#5925DC']}
                              mobile
                            />
                          </div>
                          <Td
                            type="access"
                            entrepriseSlot={user?.nbAccessCompanies}
                            etablissementSlot={user?.nbAccessEstablishements}
                            width={4}
                            align="center"
                            flex="center"
                            mobile
                          />
                        </div>
                      </div>
                    </div>
                  </MobileTableCard>
                ))}
            </MobileTableContainer>
          ) : (
            <EmptyTable title="Utilisateur(s) introuvable(s)" />
          )}
        </TableContent>
      </TableSection>
    </PageTable>
  );
}
