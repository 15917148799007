import React from 'react';

interface Props {
  strokeColor?: string;
}

const ArticleIcon: React.FC<Props> = ({ strokeColor  }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33301 10.332L14.6663 10.332" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M8 3.66667C8 3.66667 9 2.97759 9 2.12757C9 0.624144 7 0.624144 7 2.12757C7 2.97759 8 3.66667 8 3.66667Z" stroke={strokeColor} strokeWidth="1.5" strokeLinejoin="round"/>
      <path d="M2 10.332L2.41418 11.9887C2.56257 12.5823 3.09588 12.9987 3.7077 12.9987L12.2923 12.9987C12.9041 12.9987 13.4374 12.5823 13.5858 11.9887L14 10.332" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M13.6663 8.66797C13.3341 5.85252 10.9238 3.66797 7.99967 3.66797C5.07558 3.66797 2.66523 5.85252 2.33301 8.66797" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
}

export default ArticleIcon;
