import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEtablissementByIdFinancialInfos,
  getEtablissementByIdInfos,
  getEtablissementByIdSheet,
} from '../../../store/actions/actions';
import EtablissementProfileInfoContainer from '../../../container/Etablissement/TabsEtablissements/EtablissementsProfileInfoContainer';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import { serviceTabsModels } from '../../../models/services/module.tabs';
export default function EtablissementProfileInfo() {
  const dispatch = useDispatch();
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  const [activeMiniTab, setActiveMiniTab] = React.useState<string>('1');

  useEffect(() => {
    if (activeTabContent.miniTab === 'catalogue') {
      setActiveMiniTab('7');
    }
  }, [activeTabContent]);
  useEffect(() => {
    if (activeTabContent.id) {
      dispatch(getEtablissementByIdSheet({ id: activeTabContent.id }));
      dispatch(getEtablissementByIdInfos({ id: activeTabContent.id }));
      dispatch(getEtablissementByIdFinancialInfos({ id: activeTabContent.id }));
    }
  }, []);
  const etablissementInfos = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.etablissementInfos,
  );
  const etablissementSheet = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.etablissementSheet,
  );

  const financialData = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.financialData,
  );
  return (
    <div className="EntrepriseProfileInfo h-full">
      <EtablissementProfileInfoContainer
        id={activeTabContent.id ?? 0}
        activeMiniTab={activeMiniTab}
        setActiveMiniTab={setActiveMiniTab}
        etablissementInfos={etablissementInfos}
        etablissementSheet={etablissementSheet}
        etablissementFinancialData={financialData}
      />
    </div>
  );
}
