import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionsModalContent from '../../../components/Etablissement/Modal/SubscriptionsModalContent';
import Modal from '../../../components/Modal/Modal';
import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';
import Tbody from '../../../components/Table/Tbody-table';
import Td from '../../../components/Table/Td-table';
import Thead from '../../../components/Table/Thead-table';
import Tr from '../../../components/Table/Tr-table';
import { serviceEtablissmentMachineTypeModels } from '../../../models/services/module.EtablisementMachineType';
import {
  createAbonnementRequest,
  fetchAbonnementDataRequest,
  getAbonnementByIdRequest,
  getListAbonnementsClientRequest,
  resetAbonnementState,
  updateAbonnementRequest,
  updateAbonnementStatusRequest,
} from '../../../store/actions/actions';
import { languageData } from '../../../i18n';
import { convertDate } from '../../../utils/ConvertTDate';
import { serviceEtablissementModels } from '../../../models/services/module.etablissement';
import Spinner from '../../../components/skeleton/spinner';
import { usePagination } from '../../../pages/etablissement/pages/usePagination';
import DesktopTableContainer from '../../Table/DesktopTableContainer';
import PageTable from '../../Table/PageTable';
import TableSection from '../../Table/TableSection';
import TableContent from '../../Table/TableContent';
import CardContainer from '../../CardContainer';
import TableFilter from './SubscriptionsTable/TableFilter';
import TableUserCard from './SubscriptionsTable/TableCard';
import TableCardType from './SubscriptionsTable/TableCardType';
import Pagination from '../../../components/Table/Pagination-table';

export default function SubscriptionsListAdminContainer() {
  const [showModalAddSub, setShowModalAddSub] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAbonnment, setShowAbonnment] = useState('Tous');
  const [addAbonnment, setAddAbonnment] =
    useState<serviceEtablissmentMachineTypeModels.IaddAbonnment>({
      designation: '',
      description: '',
      totalHT: '',
      totalTTC: 0,
      vat: '',
      intervalleId: 3,
    });
  const dispatch = useDispatch();

  const { ListAbonnement } = useSelector(
    (state: {
      ListAbonnement: {
        ListAbonnement: serviceEtablissmentMachineTypeModels.createAbonnementResponse;
      };
    }) => state.ListAbonnement,
  );
  const { loading: loadingState, error: errorState } = useSelector(
    (state: { abonnementStatus: { loading: boolean; error: boolean } }) =>
      state.abonnementStatus,
  );
  const {
    abonnementClient,
    error: abonnementClientError,
    loading: loadingClient,
  } = useSelector(
    (state: {
      clientAbonnement: {
        error: string;
        loading: boolean;
        abonnementClient: serviceEtablissmentMachineTypeModels.ListAbonnementsClient;
      };
    }) => state.clientAbonnement,
  );

  const { loading, error } = useSelector(
    (state: { ListAbonnement: { loading: boolean; error: string } }) =>
      state.ListAbonnement,
  );

  const { isAdmin } = useSelector(
    (state: { auth: serviceAuthModels.authState }) => state.auth,
  );

  const { abonnement } = useSelector(
    (state: {
      AbonnementById: {
        abonnement: serviceEtablissmentMachineTypeModels.IaddAbonnment;
      };
    }) => state.AbonnementById,
  );

  const etablissementSheet = useSelector(
    (state: { etablissement: serviceEtablissementModels.EtablissementState }) =>
      state.etablissement.etablissementSheet,
  );

  const paginationConfig = useMemo(
    () => ({
      currentPage: 1,
      itemsPerPage: 10,
    }),
    [],
  );
  const {
    currentPage,
    itemsPerPage,
    handleNextClick,
    handlePreviousClick,
    handlePageChange,
  } = usePagination(paginationConfig);

  const pagination = useMemo(
    () => ({
      paginationModel: {
        page: currentPage,
        pageSize: itemsPerPage,
      },
    }),
    [currentPage, itemsPerPage],
  );

  useEffect(() => {
    dispatch(
      isAdmin
        ? fetchAbonnementDataRequest(
            etablissementSheet?.id as number,
            pagination?.paginationModel?.page,
            pagination?.paginationModel?.pageSize,
          )
        : getListAbonnementsClientRequest(etablissementSheet?.id as number),
    );
  }, [dispatch, pagination]);

  const calculeTheTotalTTC = () => {
    const { totalHT, vat } = addAbonnment;
    const percentage = 100;
    const theAdditons = 1;
    const calculatedTotalTTC =
      parseFloat(totalHT.toString()) *
      (theAdditons + parseFloat(vat.toString()) / percentage);

    return calculatedTotalTTC;
  };

  useEffect(() => {
    setAddAbonnment((prev) => ({
      ...prev,
      designation: abonnement?.designation || '',
      description: abonnement?.description || '',
      totalHT: abonnement?.totalHT || '',
      vat: abonnement?.vat || '',
    }));
  }, [abonnement]);

  useEffect(() => {
    const calculatedTotalTTC = calculeTheTotalTTC();
    setAddAbonnment((prev) => ({
      ...prev,
      totalTTC: calculatedTotalTTC,
    }));
  }, [addAbonnment.totalHT, addAbonnment.vat]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddAbonnment((prev) => ({
      ...prev,
      [name]:
        name === 'totalHT' || name === 'vat' ? parseFloat(value) || '' : value,
    }));
  };
  const validateFields = () => {
    const { designation, description, totalHT, vat } = addAbonnment;

    if (designation?.trim() === '') {
      setErrorMessage(languageData.fr.messages.Empty);
      return false;
    }
    if (description?.trim() === '') {
      setErrorMessage(languageData.fr.messages.Empty_description);
      return false;
    }
    if (!totalHT || parseFloat(totalHT?.toString()) <= 0) {
      setErrorMessage(languageData.fr.messages.totalHT);
      return false;
    }
    if (!vat || parseFloat(vat?.toString()) <= 0) {
      setErrorMessage(languageData.fr.messages.vat);
      return false;
    }
    return true;
  };


  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLFormElement>,
  ) => {
    validateFields();
  };

  const handleSubmit = () => {
    if (validateFields()) {
      dispatch(
        createAbonnementRequest(
          etablissementSheet?.id as number,
          addAbonnment as serviceEtablissmentMachineTypeModels.IaddAbonnment,
          () => {
            dispatch(
              fetchAbonnementDataRequest(
                etablissementSheet?.id as number,
                pagination?.paginationModel?.page,
                pagination?.paginationModel?.pageSize,
              ),
            );
          },
        ),
      );
      setAddAbonnment({
        designation: '',
        description: '',
        totalHT: '',
        totalTTC: 0,
        vat: '',
        intervalleId: 3,
      });
      setShowModalAddSub(false);
    }
  };

  const handleUpdate = () => {
    if (validateFields()) {
      dispatch(
        updateAbonnementRequest(abonnement?.id as number, addAbonnment, () => {
          dispatch(
            fetchAbonnementDataRequest(
              etablissementSheet?.id as number,
              pagination?.paginationModel?.page,
              pagination?.paginationModel?.pageSize,
            ),
          );
        }),
      );
      setShowModalAddSub(false);
    }
  };

  const handleGetAbByID = (id: number) => {
    dispatch(getAbonnementByIdRequest(id));
  };

  const handleChangeStatusAbonnement = (abonnmentId: any) => {
    if (abonnmentId) {
      dispatch(
        updateAbonnementStatusRequest(abonnmentId?.id, true, () => {
          dispatch(
            fetchAbonnementDataRequest(
              etablissementSheet?.id as number,
              pagination?.paginationModel?.page,
              pagination?.paginationModel?.pageSize,
            ),
          );
        }),
      );
      setShowModalConfirm(false);
    }
  };
  const showAbonnmentByStatus = (status: string) => {
    switch (status) {
      case 'Activé':
        return (
          <div className="table-elements fl_col gp16">
            <TableCardType type="activated">
              {abonnementClient?.result?.activeAbonnements?.map((item: any) => (
                <TableUserCard
                  title={item.designation}
                  type={item.status === 'Activé' ? 'activated' : 'activated'}
                  total={item.totalTTC}
                  prefix="€"
                  dateStart={
                    item.periodStart == null
                      ? 'N/A'
                      : convertDate(item.periodStart)
                  }
                  dateEnd={
                    item.periodEnd == null ? 'N/A' : convertDate(item.periodEnd)
                  }
                />
              ))}
            </TableCardType>
          </div>
        );
      case 'Désabonné':
        return (
          <div className="table-elements fl_col gp16">
            <TableCardType type="canceled">
              {abonnementClient?.result?.canceledAbonnements?.map(
                (item: any) => (
                  <TableUserCard
                    title={item.designation}
                    type={item.status === 'Désabonné' ? 'canceled' : 'canceled'}
                    total={item.totalTTC}
                    prefix="€"
                    dateStart={
                      item.periodStart == null
                        ? 'N/A'
                        : convertDate(item.periodStart)
                    }
                    dateEnd={
                      item.periodEnd == null
                        ? 'N/A'
                        : convertDate(item.periodEnd)
                    }
                  />
                ),
              )}
            </TableCardType>
          </div>
        );
      case 'Expiré':
        return (
          <div className="table-elements fl_col gp16">
            <TableCardType type="expired">
              {abonnementClient?.result?.expiredAbonnements?.map(
                (item: any) => (
                  <TableUserCard
                    title={item.designation}
                    type={item.status == 'Expired' ? 'expired' : 'expired'}
                    total={item.totalTTC}
                    prefix="€"
                    dateStart={
                      item.periodStart == null
                        ? 'N/A'
                        : convertDate(item.periodStart)
                    }
                    dateEnd={
                      item.periodEnd == null
                        ? 'N/A'
                        : convertDate(item.periodEnd)
                    }
                    onClickActivate={() => alert('onClickSubscribe')}
                  />
                ),
              )}
            </TableCardType>
          </div>
        );
      default:
        return (
          <div className="table-elements fl_col gp16">
            <TableCardType type="expired">
              {abonnementClient?.result?.expiredAbonnements?.map(
                (item: any) => (
                  <TableUserCard
                    title={item.designation}
                    type={item.status == 'Expired' ? 'expired' : 'expired'}
                    total={item.totalTTC}
                    prefix="€"
                    dateStart={
                      item.periodStart == null
                        ? 'N/A'
                        : convertDate(item.periodStart)
                    }
                    dateEnd={
                      item.periodEnd == null
                        ? 'N/A'
                        : convertDate(item.periodEnd)
                    }
                    onClickActivate={() => alert('onClickSubscribe')}
                  />
                ),
              )}
            </TableCardType>
            <TableCardType type="activated">
              {abonnementClient?.result?.activeAbonnements?.map((item: any) => (
                <TableUserCard
                  title={item.designation}
                  type={item.status === 'Activé' ? 'activated' : 'activated'}
                  total={item.totalTTC}
                  prefix="€"
                  dateStart={
                    item.periodStart == null
                      ? 'N/A'
                      : convertDate(item.periodStart)
                  }
                  dateEnd={
                    item.periodEnd == null ? 'N/A' : convertDate(item.periodEnd)
                  }
                />
              ))}
            </TableCardType>

            <TableCardType type="canceled">
              {abonnementClient?.result?.canceledAbonnements?.map(
                (item: any) => (
                  <TableUserCard
                    title={item.designation}
                    type={item.status === 'Désabonné' ? 'canceled' : 'canceled'}
                    total={item.totalTTC}
                    prefix="€"
                    dateStart={
                      item.periodStart == null
                        ? 'N/A'
                        : convertDate(item.periodStart)
                    }
                    dateEnd={
                      item.periodEnd == null
                        ? 'N/A'
                        : convertDate(item.periodEnd)
                    }
                  />
                ),
              )}
            </TableCardType>
          </div>
        );
    }
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;

  if (loadingClient) return <Spinner />;
  if (abonnementClientError) return <p>Error: {error}</p>;

  return (
    <PageTable className="subscriptions-list-table fl_col gp10">
      <Modal
        className="AddSubscriptionsModal"
        version="custom"
        title={
          abonnement?.id
            ? languageData.fr.edit_abonnement
            : languageData.fr.add_abonnement
        }
        visibility={showModalAddSub}
        onClickClose={() => setShowModalAddSub(false)}
        close
        maxWidth={640}
        content={
          <SubscriptionsModalContent
            calculeTheTotalTTC={addAbonnment.totalTTC.toFixed(2)}
            addAbonnment={addAbonnment}
            handleSubmit={() => {
              abonnement?.id ? handleUpdate() : handleSubmit();
            }}
            onBlur={handleOnBlur}
            onClickClose={() => setShowModalAddSub(false)}
            errorMsg={errorMessage}
            errorMessage={errorMessage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange(e)
            }
          />
        }
      />

      <Modal
        className="ConfirmEnableStatusModal"
        version="custom"
        title={languageData.fr.add_abonnement}
        visibility={showModalConfirm}
        close
        onClickClose={() => setShowModalConfirm(true)}
        maxWidth={544}
        content={
          // <ConfirmEnableStatusModal
          //   onClickClose={() => setShowModalConfirm(false)}
          //   onClickValidate={() => handleChangeStatusAbonnement(abonnement?.id)}
          // />
          <div></div>
        }
      />
      {isAdmin ? (
        <TableSection>
          {isAdmin ? (
            <TableHeader
              type="add"
              title={languageData.fr.abonnement}
              onClickAdd={() => {
                dispatch(resetAbonnementState());
                setShowModalAddSub(true);
              }}
              textAdd={languageData.fr.add}
            />
          ) : (
            <TableHeader type="add" title={languageData.fr.abonnement} />
          )}

          <TableContent>
            <DesktopTableContainer className="Admin-table">
              <Table className="Table SubscriptionsList">
                <Thead>
                  <Tr>
                    <Td
                      type="text"
                      value={languageData.fr.Modal.Modaldesignation}
                      width={60}
                      align="left"
                    />
                    <Td
                      type="text"
                      value={languageData.fr.Total_TTC}
                      width={5}
                      align="center"
                    />
                    <Td
                      type="text"
                      value={languageData.fr.Intervalle}
                      width={5}
                      align="center"
                    />
                    <Td
                      type="text"
                      value={languageData.fr.Ajouté_le}
                      width={5}
                      align="center"
                    />
                    <Td
                      type="text"
                      value={languageData.fr.TableHeaders.status}
                      width={5}
                      align="center"
                    />
                    <Td type="empty" width={5} />
                  </Tr>
                </Thead>
                <Tbody>
                  {ListAbonnement && ListAbonnement.result?.length > 0 ? (
                    ListAbonnement?.result?.map(
                      (
                        item: serviceEtablissmentMachineTypeModels.createAbonnementResponse,
                      ) => (
                        <Tr key={item.id}>
                          <Td
                            style="bold"
                            type="text"
                            value={item?.designation}
                            width={80}
                            align="left"
                          />
                          <Td
                            type="text"
                            value={item?.totalTTC + 'Є'}
                            width={5}
                            align="center"
                          />
                          <Td
                            type="text"
                            value={item?.intervalle?.designation}
                            width={5}
                            align="center"
                          />
                          <Td
                            type="text"
                            value={convertDate(item.iDate)}
                            width={5}
                            align="center"
                          />
                          <Td
                            type="ToggleButton"
                            width={5}
                            align="center"
                            statusToggle={
                              item.isActive
                                ? 'active'
                                : item.isInProcessing
                                  ? 'pending'
                                  : 'inactive'
                            }
                            onClickvalidate={() =>
                              handleChangeStatusAbonnement(item)
                            }
                            errorState={errorState}
                            loadingState={loadingState}
                          />
                          <Td
                            type="editStatus"
                            width={5}
                            onClick={() => {
                              setShowModalAddSub(true);
                              handleGetAbByID(item.id);
                            }}
                          />
                        </Tr>
                      ),
                    )
                  ) : (
                    <Tr>
                      <Td
                        type="text"
                        value={languageData.fr.messages.no_data}
                      />
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </DesktopTableContainer>
          </TableContent>
        </TableSection>
      ) : (
        <CardContainer ClassName="user-table h-full" type="standard">
          <div className="advanced-table-header fl_col gp16">
            <TableFilter
              abonnementClient={abonnementClient}
              setShowAbonnment={setShowAbonnment}
            />
            <div className="table-header-elements">
              <div className="elements-wrapper fl_row Independence-txt p10 w-900 uper">
                <div className="row-1">
                  <p>{languageData.fr.TabsTitle.abonnements}</p>
                </div>
                <div className="row-2 fl_row fl-1 jc_s">
                  <p>{languageData.fr.TableHeaders.status}</p>
                  <p>{languageData.fr.Montant}</p>
                  <p>{languageData.fr.Date_de_début}</p>
                  <p>{languageData.fr.Date_d_expiration}</p>
                </div>
              </div>
            </div>
          </div>

          <>{showAbonnmentByStatus(showAbonnment)}</>
        </CardContainer>
      )}

      <Pagination
        totalPagesNumbers={ListAbonnement?.pagination?.nbPage}
        nextpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handleNextClick(currentPage)
        }
        prevpageClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handlePreviousClick(currentPage)
        }
        currentPage={currentPage}
        totalPages={ListAbonnement?.pagination?.nbPage}
        handlePageChange={handlePageChange}
      />
    </PageTable>
  );
}
