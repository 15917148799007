import compareObjects from '../../../utils/CompareObjects';
import { IsChangedTab } from '../../../services/tabs/IsChangedTab';
import {
  updateTabs,
  addCompanyCurrentStep,
} from '../../../store/actions/actions';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import tabs_list from '../../../data/tabsList';
import { useDispatch, useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../../../models/services/module.entreprise';
import { useEffect } from 'react';
import { initialState } from '../../../utils/addCompanyFormInitialState';
import useFormValidation from './useFormValidation';

const useHandleFormChanges = () => {
  const dispatch = useDispatch();
  const unsavedChangesData = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.unsavedChanges,
  );
  const addCompanyStep = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.addCompanyStep,
  );
  const open_tabs = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs?.tabs,
  );
  const { tabFormDirtyIndex, isDirty } = useFormValidation();
  const newFormData = isDirty
    ? unsavedChangesData[tabFormDirtyIndex].value.payload
    : initialState;
  const tabIndex = open_tabs.findIndex(
    (item) => item.name === tabs_list[3].name,
  );
  const isChangedTab = IsChangedTab({
    label: tabs_list[3].name,
    tab: open_tabs[tabIndex],
  });
  useEffect(() => {
    if (!compareObjects(initialState, newFormData) && !isChangedTab) {
      const updatedTabs = [...open_tabs];
      updatedTabs[tabIndex] = {
        ...updatedTabs[tabIndex],
        isChanged: true,
      };
      dispatch(updateTabs(updatedTabs));
    }
    dispatch(addCompanyCurrentStep(addCompanyStep));
  }, [newFormData, isChangedTab]);
};
export default useHandleFormChanges;
