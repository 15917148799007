import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../models/services/module.entreprise';
import { setAllEntrepriseWithEstablishementsAction } from '../store/actions/actions';

const useGetCompaniesLogic = () => {
  const dispatch = useDispatch();
  const companies = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.listCompaniesEstablishments,
  );
  
  useEffect(() => {
    dispatch(
      setAllEntrepriseWithEstablishementsAction({
        module: 'FioManagerMobile.USERS',
        paginationModel: {
          page: 1,
          pageSize: 1000,
        },
      }),
    );
  }, []);

  return {
    companies,
  };
};

export default useGetCompaniesLogic;
