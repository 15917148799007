import { languageData } from '../i18n';

export const RolesSectionList = [
  { id: '1', name: `${languageData.global.Entreprise}`, isActive: true },
  { id: '2', name: `${languageData.global.etablissement}`, isActive: false },
  { id: '3', name: `Roles et utilisateurs`, isActive: false },
  // {
  //   id: '3',
  //   name: `${languageData.global.FioManagerMobile}`,
  //   isActive: false,
  // },
];
