import * as actionTypes from '../actions/actionTypes';
interface Action {
  type: string;
  payload?: boolean;
}

const initialState = {
  combineEtablissement: false,
};

function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actionTypes.COMBINE_ETABLISSEMENT: {
      return {
        ...state,
        combineEtablissement: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
