import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/apiHandlers/catalogueApi';

import {
  allergenAction,
  badgeAction,
} from '../../../models/services/module.catalogue';
import { NotificationErrorProvider } from '../../../utils/Notificationprovider';
import {
  getAllergenFail,
  getAllergenSuccess,
  postAllergenSuccess,
} from '../../actions/catalogues/actions';
export function* startGetAllergens(action: allergenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.getAllergene();
    if (response.data) {
      yield put(getAllergenSuccess(response.data?.data?.result??[]));
      action._&& action._(response.data?.data?.result);
    }
  } catch (error: any) {
    yield put(getAllergenFail(error));
  }
}

export function* startPostAllergens(action: allergenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.postAllergene(
      action.allergen,
    );

    if (response.data) {
      yield put(postAllergenSuccess());
      action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
export function* startDeleteAllergens(action: allergenAction) {
  try {
    const response: AxiosResponse = yield catalogueApi.deleteAllergene(
      action.allergen.id,
    );
    if (response.data) {
      yield put(postAllergenSuccess());
      action._();
    }
  } catch (error: any) {
    NotificationErrorProvider(error);
  }
}
