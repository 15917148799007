import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IsTabFormDirty } from '../../../services/tabs/IsTabFormDirty';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import tabs_list from '../../../data/tabsList';

const useFormValidation = () => {
  const unsavedChangesData = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) => state.tabs.unsavedChanges,
  );
  let tabFormDirtyIndex = -1;
  let isDirty = false;
  useEffect(() => {
    if (!unsavedChangesData) return;
    tabFormDirtyIndex = IsTabFormDirty({
      array: unsavedChangesData,
      key: tabs_list[3].name,
    });
    isDirty = tabFormDirtyIndex !== -1;
  }, []);
  return {
    isDirty,
    tabFormDirtyIndex,
  };
};
export default useFormValidation;
