import { serviceEntrepriseModels } from '../models/services/module.entreprise';

export const initialState: serviceEntrepriseModels.Entreprise = {
  generalInformation: {
    company_name: '',
    commercial_Name: '',
    construction_date: null,
    legal_form_id: undefined,
    share_capital: '',
    mobile_telephone_number: '',
    fixed_line_telephone_number: '',
    email: '',
    address: '',
    country: '',
    city: '',
    postal_code: '',
  },
  financialInformation: {
    owner: '',
    owner_mobile_number: '',
    iban: '',
    swifT_BIC: '',
    card_number: 0,
    royaltiesInformation: [],
  },
  fiscalInformation: {
    sireN_code: '',
    naF_code_id: null,
    vaT_Intracommunity: '',
  },
};
export interface ErrorField {
  required: boolean;
  hasError: boolean;
  errorMsg: string;
}

export interface ValidationItem {
  required: boolean;
  hasError: boolean;
  errorMsg: string;
}

export interface FormErrorsState {
  generalInformation: {
    [key: string]: ValidationItem;
  };
  financialInformation: {
    [key: string]: ValidationItem | ValidationItem[];
  };
  fiscalInformation: {
    [key: string]: ValidationItem;
  };
}
export const formErrorsInitialState: FormErrorsState = {
  generalInformation: {
    company_name: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    commercial_Name: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    construction_date: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    legal_form_id: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    share_capital: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    mobile_telephone_number: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    fixed_line_telephone_number: {
      required: false,
      hasError: false,
      errorMsg: '',
    },
    email: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    address: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    country: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    city: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    postal_code: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
  },
  financialInformation: {
    owner: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    owner_mobile_number: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    iban: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    swifT_BIC: {
      required: false,
      hasError: false,
      errorMsg: '',
    },
    card_number: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    royaltiesInformation: [],
  },
  fiscalInformation: {
    sireN_code: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    naF_code_id: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
    vaT_Intracommunity: {
      required: true,
      hasError: false,
      errorMsg: '',
    },
  },
};
