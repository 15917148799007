import React, { ChangeEvent, useEffect, useState } from 'react';
import EditGreyIcon from '../../assets/icons/EditGreyIcon.svg';
import ErrorIconRed from '../../assets/icons/ErrorIconRed.svg';
import ClearInputGrey from '../../assets/icons/ClearInputGrey.svg';
import SubtractInputPrimary from '../../assets/icons/SubtractInputPrimary.svg';
import { onKeyUpWithoutSymbol } from '../../utils/catalogueHelper/inputs';

interface FieldNameProps {
  label?: string;
  type?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  showEdit?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  defaultValue?: string;
  max?: number;
  errorMsg?: string;
  showEditTools?: boolean;
  onEdit?: () => void;
  disabelEditModeProps?: boolean;
  requied?: boolean;
}

const FieldName: React.FC<FieldNameProps> = ({
  label,
  type,
  placeholder,
  name,
  id,
  showEdit,
  onChange,
  onClick,
  defaultValue,
  max,
  errorMsg,
  showEditTools,
  onEdit,
  disabelEditModeProps,
  requied,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [disabelEditMode, setdisabelEditMode] = useState(
    disabelEditModeProps ?? false,
  );
  useEffect(() => {
    setInputValue(defaultValue as string)
  }, [defaultValue]);
  const [showToolsEdit, setShowToolsEdit] = useState(false);
  const HandelEditMode = () => {
    if (!disabelEditMode && onEdit) {
      onEdit();
    }
    setdisabelEditMode((prevState) => !prevState);
  };

  const handleClearInput = () => {
    setInputValue('');
    if (onChange) {
      onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
    if (event.target.value != '') {
      setShowToolsEdit(true);
    } else setShowToolsEdit(false);
  };

  return (
    <div
      className={`FieldName w-full fl_col gp5 relative ${label ? '' : 'has-no-label'} ${disabelEditMode ? 'EditMode' : ''}`}
    >
      {label && (
        <label className="DarkBlueGray-txt p14 w-600 pd16-r-l" htmlFor={id}>
          {label}
          {requied && (
            <span
              style={{ color: '#e23439', backgroundColor: 'transparent' }}
              className="p12"
            >
               *
            </span>
          )}
        </label>
      )}
      <div className="fl_row fl-1 relative">
        {!disabelEditMode ? (
          <div className="field-wrapper fl_row ai_c fl-1">
            <input
              onKeyUp={onKeyUpWithoutSymbol}
              autoComplete="off"
              value={inputValue}
              className="EerieBlack p16 w-300 w-full pd16-r-l"
              maxLength={max ?? 50}
              type="text"
              placeholder={placeholder}
              name={name}
              id={id}
              onChange={handleInputChange}
              onClick={onClick}
              // disabled
            />

            <div className="tools default-field" onClick={HandelEditMode}>
              <img
                className="c-p fl_row"
                src={EditGreyIcon}
                alt=""
                width={15}
              />
            </div>
          </div>
        ) : (
          <>
            <input
              onKeyUp={onKeyUpWithoutSymbol}
              autoComplete="off"
              value={inputValue}
              className="EerieBlack p16 w-300 w-full pd16-r-l"
              maxLength={max ?? 25}
              type="text"
              placeholder={placeholder}
              name={name}
              id={id}
              onChange={handleInputChange}
              onClick={onClick}
            />
            <div className="tools fl_row gp10">
              {showToolsEdit && (
                <img
                  className="c-p"
                  src={ClearInputGrey}
                  alt=""
                  width={15}
                  onClick={handleClearInput}
                />
              )}
              <img
                className="c-p"
                src={SubtractInputPrimary}
                alt=""
                width={15}
                onClick={HandelEditMode}
              />
            </div>
          </>
        )}
      </div>
      {errorMsg && (
        <div className="fl_row gp5 ai_c errorMsg">
          <img src={ErrorIconRed} alt="" style={{ width: 15, height: 15 }} />
          <p className="p12 VenetianRed-text">{errorMsg}</p>
        </div>
      )}
    </div>
  );
};

export default FieldName;
