import React from 'react';
import { ToastProps } from '../../../components/Toast/Toast';
import ToastHandler from '../Toast/ToastHandler';
import FormWizzard from './FormWizzard/FormWizzard';
import useToast from './useToast';

export default function SelectedCompanyCreateEtablissement() {
  const toastRef = React.useRef<{
    openToast: (type: ToastProps['type'], title: string) => void;
    closeToast: () => void;
  }>(null);

  useToast(toastRef);

  return (
    <div className="h-full">
      <FormWizzard />
      <ToastHandler ref={toastRef} />
    </div>
  );
}
