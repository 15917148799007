import React, { ReactNode } from 'react';

interface SubscriptionInvoiceProps {
  title: string;
  tva: number;
  ht: number;
  children: ReactNode;
}

const SubscriptionInvoice: React.FC<SubscriptionInvoiceProps> = ({
  children,
  title,
  tva,
  ht,
}) => {
  return (
    <div className="SubscriptionInvoice gp8 fl_col">
      <div className="fl_row">
        <p className="p10 row-1 w-900">{title}</p>
        <p className="p10 row-2">{tva}%</p>
        <p className="p10 row-3">€{ht}</p>
      </div>
      <div className="fl_col gp2">{children}</div>
    </div>
  );
};

export default SubscriptionInvoice;
