import React from 'react';
import AddCompany from '../FormWizzard/AddCompany';

export default function NewCompanyCreateEtablissement() {
  return (
    <div className='h-full form-section'>
      <AddCompany />
    </div>
  );
}
