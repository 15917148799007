import { serviceEntrepriseModels } from '../models/services/module.entreprise';
import {
  setEditFormDataFinancial,
  unsavedChangesRegister,
} from '../store/actions/actions';
import tabs_list from '../data/tabsList';
import { Dispatch } from 'redux';

interface DeleteRedevanceProps<T> {
  id: string;
  newFormData: T;
  dispatch: Dispatch;
  key: number;
}
interface DeleteRedevanceDetailsProps<T> {
  formData: T;
  dispatch: Dispatch;
  id: string;
}
export const DeleteRedevance = <T extends {payload: {financialInformation?: {royaltiesInformation?: serviceEntrepriseModels.royaltiesInformation[];};};
  },
>(
  props: DeleteRedevanceProps<T>,
) => {
  if (props.newFormData?.payload?.financialInformation?.royaltiesInformation) {
    const updatedRoyalties =
      props.newFormData?.payload?.financialInformation?.royaltiesInformation?.filter(
        (item: serviceEntrepriseModels.royaltiesInformation) =>
          item.key !== props.id,
      );

    const updatedFormData = {
      payload: {
        ...props.newFormData?.payload,
        financialInformation: {
          ...props.newFormData?.payload?.financialInformation,
          royaltiesInformation: updatedRoyalties,
        },
      },
    };
    
    const array = [
      {
        key: tabs_list[props.key].name,
        value: { type: 'action', payload: updatedFormData },
      },
    ];
    props.dispatch(unsavedChangesRegister(array));
  } else {
    console.log('Invalid royaltiesInformation array');
  }
};
export const DeleteRedevanceDetails = <
  T extends serviceEntrepriseModels.formDataFinancialInfosTypeActionPayload,
>(
  props: DeleteRedevanceDetailsProps<T>,
) => {
  if (props.formData.royalties) {
    const updatedRoyalties = props.formData.royalties.filter(
      (item) => item.key !== props.id,
    );

    const updatedFormData: T = {
      ...props.formData,
      royalties: updatedRoyalties,
    };
    props.dispatch(setEditFormDataFinancial(updatedFormData));
  } else {
    console.log('Invalid royaltiesInformation array');
    // Handle invalid royaltiesInformation array
  }
};
